import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import { useCallback, useMemo, useRef, useState } from 'react';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { CellValueChangedEvent, GetRowIdFunc, GetRowIdParams, GridOptions, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { isNil, isNumber } from 'lodash-es';
import { campaignService } from '@/modules/optimizer/api/campaign/campaign-service';
import { toast } from 'react-toastify';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import AddIcon from '@mui/icons-material/Add';
import { Button, Popover } from '@mui/material';
import { CreateGroup } from '@/modules/optimizer/components/optimization-groups-popover/CreateGroup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ButtonCallbackCellRenderer, { IButtonCallbackCellRendererParams } from '@/components/grid/cells/ButtonCallbackCellRenderer';
import { useDeleteConfirmation } from '@/components/modals/delete-confirmation-modal/useDeleteConfirmationModal';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { useTranslation } from '@/lib';
import { sleep } from '@/lib/api/api-utils';

const CampaignGroupsTable = () => {
  const { activeTeam, activeProfile, CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE } = useActiveTeamContext();
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const { data: rowData, isLoading } = useQuery({
    queryKey: CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE,
    queryFn: async () => {
      const result = await campaignService.getGroups();

      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading groups');
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  // CREATE GROUP
  const createButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isCreatePopoverOpen, setIsCreatePopoverOpen] = useState(false); // state to control the popover
  const onCreateClicked = () => {
    setIsCreatePopoverOpen((previousValue) => !previousValue);
  };

  const handleCreatePopoverClose = () => {
    console.log('create close');
    setIsCreatePopoverOpen((previousValue) => !previousValue);
  };

  const onCreateConfirmed = (newGroup: CampaignGroupModel) => {
    addNewRowToCampaignGroups(newGroup);
  };

  const addNewRowToCampaignGroups = (newRow: CampaignGroupModel) => {
    queryClient.setQueryData<CampaignGroupModel[]>(CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE, (oldData) => {
      return oldData ? [...oldData, newRow] : [newRow];
    });
  };

  // UPDATE GROUP
  const { mutate: updateGroup } = useMutation({
    mutationFn: (groups: CampaignGroupModel[]) => campaignService.updateGroups(groups),
    onMutate: () => {
      console.log('Updating group');
    },
    onSuccess: (data, variables) => {
      if (isNil(data?.payload) || data.httpResponseCode !== 200) {
        toast.error(`Did not receive a response from server: ${data.message}`);
      } else {
        toast.success(`Campaign group '${variables[0].name}' updated successfully`);

        updateCampaignGroups(variables);
      }
    },
    onError: (error) => {
      console.error('error:', error);
      toast.error(`Update failed: ${error}`);
    },
  });

  const updateCampaignGroups = (updatedGroups: CampaignGroupModel[]) => {
    queryClient.setQueryData<CampaignGroupModel[]>(CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE, (oldData) => {
      if (!oldData) return [];

      return oldData.map((group) => updatedGroups.find((updatedGroup) => updatedGroup.id === group.id) ?? group);
    });
  };

  const onCellValueChanged = useCallback((event: CellValueChangedEvent<CampaignGroupModel>) => {
    updateGroup([
      {
        id: event.data.id,
        name: event.data.name,
        description: event.data.description,
        tacos: event.data.tacos,
        preset: event.data.preset as OptimizationPreset,
        bidCeiling: event.data.bidCeiling,
        totalCampaigns: event.data.totalCampaigns,
      },
    ]);
  }, []);

  // DELETE GROUP
  const onDeleteClicked = (params: CampaignGroupModel) => {
    setDeletePendingGroupId(params.id ?? null);
    openDeleteConfirmationModal();
  };

  const [deletePendingGroupId, setDeletePendingGroupId] = useState<number | null>(null);

  const onDeleteConfirmed = async () => {
    if (deletePendingGroupId) deleteGroup([deletePendingGroupId]);
    await sleep(200); // wait for the popover close animation to finish, otherwise name will flash 'undefined'
    setDeletePendingGroupId(null);
    //TODO: isn't set to null when modal is just closed without a cancel - is that a problem? Add onCancel?
  };

  const { mutate: deleteGroup, isPending: isLoadingDeleteGroup } = useMutation({
    mutationFn: (groupIds: number[]) => campaignService.deleteGroups(groupIds),
    onMutate: () => {
      console.log('Removing group');
    },
    onSuccess: (data, variables) => {
      if (isNil(data?.payload) || data.httpResponseCode !== 200) {
        toast.error(`Did not receive a response from server: ${data.message}`);
      } else {
        deleteCampaignGroups(variables);
        toast.success(`Campaign group deleted successfully`);
      }
    },
    onError: (error) => {
      console.error('error:', error);
      toast.error(`Deleting group failed: ${error}`);
    },
  });

  const deleteCampaignGroups = (groupIdsToDelete: number[]) => {
    queryClient.setQueryData<CampaignGroupModel[]>(CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE, (oldData) => {
      if (!oldData) return [];

      // Filter out the groups with IDs that are in the groupIdsToDelete array
      return oldData.filter((group) => !groupIdsToDelete.includes(group.id));
    });
  };

  const deletePendingGroup = rowData?.find((data) => data.id === deletePendingGroupId);
  const deleteConfirmationHeaderText = `Delete group '${deletePendingGroup?.name}'?`;
  const deleteConfirmationQuestion =
    deletePendingGroup && deletePendingGroup?.totalCampaigns > 0
      ? `This group contains ${deletePendingGroup?.totalCampaigns} campaigns which will have their group unassigned.`
      : 'This group does contain any campaigns.';
  const { ModalComponent: DeleteConfirmationModal, handleOpenModal: openDeleteConfirmationModal } = useDeleteConfirmation(
    deleteConfirmationQuestion,
    deleteConfirmationHeaderText,
    onDeleteConfirmed,
  );

  const columnDefs: ColDefOrGroup<CampaignGroupModel>[] = [
    {
      colId: ColumnId.GROUP_NAME,
      headerName: 'Name',
      field: 'name',
      editable: true,
      valueParser: (params) => {
        const newName = params.newValue.trim();
        const allRows = rowData ?? [];

        // Check if any row has the same name, excluding the current row
        const isNameUnique = !allRows.some((group) => group.id !== params.data.id && group.name.toLowerCase() === newName.toLowerCase());

        if (!isNameUnique) {
          toast.error('A group with that name already exists');
          return params.oldValue; // Reject the change
        }

        return newName; // Accept the change
      },
    },

    {
      colId: ColumnId.GROUP_DESCRIPTION,
      headerName: 'Description',
      field: 'description',
      editable: true,
    },
    {
      colId: ColumnId.GROUP_TACOS,
      headerName: 'TACOS',
      field: 'tacos',
      editable: true,
      valueFormatter: (params: ValueFormatterParams<CampaignGroupModel>) => {
        // valueGetter already multiples by 100, so only need to show %
        return params.value ? parseFloat(params.value.toFixed(2)) + '%' : '';
      },
      // Get the value for editing, as a whole number percentage
      valueGetter: (params: ValueGetterParams<CampaignGroupModel>) => {
        // Under the hood the value is in fraction, but for display and edit display we want to show %
        return params.data && isNumber(params.data.tacos) ? parseFloat(params.data.tacos.toFixed(2)) * 100 : '';
      },
      // Parse the edited percentage back to a fraction
      valueParser: (params) => {
        try {
          const newValue = parseFloat(params.newValue) / 100;
          if (newValue > 0) {
            return newValue;
          }
        } catch (e) {
          // Ignore
        }

        toast.error('Invalid TACOS value entered');
        console.log('Invalid TACOS value entered: ', params.newValue);

        // Revert to old when invalid
        return params.oldValue;
      },
      cellEditorParams: {
        type: 'number', // Use HTML5 number input which allows only numbers - doesn't work in most cases
        step: '1', // Increment values by 1 on up/down arrows
      },
    },

    {
      colId: ColumnId.GROUP_PRESET,
      headerName: 'Prioritization',
      field: 'preset',
      cellEditor: 'agSelectCellEditor',
      editable: true,
      cellEditorParams: {
        values: [OptimizationPreset.BALANCED, OptimizationPreset.INCREASE_SALES, OptimizationPreset.REDUCE_ACOS],
      },
      valueFormatter: (params) => t(`optimizer_page.optimization_presets.${params.value}`),
    },
    // {
    //   colId: ColumnId.GROUP_BID_CEILING,
    //   headerName: 'Bid Ceiling',
    //   field: 'bidCeiling',
    //   editable: true,
    // },
    {
      colId: ColumnId.CAMPAIGN_GROUP_COUNT,
      headerName: 'Campaigns In Group',
      field: 'totalCampaigns',
    },
    {
      colId: ColumnId.CAMPAIGN_GROUP_DELETE,
      headerName: 'Delete group',
      cellRenderer: ButtonCallbackCellRenderer,
      cellRendererParams: (): IButtonCallbackCellRendererParams<CampaignGroupModel> => {
        return {
          buttonText: 'Delete',
          callback: onDeleteClicked,
          color: 'error',
          variant: 'outlined',
          tooltip: 'Delete this group',
          isDisabled: isLoadingDeleteGroup,
        };
      },
    },
  ];

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams<CampaignGroupModel>) => params.data.id.toString();
  }, [rowData]);

  const customGridOptions: GridOptions<CampaignGroupModel> = {
    ...DEFAULT_GRID_OPTIONS,
    defaultColDef: {
      resizable: true,
      sortable: true,
      minWidth: 100,
    },
  };

  return (
    <>
      <div className="flex flex-row mb-4">
        <Button ref={createButtonRef} size="medium" onClick={onCreateClicked} variant="contained" startIcon={<AddIcon />}>
          Create New Group
        </Button>

        <span className="ml-4 flex items-center gap-2">
          <InfoOutlinedIcon />
          Double click on a cell to edit
        </span>
      </div>
      <div style={{ height: `calc(100vh - 140px)` }}>
        <AlGrid
          colDefs={columnDefs}
          rowData={rowData}
          gridOptions={customGridOptions}
          getRowId={getRowId}
          isLoading={isLoading}
          onCellValueChanged={onCellValueChanged}
        />
      </div>
      <Popover
        id={'create-group-popover'}
        open={isCreatePopoverOpen}
        anchorEl={createButtonRef.current}
        onClose={handleCreatePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{ paper: { style: { maxWidth: 500 } } }}
      >
        <CreateGroup existingGroups={rowData ?? []} onClose={handleCreatePopoverClose} onCreateConfirmed={onCreateConfirmed} />
      </Popover>
      {DeleteConfirmationModal}
    </>
  );
};

export default CampaignGroupsTable;
