import { FunctionComponent } from 'react';
import { METRICS } from './MetricsConfig';
import { Card, CardActionArea, CardContent, Tooltip, Typography } from '@mui/material';
import './Metrics.css';
import useFormatting from '@/hooks/useFormatting';
import { getColorClassBySentimentCurrentPrevious } from '@/types/colors.enum';
import { MetricField } from './models/CommonMetricsModel';
interface MetricProps {
  metricField: MetricField;
  isSelected: boolean;
  selectionChanged: (changedMetric: MetricField) => void;
  isDisabled: boolean;
  tooltipText: string;
  currentValue: number;
  previousValue: number;
  color: string | undefined;
}

const Metric: FunctionComponent<MetricProps> = ({
  metricField,
  isSelected,
  selectionChanged,
  isDisabled,
  tooltipText,
  currentValue,
  previousValue,
  color,
}) => {
  const { formatChangeInUnsignedPercentWithArrow, getShortFormatterForMetricField } = useFormatting();

  const handleCardClick = () => {
    if (isDisabled) return;
    selectionChanged(metricField);
  };

  const config = METRICS[metricField];

  const colorClass = config ? getColorClassBySentimentCurrentPrevious(config?.sentimentDirection, currentValue, previousValue) : '';
  const formattedChange = formatChangeInUnsignedPercentWithArrow(currentValue, previousValue);

  const valueFormatter = (value: number | undefined): string => {
    if (!config?.key) return '';
    return getShortFormatterForMetricField(config?.key)(value);
  };

  return (
    <Card sx={{ minWidth: 145, borderRadius: '12px' }} onClick={handleCardClick}>
      <Tooltip title={tooltipText}>
        <CardActionArea disableRipple={isDisabled} disableTouchRipple={isDisabled}>
          <CardContent className={`${isDisabled ? 'disabled-opacity-reduce' : ''}`}>
            <Typography className="text-sm font-semibold opacity-90">{config?.title}</Typography>
            <Typography className="mt-2 text-2xl font-semibold">{valueFormatter(currentValue)}</Typography>
            <Typography className="whitespace-no-wrap flex justify-between text-sm font-medium">
              <span className="self-start font-semibold opacity-90">{valueFormatter(previousValue)}</span>
              <span className={`self-end ${colorClass}`}>{formattedChange}</span>
            </Typography>
          </CardContent>
          <div style={{ backgroundColor: isSelected && color ? color : '', height: 6 }} />
        </CardActionArea>
      </Tooltip>
    </Card>
  );
};

export default Metric;
