import { FunctionComponent, useContext, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridOptions } from 'ag-grid-enterprise';
import { ColorModeContext } from '@/modules/application';
import { Skeleton } from '@mui/material';
import { CellValueChangedEvent, GetRowIdFunc, GridReadyEvent, RowValueChangedEvent } from 'ag-grid-community';
import { v4 } from 'uuid';

export interface AlGridProps {
  colDefs: ColDef[];
  rowData?: unknown[];
  gridOptions?: GridOptions;
  onGridReadyCallback?: (params: GridReadyEvent) => void;
  isLoading?: boolean;
  deselectAll?: boolean;
  getRowId?: GetRowIdFunc;
  onCellValueChanged?: (event: CellValueChangedEvent) => void;
  onRowValueChanged?: (event: RowValueChangedEvent) => void;
}

export const DEFAULT_GRID_OPTIONS: GridOptions = {
  scrollbarWidth: 15,
  multiSortKey: 'ctrl',
  defaultColDef: {
    headerClass: 'text-only-header',
    resizable: true,
    sortable: true,
    cellClass: [],
  },
  ///popupParent: document.body,
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          // suppressColumnFilter: true,
          // suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  },
};

const AlGrid: FunctionComponent<AlGridProps> = ({
  colDefs,
  rowData,
  gridOptions,
  onGridReadyCallback,
  isLoading = false,
  deselectAll = false,
  getRowId,
  onCellValueChanged,
  onRowValueChanged,
}) => {
  const gridRef = useRef<AgGridReact | null>(null);
  const { mode } = useContext(ColorModeContext);
  const gridUuid = useRef(v4()).current;

  if (gridOptions) {
    gridOptions.gridId = gridUuid;
  }

  useEffect(() => {
    if (deselectAll && gridRef.current) {
      const { api } = gridRef.current;
      if (api) {
        api.deselectAll();
      }
    }
  }, [deselectAll]);

  const onGridReady = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
    if (onGridReadyCallback) {
      onGridReadyCallback(params);
    }
  };

  return (
    <div className="flex h-full flex-1 flex-shrink-0 flex-grow flex-row ">
      <div className={`${mode === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'} h-full w-full overflow-hidden`}>
        {isLoading ? (
          <div className=" flex animate-fadeInBg flex-col gap-y-2">
            {Array(12)
              .fill(1)
              .map((val, i) => (
                <Skeleton key={i} width={'full'} height={41} />
              ))}
          </div>
        ) : (
          <AgGridReact
            ref={gridRef}
            key={gridUuid}
            gridId={gridUuid}
            columnDefs={colDefs}
            rowData={rowData}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            getRowId={getRowId}
            onCellValueChanged={onCellValueChanged}
            onRowValueChanged={onRowValueChanged}
          />
        )}
      </div>
    </div>
  );
};

export default AlGrid;
