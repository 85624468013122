import { FunctionComponent } from 'react';
import { MetricField } from './models/CommonMetricsModel';
import Metric from './Metric';
import { MetricsModel } from './models/MetricsModel';

interface MetricsListProps {
  visibleMetrics: MetricField[];
  selectedMetrics: MetricField[];
  selectedMetricColors: { key: MetricField; color: string }[];
  metricValues: MetricsModel[] | undefined;
  onSingleSelectionChanged: (changedMetric: MetricField) => void;
}

const MetricsList: FunctionComponent<MetricsListProps> = ({
  visibleMetrics,
  selectedMetrics,
  selectedMetricColors,
  metricValues,
  onSingleSelectionChanged,
}) => {
  const MAX_METRICS_CAN_SELECT = 5;

  const generateTooltipText = (isSelected: boolean, selectedMetricsLength: number, maxMetrics: number): string => {
    const actionText = isSelected
      ? 'Remove from timeline'
      : selectedMetrics.length >= MAX_METRICS_CAN_SELECT
      ? 'Timeline full'
      : 'Add to timeline';

    return `${actionText} (${selectedMetricsLength}/${maxMetrics} selected)`;
  };

  return visibleMetrics.map((visibleMetric) => {
    const isSelected = selectedMetrics.includes(visibleMetric);
    const isDisabled = !isSelected && selectedMetrics.length >= MAX_METRICS_CAN_SELECT;
    const tooltipText = generateTooltipText(isSelected, selectedMetrics.length, MAX_METRICS_CAN_SELECT);
    const metricValue = metricValues ? metricValues.find((mv) => mv.key === visibleMetric) : undefined;

    return (
      <Metric
        key={visibleMetric}
        metricField={visibleMetric}
        currentValue={metricValue ? metricValue.current : 0}
        previousValue={metricValue ? metricValue.previous : 0}
        isSelected={isSelected}
        selectionChanged={onSingleSelectionChanged}
        isDisabled={isDisabled}
        tooltipText={tooltipText}
        color={selectedMetricColors.find((color) => color.key === visibleMetric)?.color}
      />
    );
  });
};

export default MetricsList;
