import { Autocomplete, Button, DialogActions, DialogContent, DialogTitle, ListItem, TextField } from '@mui/material';
import { FunctionComponent, forwardRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotionRounded';
import { ChangeCampaignGroupDTO } from '../../api/campaign/campaign-contracts';
import { CampaignGroupModel } from '../../api/campaign/models/CampaignGroupModel';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Check } from '@mui/icons-material';

interface UpdateGroupsProps {
  selectedCampaigns: number[];
  existingGroups: CampaignGroupModel[];
  onClose: () => void;
  onCreateClicked: () => void;
  applyChanges: (changeGroups: ChangeCampaignGroupDTO[]) => void;
  isApplyPending: boolean;
}

export const UpdateGroups: FunctionComponent<UpdateGroupsProps> = ({
  selectedCampaigns,
  existingGroups,
  onClose,
  onCreateClicked,
  applyChanges,
  isApplyPending,
}) => {
  const [selectedOptimizationGroupId, setSelectedOptimizationGroupId] = useState<number>(
    existingGroups.length > 0 ? existingGroups[0].id : 0,
  );
  //TODO const { activeTeam, activeProfile } = useActiveTeamContext();
  const navigate = useNavigate();

  function onApplyClicked() {
    console.log('Apply clicked', selectedOptimizationGroupId);
    onClose();

    const changeGroups: ChangeCampaignGroupDTO[] = selectedCampaigns.map((campaignId) => ({
      campaign_id: campaignId,
      group_id: selectedOptimizationGroupId,
    }));

    applyChanges(changeGroups);
  }
  // TODO: remove groups like select all button

  function onManageGroupsClicked() {
    navigate('/app/campaign-groups');
  }

  function handleRemoveAll() {
    console.log('Remove all clicked');
    console.log('Apply clicked', selectedOptimizationGroupId);
    onClose();

    const changeGroups: ChangeCampaignGroupDTO[] = selectedCampaigns.map((campaignId) => ({
      campaign_id: campaignId,
      group_id: 0,
    }));

    applyChanges(changeGroups);
  }

  const RemoveButtonListboxComponent = forwardRef<HTMLUListElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
    return (
      <ul ref={ref} {...props}>
        <ListItem>
          <Button variant="text" onClick={handleRemoveAll}>
            {'Remove groups from selected campaigns'}
          </Button>
        </ListItem>
        {props.children}
      </ul>
    );
  });

  return (
    <>
      <DialogTitle>Change Optimization Groups</DialogTitle>
      <DialogContent>
        {existingGroups && existingGroups.length > 0 && (
          <Autocomplete
            id="select-campaign-groups"
            defaultValue={existingGroups[0]}
            onChange={(event, newValue) => {
              setSelectedOptimizationGroupId(newValue ? newValue.id : 0);
            }}
            options={existingGroups}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Select an Optimization Group" />}
            ListboxComponent={RemoveButtonListboxComponent}
          />
        )}
        <div className="flex flex-row justify-between">
          <Button autoFocus variant="text" startIcon={<AddIcon />} onClick={onCreateClicked}>
            Create Group
          </Button>
          <Button variant="text" startIcon={<AutoAwesomeMotionIcon />} onClick={onManageGroupsClicked}>
            Manage Groups
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>

        <LoadingButton
          onClick={onApplyClicked}
          loading={isApplyPending}
          loadingPosition="start"
          startIcon={<Check />}
          variant="contained"
          disabled={selectedCampaigns.length == 0 || existingGroups.length == 0}
        >
          <span>
            {selectedCampaigns.length > 0
              ? `Assign Group to ${selectedCampaigns.length} Campaign` + (selectedCampaigns.length > 1 ? 's' : '')
              : 'No Campaigns Selected'}
          </span>
        </LoadingButton>
      </DialogActions>
    </>
  );
};
