import { OptimizationResponse } from '../api/optimization-contracts';
import { AdGroupDebugRow } from './AdGroupDebugRow';
import { CampaignDebugRow } from './CampaignDebugRow';
import { PreviewDataRow } from './PreviewDataRow';

export enum BiddingEntity {
  PLACEMENT_TOP = 'PLACEMENT_TOP',
  PLACEMENT_PRODUCT_PAGE = 'PLACEMENT_PRODUCT_PAGE',
  PLACEMENT_REST_OF_SEARCH = 'PLACEMENT_REST_OF_SEARCH',
  KEYWORD = 'KEYWORD',
  PRODUCT_TARGET = 'PRODUCT_TARGET',
}
const percentEntities = [BiddingEntity.PLACEMENT_PRODUCT_PAGE, BiddingEntity.PLACEMENT_REST_OF_SEARCH, BiddingEntity.PLACEMENT_TOP];
export const isPercentEntity = (entity: BiddingEntity): boolean => percentEntities.includes(entity);

const currencyEntities = [BiddingEntity.KEYWORD, BiddingEntity.PRODUCT_TARGET];
export const isCurrencyEntity = (entity: BiddingEntity): boolean => currencyEntities.includes(entity);

export enum OptimizationModelFields {
  PREVIEW_DATA = 'previewData',
  AD_GROUP_DEBUG_DATA = 'adGroupDebugData',
  CAMPAIGN_DEBUG_DATA = 'campaignDebugData',
  CAMPAIGN_GROUP_DEBUG_DATA = 'campaignGroupDebugData',
  PROFILE_DEBUG_DATA = 'profileDebugData',
}

type OptimizationModelType = number | PreviewDataRow[] | AdGroupDebugRow[] | CampaignDebugRow[] | CampaignDebugRow;

export class OptimizationModel implements Record<OptimizationModelFields, OptimizationModelType> {
  public jobId: number;
  public previewData: PreviewDataRow[];
  public adGroupDebugData: AdGroupDebugRow[];
  public campaignDebugData: CampaignDebugRow[];
  public campaignGroupDebugData: CampaignDebugRow[];
  public profileDebugData: CampaignDebugRow;

  constructor(args: OptimizationCreateArguments) {
    this.jobId = args.jobId;
    this.previewData = args.previewData;
    this.adGroupDebugData = args.adGroupDebugData;
    this.campaignDebugData = args.campaignDebugData;
    this.campaignGroupDebugData = args.campaignGroupDebugData;
    this.profileDebugData = args.profileDebugData;
  }

  public static fromResponse(response: OptimizationResponse): OptimizationModel {
    const jobId = response.job_id;
    const previewData = PreviewDataRow.fromResponseArray(response.preview_data);
    const adGroupDebugData = AdGroupDebugRow.fromResponseArray(response.debug_data);
    const campaignDebugData = CampaignDebugRow.fromResponseArray(response.campaign_debug_data);
    const campaignGroupDebugData = CampaignDebugRow.fromResponseArray(response.campaign_group_debug_data);
    const profileDebugData = CampaignDebugRow.fromResponse(response.profile_debug_data);

    return new OptimizationModel({
      jobId,
      previewData,
      adGroupDebugData,
      campaignDebugData,
      campaignGroupDebugData,
      profileDebugData,
    });
  }
}

interface OptimizationCreateArguments {
  jobId: number;
  previewData: PreviewDataRow[];
  adGroupDebugData: AdGroupDebugRow[];
  campaignDebugData: CampaignDebugRow[];
  campaignGroupDebugData: CampaignDebugRow[];
  profileDebugData: CampaignDebugRow;
}
