import { FunctionComponent, PropsWithChildren } from 'react';

interface OverlayBarProps extends PropsWithChildren {
  isExpanded?: boolean; // New prop to control expansion
  isFullPageWidth?: boolean; // New prop to control width
}

const OverlayBar: FunctionComponent<OverlayBarProps> = ({ children, isExpanded, isFullPageWidth }) => {
  const baseOverlayBarStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
    paddingLeft: isFullPageWidth ? '40px' : '100px',
    zIndex: 3,
    paddingRight: '40px',
  };

  const expandedOverlayBarStyle: React.CSSProperties = {
    height: '300px', // Define the height you want when expanded
  };

  const overlayBarStyle = isExpanded ? { ...baseOverlayBarStyle, ...expandedOverlayBarStyle } : baseOverlayBarStyle;

  return (
    <div style={overlayBarStyle} className="bg-gray-100 dark:bg-gray-700">
      {children}
    </div>
  );
};

export default OverlayBar;
