import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { DATE_FORMAT } from './filter-builder/FiltersConfig';
import { useTemplateContext } from '../contexts/TemplateContext';
import { FilterKey } from './filter-builder/types/FilterKey';

const OptimizationRangeDisplay: FunctionComponent = () => {
  const { filters } = useTemplateContext();
  const optimizationDateRange = filters.find((filterItem) => filterItem.key === FilterKey.DATE)?.conditions;
  const comparisonDateRange = filters.find((filterItem) => filterItem.key === FilterKey.COMPARE_DATE)?.conditions;

  return (
    <div className="flex w-full">
      <div className="flex flex-col">
        {optimizationDateRange && optimizationDateRange.length > 0 ? (
          <div className="flex font-bold ">
            {/* <div className="text-xs">From</div> */}
            <div className="">{dayjs(optimizationDateRange[0].values[0], DATE_FORMAT).format('D MMM')}</div>
            <div>
              {dayjs(optimizationDateRange[0].values[0], DATE_FORMAT).isSame(dayjs(optimizationDateRange[1].values[0], DATE_FORMAT), 'year')
                ? ''
                : ', ' + dayjs(optimizationDateRange[0].values[0], DATE_FORMAT).format('YYYY')}
            </div>
            <div className=" px-1">-</div>
            <div className="">{dayjs(optimizationDateRange[1].values[0], DATE_FORMAT).format('D MMM, YYYY')}</div>
          </div>
        ) : (
          <div className="flex ">
            <div className="text-sm">-</div>
          </div>
        )}
        {comparisonDateRange && comparisonDateRange.length > 0 ? (
          <div className="flex items-center text-xs opacity-70">
            <div className="">{dayjs(comparisonDateRange[0].values[0], DATE_FORMAT).format('D MMM')}</div>
            <div>
              {dayjs(comparisonDateRange[0].values[0], DATE_FORMAT).isSame(dayjs(comparisonDateRange[1].values[0], DATE_FORMAT), 'year')
                ? ''
                : ', ' + dayjs(comparisonDateRange[0].values[0], DATE_FORMAT).format('YYYY')}
            </div>
            <div className=" px-1">-</div>
            <div className="">{dayjs(comparisonDateRange[1].values[0], DATE_FORMAT).format('D MMM, YYYY')}</div>
          </div>
        ) : (
          <div className="flex ">
            <div className="text-sm">-</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OptimizationRangeDisplay;
