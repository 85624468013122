import { ICellRendererParams } from 'ag-grid-enterprise';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen as faPenSolid } from '@fortawesome/pro-solid-svg-icons';
import { faPen as faPenRegular } from '@fortawesome/pro-light-svg-icons';

import { Tooltip } from '@mui/material';
import { Button } from '@mui/base';
import { FunctionComponent } from 'react';

export interface IEditiableCellRendererParams extends ICellRendererParams {
  originalValue: string | number;
  valueFormatter: (value: string | number) => string;
}

const EditableCellRenderer: FunctionComponent<IEditiableCellRendererParams> = (props) => {
  const { value, node, column, api, originalValue, valueFormatter } = props;

  const isEdited = originalValue !== value;

  const makeCellEditable = () => {
    if (node.rowIndex !== null && column) {
      const colKey = column.getColDef().field;
      if (colKey) {
        api.startEditingCell({
          rowIndex: node.rowIndex,
          colKey: colKey,
        });
      }
    }
  };

  const content = (
    <div className={`flex justify-end font-semibold ${isEdited ? '  text-primary-600' : ''}`}>
      {valueFormatter(value)}
      <Button onClick={makeCellEditable} className="ml-2 cursor-pointer bg-transparent">
        {isEdited ? (
          <FontAwesomeIcon icon={faPenSolid} className="text-primary-800" />
        ) : (
          <Tooltip title="Edit">
            <FontAwesomeIcon icon={faPenRegular} />
          </Tooltip>
        )}
      </Button>
    </div>
  );

  return isEdited ? (
    <Tooltip className="flex justify-end" title={`Original value: ${valueFormatter(originalValue)}`}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export default EditableCellRenderer;
