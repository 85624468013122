import { useState, useEffect } from 'react';

// Interfaces
interface WizardUser {
  email: string;
  userId: number;
  firebaseId: string;
}

interface WizardProfile {
  profileId: number;
}

interface RegistrationState {
  user: WizardUser | null;
  teamName: string;
  teamId: number;
  profiles: WizardProfile[];
}

const LOCAL_STORAGE_KEY = 'registrationState';

const DEFAULT_STATE: RegistrationState = {
  user: null,
  teamName: '',
  teamId: 0,
  profiles: [],
};

// Hook
export const useRegistration = () => {
  const [state, setState] = useState<RegistrationState>(DEFAULT_STATE);

  // Load state from local storage
  useEffect(() => {
    const storedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedState) {
      setState(JSON.parse(storedState));
    }
  }, []);

  // Save state to local storage whenever it changes
  useEffect(() => {
    // Save only if state is different from default
    if (JSON.stringify(state) != JSON.stringify(DEFAULT_STATE)) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    }
  }, [JSON.stringify(state)]);

  const setUser = (user: WizardUser) => {
    setState((prevState) => ({ ...prevState, user }));
  };

  const setTeamName = (teamName: string) => {
    setState((prevState) => ({ ...prevState, teamName }));
  };

  const setTeamId = (teamId: number) => {
    setState((prevState) => ({ ...prevState, teamId }));
  };

  const addProfile = (profile: WizardProfile) => {
    setState((prevState) => ({ ...prevState, profiles: [...prevState.profiles, profile] }));
  };

  const loadStateFromStorage = () => {
    const storedState = localStorage.getItem('registrationState');
    if (storedState) {
      setState(JSON.parse(storedState));
    }
  };

  return {
    state,
    setUser,
    teamName: state.teamName,
    teamId: state.teamId,
    firebaseId: state.user?.firebaseId,
    userId: state.user?.userId,
    setTeamName,
    setTeamId,

    addProfile,
    loadStateFromStorage,
  };
};
