import { FunctionComponent, useState } from 'react';
import OverlayBar from './OverlayBar';
import { OptimizationParams, OptimizationPreset, availableOptimizationPresets } from './optimization/OptimizerConfig';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FontAwesomeSvgIcon } from '@/components/icons/FontAwesomeSvgIcon';
import { TextLabel } from '@/components/typography/TextLabel';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { t } from 'i18next';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';

interface OptimizationParamsBarProps {
  optimizationParams: OptimizationParams;
  onOptimizationParamsChanged: (optimizationParams: OptimizationParams) => void;
  selectedCampaignsCount: number;
  totalCampaignsCount: number;
  onPreviewOptimizationsClicked: () => void;
  onDiscardClicked: () => void;
}

const MAX_OPTIMIZATION_TACOS = 1000;

const OptimizationParamsBar: FunctionComponent<OptimizationParamsBarProps> = ({
  optimizationParams,
  onOptimizationParamsChanged,
  selectedCampaignsCount,
  totalCampaignsCount,
  onPreviewOptimizationsClicked,
  onDiscardClicked,
}) => {
  const handleOptimizationParamsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;

    if (type === 'checkbox') {
      onOptimizationParamsChanged({
        ...optimizationParams,
        [name]: checked,
      });
    } else {
      onOptimizationParamsChanged({
        ...optimizationParams,
        [name]: value,
      });
    }
  };

  const isTacosValid = !(
    isNaN(optimizationParams.tacos) ||
    optimizationParams.tacos <= 0 ||
    optimizationParams.tacos > MAX_OPTIMIZATION_TACOS
  );

  const handlePresetChange = (event: SelectChangeEvent<OptimizationPreset>) => {
    onOptimizationParamsChanged({
      ...optimizationParams,
      selectedPreset: event.target.value as OptimizationPreset,
    });
  };

  const [showAdvanced, setShowAdvanced] = useState(false);

  const toggleAdvanced = () => {
    setShowAdvanced((prev) => {
      return !prev;
    });
  };

  return (
    <>
      {selectedCampaignsCount > 0 && (
        <OverlayBar isExpanded={showAdvanced}>
          <div className="flex-grow">
            <div className="flex items-center justify-between">
              <div className="">
                <TextLabel>Selection</TextLabel>
                <Typography className="my-3 font-semibold">
                  {selectedCampaignsCount} of {totalCampaignsCount} Campaigns
                </Typography>
              </div>
              <div className="flex gap-8">
                <FormControl>
                  <TextLabel>Target ACOS %</TextLabel>
                  <TextField
                    type="number"
                    onChange={handleOptimizationParamsChange}
                    name="tacos"
                    defaultValue={optimizationParams.tacos}
                    helperText={!isTacosValid ? `Enter a valid number (1-${MAX_OPTIMIZATION_TACOS})` : ''}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    className="w-32"
                  />
                </FormControl>

                <FormControl>
                  <TextLabel>{t(`optimizer_page.labels.optimization_preset`)}</TextLabel>
                  <Select value={optimizationParams.selectedPreset} onChange={handlePresetChange} className="mt-2">
                    {availableOptimizationPresets.map((preset) => (
                      <MenuItem key={preset} value={preset}>
                        {t(`optimizer_page.optimization_presets.${preset}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className="mb-2 flex flex-col justify-end">
                  <Button size="medium" variant="outlined" onClick={toggleAdvanced}>
                    {showAdvanced ? 'Hide Advanced' : 'Show Advanced'}
                    {showAdvanced ? <ArrowDropUpRoundedIcon fontSize="small" /> : <ArrowDropDownRoundedIcon fontSize="small" />}
                  </Button>
                </FormControl>
              </div>

              <div className=" flex flex-col">
                <div className="mb-2 flex">
                  <TextLabel>Actions</TextLabel>
                  <div className=" ml-2 text-right text-xs ">(No changes will be applied yet)</div>
                </div>
                <div className="flex flex-col">
                  <div className=" mt-px flex gap-4">
                    <Button onClick={onDiscardClicked} variant="outlined" size="medium">
                      Discard
                    </Button>
                    <Button
                      onClick={onPreviewOptimizationsClicked}
                      color="primary"
                      size="medium"
                      disabled={!isTacosValid}
                      endIcon={<FontAwesomeSvgIcon icon={faArrowRight} />}
                    >
                      Preview Optimization
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {showAdvanced && (
              <>
                <Divider className="my-4">Advanced Settings</Divider>
                <div className=" mt-4 flex justify-center gap-8">
                  <div>
                    <div className="mb-2">
                      <TextLabel>Targets to Include</TextLabel>
                    </div>
                    <div>
                      <div className='mr-2'>
                      Improve Efficiency
                      </div>
                    <FormControlLabel
                      control={<Checkbox checked={optimizationParams.highAcos} onChange={handleOptimizationParamsChange} name="highAcos" />}
                      label="High ACOS"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={optimizationParams.highSpend} onChange={handleOptimizationParamsChange} name="highSpend" />}
                      label="High Spend, Non-Converting"
                    />
                    </div>
                    <div className='mr-2'>
                      Improve Sales
                      </div>
                    <div>
                    <FormControlLabel
                      control={<Checkbox checked={optimizationParams.lowAcos} onChange={handleOptimizationParamsChange} name="lowAcos" />}
                      label="Low ACOS"
                    />
                    
                    <FormControlLabel
                      control={
                        <Checkbox checked={optimizationParams.lowVisibility} onChange={handleOptimizationParamsChange} name="lowVisibility" />
                      }
                      label="Low Visibility"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={optimizationParams.showZeroImpressions}
                          onChange={handleOptimizationParamsChange}
                          name="showZeroImpressions"
                        />
                      }
                      label="0 Impressions"
                    />
                    </div>
                  </div>
                  <div className="flex items-start gap-8">
                    <div>
                      <div className="mb-4">
                        <TextLabel>Smart Bid Ceilings</TextLabel>
                      </div>

                      <FormControlLabel
                        control={
                          <Switch
                            checked={optimizationParams.smartBidCeilingActive}
                            onChange={handleOptimizationParamsChange}
                            name="smartBidCeilingActive"
                          />
                        }
                        label=""
                      />
                      <span
                        className={`text-md font-black ${optimizationParams.smartBidCeilingActive ? 'text-blue-600' : 'text-orange-400'}`}
                      >
                        {optimizationParams.smartBidCeilingActive ? 'AUTO' : 'MANUAL'}
                      </span>
                    </div>

                    <div>
                      <TextLabel>Manual Bid Ceiling</TextLabel>
                      <Tooltip
                        title={optimizationParams.smartBidCeilingActive ? 'This field is disabled because Smart Bid Ceiling is active.' : ''}
                        disableHoverListener={!optimizationParams.smartBidCeilingActive}
                      >
                        <TextField
                          type="number"
                          disabled={optimizationParams.smartBidCeilingActive}
                          onChange={handleOptimizationParamsChange}
                          name="bidCeiling"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">$</InputAdornment>,
                          }}
                          className="w-32"
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </OverlayBar>
      )}
    </>
  );
};

export default OptimizationParamsBar;
