import { MuiColorVariant } from '@/config/theme/color.type';
import { AdditionalStatusType, StatusType } from '@/modules/profiles/api/profile.contracts';

export enum SentimentColors {
  RED = 'text-orange-400 dark:text-orange-400',
  GRAY = 'text-slate-400 dark:text-slate-400',
  GREEN = 'text-emerald-600 dark:text-emerald-600',
}

export const getColorClassBySentimentCurrentPrevious = (
  sentimentDirection: SentimentDirection,
  current: number,
  previous: number,
): string => {
  if (sentimentDirection === SentimentDirection.NEUTRAL || current === previous) {
    return SentimentColors.GRAY;
  } else {
    const isPositiveChange = current > previous;
    return (sentimentDirection === SentimentDirection.SYNCED) === isPositiveChange ? SentimentColors.GREEN : SentimentColors.RED;
  }
};

export enum SentimentDirection {
  SYNCED = 'SYNCED',
  REVERSED = 'REVERSED',
  NEUTRAL = 'NEUTRAL',
}

export const profileDataStatusColorMap = {
  [AdditionalStatusType.LOADING]: MuiColorVariant.Primary,
  [StatusType.DOWNLOADED]: MuiColorVariant.Info,
  [StatusType.ONGOING]: MuiColorVariant.Info,
  [AdditionalStatusType.NEVER]: MuiColorVariant.Warning,
  [AdditionalStatusType.OUTDATED]: MuiColorVariant.Warning,
  [StatusType.PENDING]: MuiColorVariant.Warning,
  [StatusType.PROCESSED]: MuiColorVariant.Success,
  [StatusType.FAILED]: MuiColorVariant.Error,
  [AdditionalStatusType.ERROR]: MuiColorVariant.Error,
};
