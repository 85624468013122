import { ApiResponse } from '@/lib/api/api-response';
import { apiTeamClient } from '@/lib/api/base-client';
import { PortalSessionDTO } from './plan.contracts';
import { PlanType } from '@/modules/plans/types/SubscriptionPlan';

class PlanService {
  public basePath = 'plans';

  async getCheckoutLinkForPlan(plan: PlanType): Promise<ApiResponse<PortalSessionDTO>> {
    try {
      return await apiTeamClient.post<PortalSessionDTO>(`${this.basePath}/create-checkout-session`, null, {
        plan: plan,
        return_url: `${window.location.origin}/app/configuration/settings/billing`,
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getBillingLinkForTeam(): Promise<ApiResponse<PortalSessionDTO>> {
    try {
      return await apiTeamClient.post<PortalSessionDTO>(`${this.basePath}/create-billing-session`, null, {
        return_url: `${window.location.origin}/app/configuration/settings/billing`,
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getCancellationLinkForPlan(): Promise<ApiResponse<PortalSessionDTO>> {
    try {
      return await apiTeamClient.post<PortalSessionDTO>(`${this.basePath}/create-cancel-session`, null, {
        return_url: `${window.location.origin}/app/configuration/settings/billing`,
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async cancelCancellation(): Promise<ApiResponse<undefined>> {
    try {
      return await apiTeamClient.post<undefined>(`${this.basePath}/cancel-cancellation`, null, {});
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const planService = new PlanService();
