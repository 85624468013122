import { MenuItemGroup } from '@/modules/application';

export const SETTING_CONFIGURATION_SUB_MENU_ITEMS: MenuItemGroup[] = [
  {
    label: 'Settings',
    children: [
      {
        activeRouteIds: ['/app/configuration/settings/billing'],
        label: 'Subscription',
        url: '/app/configuration/settings/billing',
      },
      // {
      //   activeRouteIds: ['/app/configuration/settings/amazon'],
      //   label: 'Amazon',
      //   url: '/app/configuration/settings/amazon',
      // },
    ],
  },
];
