import { Chip } from '@mui/material';
import { FilterModel, OperatorDisplayMap, UnitType } from './models/FilterModel';
import { FilterType } from './types/FilterKey';
import useFormatting from '@/hooks/useFormatting';
import { FunctionComponent } from 'react';
import { truncateInputValues } from './filters/utils';

interface FilterChipProps {
  filter: FilterModel;
  handleClick: () => void;
}

export const FilterChip: FunctionComponent<FilterChipProps> = ({ filter, handleClick }) => {
  const { formatPercent, formatWithThousandsSeparator, formatCurrency, formatDateStringTimeNoHours } = useFormatting();

  const getBetweenFormatter = (filter: FilterModel) => {
    switch (filter.unitType) {
      case UnitType.CURRENCY:
        return formatCurrency;
      case UnitType.PERCENTAGE:
        return (value: number | null | undefined) => {
          if (value !== null && value !== undefined) {
            return formatPercent(value / 100); // Formatter expects fractions
          }
          return '';
        };
      default:
        return formatWithThousandsSeparator;
    }
  };

  const formatIfNumber = (formatter: (value: number | null | undefined) => string, value: string | number): string | number => {
    const numValue = Number(value);
    return !isNaN(numValue) ? formatter(numValue) : value;
  };

  const toChipLabel = (filter: FilterModel): JSX.Element => {
    if (!filter.conditions || filter.conditions.length === 0) {
      return <></>;
    }

    const condition = filter.conditions[0];
    const firstValue = condition.values[0];

    switch (filter.type) {
      case FilterType.BETWEEN:
        return filter.unitType == UnitType.DATE ? getDateBetweenLabel(filter, firstValue.toString()) : getBetweenLabel(filter, firstValue);

      case FilterType.MULTI_SELECT:
        return (
          <>
            <span className="font-semibold">{filter.name}</span>: {condition.values.length} selected
          </>
        );

      case FilterType.STRING_COMPARISON:
        return (
          <>
            <span className="font-semibold">{filter.name}</span> {OperatorDisplayMap[condition.operator]}{' '}
            <span className="italic">{truncateInputValues(condition.values).join(', ')}</span>
          </>
        );

      default:
        return <></>;
    }
  };

  const getBetweenLabel = (filter: FilterModel, firstValue: string | number): JSX.Element => {
    if (!filter.conditions || filter.conditions.length === 0) {
      return <></>;
    }

    const formatter = getBetweenFormatter(filter);

    if (filter.conditions.length === 1) {
      return (
        <>
          <span className="font-semibold">{filter.name}</span> {OperatorDisplayMap[filter.conditions[0].operator]}{' '}
          {formatIfNumber(formatter, firstValue)}
        </>
      );
    } else {
      const secondValue = filter.conditions[1].values[0];
      return (
        <>
          <span className="font-semibold">{filter.name}</span> BETWEEN {formatIfNumber(formatter, firstValue)} AND{' '}
          {formatIfNumber(formatter, secondValue)}
        </>
      );
    }
  };

  const getDateBetweenLabel = (filter: FilterModel, firstValue: string): JSX.Element => {
    if (!filter.conditions || filter.conditions.length === 0) {
      return <></>;
    }

    const isSameDate = (date1: Date, date2: Date): boolean => {
      const newDate1 = new Date(date1);
      const newDate2 = new Date(date2);

      newDate1.setHours(0, 0, 0, 0);
      newDate2.setHours(0, 0, 0, 0);
      return newDate1.getTime() === newDate2.getTime();
    };

    if (filter.conditions.length === 1) {
      // should not happen
      return (
        <>
          <span className="font-semibold">{filter.name}</span> {OperatorDisplayMap[filter.conditions[0].operator]}{' '}
          {formatDateStringTimeNoHours(firstValue)}
        </>
      );
    }

    // Both beginning and end provided
    const BEGINNING = 'the beginning';
    const TODAY = 'Today';

    const beginningOfTime = new Date(0);
    const today = new Date();

    const firstValueDate = new Date(firstValue);

    const beginning = isSameDate(firstValueDate, beginningOfTime)
      ? BEGINNING
      : isSameDate(firstValueDate, today)
      ? TODAY
      : formatDateStringTimeNoHours(firstValue);

    const secondValue = filter.conditions[1].values[0].toString();
    const ending = isSameDate(firstValueDate, today) ? TODAY : formatDateStringTimeNoHours(secondValue);

    if (beginning == TODAY && ending == TODAY) {
      return (
        <>
          <span className="font-semibold">{filter.name}</span> {TODAY}
        </>
      );
    }

    return (
      <>
        <span className="font-semibold">{filter.name}</span> From <span className="italic">{beginning}</span> to{'   '}
        <span className="italic">{ending}</span>
      </>
    );
  };

  return <Chip label={toChipLabel(filter)} size="medium" variant="outlined" onClick={handleClick} />;
};
