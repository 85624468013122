import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';

interface AlSelectProps<T, V> {
  label?: string;
  value: V | '';
  options: T[];
  onChange: (event: SelectChangeEvent<V>) => void;
  renderOption: (item: T) => React.ReactNode;
  valueExtractor: (item: T) => V;
  disabled?: boolean;
}

function AlSelect<T, V>({ label, value, options, onChange, renderOption, valueExtractor, disabled }: AlSelectProps<T, V>): JSX.Element {
  return (
    <FormControl fullWidth>
      {label && <InputLabel>{label}</InputLabel>}
      <Select value={value || ''} onChange={onChange} label={label} disabled={disabled}>
        {options.map((option, index) => (
          <MenuItem key={`${valueExtractor(option)}` || `${index}`} value={valueExtractor(option) as string}>
            {renderOption(option)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default AlSelect;
