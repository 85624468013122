import { isNil } from 'lodash-es';
import { ProfileDTO } from '../api/users/users.contracts';
import { CountryCode } from '../types/CountryCode';
import { CurrencyCode } from '../types/CurrencyCode';
import { ProfileState } from '../types/ProfileState';
import { Timezone } from '../types/Timezone';

export class ProfileModel {
  public id: number;
  public accountId: number;
  public name: string;
  public type: string;
  public subType: string;
  public countryCode: CountryCode;
  public currencyCode: CurrencyCode;
  public dailyBudget: number;
  public timeZone: Timezone;
  public marketplaceId: string;
  public state: ProfileState;
  public createdAt: string;

  constructor(args: ProfileCreateArguments) {
    this.id = args.id;
    this.accountId = args.accountId;
    this.name = args.name;
    this.type = args.type;
    this.subType = args.subType;
    this.countryCode = args.countryCode;
    this.currencyCode = args.currencyCode;
    this.dailyBudget = args.dailyBudget;
    this.timeZone = args.timeZone;
    this.marketplaceId = args.marketplaceId;
    this.state = args.state;
    this.createdAt = args.createdAt;
  }

  public static fromDTO(dto: ProfileDTO): ProfileModel {
    return new ProfileModel({
      id: dto.id,
      accountId: dto.account_id,
      name: dto.name,
      type: dto.type,
      subType: dto.sub_type,
      countryCode: dto.country_code as CountryCode,
      currencyCode: dto.currency_code as CurrencyCode,
      dailyBudget: dto.daily_budget,
      timeZone: dto.timezone as Timezone,
      marketplaceId: dto.marketplace_id,
      state: isNil(dto.state) ? ProfileState.NOT_AUTHORIZED : (dto.state as ProfileState),
      createdAt: dto.created_at,
    });
  }
}

export interface ProfileCreateArguments {
  id: number;
  accountId: number;
  name: string;
  type: string;
  subType: string;
  countryCode: CountryCode;
  currencyCode: CurrencyCode;
  dailyBudget: number;
  timeZone: Timezone;
  marketplaceId: string;
  state: ProfileState;
  createdAt: string;
}
