import { FunctionComponent } from 'react';
import { MainMenuButton } from './MainMenuButton';
import { useUserContext } from '@/modules/users';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
interface AdminModeToggleButtonProps {
  isExpanded: boolean;
}

export const AdminModeToggleButton: FunctionComponent<AdminModeToggleButtonProps> = ({ isExpanded }) => {
  const { user, isAdminModeActive, toggleAdminMode } = useUserContext();
  if (!user || !user.isAdmin) {
    return null;
  }

  return (
    <MainMenuButton
      icon={isAdminModeActive ? <SelfImprovementIcon /> : <DirectionsRunIcon />}
      isActive={false}
      isExpanded={isExpanded}
      label={isAdminModeActive ? 'Admin ACTIVE' : 'Admin Disabled'}
      onClick={toggleAdminMode}
    />
  );
};
