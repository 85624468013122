import { FunctionComponent } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { CampaignModel, CampaignState } from '@/modules/optimizer/api/campaign/models/CampaignModel';
import { Tooltip } from '@mui/material';

const CampaignStateCellRenderer: FunctionComponent<ICellRendererParams<CampaignModel>> = (props) => {
  const { data } = props;
  const defaultClasses = 'rounded-full bg-opacity-10 flex items-center justify-center w-5 h-5 mt-2.5';

  switch (data?.state) {
    case CampaignState.PAUSED:
      return (
        <Tooltip title="Paused">
          <div className={`${defaultClasses} border-orange-600 bg-orange-600 `}>
            <PauseCircleOutlineRoundedIcon color={'warning'} fontSize={'medium'} />
          </div>
        </Tooltip>
      );
    case CampaignState.ARCHIVED:
      return (
        <Tooltip title="Archived">
          <div className={`${defaultClasses}  border-slate-600 bg-slate-600 `}>
            <RemoveCircleOutlineRoundedIcon color={'disabled'} fontSize={'medium'} />
          </div>
        </Tooltip>
      );
    case CampaignState.ENABLED:
    default:
      return (
        <Tooltip title="Enabled">
          <div className={`${defaultClasses} border-green-600 bg-green-600 `}>
            <CheckCircleOutlineRoundedIcon color={'success'} fontSize={'medium'} />
          </div>
        </Tooltip>
      );
  }
};

export default CampaignStateCellRenderer;
