import { FunctionComponent, useRef, useState } from 'react';
import RegistrationWizardPage from '../components/RegistrationWizardPage';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RegistrationWizardPageTitle from '../components/RegistrationWizardPageTitle';
import { RegistrationWizardPageDescription } from '../components/RegistrationWizardPageDescription';
import { useUserContext, userService } from '@/modules/users';
import { debounce, isEmpty, isNil } from 'lodash-es';
import { useRegistrationContext } from '../contexts/registration/RegistrationContext';

interface RegistrationStepAuthorizeAmazonPageProps {}

export const RegistrationStepAuthorizeAmazonPage: FunctionComponent<RegistrationStepAuthorizeAmazonPageProps> = () => {
  const { teamId, userId } = useRegistrationContext();
  const { user } = useUserContext();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorizeEnabled, setIsAuthorizeEnabled] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Amazon redirects back to this page including code and state GET params
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  const debouncedAuthorizeUser = useRef(
    debounce(async (userId, code, state) => {
      await authorizeUser(userId, code, state);
    }, 300),
  ).current;

  if (!isNil(code) && !isNil(state)) {
    if (user && user.id) {
      // If a logged in user is redirected to this page then use user from user-context not registration context
      debouncedAuthorizeUser(user.id, code, state);
    } else if (userId && userId > 0) {
      debouncedAuthorizeUser(userId, code, state);
    }
  }

  async function authorizeUser(userId: number, code: string, state: string) {
    try {
      setIsLoading(true);
      setIsAuthorizeEnabled(false);

      const res = await userService.authorizeUser(userId, { code, state }); // backend exchanges code for access token

      if (res.isSuccess) {
        navigate('/register/select-profiles');
        return;
      } else {
        setIsError(true);
        setErrorMessage('Something went wrong. ' + res.message);
      }
    } catch (error) {
      setIsError(true);
      setErrorMessage('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
      setIsAuthorizeEnabled(true);
    }
  }

  async function onAuthorizeClicked() {
    try {
      setIsLoading(true);
      setIsAuthorizeEnabled(false);

      const authUrl = await getAuthUrl(); // Get auth url from backend

      if (isEmpty(authUrl)) {
        setIsError(true);
        setErrorMessage('Something went wrong. Auth url is empty. Please try again later.');
      } else {
        //window.location.href = authUrl; // ok to use browser's back button
        window.location.replace(authUrl); //❗️will not be able to use browser's back button
      }
    } catch (error) {
      setIsError(true);
      setErrorMessage('Something went wrong. Please try again later.');
      setIsLoading(false);
    }
  }

  async function getAuthUrl(): Promise<string> {
    if (userId) {
      try {
        const res = await userService.getAmazonAuthorizationUrl(userId, teamId);
        if (res.isSuccess) {
          return res.payload;
        }
      } catch (error) {
        setIsError(true);
        setErrorMessage('Unable to get auth url. Please try again later.');
      }
    } else {
      setIsError(true);
      setErrorMessage('Unable to get firebase id. Please try again later.');
    }

    return '';
  }

  return (
    <RegistrationWizardPage>
      <div className="flex flex-col items-center">
        <RegistrationWizardPageTitle>Connect with Amazon Ads</RegistrationWizardPageTitle>
        <RegistrationWizardPageDescription>You will be able to select individual Profiles ✅</RegistrationWizardPageDescription>
        <div className=" w-96">
          <div className="flex  flex-col  gap-y-3">
            <LoadingButton
              variant="contained"
              size="large"
              color="primary"
              className="mt-6"
              onClick={onAuthorizeClicked}
              disabled={!isAuthorizeEnabled}
              loading={isLoading}
            >
              Authorize
            </LoadingButton>
          </div>
        </div>
        {isError && <div className="mt-4 text-red-500">{errorMessage}</div>}
      </div>
    </RegistrationWizardPage>
  );
};
