import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import { UserSettingUpdateDTO, UserSettingReadDTO, UserSettingKey, UserSettingValue } from './user-setting.contracts';
import { UserSettingModel } from '../../types/UserSettingModel';

export class UserSettingService {
  public basePath = 'users';

  async getAll(userId: number): Promise<ApiResponse<UserSettingModel[]>> {
    try {
      const applicationResponse = await apiClient.get<UserSettingReadDTO[]>(`${this.basePath}/${userId}/settings`);
      return applicationResponse.processPayload((payload) => {
        return payload.map(UserSettingModel.fromDTO);
      });
    } catch (error) {
      console.error(error);

      return ApiResponse.UnknownErrorResponse();
    }
  }

  async updateValue(userId: number, key: UserSettingKey, value: UserSettingValue): Promise<ApiResponse<UserSettingModel>> {
    try {
      const updatedSetting: Partial<UserSettingUpdateDTO> = { value: value };
      const applicationResponse = await apiClient.post<UserSettingReadDTO>(`${this.basePath}/${userId}/settings/${key}`, updatedSetting);
      return applicationResponse.processPayload(UserSettingModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const userSettingService = new UserSettingService(); // Updated the constant name
