import { AdditionalStatusType, ProfileReportsDTO, ReportInfoDTO, StatusType } from '@/modules/profiles/api/profile.contracts';
import dayjs from 'dayjs';

const OUTDATED_AGE_SECS = 60 * 60 * 12;

export type ProfileReportStatus = {
  status: AdditionalStatusType | StatusType;
  lastUpdated: string | undefined;
  latestStarted: string | undefined;
  initialLoadFinished: boolean;
};

const useProfileReports = () => {
  function getProfileReportInfo(profileReport: ProfileReportsDTO | null): ProfileReportStatus {
    if (!profileReport || !profileReport.placement || !profileReport.targeting) {
      return {
        status: AdditionalStatusType.ERROR,
        lastUpdated: undefined,
        latestStarted: undefined,
        initialLoadFinished: false,
      };
    }

    const placement = profileReport.placement;
    const targeting = profileReport.targeting;
    if (placement.length == 0 || targeting.length == 0) {
      return {
        status: StatusType.ONGOING, // AdditionalStatusType.NEVER, assume that when never then it's auto started by cron
        lastUpdated: undefined,
        latestStarted: undefined,
        initialLoadFinished: true,
      };
    }

    const getLatestReport = (reports: ReportInfoDTO[]) => {
      return reports.reduce(
        (latest, report) => {
          return !latest || new Date(report.updated_at) > new Date(latest.updated_at) ? report : latest;
        },
        undefined as ReportInfoDTO | undefined,
      );
    };

    const latestPlacement = getLatestReport(placement);
    const latestTargeting = getLatestReport(targeting);

    if (!latestPlacement || !latestTargeting) {
      return {
        status: StatusType.ONGOING, // AdditionalStatusType.NEVER, assume that when never then it's auto started by cron
        lastUpdated: undefined,
        latestStarted: undefined,
        initialLoadFinished: true,
      };
    }

    const latestStarted = latestPlacement.created_at < latestTargeting.created_at ? latestPlacement.created_at : latestTargeting.created_at;

    // Merge 2 array reports into a single state
    let status: StatusType | AdditionalStatusType;
    if (latestPlacement?.status === StatusType.PROCESSED && latestTargeting?.status === StatusType.PROCESSED) {
      status = StatusType.PROCESSED;
    } else if (latestPlacement?.status === StatusType.FAILED || latestTargeting?.status === StatusType.FAILED) {
      status = StatusType.FAILED;
    } else if (latestPlacement?.status === StatusType.PENDING || latestTargeting?.status === StatusType.PENDING) {
      status = StatusType.PENDING;
    } else if (latestPlacement?.status === StatusType.DOWNLOADED || latestTargeting?.status === StatusType.DOWNLOADED) {
      status = StatusType.DOWNLOADED;
    } else if (latestPlacement?.status === StatusType.ONGOING || latestTargeting?.status === StatusType.ONGOING) {
      status = StatusType.ONGOING;
    } else {
      console.log('Unknown status, setting to pending', latestPlacement?.status, latestTargeting?.status);
      status = StatusType.PENDING;
    }

    const getNewestProcessedReport = (reports: ReportInfoDTO[]) => {
      return reports
        .filter((report) => report.status === StatusType.PROCESSED)
        .reduce(
          (newest, report) => {
            return !newest || new Date(report.updated_at) > new Date(newest.updated_at) ? report : newest;
          },
          undefined as ReportInfoDTO | undefined,
        );
    };

    const newestProcessedPlacement = getNewestProcessedReport(placement);
    const newestProcessedTargeting = getNewestProcessedReport(targeting);

    let lastUpdated: string | undefined;
    if (newestProcessedPlacement && newestProcessedTargeting) {
      // Pick the oldest of the two
      lastUpdated =
        new Date(newestProcessedPlacement.updated_at) < new Date(newestProcessedTargeting.updated_at)
          ? newestProcessedPlacement.updated_at
          : newestProcessedTargeting.updated_at;
    } else {
      lastUpdated = undefined;
    }

    if (lastUpdated && status !== StatusType.PENDING && status !== StatusType.DOWNLOADED && status !== StatusType.ONGOING) {
      const outdatedAge = dayjs().subtract(OUTDATED_AGE_SECS, 'seconds');
      if (dayjs(lastUpdated).isBefore(outdatedAge)) {
        status = AdditionalStatusType.OUTDATED;
      }
    }

    return {
      status,
      lastUpdated,
      latestStarted,
      initialLoadFinished: true,
    };
  }

  return {
    getProfileReportInfo,
  };
};

export { useProfileReports };
