import { MetricsData } from '@/modules/optimizer/api/campaign/models/CampaignModel';
import { ResponseAdGroupDebugRow } from '../api/optimization-contracts';

export class AdGroupDebugRow {
  public adGroup: string;
  public tcpa: MetricsData;
  public tcpc: MetricsData;
  public rpcInherited: string;
  public aovInherited: string;
  public actcInherited: string;
  public impressions: MetricsData;
  public clicks: MetricsData;
  public orders: MetricsData;
  public ctr: MetricsData;
  public cvr: MetricsData;
  public cpc: MetricsData;
  public spend: MetricsData;
  public sales: MetricsData;
  public acos: MetricsData;
  public roas: MetricsData;
  public rpc: MetricsData;
  public cpa: MetricsData;
  public aov: MetricsData;
  public cpm: MetricsData;

  constructor(args: AdGroupDebugRowCreateArguments) {
    this.adGroup = args.adGroup;
    this.tcpa = args.tcpa;
    this.tcpc = args.tcpc;
    this.rpcInherited = args.rpcInherited;
    this.aovInherited = args.aovInherited;
    this.actcInherited = args.actcInherited;
    this.impressions = args.impressions;
    this.clicks = args.clicks;
    this.orders = args.orders;
    this.ctr = args.ctr;
    this.cvr = args.cvr;
    this.cpc = args.cpc;
    this.spend = args.spend;
    this.sales = args.sales;
    this.acos = args.acos;
    this.roas = args.roas;
    this.rpc = args.rpc;
    this.cpa = args.cpa;
    this.aov = args.aov;
    this.cpm = args.cpm;
  }

  public static fromResponseArray(responseArray: ResponseAdGroupDebugRow[]): AdGroupDebugRow[] {
    return responseArray.map(
      (response) =>
        new AdGroupDebugRow({
          adGroup: response.ad_group,
          tcpa: response.tcpa,
          tcpc: response.tcpc,
          rpcInherited: response.rpc_inherited,
          aovInherited: response.aov_inherited,
          actcInherited: response.actc_inherited,
          impressions: response.impressions,
          clicks: response.clicks,
          orders: response.orders,
          ctr: response.ctr,
          cvr: response.cvr,
          cpc: response.cpc,
          spend: response.spend,
          sales: response.sales,
          acos: response.acos,
          roas: response.roas,
          rpc: response.rpc,
          cpa: response.cpa,
          aov: response.aov,
          cpm: response.cpm,
        }),
    );
  }
}

export interface AdGroupDebugRowCreateArguments {
  adGroup: string;
  tcpa: MetricsData;
  tcpc: MetricsData;
  rpcInherited: string;
  aovInherited: string;
  actcInherited: string;
  impressions: MetricsData;
  clicks: MetricsData;
  orders: MetricsData;
  ctr: MetricsData;
  cvr: MetricsData;
  cpc: MetricsData;
  spend: MetricsData;
  sales: MetricsData;
  acos: MetricsData;
  roas: MetricsData;
  rpc: MetricsData;
  cpa: MetricsData;
  aov: MetricsData;
  cpm: MetricsData;
}
