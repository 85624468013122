import {
  ComparisonDateFilterModel,
  DateFilterModel,
  FilterModel,
  LogicalOperatorType,
  OperatorType,
  getUpdatedFiltersValue,
} from '@/modules/optimizer/components/filter-builder/models/FilterModel';
import { createContext, useContext, useEffect, useState } from 'react';
import { METRIC_COLORS, MetricColor, getUpdatedSelectionColors } from '../components/metrics/MetricsConfig';
import { MetricField } from '../components/metrics/models/CommonMetricsModel';
import { useUserSetting } from '@/hooks/useUserSetting';
import { UserSettingKey } from '@/modules/users';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../components/filter-builder/FiltersConfig';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';

interface TemplateContext {
  filters: FilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<FilterModel[]>>;
  setFilterValue: (filter: FilterModel) => void;
  visibleMetrics: MetricField[];
  setVisibleMetrics: React.Dispatch<React.SetStateAction<MetricField[]>>;
  selectedMetrics: MetricField[];
  setSelectedMetrics: React.Dispatch<React.SetStateAction<MetricField[]>>; // Add logic to save selected metrics as a setting
  selectedMetricColors: MetricColor[];
  setSelectedMetricColors: React.Dispatch<React.SetStateAction<MetricColor[]>>;
  setDefaultDateRange: () => void;
}

export const TemplateContext = createContext<TemplateContext>({
  filters: [],
  setFilters: () => null,
  setFilterValue: () => null,
  visibleMetrics: [],
  setVisibleMetrics: () => null,
  selectedMetrics: [],
  setSelectedMetrics: () => null,
  selectedMetricColors: [],
  setSelectedMetricColors: () => null,
  setDefaultDateRange: () => null,
});

export const useTemplateContext = () => {
  const context = useContext(TemplateContext);
  if (!context) {
    throw new Error('useTemplateContext must be used within a TemplateProvider');
  }
  return context;
};

const DEFAULT_SELECTED_METRICS = [MetricField.ACOS, MetricField.SALES];
const DEFAULT_SELECTED_METRIC_COLORS = [
  { key: MetricField.ACOS, color: METRIC_COLORS[0] },
  { key: MetricField.SALES, color: METRIC_COLORS[1] },
];
const DEFAULT_VISIBLE_METRICS = [
  MetricField.IMPRESSIONS,
  MetricField.CLICKS,
  MetricField.ORDERS,
  MetricField.SPEND,
  MetricField.SALES,
  MetricField.ACOS,
  MetricField.CTR,
  MetricField.CVR,
];

export const useTemplate = (): TemplateContext => {
  const { activeProfileIdChange } = useActiveTeamContext();
  const { handleSettingStateChange: updateSelectedMericsSetting, settingState: selectedMetricsSetting } = useUserSetting<MetricField[]>(
    UserSettingKey.SELECTED_METRICS,
  );

  const { handleSettingStateChange: updateSelectedMetricColorsSetting, settingState: selectedMetricColorsSetting } = useUserSetting<
    MetricColor[]
  >(UserSettingKey.SELECTED_METRIC_COLORS);

  const { handleSettingStateChange: updateVisibleMetrics, settingState: visibleMetricsSetting } = useUserSetting<MetricField[]>(
    UserSettingKey.VISIBLE_METRICS,
  );

  const [filters, setFilters] = useState<FilterModel[]>([]); //app
  const [selectedMetrics, setSelectedMetrics] = useState<MetricField[]>(selectedMetricsSetting ?? DEFAULT_SELECTED_METRICS);
  const [visibleMetrics, setVisibleMetrics] = useState<MetricField[]>(visibleMetricsSetting ?? DEFAULT_VISIBLE_METRICS);
  const [selectedMetricColors, setSelectedMetricColors] = useState<MetricColor[]>(
    getUpdatedSelectionColors(
      selectedMetricColorsSetting ?? DEFAULT_SELECTED_METRIC_COLORS,
      selectedMetricsSetting ?? DEFAULT_SELECTED_METRICS,
    ),
  );

  console.log({ selectedMetricColorsSetting, selectedMetricsSetting, selectedMetricColors, selectedMetrics });

  const [isMounted, setIsMounted] = useState(false);

  // setSelectedMetricColors();

  const setFilterValue = (filter: FilterModel) => {
    const newFilterValue = getUpdatedFiltersValue(filters, filter);
    setFilters(newFilterValue);
  };

  const setFilterValues = (newFilterValues: FilterModel[]) => {
    if (!newFilterValues || newFilterValues.length === 0) return;

    newFilterValues.reduce((acc, newFilterValue) => {
      return getUpdatedFiltersValue(acc, newFilterValue);
    }, filters);

    setFilters(newFilterValues);
  };

  useEffect(() => {
    if (!isMounted) return;
    updateSelectedMetricColorsSetting(selectedMetricColors);
  }, [selectedMetricColors]);

  useEffect(() => {
    if (!isMounted) return;
    updateSelectedMericsSetting(selectedMetrics);
  }, [selectedMetrics]);

  useEffect(() => {
    if (!isMounted) return;
    updateVisibleMetrics(visibleMetrics);
  }, [visibleMetrics]);

  useEffect(() => {
    if (activeProfileIdChange && activeProfileIdChange.hasChanged) {
      setFilters([]); // Clear filters if profile is changed
      setDefaultDateRange(); // setDefault dates
    }
  }, [activeProfileIdChange]);

  function setDefaultDateRange() {
    const today = dayjs();
    const periodLengthInDays = 30;
    const startDate = today.subtract(periodLengthInDays, 'day');
    const endDate = today.subtract(1, 'day');

    const defaultDateRange = new DateFilterModel({
      logicalOperator: LogicalOperatorType.AND,
      conditions: [
        {
          values: [startDate.format(DATE_FORMAT)],
          operator: OperatorType.GREATER_THAN_OR_EQUAL,
        },
        {
          values: [endDate.format(DATE_FORMAT)],
          operator: OperatorType.LESS_THAN_OR_EQUAL,
        },
      ],
    });

    const defaultComparisonDateRange = new ComparisonDateFilterModel({
      logicalOperator: LogicalOperatorType.AND,
      conditions: [
        {
          values: [startDate.subtract(periodLengthInDays, 'day').format(DATE_FORMAT)],
          operator: OperatorType.GREATER_THAN_OR_EQUAL,
        },
        {
          values: [endDate.subtract(periodLengthInDays, 'day').format(DATE_FORMAT)],
          operator: OperatorType.LESS_THAN_OR_EQUAL,
        },
      ],
    });

    setFilterValues([defaultComparisonDateRange, defaultDateRange]);
  }

  useEffect(() => {
    setIsMounted(true);

    setDefaultDateRange();
  }, []);

  return {
    filters,
    setFilters,
    setFilterValue,
    visibleMetrics,
    setVisibleMetrics,
    selectedMetrics,
    setSelectedMetrics,
    selectedMetricColors,
    setSelectedMetricColors,
    setDefaultDateRange,
  };
};
