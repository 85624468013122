import { Dialog, DialogContent, DialogActions, Button, FormControlLabel, Checkbox, Typography, Tooltip, useTheme } from '@mui/material';
import { FunctionComponent, useRef, useState } from 'react';
import { OptimizationApplyData } from './OptimizerConfig';
import OverlayBar from '../OverlayBar';
import OptimizationTable from './OptimizationTable';
import { BiddingEntity, OptimizationModel } from './models/OptimizationModel';
import { TextLabel } from '@/components/typography/TextLabel';
import { FontAwesomeSvgIcon } from '@/components/icons/FontAwesomeSvgIcon';
import { FilterBuilderPopover } from '../FilterBuilderPopover';
import { FilterChip } from '../filter-builder/FilterChip';
import { faArrowLeft, faBarsFilter, faEdit } from '@fortawesome/pro-light-svg-icons';
import { FilterModel, createOptimizerFilters } from '../filter-builder/models/FilterModel';
import { HelpOutline } from '@mui/icons-material';
import Gleap from 'gleap';
import { useTranslation } from '@/lib';
import { GleapWorkflowType } from '@/lib/gleap';
import OptimizationRangeDisplay from '../OptimizationRangeDisplay';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { AdditionalStatusType } from '@/modules/profiles/api/profile.contracts';
import { useReportsContext } from '@/modules/teams/contexts/ReportsContext';
import useFormatting from '@/hooks/useFormatting';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { CAMPAIGN_GROUPS_QUERY_KEY, campaignService } from '../../api/campaign/campaign-service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import OptimizationEditSelectionModal, {
  BidUpdateData,
  KeywordUpdateType,
  PlacementUpdateData,
  PlacementUpdateType,
  ProductTargetUpdateData,
  ProductTargetUpdateType,
  TotalUpdateData,
} from './OptimizationEditSelectionModal';
import { PreviewDataRow } from './models/PreviewDataRow';

interface OptimizationsPreviewModalProps {
  optimizationResults: OptimizationModel;
  isOpen: boolean;
  onClose: () => void;
  onApplyOptimization: () => void;
  optimizationPreviewSelection: OptimizationApplyData[];
  setOptimizationPreviewSelection: (optimizationApplyData: OptimizationApplyData[]) => void;
  tacos: number | '';
  preset: string | '';
}

export const OptimizationsPreviewModal: FunctionComponent<OptimizationsPreviewModalProps> = ({
  optimizationResults,
  isOpen,
  onClose,
  onApplyOptimization: onApplyChanges,
  optimizationPreviewSelection,
  setOptimizationPreviewSelection,
  tacos,
  preset,
}) => {
  const [isConfirmationCheckboxChecked, setIsConfirmationCheckboxChecked] = useState(false);
  const [selectionContainsRecentlyOptimized, setSelectionContainsRecentlyOptimized] = useState<boolean>(false);
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const [isEditSelectionModalOpen, setIsEditSelectionModalOpen] = useState(false);

  const handleConfirmationCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsConfirmationCheckboxChecked(event.target.checked);
  };

  const { t } = useTranslation();
  const theme = useTheme();
  const { formatDateStringTimeNoSeconds } = useFormatting();
  const { activeProfileDataStatusInfo } = useReportsContext();

  const onApply = () => {
    setIsApplyLoading(true);
    onApplyChanges();
    localOnClose();
  };

  const localOnClose = () => {
    onClose();
    setIsApplyLoading(false);
    setIsConfirmationCheckboxChecked(false);
    setSelectionContainsRecentlyOptimized(false);
    setFilters([]);
  };

  // FILTERS
  const [filters, setFilters] = useState<FilterModel[]>([]);
  const filtersButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false); // state to control the popover
  const [isApplyLoading, setIsApplyLoading] = useState(false);
  const [visibleRowCount, setVisibleRowCount] = useState<number>(0);

  const onFiltersButtonClicked = () => {
    setIsFilterPopoverOpen((previousValue) => !previousValue);
  };

  //Gleap stuff
  const startGleapFeedbackFlow = (workflowToStart: GleapWorkflowType = GleapWorkflowType.Default) => {
    if (workflowToStart === GleapWorkflowType.BugReport) {
      Gleap.startFeedbackFlow(GleapWorkflowType.BugReport, true);
    } else {
      Gleap.open();
    }
  };

  const adGroups = Array.from(
    new Set(optimizationResults.previewData.map((optimizationResult) => optimizationResult.adGroup).filter((adGroup) => adGroup !== '')),
  ).sort();

  const { data: campaignGroups } = useQuery({
    queryKey: [CAMPAIGN_GROUPS_QUERY_KEY, activeProfile?.id],
    queryFn: async () => {
      const result = await campaignService.getGroups();
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading groups');
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  const OPTIMIZER_FILTERS = createOptimizerFilters(adGroups, campaignGroups || []);

  const onFiltersChanged = (filters: FilterModel[]) => {
    console.log('filters changed', { filters });
    setFilters(filters);
  };

  function onEditSlectionClicked() {
    setIsEditSelectionModalOpen(true);
  }

  function applyUpdatesToPreviewDataRows(
    previewDataRows: PreviewDataRow[],
    updateData: TotalUpdateData,
    selectedRows: OptimizationApplyData[],
  ): PreviewDataRow[] {
    console.log({
      previewDataRows,
      updateData,
      selectedRows,
    });

    return previewDataRows.map((row) => {
      if (selectedRows.some((selectedRow) => selectedRow.id === row.id && selectedRow.bidding_entity === row.biddingEntity)) {
        switch (row.biddingEntity) {
          case BiddingEntity.KEYWORD:
            applyKeywordUpdate(row, updateData.bidUpdateData);
            break;
          case BiddingEntity.PRODUCT_TARGET:
            applyProductTargetUpdate(row, updateData.productTargetUpdateData);
            break;
          default:
            applyPlacementUpdate(row, updateData.placementUpdateData);
            break;
        }
        // Calculate delta
        row.delta = row.newValue - row.oldValue;
      }
      return row;
    });
  }

  function applyKeywordUpdate(row: PreviewDataRow, updateData: BidUpdateData) {
    switch (updateData.bidUpdateType) {
      case KeywordUpdateType.SET_BID_TO_AMOUNT:
        row.newValue = updateData.newBidValue;
        break;
      case KeywordUpdateType.INCREASE_BID_BY_AMOUNT:
        row.newValue = row.oldValue + updateData.newBidValue;
        break;
      case KeywordUpdateType.DECREASE_BID_BY_AMOUNT:
        row.newValue = row.oldValue - updateData.newBidValue;
        break;
      case KeywordUpdateType.INCREASE_BID_BY_PERCENTAGE:
        row.newValue = row.oldValue * (1 + updateData.newBidValue / 100);
        break;
      case KeywordUpdateType.DECREASE_BID_BY_PERCENTAGE:
        row.newValue = row.oldValue * (1 - updateData.newBidValue / 100);
        break;
      // No need for NO_CHANGE as it does nothing
    }
  }

  function applyProductTargetUpdate(row: PreviewDataRow, updateData: ProductTargetUpdateData) {
    // Similar logic to applyKeywordUpdate
    switch (updateData.productTargetUpdateType) {
      case ProductTargetUpdateType.SET_BID_TO_AMOUNT:
        row.newValue = updateData.newProductTargetValue;
        break;
      case ProductTargetUpdateType.INCREASE_BID_BY_AMOUNT:
        row.newValue = row.oldValue + updateData.newProductTargetValue;
        break;
      case ProductTargetUpdateType.DECREASE_BID_BY_AMOUNT:
        row.newValue = row.oldValue - updateData.newProductTargetValue;
        break;
      case ProductTargetUpdateType.INCREASE_BID_BY_PERCENTAGE:
        row.newValue = row.oldValue * (1 + updateData.newProductTargetValue / 100);
        break;
      case ProductTargetUpdateType.DECREASE_BID_BY_PERCENTAGE:
        row.newValue = row.oldValue * (1 - updateData.newProductTargetValue / 100);
        break;
      // No need for NO_CHANGE as it does nothing
    }
  }

  function applyPlacementUpdate(row: PreviewDataRow, updateData: PlacementUpdateData) {
    switch (updateData.placementUpdateType) {
      case PlacementUpdateType.SET_PLACEMENT_TO_PERCENTAGE:
        row.newValue = updateData.newPlacementValue / 100;
        break;
      case PlacementUpdateType.INCREASE_PLACEMENT_BY_PERCENTAGE_POINTS:
        row.newValue = row.oldValue + updateData.newPlacementValue / 100;
        break;
      case PlacementUpdateType.DECREASE_PLACEMENT_BY_PERCENTAGE_POINTS:
        row.newValue = row.oldValue - updateData.newPlacementValue / 100;
        break;
      // No need for NO_CHANGE as it does nothing
    }
  }

  function onApplySelectionEdit(updateData: TotalUpdateData) {
    // Apply updates to preview data rows
    optimizationResults.previewData = applyUpdatesToPreviewDataRows(
      optimizationResults.previewData,
      updateData,
      optimizationPreviewSelection,
    );

    // Update the selection
    setOptimizationPreviewSelection(
      optimizationPreviewSelection.map((optimizationApplyData) => {
        return {
          ...optimizationApplyData,
          bid: updateData.bidUpdateData.newBidValue,
          placement: updateData.placementUpdateData.newPlacementValue,
          tacos: tacos,
        };
      }),
    );
  }

  return (
    <Dialog fullScreen open={isOpen} onClose={onClose} fullWidth={true}>
      {/* <DialogTitle>Title here?</DialogTitle> */}
      <DialogContent>
        <div className="flex w-full flex-row justify-between">
          <div className="flex gap-x-8">
            <div className="flex flex-col">
              <TextLabel>Filter</TextLabel>
              <div className="mt-1 flex items-start">
                <Button
                  variant="outlined"
                  onClick={onFiltersButtonClicked}
                  startIcon={<FontAwesomeSvgIcon icon={faBarsFilter} />}
                  ref={filtersButtonRef}
                  className="mr-2 flex-shrink-0"
                >
                  Adjust Filters
                </Button>

                <FilterBuilderPopover
                  buttonRef={filtersButtonRef}
                  setIsOpen={setIsFilterPopoverOpen}
                  isOpen={isFilterPopoverOpen}
                  appliedFilters={filters}
                  onFiltersChanged={onFiltersChanged}
                  availableFilters={OPTIMIZER_FILTERS}
                />

                <div className="flex flex-row flex-wrap gap-2">
                  {filters
                    .filter((f) => f.isFilterBuilderFilter)
                    .map((filter, index) => {
                      return <FilterChip key={filter.key + index} filter={filter} handleClick={onFiltersButtonClicked} />;
                    })}
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <TextLabel>Bulk Edit</TextLabel>
              <div className="mt-1 flex items-start">
                <Button
                  variant="outlined"
                  onClick={onEditSlectionClicked}
                  startIcon={<FontAwesomeSvgIcon icon={faEdit} />}
                  ref={filtersButtonRef}
                  disabled={optimizationPreviewSelection.length === 0}
                  className="mr-2 flex-shrink-0"
                >
                  {optimizationPreviewSelection.length > 0
                    ? `Edit ${optimizationPreviewSelection.length} item${optimizationPreviewSelection.length > 1 ? 's' : ''}`
                    : 'Edit Selection'}
                </Button>

                {/* Show modal for editing the selected items */}
                <OptimizationEditSelectionModal
                  selectedItems={optimizationPreviewSelection}
                  isOpen={isEditSelectionModalOpen}
                  onClose={() => setIsEditSelectionModalOpen(false)}
                  onApply={onApplySelectionEdit}
                />
              </div>
            </div>
          </div>
          <div className="mb-2 flex items-start gap-12">
            <div className=" flex-1 whitespace-nowrap">
              <TextLabel>{t(`optimizer_page.labels.optimization_preset`)}</TextLabel>
              <div className="mt-1 font-bold">{t(`optimizer_page.optimization_presets.${preset}`)}</div>
            </div>
            <div className=" flex-1">
              <TextLabel>TARGET ACOS</TextLabel>
              <div className="mt-1 font-bold">{tacos}%</div>
            </div>
            <div className="flex min-w-44 flex-1 flex-col">
              <div className="">
                <TextLabel>OPTIMIZATION RANGE</TextLabel>
              </div>
              <div className="mt-1 flex w-full flex-shrink-0">
                <OptimizationRangeDisplay />
              </div>
            </div>
          </div>
        </div>

        <OptimizationTable
          externalFilters={filters}
          optimizationResults={optimizationResults}
          setOptimizationPreviewSelection={setOptimizationPreviewSelection}
          setSelectionContainsRecentlyOptimized={setSelectionContainsRecentlyOptimized}
          setVisibleRowCount={setVisibleRowCount}
        />

        <OverlayBar isFullPageWidth>
          <div className="flex w-full flex-row items-end justify-between">
            {/* Left: Selection Count */}
            <div className="mt-2.5 flex-col">
              <TextLabel>Selection</TextLabel>
              <Typography className="my-2.5 font-semibold">
                {optimizationPreviewSelection.length} of {visibleRowCount} selected
              </Typography>
            </div>

            {selectionContainsRecentlyOptimized || activeProfileDataStatusInfo.status == AdditionalStatusType.OUTDATED ? (
              <div className="flex-col gap-4 items-start justify-start h-full">
                {activeProfileDataStatusInfo.status == AdditionalStatusType.OUTDATED ? (
                  <div className="flex flex-row my-2.5">
                    <WarningRoundedIcon fontSize="small" style={{ marginRight: '8px', color: theme.palette.warning.main }} />
                    <Typography variant="body2">
                      Data might be outdated. Last sync: {formatDateStringTimeNoSeconds(activeProfileDataStatusInfo.lastUpdated)}
                    </Typography>
                  </div>
                ) : null}
                {selectionContainsRecentlyOptimized ? (
                  <div className="flex flex-row my-2.5">
                    <WarningRoundedIcon fontSize="small" style={{ marginRight: '8px', color: theme.palette.warning.main }} />
                    <Typography variant="body2">Some selected entities already optimized today</Typography>
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* Center: Help Section */}
            <div className="mb-1 flex flex-row items-center">
              <Button onClick={() => startGleapFeedbackFlow(GleapWorkflowType.Default)} variant="text">
                <div className="flex flex-row items-center">
                  <HelpOutline />
                  <span className="ml-2">I NEED HELP</span>
                </div>
              </Button>
              {/* Help & Bug here is perhaps too excessive
               <Button onClick={() => startGleapFeedbackFlow(GleapWorkflowType.BugReport)} variant="text">
                <div className="flex flex-row items-center">
                  <BugReport />
                  <span className="ml-2">REPORT A BUG</span>
                </div>
              </Button> */}
            </div>

            {/* Right: Buttons and Checkbox */}
            <div className="flex flex-col items-end">
              {/* Checkbox */}
              <FormControlLabel
                control={<Checkbox checked={isConfirmationCheckboxChecked} onChange={handleConfirmationCheckboxChange} color="primary" />}
                label="I know what I'm doing"
              />

              {/* Buttons */}
              <div className="flex flex-row items-center">
                <div className="mr-4">
                  <Button onClick={localOnClose} variant="outlined" size="medium" startIcon={<FontAwesomeSvgIcon icon={faArrowLeft} />}>
                    Back to Campaigns
                  </Button>
                </div>

                <Tooltip title={optimizationPreviewSelection.length == 0 ? 'No entities selected' : 'Send changes to Amazon'}>
                  {/* Wrapping span to avoid disabled Button preventing Tooltip */}
                  <span>
                    <LoadingButton
                      onClick={onApply}
                      endIcon={<SendIcon />}
                      loading={isApplyLoading}
                      loadingPosition="end"
                      variant="contained"
                      disabled={!isConfirmationCheckboxChecked || optimizationPreviewSelection.length == 0}
                      size="medium"
                      color="primary"
                    >
                      <span>Apply Changes</span>
                    </LoadingButton>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </OverlayBar>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};
