export class FilterSelectOptionModel {
  name: string;
  selectOptionId: number | string;
  groupName: string;

  constructor(name: string, selectOptionId: number | string, groupName?: string) {
    this.name = name;
    this.selectOptionId = selectOptionId;
    this.groupName = groupName ?? '';
  }
}
