import { apiProfileClient } from '@/lib/api/base-client';
import { MetricsRequest, MetricsResponse } from './metrics-contracts';
import { FilterModel } from '../../filter-builder/models/FilterModel';
import { ApiResponse } from '@/lib/api/api-response';
import { MetricsModel } from '../models/MetricsModel';

export const METRICS_QUERY_KEY = 'metrics';

export class MetricService {
  public basePath = '/campaigns/metrics';

  async getMetrics(filters: FilterModel[]): Promise<ApiResponse<MetricsModel[]>> {
    // Mock front data
    // await new Promise((resolve) => setTimeout(resolve, 1000));
    // return new ApiResponse({
    //   responseCode: ApiResponseCode.SUCCESS,
    //   httpResponseCode: 200,
    //   payload: MockApi.getMetricValues(metrics),
    //   message: '',
    // });
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: MetricsRequest = {
        filters: filterData,
      };

      const applicationResponse = await apiProfileClient.post<MetricsResponse>(`${this.basePath}`, requestData);

      return applicationResponse.processPayload(MetricsModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const metricsService = new MetricService();
