import { ApiResponse } from '@/lib/api/api-response';
import { apiClient, apiTeamClient } from '@/lib/api/base-client';
import {
  CreateProfileReportDTO,
  CreateProfileReportResponse,
  CreateReportsResponse,
  ProfileReportsDTO,
  RestartInitialReportsDTO,
  RestartInitialReportsResponse,
} from './profile.contracts';

export const QUERY_STATUS_REPORT_KEY = 'query_status_report';

class ProfileService {
  public basePath = 'profiles';

  async getReports(activeTeamId: number, profileId: number): Promise<ApiResponse<ProfileReportsDTO>> {
    // const mockData = await getMockReportsData(profileId);
    // return new ApiResponse({
    //   responseCode: ApiResponseCode.SUCCESS,
    //   httpResponseCode: 200,
    //   payload: mockData,
    //   message: '',
    // });

    try {
      // Not using apiTeamClient because active team might not be set in apiClient yet
      const applicationResponse = await apiClient.get<ProfileReportsDTO>(`teams/${activeTeamId}/profiles/${profileId}/reports`, {
        limit: 15,
      });
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async createReports(profileId: number): Promise<CreateReportsResponse> {
    const [targetingReportResponse, placementReportResponse] = await Promise.all([
      this.createReport(profileId, 'targeting'),
      this.createReport(profileId, 'placement'),
    ]);

    return {
      isCreateTargetingReportRequestSuccess: targetingReportResponse.isSuccess,
      isCreatePlacementReportRequestSuccess: placementReportResponse.isSuccess,
    };
  }

  async createReport(profileId: number, type: 'targeting' | 'placement'): Promise<ApiResponse<CreateProfileReportResponse>> {
    try {
      const applicationResponse = await apiTeamClient.post<CreateProfileReportDTO>(
        `${this.basePath}/${profileId}/reports/create?type=${type}`,
        {},
      );
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async restartInitialReports(profileId: number): Promise<ApiResponse<RestartInitialReportsResponse>> {
    try {
      const applicationResponse = await apiClient.post<RestartInitialReportsDTO>(
        `admin/reports/profiles/${profileId}/restart-initial-reports`,
        {},
      );
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async downloadAndProcessReport(profileId: number, reportId: string): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiTeamClient.post<undefined>(
        `${this.basePath}/${profileId}/reports/download?reportid=` + reportId,
        {},
      );
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const profileService = new ProfileService();
