import { Tooltip } from '@mui/material';
import { IHeaderParams } from 'ag-grid-enterprise';
import { FunctionComponent, useEffect, useState } from 'react';

export interface IChangePercentageHeaderRendererProps extends IHeaderParams {
  currentValue?: string;
  currentValueDescription?: string;
}

const ChangePercentageHeaderRenderer: FunctionComponent<IChangePercentageHeaderRendererProps> = (props) => {
  const { displayName, currentValue, column, api, currentValueDescription, progressSort, enableSorting } = props;

  const handleLabelClick = () => {
    if (!enableSorting) return;

    progressSort();
    setIsSortDescending(column.isSortDescending());
    setIsSortAscending(column.isSortAscending());
  };

  const showColumnMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    api.showColumnMenuAfterButtonClick(column.getColId(), event.currentTarget);
  };

  const [isSortDescending, setIsSortDescending] = useState(column.isSortDescending());
  const [isSortAscending, setIsSortAscending] = useState(column.isSortAscending());

  useEffect(() => {
    const sortChangedHandler = () => {
      setIsSortDescending(column.isSortDescending());
      setIsSortAscending(column.isSortAscending());
    };

    api.addEventListener('sortChanged', sortChangedHandler);

    return () => {
      api.removeEventListener('sortChanged', sortChangedHandler);
    };
  }, []);

  return (
    <div className="ag-cell-label-container w-full flex-row items-center justify-between">
      <div onClick={showColumnMenu} className="ag-header-icon ag-header-cell-menu-button">
        <span className="ag-icon ag-icon-menu"></span>
      </div>
      <div className="flex-col items-end text-right" onClick={handleLabelClick}>
        <div className="flex cursor-pointer flex-row items-end justify-end">
          {displayName}
          {isSortAscending === true && <span className="ag-icon ag-icon-asc"></span>}
          {isSortDescending === true && <span className="ag-icon ag-icon-desc"></span>}
        </div>

        {currentValue && (
          <Tooltip title={currentValueDescription ?? ''}>
            <span className="cursor-pointer font-medium">{currentValue}</span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ChangePercentageHeaderRenderer;
