import { createContext, useState, useContext, FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { UserModel } from '../types/UserModel';
import { useQuery } from '@tanstack/react-query';
import { USERS_QUERY_KEY, userService } from '../api/users/users.service';
import { isNil } from 'lodash-es';
import Gleap from 'gleap';

const UPDATE_USER_REFETCH_INTERVAL = 10 * 60 * 1000;
interface UserContextProps {
  user: UserModel | undefined;
  setUser: (user: UserModel | undefined) => void;
  isAdminModeActive: boolean;
  toggleAdminMode: () => void;
  firebaseId: string | undefined;
  clear: () => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps extends PropsWithChildren {
  initialUser: UserModel | undefined;
}

export const UserProvider: FunctionComponent<UserProviderProps> = ({ children, initialUser }) => {
  const [user, setUserState] = useState<UserModel | undefined>(initialUser);
  const [isAdminModeActive, setIsAdminModeActive] = useState<boolean>(false);
  const [isAutoUpdateUserEnabled, setIsAutoUpdateUserEnabled] = useState(false);

  function clear() {
    setUser(undefined);
    setGleapIdentity(undefined);
  }

  function setUser(user: UserModel | undefined) {
    setUserState(user);
    setGleapIdentity(user);
  }

  function setGleapIdentity(user: UserModel | undefined) {
    if (user && user.hash) {
      // Create a map of teams to send to gleap
      const teamsData = user.teams.reduce<{ [key: string]: string }>((acc, team, index) => {
        acc[`team${index + 1}name`] = team.name;
        acc[`team${index + 1}plan`] = team.adlabsPlan;
        return acc;
      }, {});

      Gleap.identify(
        user.firebaseId,
        {
          email: user.email,
          name: user.name,
          customData: {
            ...teamsData,
          },
        },
        user.hash,
      );
    } else {
      Gleap.clearIdentity();
    }
  }

  function toggleAdminMode() {
    setIsAdminModeActive(!isAdminModeActive);
  }
  useQuery({
    queryKey: [USERS_QUERY_KEY, user?.id],
    queryFn: async () => {
      const userSettingsResponse = await userService.getUserById(user?.id as number);
      if (userSettingsResponse.isSuccess) {
        setUser(userSettingsResponse.payload);
        return userSettingsResponse.payload;
      }
      return undefined;
    },
    enabled: !isNil(user) && isAutoUpdateUserEnabled,
    refetchInterval: UPDATE_USER_REFETCH_INTERVAL,
  });

  useEffect(() => {
    if (initialUser) {
      setGleapIdentity(initialUser);
    }

    const timer = setTimeout(() => {
      setIsAutoUpdateUserEnabled(true); // Enable the query after the initial delay
    }, UPDATE_USER_REFETCH_INTERVAL);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, firebaseId: user?.firebaseId, clear, isAdminModeActive, toggleAdminMode }}>
      {children}
    </UserContext.Provider>
  );
};

export function useUserContext(): UserContextProps {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }

  return context;
}
