import { FilterConditionDTO, FilterDTO } from '../api/filters-contracts';
import { FilterKey, FilterType } from '../types/FilterKey';
import { campaignService } from '@/modules/optimizer/api/campaign/campaign-service';
import { isNil, isNumber } from 'lodash-es';
import { MatchType, OptimizationReason } from '../../optimization/api/optimization-contracts';
import { PreviewDataRow } from '../../optimization/models/PreviewDataRow';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../FiltersConfig';
import { FilterSelectOptionModel } from './FilterModelSelectOption';
import { BiddingEntity, isPercentEntity } from '../../optimization/models/OptimizationModel';
import { CampaignState } from '@/modules/optimizer/api/campaign/models/CampaignModel';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';

export enum UnitType {
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
  DATE = 'DATE',
}
export abstract class FilterModel {
  _key?: FilterKey;
  _name?: string;
  _longName?: string;
  _type?: FilterType;
  unitType?: UnitType;
  minLabel?: string;
  maxLabel?: string;
  isOperatorDisabled?: boolean;
  logicalOperator?: LogicalOperatorType;
  conditions?: FilterCondition[];
  options?: FilterSelectOptionModel[];
  isFilterBuilderFilter = true;

  constructor(args?: FilterArguments) {
    if (args) {
      this.logicalOperator = args.logicalOperator;
      this.conditions = args.conditions;
    }
  }

  get key(): FilterKey {
    return this._key ?? ('' as FilterKey);
  }

  get name(): string {
    return this._name ?? '';
  }

  get longName(): string {
    return this._longName ?? '';
  }

  get type(): FilterType {
    return this._type ?? ('' as FilterType);
  }

  async getOptions(): Promise<FilterSelectOptionModel[]> {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  doesFilterPass(value: PreviewDataRow) {
    return true;
  }

  reset(): void {
    this.logicalOperator = undefined;
    this.conditions = undefined;
  }

  doesBetweenFilterPass(currentValue: number): boolean {
    if (this.conditions && this.conditions.length > 0) {
      for (const condition of this.conditions) {
        const operator = condition.operator;
        const conditionValue = Number.parseFloat(condition.values[0] as string);

        if (operator === OperatorType.LESS_THAN_OR_EQUAL) {
          if (currentValue > conditionValue) {
            return false;
          }
        } else if (operator === OperatorType.GREATER_THAN_OR_EQUAL) {
          if (currentValue < conditionValue) {
            return false;
          }
        }
      }
    }
    return true;
  }

  doesDateBetweenFilterPass(currentValue: string | null): boolean {
    if (this.conditions && this.conditions.length > 0) {
      for (const condition of this.conditions) {
        const operator = condition.operator;
        const conditionValue = new Date(condition.values[0]);

        const currentValueDate = currentValue ? new Date(currentValue) : new Date(0);
        if (operator === OperatorType.LESS_THAN_OR_EQUAL) {
          if (currentValueDate > conditionValue) {
            return false;
          }
        } else if (operator === OperatorType.GREATER_THAN_OR_EQUAL) {
          if (currentValueDate < conditionValue) {
            return false;
          }
        }
      }
    }
    return true;
  }

  doesStringComparisonFilterPass(currentValue: string): boolean {
    const currentValueLowerCase = currentValue.toLowerCase();
    if (this.conditions && this.conditions.length > 0) {
      switch (this.logicalOperator) {
        case LogicalOperatorType.AND:
          // All conditions must be true
          for (const condition of this.conditions) {
            const operator = condition.operator;
            // Loop over all condition values
            for (const conditionValue of condition.values.map((value) => value.toString().toLowerCase())) {
              if (operator === OperatorType.LIKE) {
                if (!currentValueLowerCase.includes(conditionValue)) {
                  return false;
                }
              } else if (operator === OperatorType.NOT_LIKE) {
                if (currentValueLowerCase.includes(conditionValue)) {
                  return false;
                }
              }
            }
          }
          break;
        case LogicalOperatorType.OR: {
          // At least one condition must be true
          let isConditionTrue = false;
          for (const condition of this.conditions) {
            const operator = condition.operator;
            // Loop over all condition values
            for (const conditionValue of condition.values.map<string>((value) => value.toString().toLowerCase())) {
              if (operator === OperatorType.LIKE) {
                if (currentValueLowerCase.includes(conditionValue)) {
                  isConditionTrue = true;
                  break;
                }
              } else if (operator === OperatorType.NOT_LIKE) {
                if (!currentValueLowerCase.includes(conditionValue)) {
                  isConditionTrue = true;
                  break;
                }
              }
            }
          }
          return isConditionTrue;
        }
      }
    }
    return true;
  }

  mapConditionsToDTOs(): FilterConditionDTO[] {
    if (isNil(this.conditions) || this.conditions.length === 0) {
      return [];
    }

    return this.conditions.map((condition) => this.mapConditionToDTO(condition));
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((value) => value.toString()),
      operator: condition.operator,
    };
  }

  percentageValueToRatio(value: number | string): string {
    if (isNumber(value)) {
      return (value / 100).toString();
    }
    return (parseFloat(value) / 100).toString();
  }

  toDTO(): FilterDTO {
    return {
      key: this.key,
      logical_operator: this.logicalOperator?.toString(),
      conditions: this.mapConditionsToDTOs(),
    };
  }
}

export interface FilterArguments {
  conditions: FilterCondition[];
  logicalOperator?: LogicalOperatorType;
}

export enum OperatorType {
  EQUAL = '=',
  NOT_EQUAL = '!=',
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL = '<=',
  GREATER_THAN = '>',
  GREATER_THAN_OR_EQUAL = '>=',
  LIKE = 'LIKE',
  NOT_LIKE = 'NOT_LIKE',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
}

export const OperatorDisplayMap: Record<OperatorType, string> = {
  [OperatorType.EQUAL]: '=',
  [OperatorType.NOT_EQUAL]: '≠',
  [OperatorType.LESS_THAN]: '<',
  [OperatorType.LESS_THAN_OR_EQUAL]: '≤',
  [OperatorType.GREATER_THAN]: '>',
  [OperatorType.GREATER_THAN_OR_EQUAL]: '≥',
  [OperatorType.LIKE]: 'CONTAINS',
  [OperatorType.NOT_LIKE]: 'DOES NOT CONTAIN', // TODO: add to translation file? needs hooks
  [OperatorType.IN]: 'IN',
  [OperatorType.NOT_IN]: 'NOT IN',
};

export enum LogicalOperatorType {
  AND = 'AND',
  OR = 'OR',
}

export interface FilterCondition {
  values: string[] | number[];
  operator: OperatorType;
}

export class EmptyFilterModel extends FilterModel {
  _key = '' as FilterKey;
}
export class AcosFilterModel extends FilterModel {
  _key = FilterKey.ACOS;
  _name = 'ACOS';
  _longName = 'ACOS (Ad Cost of Sales)';
  _type = FilterType.BETWEEN;
  unit = '%';
  unitType = UnitType.PERCENTAGE;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.acos.current * 100);
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((v) => this.percentageValueToRatio(v)),
      operator: condition.operator,
    };
  }
}

export class AdGroupFilterModel extends FilterModel {
  _key = FilterKey.AD_GROUP_ID;
  _name = 'Ad Groups Select';
  _type = FilterType.MULTI_SELECT;

  constructor(adGroups: string[]) {
    super();
    this.options = adGroups.map((adGroup) => {
      return new FilterSelectOptionModel(adGroup, adGroup);
    });
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }
    return [];
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as string).includes(value.adGroup);
  }
}

export class AdGroupNameFilterModel extends FilterModel {
  _key = FilterKey.AD_GROUP_NAME;
  _name = 'Ad Group Name';
  _type = FilterType.STRING_COMPARISON;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.adGroup);
  }
}

export class AovFilterModel extends FilterModel {
  _key = FilterKey.AOV;
  _name = 'AOV';
  _longName = 'AOV (Average Order Value)';
  _type = FilterType.BETWEEN;
  unit = '$';
  unitType = UnitType.CURRENCY;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.aov.current);
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((v) => this.percentageValueToRatio(v)),
      operator: condition.operator,
    };
  }
}

export class BudgetFilterModel extends FilterModel {
  _key = FilterKey.BUDGET;
  _name = 'Budget';
  _type = FilterType.BETWEEN;
  unit = '$';
  unitType = UnitType.CURRENCY;
}

export class CampaignFilterModel extends FilterModel {
  _key = FilterKey.CAMPAIGN_ID;
  _name = 'Campaign Select';
  _type = FilterType.MULTI_SELECT;

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }

    const res = await campaignService.getCampaignIds();
    const data = res.payload.map((campaign) => {
      return new FilterSelectOptionModel(campaign.name, campaign.id);
    });

    this.options = data;
    return data;
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as number).includes(value.id);
  }
}

export class CampaignNameFilterModel extends FilterModel {
  _key = FilterKey.CAMPAIGN_NAME;
  _name = 'Campaign Name (contains)';
  _type = FilterType.STRING_COMPARISON;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.campaignName);
  }
}

export class CampaignNameNotFilterModel extends FilterModel {
  _key = FilterKey.CAMPAIGN_NAME_NOT;
  _name = "Campaign Name (doesn't contain)";
  _type = FilterType.STRING_COMPARISON;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.campaignName);
  }
}

export class CampaignGroupFilterModel extends FilterModel {
  _key = FilterKey.CAMPAIGN_GROUP;
  _name = 'Optimization Groups Select';
  _type = FilterType.MULTI_SELECT;

  constructor(campaignGroups: CampaignGroupModel[]) {
    super();
    this.options = campaignGroups.map((campaignGroup) => {
      return new FilterSelectOptionModel(campaignGroup.name, campaignGroup.id);
    });
  }

  async getOptions() {
    if (!isNil(this.options) && this.options.length > 0) {
      return this.options;
    }
    return [];
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    console.log('filter values: ', this.conditions[0].values);
    return this.conditions[0].values.map((v) => v as string).includes(value.campaignGroup);
  }
}

export class CampaignStateFilterModel extends FilterModel {
  _key = FilterKey.CAMPAIGN_STATE;
  _name = 'Campaign Status';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<FilterSelectOptionModel[]> {
    return [
      new FilterSelectOptionModel('Paused', CampaignState.PAUSED),
      new FilterSelectOptionModel('Enabled', CampaignState.ENABLED),
      new FilterSelectOptionModel('Archived', CampaignState.ARCHIVED),
    ];
  }
}

export class ClicksFilterModel extends FilterModel {
  _key = FilterKey.CLICKS;
  _name = 'Clicks';
  _type = FilterType.BETWEEN;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.clicks.current);
  }
}

export class ComparisonDateFilterModel extends FilterModel {
  _key = FilterKey.COMPARE_DATE;
  _name = 'Comparison Date';
  _type = FilterType.BETWEEN;
  minLabel = 'Start Date';
  maxLabel = 'End Date';
  isOperatorDisabled = true;
  isFilterBuilderFilter = false;

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((value) => dayjs(value).format(DATE_FORMAT)),
      operator: condition.operator,
    };
  }
}

export class CpaFilterModel extends FilterModel {
  _key = FilterKey.CPA;
  _name = 'CPA';
  _longName = 'CPA (Cost Per Acquisition)';
  _type = FilterType.BETWEEN;
  unit = '$';

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.cpc.current);
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((v) => this.percentageValueToRatio(v)),
      operator: condition.operator,
    };
  }
}

export class CpcFilterModel extends FilterModel {
  _key = FilterKey.CPC;
  _name = 'CPC';
  _longName = 'CPC (Cost Per Click)';
  _type = FilterType.BETWEEN;
  unit = '$';
  unitType = UnitType.CURRENCY;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.cpc.current);
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((v) => this.percentageValueToRatio(v)),
      operator: condition.operator,
    };
  }
}
export class CpmFilterModel extends FilterModel {
  _key = FilterKey.CPM;
  _name = 'CPM';
  _longName = 'CPM (Cost Per Thousand Impressions)';
  _type = FilterType.BETWEEN;
  unit = '$';

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.cpm.current);
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((v) => this.percentageValueToRatio(v)),
      operator: condition.operator,
    };
  }
}

export class CtrFilterModel extends FilterModel {
  _key = FilterKey.CTR;
  _name = 'CTR';
  _longName = 'CTR (Click-Through Rate)';
  _type = FilterType.BETWEEN;
  unit = '%';
  unitType = UnitType.PERCENTAGE;

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((v) => this.percentageValueToRatio(v)),
      operator: condition.operator,
    };
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.ctr.current * 100);
  }
}

export class CvrFilterModel extends FilterModel {
  _key = FilterKey.CVR;
  _name = 'CVR';
  _longName = 'CVR (Conversion Rate)';
  _type = FilterType.BETWEEN;
  unit = '%';
  unitType = UnitType.PERCENTAGE;

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((v) => this.percentageValueToRatio(v)),
      operator: condition.operator,
    };
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.cvr.current * 100);
  }
}

export class DateFilterModel extends FilterModel {
  _key = FilterKey.DATE;
  _name = 'Date';
  _type = FilterType.BETWEEN;
  minLabel = 'Start Date';
  maxLabel = 'End Date';
  isOperatorDisabled = true;
  isFilterBuilderFilter = false;

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((value) => dayjs(value).format(DATE_FORMAT)),
      operator: condition.operator,
    };
  }
}

export class LastOptimizedDateModel extends FilterModel {
  _key = FilterKey.DATE;
  _name = 'Last Optimized';
  _type = FilterType.BETWEEN;
  minLabel = 'Start Date';
  maxLabel = 'End Date';
  isOperatorDisabled = true;
  isFilterBuilderFilter = true;
  unitType = UnitType.DATE;

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((value) => dayjs(value).format(DATE_FORMAT)),
      operator: condition.operator,
    };
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesDateBetweenFilterPass(value.lastOptimizedAt);
  }
}

export class OldValueFilterModel extends FilterModel {
  _key = FilterKey.OLD_VALUE;
  _name = 'Old Value';
  _type = FilterType.BETWEEN;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(isPercentEntity(value.biddingEntity) ? value.oldValue * 100 : value.oldValue);
  }
}

export class NewValueFilterModel extends FilterModel {
  _key = FilterKey.NEW_VALUE;
  _name = 'New Value';
  _type = FilterType.BETWEEN;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(isPercentEntity(value.biddingEntity) ? value.newValue * 100 : value.newValue);
  }
}

export class DeltaFilterModel extends FilterModel {
  _key = FilterKey.DELTA;
  _name = 'Delta';
  _type = FilterType.BETWEEN;
  unit = '%'; //TODO: replace 'unit' with 'unitType' everywhere
  unitType = UnitType.PERCENTAGE;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.delta * 100);
  }
}

export class EntityTypeFilterModel extends FilterModel {
  _key = FilterKey.ENTITY_TYPE;
  _name = 'Bidding Entities';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<FilterSelectOptionModel[]> {
    return [
      new FilterSelectOptionModel('Keyword', BiddingEntity.KEYWORD),
      new FilterSelectOptionModel('Placement Product Page', BiddingEntity.PLACEMENT_PRODUCT_PAGE),
      new FilterSelectOptionModel('Placement Rest of Search', BiddingEntity.PLACEMENT_REST_OF_SEARCH),
      new FilterSelectOptionModel('Placement Top', BiddingEntity.PLACEMENT_TOP),
      new FilterSelectOptionModel('Product Target', BiddingEntity.PRODUCT_TARGET),
    ];
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values.map((v) => v as BiddingEntity).includes(value.biddingEntity);
  }
}
export class ImpressionsFilterModel extends FilterModel {
  _key = FilterKey.IMPRESSIONS;
  _name = 'Impressions';
  _type = FilterType.BETWEEN;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.impressions.current);
  }
}

export class MatchTypeFilterModel extends FilterModel {
  _key = FilterKey.MATCH_TYPE;
  _name = 'Match Type';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<FilterSelectOptionModel[]> {
    return [
      new FilterSelectOptionModel('Auto', 0),
      new FilterSelectOptionModel('Exact', 1),
      new FilterSelectOptionModel('Expanded', 2),
      new FilterSelectOptionModel('Category', 3),
      new FilterSelectOptionModel('Phrase', 4),
      new FilterSelectOptionModel('Broad', 5),
    ];
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values
      .map<MatchType>((v) => {
        switch (v) {
          case 0:
            return MatchType.AUTO;
          case 1:
            return MatchType.EXACT;
          case 2:
            return MatchType.EXPANDED;
          case 3:
            return MatchType.CATEGORY;
          case 4:
            return MatchType.PHRASE;
          case 5:
            return MatchType.BROAD;
          default:
            return MatchType.NONE;
        }
      })
      .includes(value.match);
  }
}
export class OrdersFilterModel extends FilterModel {
  _key = FilterKey.ORDERS;
  _name = 'Orders';
  _type = FilterType.BETWEEN;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.orders.current);
  }
}

export class PortfolioFilterModel extends FilterModel {
  _key = FilterKey.PORTFOLIO_ID;
  _name = 'Portfolios';
  _type = FilterType.MULTI_SELECT;
}

export class PortfolioNameFilterModel extends FilterModel {
  _key = FilterKey.PORTFOLIO_NAME;
  _name = 'Portfolio name';
  _type = FilterType.STRING_COMPARISON;
}

export class OptimizationReasonFilterModel extends FilterModel {
  _key = FilterKey.REASON;
  _name = 'Change Reason';
  _type = FilterType.MULTI_SELECT;

  async getOptions(): Promise<FilterSelectOptionModel[]> {
    const keywordsGroupName = 'Keywords';
    const placementsGroupName = 'Placements';

    const KEYWORD_REASONS_1: FilterSelectOptionModel[] = [
      new FilterSelectOptionModel('High ACOS', OptimizationReason.HIGH_ACOS, keywordsGroupName),
      new FilterSelectOptionModel('High Spend, No Sales', OptimizationReason.HIGH_SPEND, keywordsGroupName),
      new FilterSelectOptionModel('Low Visibility', OptimizationReason.LOW_VISIBILITY, keywordsGroupName),
      new FilterSelectOptionModel('Low ACOS', OptimizationReason.LOW_ACOS, keywordsGroupName),
    ];

    const KEYWORD_REASONS_2: FilterSelectOptionModel[] = [
      new FilterSelectOptionModel('User bid ceiling', OptimizationReason.USER_BID_CEILING, keywordsGroupName),
      new FilterSelectOptionModel('User bid floor', OptimizationReason.MAX_BID_DECREASE, keywordsGroupName),
      new FilterSelectOptionModel('Ad group bid ceiling', OptimizationReason.AD_GROUP_BID_CEILING, keywordsGroupName),
      new FilterSelectOptionModel('Campaign bid ceiling', OptimizationReason.CAMPAIGN_BID_CEILING, keywordsGroupName),
      new FilterSelectOptionModel('Campaign group bid ceiling', OptimizationReason.CAMPAIGN_GROUP_BID_CEILING, keywordsGroupName),
      new FilterSelectOptionModel('Profile bid ceiling', OptimizationReason.PROFILE_BID_CEILING, keywordsGroupName),
      new FilterSelectOptionModel('Lowest possible bid', OptimizationReason.LOWEST_POSSIBLE_BID, keywordsGroupName),
    ];
    const PLACEMENTS_REASONS_1: FilterSelectOptionModel[] = [
      new FilterSelectOptionModel('Campaign placement performance', OptimizationReason.CAMPAIGN_PERFORMANCE, placementsGroupName),
      new FilterSelectOptionModel('Campaign group placement performance', OptimizationReason.CAMPAIGN_GROUP_PERFORMANCE, placementsGroupName),
      new FilterSelectOptionModel('Profile placement performance', OptimizationReason.PROFILE_PERFORMANCE, placementsGroupName),
    ];

    const PLACEMENTS_REASONS_2: FilterSelectOptionModel[] = [
      new FilterSelectOptionModel('25% incremental limit', OptimizationReason.SMALLEST_POSSIBLE_INCREASE, placementsGroupName),
      new FilterSelectOptionModel('Lowest possible adjustment (if 0%)', OptimizationReason.LOWEST_POSSIBLE_ADJUSTMENT, placementsGroupName),
      new FilterSelectOptionModel(
        'Highest possible adjustment (if 900%)',
        OptimizationReason.HIGHEST_POSSIBLE_ADJUSTMENT,
        placementsGroupName,
      ),
    ];

    return [...KEYWORD_REASONS_1, ...KEYWORD_REASONS_2, ...PLACEMENTS_REASONS_1, ...PLACEMENTS_REASONS_2];
  }

  doesFilterPass(value: PreviewDataRow): boolean {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    }

    return this.conditions[0].values
      .map((v) => v as OptimizationReason)
      .some((v) => {
        if (!value.reasons) {
          return false;
        }

        console.log({ v, reasons: value.reasons });
        return value.reasons.some((r) => r === v);
      });
  }
}

export class RoasFilterModel extends FilterModel {
  _key = FilterKey.ROAS;
  _name = 'ROAS';
  _longName = 'ROAS (Return on Ad Spend)';
  _type = FilterType.BETWEEN;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.roas.current * 100);
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((v) => this.percentageValueToRatio(v)),
      operator: condition.operator,
    };
  }
}

export class RpcFilterModel extends FilterModel {
  _key = FilterKey.RPC;
  _name = 'RPC';
  _longName = 'RPC (Revenue Per Click)';
  _type = FilterType.BETWEEN;
  unit = '$';
  unitType = UnitType.CURRENCY;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.rpc.current);
  }

  mapConditionToDTO(condition: FilterCondition): FilterConditionDTO {
    return {
      values: condition.values.map((v) => this.percentageValueToRatio(v)),
      operator: condition.operator,
    };
  }
}

export class SalesFilterModel extends FilterModel {
  _key = FilterKey.SALES;
  _name = 'Sales';
  _type = FilterType.BETWEEN;
  unit = '$';
  unitType = UnitType.CURRENCY;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.sales.current);
  }
}

export class SpendFilterModel extends FilterModel {
  _key = FilterKey.SPEND;
  _name = 'Spend';
  _type = FilterType.BETWEEN;
  unit = '$';
  unitType = UnitType.CURRENCY;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesBetweenFilterPass(value.spend.current);
  }
}

export class TargetingFilterModel extends FilterModel {
  _key = FilterKey.TARGETING;
  _name = 'Targeting';
  _type = FilterType.STRING_COMPARISON;

  doesFilterPass(value: PreviewDataRow): boolean {
    return this.doesStringComparisonFilterPass(value.targeting);
  }
}

export class TargetingTypeFilterModel extends FilterModel {
  _key = FilterKey.TARGETING_TYPE;
  _name = 'Targeting type';
  _type = FilterType.MULTI_SELECT;
}

export function createCampaignFilters(campaignGroups: CampaignGroupModel[]): FilterModel[] {
  return [
    new CampaignGroupFilterModel(campaignGroups),
    new DateFilterModel(),
    new ComparisonDateFilterModel(),
    new CampaignFilterModel(),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new CampaignStateFilterModel(),
    new BudgetFilterModel(),
    new ImpressionsFilterModel(),
    new ClicksFilterModel(),
    new OrdersFilterModel(),
    new SpendFilterModel(),
    new SalesFilterModel(),
    new CtrFilterModel(),
    new CvrFilterModel(),
    new CpcFilterModel(),
    new AcosFilterModel(),
    new RoasFilterModel(),
    new RpcFilterModel(),
    new CpaFilterModel(),
    new AovFilterModel(),
    new CpmFilterModel(),
  ];
}

export function createOptimizerFilters(adGroups: string[], campaignGroups: CampaignGroupModel[]): FilterModel[] {
  return [
    new CampaignGroupFilterModel(campaignGroups),
    new LastOptimizedDateModel(),
    new AdGroupFilterModel(adGroups),
    new AdGroupNameFilterModel(),
    new CampaignFilterModel(),
    new CampaignNameFilterModel(),
    new CampaignNameNotFilterModel(),
    new OldValueFilterModel(),
    new NewValueFilterModel(),
    new DeltaFilterModel(),
    new OptimizationReasonFilterModel(),
    new EntityTypeFilterModel(),
    new MatchTypeFilterModel(),
    new TargetingFilterModel(),
    new ImpressionsFilterModel(),
    new ClicksFilterModel(),
    new OrdersFilterModel(),
    new SpendFilterModel(),
    new SalesFilterModel(),
    new CtrFilterModel(),
    new CvrFilterModel(),
    new CpcFilterModel(),
    new AcosFilterModel(),
    new RoasFilterModel(),
    new RpcFilterModel(),
    new CpaFilterModel(),
    new AovFilterModel(),
    new CpmFilterModel(),
  ];
}

export const getUpdatedFiltersValue = (previousValue: FilterModel[], updatedFilter: FilterModel) => {
  const updatedFilters = [...previousValue];
  const existingFilterIndex = updatedFilters.findIndex((filterItem) => filterItem.key === updatedFilter.key);

  if (existingFilterIndex !== -1) {
    const existingFilter = updatedFilters[existingFilterIndex];
    existingFilter.conditions = updatedFilter.conditions;
    updatedFilters[existingFilterIndex] = existingFilter;
  } else {
    updatedFilters.push(updatedFilter);
  }

  return updatedFilters;
};
