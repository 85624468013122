import { FunctionComponent, useState } from 'react';
import { getConfigForMetric } from '../metrics/MetricsConfig';
import Typography from '@mui/material/Typography';
import { useTemplateContext } from '../../contexts/TemplateContext';
import { Alert, AlertTitle, Card, Skeleton } from '@mui/material';
import {
  type EChartsOption,
  type ECharts,
  type TooltipComponentOption,
  LineSeriesOption,
  YAXisComponentOption,
  TooltipComponentFormatterCallbackParams,
  BarSeriesOption,
  ECElementEvent,
} from 'echarts';
import { ReactECharts } from '@/components/chart/ReactEcharts';
import { isArray, isEmpty } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';
import { TimelineModel } from './models/TimelineModel';
import useFormatting from '@/hooks/useFormatting';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { MetricField } from '../metrics/models/CommonMetricsModel';
import CollapsibleCopyableDetails from '@/components/CollapsibleCopyableDetails';
import { useTranslation } from '@/lib';
import { campaignService, createCampaignsWithTimelineQueryKey } from '../../api/campaign/campaign-service';

const MetricTimelineChart: FunctionComponent = () => {
  const { filters, selectedMetrics, selectedMetricColors } = useTemplateContext();
  const { activeProfile } = useActiveTeamContext();
  const { t } = useTranslation();

  const campaignsWithTimelineQueryKey = createCampaignsWithTimelineQueryKey(activeProfile?.id, filters);
  const {
    data: campaignsWithTimeline,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: campaignsWithTimelineQueryKey,
    queryFn: async () => {
      const result = await campaignService.getCampaignsWithTimeline(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading timeline data ' + JSON.stringify(result));
      }
    },

    enabled: !isEmpty(selectedMetrics) && !isEmpty(filters),
    retry: false,
  });

  const { getLongFormatterForMetricField, getShortFormatterForMetricField } = useFormatting();

  function getChartOptions(data: TimelineModel): EChartsOption {
    const series: (LineSeriesOption | BarSeriesOption)[] = [];
    const yAxis: YAXisComponentOption[] = [];

    const xAxisData = data.xAxisData;
    const yAxisData = data.yAxisData.filter((item) => selectedMetrics.includes(item.key));

    yAxisData.forEach((dataItem, index: number) => {
      const metricConfig = getConfigForMetric(dataItem.key);
      if (!metricConfig) {
        return;
      }

      const color = selectedMetricColors.find((color) => color.key === dataItem.key)?.color;

      series.push({
        id: dataItem.key,
        name: metricConfig.title,
        type: metricConfig.chartSeriesType,
        yAxisIndex: index,
        data: dataItem.values,
        color: color,
        emphasis: {
          focus: 'series',
        },

        showSymbol: false,
      });

      yAxis.push({
        name: metricConfig?.title,
        type: 'value',
        position: index === 0 ? 'left' : 'right',
        offset: index === 0 ? 0 : yAxisData.length === 2 ? 0 : 60 * (index - 1), // Conditional offset based on number of Y-axes
        nameTextStyle: {
          fontWeight: 'bold', // Make axis name bold
          fontFamily: 'Roboto',
        },
        axisLabel: {
          fontWeight: 'bold', // Make axis labels bold
          fontFamily: 'Roboto',
          formatter: (value: number) => getShortFormatterForMetricField(dataItem.key)(value),
        },
        axisPointer: {
          // Configure hover formatting for the Y-axis
          label: {
            show: true,
            formatter: (params) => getShortFormatterForMetricField(dataItem.key)(params.value as number),
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: color,
          },
        },
      });
    });

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return {
      xAxis: {
        type: 'category',
        data: xAxisData,
        boundaryGap: true,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          formatter: function (value: string) {
            const date = new Date(`${value}T00:00:00Z`);
            const month = monthNames[date.getUTCMonth()];
            const day = date.getUTCDate();
            return `${month} ${day}`;
          },
          fontWeight: 'bolder',
          fontFamily: 'Roboto',
        },
        splitLine: {
          show: false, // Hide vertical lines
        },
      },
      yAxis: yAxis,
      series: series,
      grid: {
        left: 50,
        right: 50 * yAxisData.length,
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { readOnly: false, buttonColor: '#0284c7' },
          // magicType: { type: ['line', 'bar'] },
          //restore: {},
          saveAsImage: { name: 'Timeline Chart' },
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
        formatter: function (params: TooltipComponentFormatterCallbackParams): string {
          if (isArray(params)) {
            let result = `<b>${params[0].name}</b>` + '<br/>'; // Add the x-axis series name (date)
            params.forEach((param) => {
              const formattedValue = getLongFormatterForMetricField(param.seriesId as MetricField)(param.value as number);
              if (param.seriesName === hoveredSeries) {
                result += `<b>${param.marker}${param.seriesName}: ${formattedValue}</b><br/>`;
              } else {
                result += `${param.marker}${param.seriesName}: ${formattedValue}<br/>`;
              }
            });
            return result;
          } else {
            return '';
          }
        },
      } as TooltipComponentOption,
    };
  }

  const [hoveredSeries, setHoveredSeries] = useState<string>();

  function onChartReady(instance: ECharts) {
    instance.on('mouseover', (params: ECElementEvent) => {
      setHoveredSeries(params.seriesName);
    });
    instance.on('mouseout', () => {
      setHoveredSeries(undefined);
    });
  }

  const renderError = () => (
    <Alert severity="error">
      <AlertTitle>Oops!</AlertTitle>
      <div className="flex w-full flex-col">
        <Typography variant="body1">{t('messages.errors.generic')}</Typography>

        <CollapsibleCopyableDetails headerText={'Details'} message={(error as Error).message} />
      </div>
    </Alert>
  );

  return (
    <Card className="rounded-xl py-0">
      {isError ? renderError() : null}
      {isLoading && !campaignsWithTimeline && selectedMetrics.length > 0 ? (
        <Skeleton variant="rectangular" width={'full'} height={300} className="my-10" />
      ) : campaignsWithTimeline &&
        campaignsWithTimeline.timeline.xAxisData.length > 0 &&
        campaignsWithTimeline.timeline.yAxisData.length > 0 &&
        selectedMetrics.length > 0 ? (
        <ReactECharts
          option={getChartOptions(campaignsWithTimeline.timeline)}
          style={{ height: '400px', paddingLeft: '0.5rem' }}
          onChartReady={onChartReady}
          settings={{ notMerge: true }}
        />
      ) : (
        !isError && (
          <div className="text-center">
            <Typography variant="overline" className="font-bold text-primary-600">
              select at least one metric to display the timeline graph
            </Typography>
          </div>
        )
      )}
    </Card>
  );
};

export default MetricTimelineChart;
