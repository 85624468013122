import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import { useMemo, useState } from 'react';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
import { Timezone } from '@/modules/users/types/Timezone';
import { CurrencyCode } from '@/modules/users/types/CurrencyCode';
import { CountryCode } from '@/modules/users/types/CountryCode';
import { ProfileState } from '@/modules/users/types/ProfileState';
import ButtonCallbackCellRenderer, { IButtonCallbackCellRendererParams } from '@/components/grid/cells/ButtonCallbackCellRenderer';
import { profileService } from '@/modules/profiles/api/profile.service';
import { toast } from 'react-toastify';
import ButtonPopoverCellRenderer, { IButtonPopoverCellRendererParams } from '@/components/grid/cells/ButtonPopoverCellRenderer';
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { AdditionalStatusType, StatusType } from '@/modules/profiles/api/profile.contracts';
import { ProfileReportStatus, useProfileReports } from '@/modules/optimizer/hooks/useProfileReports';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import { ChipCellRenderer, IChipCellRendererParams } from '@/components/grid/cells/ChipCellRenderer';
import { MuiColorVariant } from '@/config/theme/color.type';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { useTranslation } from '@/lib';
import useFormatting from '@/hooks/useFormatting';
import { useNavigate } from 'react-router-dom';
import { sleep } from '@/lib/api/api-utils';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButtonCallbackCellRenderer from '@/components/grid/cells/IconButtonCallbackCellRenderer';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { teamService } from '../api/teams.service';
import { useUserContext } from '@/modules/users';
import { profileDataStatusColorMap } from '@/types/colors.enum';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { SyncStatusReason, useReportsContext } from '../contexts/ReportsContext';
import LinkCallbackCellRenderer, { ILinkCallbackCellRendererParams } from '@/components/grid/cells/LinkCallbackCellRenderer';

interface ProfileTableRow {
  profileId: number;
  profileName: string;
  state: ProfileState;
  billingStatus: boolean;
  market: CountryCode;
  currency: CurrencyCode;
  timezone: Timezone;
  type: string;
  onboardDate: string;
  teamAccess: string[];
  reportData: string | undefined;
}

const ProfileTable = () => {
  const navigate = useNavigate();

  const { setActiveProfile, activeTeam, removeProfileFromTeam } = useActiveTeamContext();
  const {
    updateProfileData,
    getProfileReportsData,
    refetchProfileStatus,
    getProfileReportStatusInfoByProfileId,
    addProfileToLocalLoadingState,
    removeProfileFromLocalLoadingState,
    profileSyncStatus,
  } = useReportsContext();

  const { isAdminModeActive } = useUserContext();
  const { getProfileReportInfo } = useProfileReports(); // TODO: move to context
  const { t } = useTranslation();

  const { formatDateStringTimeNoSeconds } = useFormatting();

  const [gridApi, setGridApi] = useState<GridApi>();

  const rowData: ProfileTableRow[] =
    activeTeam?.profiles?.map((profile) => ({
      profileId: profile.id,
      profileName: profile.name,
      state: profile.state,
      billingStatus: true,
      market: profile.countryCode,
      currency: profile.currencyCode,
      timezone: profile.timeZone,
      type: profile.type,
      onboardDate: profile.createdAt,
      teamAccess: ['member1', 'member2', 'member3'], //TODO
      reportData: undefined,
    })) || [];

  async function onProfileNameClicked(profileRowData: ProfileTableRow) {
    try {
      if (!profileRowData.profileId) {
        return;
      }
      setActiveProfile(profileRowData.profileId);
      await sleep(50); // wait for 50 ms before navigate
      navigate('/app/optimizer');
    } catch (error) {
      console.log(error);
      toast.error('Unable to navigate to profile. Please try again later.');
    }
  }

  async function onUpdateProfileClicked(profileRowData: ProfileTableRow) {
    const profileId = profileRowData.profileId;
    if (!profileId) {
      return;
    }

    try {
      if (profileSyncStatus(profileId).reason == SyncStatusReason.PLAN_RESTRICTION) {
        setIsPaywallModalOpen(true);
        return;
      }

      addProfileToLocalLoadingState(profileId);

      await updateProfileData(profileId);

      refetchProfileStatus(profileId);
      toast.success('Report generation started successfully');
    } catch (error) {
      console.log(error);
      toast.error('Unable to update profile data. Please try again later.');

      removeProfileFromLocalLoadingState(profileId);
    }
  }

  async function onRestartInitialReportsClicked(profileRowData: ProfileTableRow) {
    const profileId = profileRowData.profileId;
    if (!profileId) {
      return;
    }

    try {
      addProfileToLocalLoadingState(profileId);

      const response = await profileService.restartInitialReports(profileId);
      if (!response.isSuccess) {
        throw new Error(response.message);
      }

      refetchProfileStatus(profileId);

      toast.success('Initial reports generation started successfully');
    } catch (error) {
      console.log(error);
      toast.error('Unable to restart initial reports');

      removeProfileFromLocalLoadingState(profileId);
    }
  }

  const columnDefs: ColDefOrGroup<ProfileTableRow>[] = [
    {
      colId: ColumnId.PROFILE_NAME,
      headerName: 'Profile Name',
      minWidth: 150,
      cellRenderer: LinkCallbackCellRenderer,
      cellRendererParams: (params: ICellRendererParams<ProfileTableRow>): ILinkCallbackCellRendererParams<ProfileTableRow> => {
        return {
          buttonText: params.data?.profileName || '',
          callback: onProfileNameClicked,
        };
      },
    },
    {
      colId: ColumnId.MANUAL_UPDATE,
      headerName: 'Update Data',
      minWidth: 135,
      cellRenderer: ButtonCallbackCellRenderer,
      cellRendererParams: (
        params: ICellRendererParams & { value: ProfileReportStatus },
      ): IButtonCallbackCellRendererParams<ProfileTableRow> => {
        const profileId = params.data?.profileId;
        const thisProfileSyncStatus = profileSyncStatus(profileId);

        return {
          buttonText: 'Update Data',
          callback: onUpdateProfileClicked,
          isDisabled: !thisProfileSyncStatus.canClickSync,
          tooltip: thisProfileSyncStatus.reason ? t(`labels.${thisProfileSyncStatus.reason}`) : undefined,
        };
      },
      valueGetter: (params: ValueGetterParams<ProfileTableRow>) => {
        return getProfileReportStatusInfoByProfileId(params.data?.profileId);
      },
    },
    {
      colId: ColumnId.DATA_STATUS,
      headerName: 'Data status',
      minWidth: 150,
      cellRenderer: ChipCellRenderer,
      cellRendererParams: (params: ICellRendererParams & { value: ProfileReportStatus }): IChipCellRendererParams => {
        const dataStatus: AdditionalStatusType | StatusType = params.value?.status || null;
        const color: MuiColorVariant = dataStatus
          ? profileDataStatusColorMap[dataStatus] || MuiColorVariant.Success
          : MuiColorVariant.Success;

        const value = dataStatus ? t(`team_profiles_page.labels.${dataStatus}`) : '';
        return {
          chipColor: color,
          chipLabel: value,
          loadingValue: AdditionalStatusType.LOADING,
          tooltip: dataStatus ? t(`team_profiles_page.labels.${dataStatus}` + ': ' + t(`team_profiles_page.descriptions.${dataStatus}`)) : '',
        };
      },
      valueGetter: (params: ValueGetterParams<ProfileTableRow>) => {
        return getProfileReportStatusInfoByProfileId(params.data?.profileId);
      },
      valueSetter: (params: ValueSetterParams & { data: ProfileTableRow; newValue: ProfileReportStatus }) => {
        const prevValue = params.data[ColumnId.DATA_STATUS];
        if (prevValue !== params.newValue.status) {
          params.data[ColumnId.DATA_STATUS] = params.newValue.status;
          return true; // This tells the grid the value has changed, and it needs to be refreshed.
        }
        return false; // This tells the grid the value hasn't changed, no need to refresh.
      },
    },
    {
      colId: ColumnId.UPDATED_AT,
      headerName: 'Last Updated',
      minWidth: 250,
      valueFormatter: (params: ValueFormatterParams & { value: ProfileReportStatus }): string => {
        const updatedAt = params.value.lastUpdated ?? '';
        const dataStatus = params.value?.status || null;
        if (dataStatus == AdditionalStatusType.NEVER) {
          return t(`team_profiles_page.labels.${AdditionalStatusType.NEVER}`);
        }

        return formatDateStringTimeNoSeconds(updatedAt);
      },
      valueGetter: (params: ValueGetterParams<ProfileTableRow>) => {
        return getProfileReportStatusInfoByProfileId(params.data?.profileId);
      },
    },
    {
      colId: ColumnId.MARKET,
      headerName: 'Market',
      field: 'market',
      minWidth: 100,
    },
    {
      colId: ColumnId.CURRENCY,
      headerName: 'Currency',
      field: 'currency',
      minWidth: 120,
    },
    {
      colId: ColumnId.TIMEZONE,
      headerName: 'Timezone',
      field: 'timezone',
      minWidth: 185,
    },
    {
      colId: ColumnId.TYPE,
      headerName: 'Type',
      field: 'type',
      minWidth: 100,
    },
    {
      colId: ColumnId.ONBOARD_DATE,
      headerName: 'Onboarded On AdLabs',
      field: 'onboardDate',
      minWidth: 250,
      valueFormatter: (params) => formatDateStringTimeNoSeconds(params.value),
    },
    {
      colId: ColumnId.REMOVE_PROFILE,
      headerName: 'Remove profile',
      cellRenderer: IconButtonCallbackCellRenderer,
      cellRendererParams: () => {
        return {
          buttonText: 'Remove',
          icon: <DeleteIcon />,
          callback: onRemoveProfileClicked,
        };
      },
    },
  ];

  if (isAdminModeActive) {
    const restartInitialReportsColDef: ColDef<ProfileTableRow> = {
      colId: ColumnId.RESTART_INITIAL_REPORTS,
      headerName: 'Restart Initial Reports',
      minWidth: 135,
      cellRenderer: ButtonCallbackCellRenderer,
      cellRendererParams: (): IButtonCallbackCellRendererParams<ProfileTableRow> => {
        return {
          buttonText: 'Restart Initial Reports',
          callback: onRestartInitialReportsClicked,
          isDisabled: false,
        };
      },
      valueGetter: (params: ValueGetterParams<ProfileTableRow>) => {
        //TODO: reduce duplication between valueGetters, tidy function types
        const profileId = params.data?.profileId;
        const reportData = getProfileReportsData(profileId);
        const profileReportInfo = getProfileReportInfo(reportData || null); //TODO: refactor

        return profileReportInfo;
      },
    };

    columnDefs.unshift(restartInitialReportsColDef);

    const debugColDef: ColDef<ProfileTableRow> = {
      colId: ColumnId.REPORT_INFO,
      headerName: 'Report Info',
      field: 'reportData',
      cellRenderer: ButtonPopoverCellRenderer,
      cellRendererParams: (params: ICellRendererParams<ProfileTableRow>): IButtonPopoverCellRendererParams => {
        return {
          content: params.value,
        };
      },
      valueGetter: (params: ValueGetterParams<ProfileTableRow>) => {
        const profileId = params.data?.profileId;
        const profileReportsData = getProfileReportsData(profileId);
        return JSON.stringify(profileReportsData, null, 2);
      },
      minWidth: 125,
    };

    columnDefs.unshift(debugColDef);
  }

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const customGridOptions: GridOptions<ProfileTableRow> = {
    ...DEFAULT_GRID_OPTIONS,
    defaultColDef: {
      resizable: true,
      sortable: true,
      minWidth: 100,
    },
  };

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => params.data.profileId as string;
  }, []);

  const tableHeight = `calc(100vh - 140px)`;

  const [removeProfileConfirmationDialogOpen, setRemoveProfileConfirmationDialogOpen] = useState(false);
  const [removeProfileData, setRemoveProfileData] = useState<ProfileTableRow>();
  async function onRemoveProfileClicked(profileRowData: ProfileTableRow) {
    setRemoveProfileData(profileRowData);
    setRemoveProfileConfirmationDialogOpen(true);
  }

  const handleConfirmProfileRemove = async () => {
    try {
      if (activeTeam && removeProfileData) {
        const result = await teamService.removeProfilesFromTeam(activeTeam.id, [removeProfileData?.profileId]);

        if (result.isSuccess) {
          toast.success('Profile removed successfully');
          //setRowData((prevRowData) => prevRowData.filter((row) => row.profileId !== removeProfileData?.profileId));
          removeRowByProfileId(removeProfileData?.profileId);
          removeProfileFromTeam(removeProfileData?.profileId);
        } else {
          throw new Error(result.message);
        }
      }
    } catch (error) {
      console.log(error);

      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Unable to remove profile. Please try again later.');
      }
    } finally {
      setRemoveProfileConfirmationDialogOpen(false);
      setRemoveProfileData(undefined);
    }
  };

  function removeRowByProfileId(profileId: number) {
    if (!gridApi) {
      return;
    }
    const rowNode = gridApi.getRowNode(profileId.toString());

    if (rowNode && rowNode.rowIndex !== null) {
      // Get the current data from the grid
      const displayedRow = gridApi.getDisplayedRowAtIndex(rowNode.rowIndex);

      if (displayedRow) {
        const currentData: ProfileTableRow[] = displayedRow.data;

        // Remove the row data
        const updatedData = currentData.filter((row: ProfileTableRow) => row.profileId !== profileId);

        // Set the updated data to the grid
        gridApi.setRowData(updatedData);
      }
    }
  }

  const handleCancelProfileRemove = () => {
    setRemoveProfileConfirmationDialogOpen(false);
    setRemoveProfileData(undefined);
  };

  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  return (
    <>
      <div style={{ height: tableHeight }}>
        <AlGrid
          colDefs={columnDefs}
          rowData={rowData}
          gridOptions={customGridOptions}
          getRowId={getRowId}
          onGridReadyCallback={onGridReady}
        />
      </div>

      <Dialog sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }} maxWidth="xs" open={removeProfileConfirmationDialogOpen}>
        <DialogTitle> {`Remove profile?`}</DialogTitle>
        <DialogContent dividers>{`Are you sure you want to remove ${removeProfileData?.profileName}?`}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelProfileRemove}>
            Cancel
          </Button>
          <Button onClick={handleConfirmProfileRemove}>Ok</Button>
        </DialogActions>
      </Dialog>

      <PaywallModal isOpen={isPaywallModalOpen} onClose={onClosePaywallModal}>
        {`Free sync limit in one day reached. Upgrade your subscription to update date more often.`}
      </PaywallModal>
    </>
  );
};

export default ProfileTable;
