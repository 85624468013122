import { apiProfileClient } from '@/lib/api/base-client';
import {
  CampaignGroupDTO,
  CampaignIdsResponse,
  CampaignWithComparisonRequest,
  CampaignWithTimelineDTO,
  CampaignsWithTimelineResponse,
  ChangeCampaignGroupDTO,
} from './campaign-contracts';
import { ApiResponse } from '@/lib/api/api-response';
import { FilterModel } from '../../components/filter-builder/models/FilterModel';
import { CampaignModel, CampaignWithIdAndName } from './models/CampaignModel';
import { TimelineModel } from '../../components/timeline/models/TimelineModel';
import { CampaignGroupModel } from './models/CampaignGroupModel';

export const CAMPAIGN_GROUPS_QUERY_KEY = 'campaign-groups';

export function createCampaignsWithTimelineQueryKey(activeProfileId: number | undefined, filters: FilterModel[]) {
  const CAMPAIGNS_WITH_TIMELINE_QUERY_KEY = 'campaigns-with-timeline'; // don't expose this and only use creator
  return [CAMPAIGNS_WITH_TIMELINE_QUERY_KEY, activeProfileId, filters];
}

export class CampaignService {
  public basePath = 'campaigns';

  async getCampaignsWithTimeline(filters?: FilterModel[]): Promise<ApiResponse<CampaignsWithTimelineResponse>> {
    try {
      const filterData = filters?.map((filter) => filter.toDTO());

      const requestData: CampaignWithComparisonRequest = {};
      if (filterData) {
        requestData.filters = filterData;
      }

      const applicationResponse = await apiProfileClient.post<CampaignWithTimelineDTO>(`${this.basePath}/with-timeline`, requestData);

      return ApiResponse.responseWithPayload<CampaignsWithTimelineResponse>(
        {
          campaigns: CampaignModel.fromResponseArray(applicationResponse.payload.campaigns),
          timeline: TimelineModel.fromResponse(applicationResponse.payload.timeline),
        },
        200,
      );
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getCampaignIds(): Promise<ApiResponse<CampaignWithIdAndName[]>> {
    // // Mock data
    // await new Promise((resolve) => setTimeout(resolve, 2000));
    // return new ApiResponse({
    //   responseCode: ApiResponseCode.SUCCESS,
    //   httpResponseCode: 200,
    //   payload: CampaignIdsModel.fromResponseArray([
    //     { id: 1, name: 'Campaign 1' },
    //     { id: 2, name: 'Campaign 2' },
    //     { id: 3, name: 'Campaign 3' },
    //     { id: 4, name: 'Campaign 4' },
    //     { id: 5, name: 'Campaign 5' },
    //     { id: 6, name: 'Campaign 6' },
    //     { id: 7, name: 'Campaign 7' },
    //   ]),
    //   message: '',
    // });
    try {
      const applicationResponse = await apiProfileClient.get<CampaignIdsResponse[]>(`${this.basePath}/ids`);
      return applicationResponse.processPayload(CampaignModel.fromIdsResponseArray);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getGroups(): Promise<ApiResponse<CampaignGroupModel[]>> {
    try {
      const applicationResponse = await apiProfileClient.get<CampaignGroupDTO[]>(`${this.basePath}/groups`);
      return applicationResponse.processPayload(CampaignGroupModel.fromResponseArray);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async createGroups(groups: Omit<CampaignGroupDTO, 'id' | 'total_campaigns'>[]): Promise<ApiResponse<CampaignGroupModel[]>> {
    //TODO: instead of omit use dedicated type
    try {
      const payload = {
        groups,
      };

      const applicationResponse = await apiProfileClient.post<CampaignGroupDTO[]>(`${this.basePath}/groups/create`, payload);
      return applicationResponse.processPayload(CampaignGroupModel.fromResponseArray);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async updateGroups(groups: CampaignGroupModel[]): Promise<ApiResponse<undefined>> {
    try {
      const payload = {
        groups: groups.map((group: CampaignGroupModel) => ({
          id: group.id,
          name: group.name,
          description: group.description,
          tacos: group.tacos,
          preset: group.preset,
          bid_ceiling: group.bidCeiling,
          total_campaigns: group.totalCampaigns,
        })),
      };

      return apiProfileClient.post<undefined>(`${this.basePath}/groups/update`, payload);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async deleteGroups(groupIds: number[]): Promise<ApiResponse<undefined>> {
    try {
      const payload = {
        delete_ids: groupIds,
      };

      return apiProfileClient.post<undefined>(`${this.basePath}/groups/delete`, payload);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async changeGroups(changeCampaignsGroups: ChangeCampaignGroupDTO[]): Promise<ApiResponse<undefined>> {
    try {
      const payload = {
        changes: changeCampaignsGroups.map((changeCampaignGroup: ChangeCampaignGroupDTO) => ({
          campaign_id: changeCampaignGroup.campaign_id,
          group_id: changeCampaignGroup.group_id,
        })),
      };

      return apiProfileClient.post<undefined>(`${this.basePath}/groups/change`, payload);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const campaignService = new CampaignService();
