import { Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ChangeEvent, FunctionComponent } from 'react';
import { SelectedProfileDTO } from '../types/SelectedProfile';

interface ProfileListProps {
  profiles: SelectedProfileDTO[];
  onProfileSelectionChanged: (profileId: number) => void;
}

const ProfileList: FunctionComponent<ProfileListProps> = ({ profiles, onProfileSelectionChanged }) => {
  async function onSwitchChanged(event: ChangeEvent<HTMLInputElement>) {
    const profileId = Number(event.target.value);
    onProfileSelectionChanged(profileId);
  }

  return (
    <TableContainer className="w-full flex-1 rounded-lg border bg-white  shadow-md">
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Profile</TableCell>
            <TableCell align="center">Manage in AdLabs</TableCell>
            <TableCell align="center">Market</TableCell>
            <TableCell align="center">Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {profiles.map((profile) => (
            <TableRow key={profile.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                <div className="flex flex-col">
                  <div className="font-bold"> {profile.name}</div>
                  <div>{profile.id}</div>
                </div>
              </TableCell>
              <TableCell align="center">
                <Switch value={profile.id} onChange={onSwitchChanged} />
              </TableCell>
              <TableCell align="center">{profile.market}</TableCell>
              <TableCell align="center">{profile.type}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProfileList;
