import { FunctionComponent, useEffect, useState } from 'react';
import { Button, DialogActions } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FilterRow } from './FilterRow';
import { isEmpty } from 'lodash-es';
import { FilterModel, getUpdatedFiltersValue } from './models/FilterModel';
import { FilterKey } from './types/FilterKey';
import { DeleteSweep } from '@mui/icons-material';
import { PendingFiltersContext } from './context/PendingFiltersContext';

interface FilterBuilderProps {
  onSearch: (filters: FilterModel[]) => void;
  filtersDefaultValue: FilterModel[];
  availableFilters: FilterModel[];
  onCancel: () => void;
}

export const FilterBuilder: FunctionComponent<FilterBuilderProps> = ({ onSearch, filtersDefaultValue, availableFilters, onCancel }) => {
  const [filters, setFilters] = useState<FilterModel[]>(filtersDefaultValue);

  const updateFilterByFilterKey = (oldKey: FilterKey, filter: FilterModel | null) => {
    setFilters((previousValue) => {
      // Delete filter
      if (filter == null) {
        const newFilters = previousValue.filter((filterItem) => filterItem.key !== oldKey);
        return newFilters;
      }
      // Update filter
      const index = previousValue.findIndex((filterItem) => filterItem.key === oldKey);
      previousValue[index] = filter;
      return [...previousValue];
    });
  };

  const setFilterValue = (filter: FilterModel) => {
    setFilters((previousValue) => {
      return getUpdatedFiltersValue(previousValue, filter);
    });
  };

  const [unUsedFilters, setUnUsedFilterKeys] = useState<FilterModel[]>(availableFilters);
  useEffect(() => {
    const usedFilterKeys = filters.map((filter) => filter.key);
    const newUnUsedFilterKeys = availableFilters.filter((f) => !usedFilterKeys.includes(f.key));
    setUnUsedFilterKeys(newUnUsedFilterKeys);
  }, [availableFilters, filters]);

  const onAddFilterClicked = () => {
    if (unUsedFilters.length === 0) {
      return;
    }

    const firstUnusedFilterKey = unUsedFilters[0].key;
    const newFilterToAdd = availableFilters.find((filter) => filter.key === firstUnusedFilterKey);

    if (newFilterToAdd) {
      setFilters((prevFilters) => [...prevFilters, newFilterToAdd]);
    }
  };

  const onApplyFiltersClicked = () => {
    onSearch(filters);
  };

  const onDiscardAllClicked = () => {
    // Only keep the date filters (which aren't shown in the popover)
    const nonFilterBuilderFilters = filters.filter((f) => !f.isFilterBuilderFilter);
    setFilters(nonFilterBuilderFilters);
    onSearch(nonFilterBuilderFilters);
  };

  const nonHiddenFilters = filters.filter((f) => f.isFilterBuilderFilter);

  return (
    <PendingFiltersContext.Provider value={{ filters, setFilters, setFilterValue }}>
      <div className="flex flex-col gap-2">
        <div className="flex min-w-[28rem] flex-row">
          <div>
            {filters.map((filter, index) => (
              <FilterRow
                key={!isEmpty(filter.key) ? filter.key : index}
                filterDefaultValue={filter}
                unUsedFilters={unUsedFilters}
                updateFilterByFilterKey={updateFilterByFilterKey}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-row justify-between">
          {unUsedFilters.length > 0 && (
            <Button autoFocus variant="text" startIcon={<AddIcon />} onClick={onAddFilterClicked}>
              Add New Filter
            </Button>
          )}
          {nonHiddenFilters.length > 0 && (
            <Button variant="text" startIcon={<DeleteSweep />} onClick={onDiscardAllClicked}>
              Discard All Filters
            </Button>
          )}
        </div>

        {/* TODO: move out from this component */}
        <DialogActions>
          <Button variant="text" size="medium" onClick={onCancel}>
            Cancel
          </Button>

          <Button size="medium" onClick={onApplyFiltersClicked}>
            {nonHiddenFilters.length === 0
              ? "Apply No Filters"
              : nonHiddenFilters.length === 1
              ? 'Apply 1 Filter'
              : `Apply ${nonHiddenFilters.length} Filters`}
          </Button>
        </DialogActions>
      </div>
    </PendingFiltersContext.Provider>
  );
};
