import { Popover } from '@mui/material';
import OptimizerDateRangePicker from './OptimizerDateRangePicker';
import { Dispatch, FunctionComponent, RefObject, SetStateAction } from 'react';

interface DateRangePickerPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const DateRangePickerPopover: FunctionComponent<DateRangePickerPopoverProps> = ({ buttonRef, isOpen, setIsOpen }) => {
  const handlePopoverClose = () => {
    setIsOpen((previousValue) => !previousValue);
  };

  return (
    <>
      <Popover
        id={'date-range-popover'}
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <OptimizerDateRangePicker setIsOpen={setIsOpen} />
      </Popover>
    </>
  );
};
