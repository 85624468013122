import { AdLabsPlanModel } from '../types/AdLabPlanModel';
import { PlanType } from '../types/SubscriptionPlan';

export const FREE_PLAN = new AdLabsPlanModel({
  planType: PlanType.FREE_PLAN,
  name: 'Free',
  price: 0,
  headline: 'Best option for casual bid management',
  description: '',
  features: ['10 campaigns optimization limit','30 days data retention','1 data update per day','Basic chat support', ],
  unit: 'per Amazon profile',
});

export const PRO_PLAN = new AdLabsPlanModel({
  planType: PlanType.PRO_PLAN,
  name: 'Pro',
  price: 40,
  headline: 'Best option for professional ad managers',
  description: '',
  features: ['Unlimited optimization batches', 'Unlimited data retention', 'Scheduled data updates', 'Premium chat support'],
  unit: 'per Amazon profile',
});
