import { FunctionComponent } from 'react';
import { AdLabsPlanModel } from '../../plans/types/AdLabPlanModel';
import { PricingCardCheckIcon } from './PricingCardCheckIcon';
import { Button } from '@mui/material';
import { PlanType } from '@/modules/plans/types/SubscriptionPlan';
import useFormatting from '@/hooks/useFormatting';

interface PricingCardProps {
  plan: AdLabsPlanModel;
  onDowngrade: (planToDowngradeTo: PlanType) => void;
  onUpgrade: (planToUpgradeTo: PlanType) => void;
  onResubscribe?: (planToResubscribe: PlanType) => void;
  isCurrentPlan: boolean;
  renewDate?: string;
  isCancelled?: boolean;
}

const PricingCard: FunctionComponent<PricingCardProps> = ({
  plan,
  onDowngrade,
  onUpgrade,
  isCurrentPlan,
  renewDate,
  isCancelled,
  onResubscribe,
}) => {
  const { formatDateStringTimeNoHours } = useFormatting();

  return (
    <div
      className={`flex flex-col p-4 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg  border border-gray-100 shadow dark:border-gray-600 xl:p-6 dark:bg-gray-800 dark:text-white ${
        isCurrentPlan && plan.price > 0 ? ' border-green-600' : 'border-transparent'
      }`}
    >
      <div className="mb-3 text-2xl font-semibold">{plan.name}</div>
      <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">{plan.headline}</div>
      <div className="flex justify-center items-baseline mt-8">
        {plan.price === 0 ? (
          <span className="text-5xl font-extrabold">Free</span>
        ) : (
          <>
            <span className=" text-5xl font-extrabold">${plan.price}</span>
            <span className="text-gray-500 dark:text-gray-400">/month</span>
          </>
        )}
      </div>
      <div className=" text-gray-500 mt-2 mb-6 dark:text-gray-400">{plan.unit}</div>

      {/* Actions */}
      {isCurrentPlan ? (
        <div className="flex flex-col items-center">
          <div className="text-green-600 font-semibold text-lg uppercase">Current Plan</div>
          {plan.price === 0 ? (
            <span className="text-gray-500 dark:text-gray-400">Free forever</span>
          ) : (
            <>
              {renewDate && (
                <span className="text-gray-500 dark:text-gray-400">
                  {isCancelled ? 'Ends at' : 'Renews at'} {formatDateStringTimeNoHours(renewDate)}
                </span>
              )}
              {!isCancelled ? (
                <Button className="mt-2" variant="outlined" color="secondary" size="small" onClick={() => onDowngrade(plan.planType)}>
                  Cancel Plan
                </Button>
              ) : (
                onResubscribe && (
                  <Button className="mt-2" variant="contained" color="primary" size="large" onClick={() => onResubscribe(plan.planType)}>
                    Re-subscribe
                  </Button>
                )
              )}
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {plan.price > 0 && (
            <>
              <Button variant="contained" color="primary" size="large" onClick={() => onUpgrade(plan.planType)}>
                Upgrade
              </Button>

              <Button variant="outlined" color="primary" size="large">
                Talk to us
              </Button>
            </>
          )}
        </div>
      )}

      <div className="flex-1"></div>

      {/* List */}
      <ul role="list" className="space-y-4 text-left p-0 mt-6 mb-0">
    {plan.features.map((feature, index) => (
      <li key={index} className="flex items-center space-x-3">
        <PricingCardCheckIcon />
        <span>{feature}</span>
      </li>
    ))}
  </ul>

    </div>
  );
};

export default PricingCard;
