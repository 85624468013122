import { MetricsData } from '@/modules/optimizer/api/campaign/models/CampaignModel';

export enum MetricField {
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  ORDERS = 'orders',
  CTR = 'ctr',
  CVR = 'cvr',
  CPC = 'cpc',
  SPEND = 'spend',
  SALES = 'sales',
  ACOS = 'acos',
  ROAS = 'roas',
  RPC = 'rpc',
  CPA = 'cpa',
  AOV = 'aov',
  CPM = 'cpm',
}

export function isValidMetricField(value: string): value is MetricField {
  return Object.values(MetricField).includes(value as MetricField);
}

export class CommonMetrics implements Record<MetricField, MetricsData> {
  public impressions: MetricsData;
  public clicks: MetricsData;
  public orders: MetricsData;
  public ctr: MetricsData;
  public cvr: MetricsData;
  public cpc: MetricsData;
  public spend: MetricsData;
  public sales: MetricsData;
  public acos: MetricsData;
  public roas: MetricsData;
  public rpc: MetricsData;
  public cpa: MetricsData;
  public aov: MetricsData;
  public cpm: MetricsData;

  constructor(args: CommonMetricsCreateArguments) {
    this.impressions = args.impressions;
    this.clicks = args.clicks;
    this.orders = args.orders;
    this.ctr = args.ctr;
    this.cvr = args.cvr;
    this.cpc = args.cpc;
    this.spend = args.spend;
    this.sales = args.sales;
    this.acos = args.acos;
    this.roas = args.roas;
    this.rpc = args.rpc;
    this.cpa = args.cpa;
    this.aov = args.aov;
    this.cpm = args.cpm;
  }
}

interface CommonMetricsCreateArguments {
  impressions: MetricsData;
  clicks: MetricsData;
  orders: MetricsData;
  ctr: MetricsData;
  cvr: MetricsData;
  cpc: MetricsData;
  spend: MetricsData;
  sales: MetricsData;
  acos: MetricsData;
  roas: MetricsData;
  rpc: MetricsData;
  cpa: MetricsData;
  aov: MetricsData;
  cpm: MetricsData;
}
