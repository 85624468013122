import { FilterBuilder } from '@/modules/optimizer/components/filter-builder/FilterBuilder';
import { DialogContent, DialogTitle, Popover } from '@mui/material';
import { CampaignNameFilterModel, FilterModel, UnitType } from './filter-builder/models/FilterModel';
import { FilterType } from './filter-builder/types/FilterKey';
import { toast } from 'react-toastify';
import { Dispatch, FunctionComponent, RefObject, SetStateAction } from 'react';

interface FilterBuilderPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  availableFilters: FilterModel[];
  appliedFilters: FilterModel[];
  onFiltersChanged: (filters: FilterModel[]) => void;
}

export const FilterBuilderPopover: FunctionComponent<FilterBuilderPopoverProps> = ({
  buttonRef,
  isOpen,
  setIsOpen,
  availableFilters,
  appliedFilters,
  onFiltersChanged,
}) => {
  const handlePopoverClose = () => {
    setIsOpen((previousValue) => !previousValue);
  };

  function splitFiltersToApplicableAndDiscarded(filters: FilterModel[]) {
    const applicableFilters: FilterModel[] = [];
    const discardedFilters: FilterModel[] = [];

    for (const filter of filters) {
      const isSpecialType = filter._type === FilterType.MULTI_SELECT || filter._type === FilterType.STRING_COMPARISON;

      if (isSpecialType && filter.conditions && (!filter.conditions[0]?.values || filter.conditions[0].values.length === 0)) {
        discardedFilters.push(filter);
      } else if (filter.unitType === UnitType.DATE && filter.conditions && filter.conditions[0]?.values[0] === 0) {
        //TODO: how to make condition better?
        discardedFilters.push(filter);
      } else {
        applicableFilters.push(filter);
      }
    }

    return { applicableFilters, discardedFilters };
  }

  function notifyAboutDiscardedFilters(filters: FilterModel[]) {
    const discardedFiltersNames = filters.map((filter) => filter._name).join(', ');

    toast.info(`Not applying filters with empty fields: ${discardedFiltersNames}`);
  }

  function onFilterBuilderFiltersChanged(filters: FilterModel[]) {
    const { applicableFilters, discardedFilters } = splitFiltersToApplicableAndDiscarded(filters);
    onFiltersChanged(applicableFilters);
    if (discardedFilters.length > 0) notifyAboutDiscardedFilters(discardedFilters);
    onClose();
  }

  function onClose() {
    setIsOpen(false);
  }
  const defaultFilter = availableFilters.find((f) => f instanceof CampaignNameFilterModel);
  const filtersShownOnOpen =
    appliedFilters.filter((f) => f.isFilterBuilderFilter).length > 0 || !defaultFilter ? appliedFilters : [defaultFilter, ...appliedFilters];

  return (
    <>
      <Popover
        id={'filter-builder-popover'}
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{ paper: { style: { minWidth: 800 } } }}
      >
        <DialogTitle>Filters</DialogTitle>
        <DialogContent>
          <FilterBuilder
            onSearch={onFilterBuilderFiltersChanged}
            filtersDefaultValue={filtersShownOnOpen}
            availableFilters={availableFilters}
            onCancel={onClose}
          />
        </DialogContent>
      </Popover>
    </>
  );
};
