import { FunctionComponent } from 'react';
import { BugReport } from '@mui/icons-material';
import { MainMenuButton } from './MainMenuButton';
import Gleap from 'gleap';
import { GleapWorkflowType } from '@/lib/gleap';

interface BugButtonProps {
  isExpanded: boolean;
}

export const BugButton: FunctionComponent<BugButtonProps> = ({ isExpanded }) => {
  const startGleapFeedbackFlow = () => {
    Gleap.startFeedbackFlow(GleapWorkflowType.BugReport, true);
  };
  return (
    <MainMenuButton icon={<BugReport />} isActive={false} isExpanded={isExpanded} label={'Report a Bug'} onClick={startGleapFeedbackFlow} />
  );
};
