import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { CampaignGroupDTO } from '../campaign-contracts';

export class CampaignGroupModel {
  public id: number;
  public name: string;
  public description: string;
  public tacos: number;
  public preset: OptimizationPreset;
  public bidCeiling: number;
  public totalCampaigns: number;

  constructor(args: CampaignGroupArguments) {
    this.id = args.id;
    this.name = args.name;
    this.description = args.description;
    this.tacos = args.tacos;
    this.preset = args.preset;
    this.bidCeiling = args.bidCeiling;
    this.totalCampaigns = args.totalCampaigns;
  }

  public static fromResponseArray(responseArray: CampaignGroupDTO[]): CampaignGroupModel[] {
    return responseArray.map(CampaignGroupModel.fromResponse);
  }

  public static fromResponse(response: CampaignGroupDTO): CampaignGroupModel {
    return new CampaignGroupModel({
      id: response.id,
      name: response.name,
      description: response.description,
      tacos: response.tacos,
      preset: response.preset,
      bidCeiling: response.bid_ceiling,
      totalCampaigns: response.total_campaigns,
    });
  }
}

interface CampaignGroupArguments {
  id: number;
  name: string;
  description: string;
  tacos: number;
  preset: OptimizationPreset;
  bidCeiling: number;
  totalCampaigns: number;
}
