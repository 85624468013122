import { router } from '@/router/router';
import { AxiosStatic } from 'axios';

export const registerErrorInterceptor = (axios: AxiosStatic) => {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (!error.response) {
        router.navigate('/login?noConnection=true');
        console.log('No connection to server');
      }
      if (error.response && error.response.status === 401) {
        if (router.state.location.pathname.startsWith('/app')) {
          router.navigate('/login?logOut=true');
          console.log('Unauthorized');
        }
      }
      return Promise.reject(error);
    },
  );
};
