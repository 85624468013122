import { AdditionalStatusType, ProfileReportsDTO, StatusType } from '@/modules/profiles/api/profile.contracts';
import { FunctionComponent, PropsWithChildren, createContext, useContext, useRef, useState } from 'react';
import { useActiveTeamContext } from './ActiveTeamContext';
import { QUERY_STATUS_REPORT_KEY, profileService } from '@/modules/profiles/api/profile.service';
import dayjs from 'dayjs';
import { isEqual, isNil } from 'lodash-es';
import { ProfileReportStatus, useProfileReports } from '@/modules/optimizer/hooks/useProfileReports';
import { useQueries } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

interface ReportsContextType {
  updateProfileData(profileId: number): Promise<void>;
  profileSyncStatus: (profileId: number | undefined) => SyncStatus;
  activeProfileDataStatusInfo: ProfileReportStatus;
  isActiveProfileDataBeingProcessed: boolean;
  activeProfileHasProcessedReports: boolean;
  getProfileReportsData: (profileId: number | null | undefined) => ProfileReportsDTO | null | undefined;
  refetchProfileStatus: (profileId: number) => void;
  getProfileReportStatusInfoByProfileId: (profileId: number | undefined) => ProfileReportStatus | undefined;
  addProfileToLocalLoadingState: (profileId: number) => void;
  removeProfileFromLocalLoadingState: (profileId: number) => void;
}

const loadingStatusInfo: ProfileReportStatus = {
  status: AdditionalStatusType.LOADING,
  lastUpdated: undefined,
  latestStarted: undefined,
  initialLoadFinished: false,
};

// Default values
const ReportsContext = createContext<ReportsContextType>({
  updateProfileData: () => Promise.resolve(),
  profileSyncStatus: () => ({ canClickSync: true, reason: null }),
  activeProfileDataStatusInfo: loadingStatusInfo,
  isActiveProfileDataBeingProcessed: false,
  activeProfileHasProcessedReports: false,
  getProfileReportsData: () => null,
  refetchProfileStatus: () => null,
  getProfileReportStatusInfoByProfileId: () => undefined,
  addProfileToLocalLoadingState: () => null,
  removeProfileFromLocalLoadingState: () => null,
});

// Wrapper that adds profileId to result. Queries don't have queryKeys. "data" is unreliable because on error it's undefined
interface ReportStatusQueryResult {
  profileId: number;
  data: ProfileReportsDTO | null;
}

export type SyncStatus = {
  canClickSync: boolean;
  reason: SyncStatusReason | null;
};

export enum SyncStatusReason {
  LOADING = 'fetching report status in progress',
  ONGOING = 'sync already in progress',
  PLAN_RESTRICTION = 'plan restriction',
  TOO_EARLY = 'too early, cant sync too often',
}

interface ReportsProviderProps extends PropsWithChildren {}

export const ReportsProvider: FunctionComponent<ReportsProviderProps> = ({ children }) => {
  const locationsWhereFetchAll = new Set(['/app/profiles']); //TODO: make sure this is a valid path with type somehow (import?)
  const currentPage = useLocation().pathname;
  const isFetchReportsForAllProfiles = locationsWhereFetchAll.has(currentPage);

  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { getProfileReportInfo } = useProfileReports();

  const [localProfilesInLoadingState, setLocalProfilesInLoadingState] = useState<Set<number>>(new Set());

  function addProfileToLocalLoadingState(profileId: number) {
    setLocalProfilesInLoadingState((prevSet) => new Set(prevSet).add(profileId));
  }

  function removeProfileFromLocalLoadingState(profileId: number) {
    setLocalProfilesInLoadingState((prevSet) => {
      const newSet = new Set(prevSet);
      newSet.delete(profileId);
      return newSet;
    });
  }

  const reportStatusQueries = useQueries({
    queries:
      activeTeam?.profiles?.map((profile) => ({
        queryKey: [QUERY_STATUS_REPORT_KEY, activeTeam, profile.id],
        queryFn: async (): Promise<ReportStatusQueryResult> => {
          const profileId = profile.id;
          console.log('Fetching report status for profile', profile.name, profileId);
          const response = await profileService.getReports(activeTeam.id, profileId);

          removeProfileFromLocalLoadingState(profileId);

          const result: ReportStatusQueryResult = {
            profileId: profileId,
            data: null,
          };

          if (response.isSuccess) {
            result.data = response.payload;
          } else {
            console.log('Error fetching report status:', response);
          }

          return result;
        },

        refetchInterval: 5 * 60 * 1000,
        enabled: activeTeam && !isNil(activeTeam.profiles) && (isFetchReportsForAllProfiles || profile.id == activeProfile?.id),
      })) || [],
  });

  function refetchProfileStatus(profileId: number) {
    reportStatusQueries.find((query) => query.data?.profileId === profileId)?.refetch();
  }

  function getProfileReportsData(profileId: number | null | undefined): ProfileReportsDTO | null | undefined {
    return reportStatusQueries.find((query) => query.data?.profileId === profileId)?.data?.data;
  }

  // So that new references aren't created when there's no value change
  const profileReportStatusCache = useRef<Map<number, ProfileReportStatus>>(new Map());
  function getProfileReportStatusInfoByProfileId(profileId: number | undefined): ProfileReportStatus {
    let result: ProfileReportStatus = loadingStatusInfo;

    if (profileId) {
      const query = reportStatusQueries.find((q) => q.data?.profileId === profileId);

      if (query) {
        const reportData = query.data?.data;
        const profileReportInfo = getProfileReportInfo(reportData || null);

        if (localProfilesInLoadingState.has(profileId) || query.isFetching) {
          result = {
            ...profileReportInfo,
            status: AdditionalStatusType.LOADING,
          };
        } else {
          result = profileReportInfo;
        }
      }
    }

    // Check against cache
    const cachedResult = profileReportStatusCache.current.get(profileId!);
    if (cachedResult && isEqual(result, cachedResult)) {
      return cachedResult;
    }

    profileReportStatusCache.current.set(profileId!, result);
    return result;
  }

  const activeProfileDataStatusInfo = getProfileReportStatusInfoByProfileId(activeProfile?.id);

  function isProfileDataBeingProcessed(profileId: number | undefined): boolean {
    const profileReportStatusInfo = getProfileReportStatusInfoByProfileId(profileId);

    return (
      profileReportStatusInfo.status == AdditionalStatusType.NEVER ||
      profileReportStatusInfo.status == StatusType.PENDING ||
      profileReportStatusInfo.status == StatusType.DOWNLOADED ||
      profileReportStatusInfo.status == StatusType.ONGOING
    );
  }

  function isMinSyncTimePassed(profileReportsData?: ProfileReportsDTO | null): boolean {
    if (isNil(profileReportsData)) {
      return true;
    }

    const { latestStarted } = getProfileReportInfo(profileReportsData);
    if (!latestStarted) {
      return true;
    }

    const lastReportCreatedAt = dayjs(latestStarted);
    if (!isNil(activeTeam) && activeTeam.isMinSyncTimePassed(lastReportCreatedAt)) {
      return true;
    }

    return false;
  }

  //TODO: this gets called a lot, memoize?
  function profileSyncStatus(profileId: number | undefined): SyncStatus {
    const syncStatus: SyncStatus = {
      canClickSync: true,
      reason: null,
    };

    const profileReportsData = getProfileReportsData(profileId);
    if (isNil(profileReportsData)) {
      return syncStatus;
    }

    const profileReportStatusInfo = getProfileReportStatusInfoByProfileId(profileId);
    if (profileReportStatusInfo.status == AdditionalStatusType.LOADING) {
      return {
        canClickSync: false,
        reason: SyncStatusReason.LOADING,
      };
    }

    if (isProfileDataBeingProcessed(profileId)) {
      return {
        canClickSync: false,
        reason: SyncStatusReason.ONGOING,
      };
    }

    if (profileReportStatusInfo.status == AdditionalStatusType.NEVER) {
      return {
        canClickSync: false,
        reason: SyncStatusReason.ONGOING,
      };
    }

    if (!isMinSyncTimePassed(profileReportsData)) {
      return {
        canClickSync: false,
        reason: SyncStatusReason.TOO_EARLY,
      };
    }

    const { latestStarted } = getProfileReportInfo(profileReportsData);
    const lastReportCreatedAt = dayjs(latestStarted);
    if (!isNil(activeTeam) && !activeTeam.canSyncProfile(lastReportCreatedAt)) {
      return {
        canClickSync: true,
        reason: SyncStatusReason.PLAN_RESTRICTION,
      };
    }

    return syncStatus;
  }

  async function updateProfileData(profileId: number): Promise<void> {
    console.log('Update profile data for:', profileId);
    try {
      addProfileToLocalLoadingState(profileId);
      const result = await profileService.createReports(profileId);

      if (!result.isCreateTargetingReportRequestSuccess) {
        throw new Error('Error creating targeting reports');
      }

      if (!result.isCreatePlacementReportRequestSuccess) {
        throw new Error('Error creating placement reports');
      }

      refetchProfileStatus(profileId);
    } catch (error) {
      console.error('Error updating profile data:', error);
      throw error;
    } finally {
      removeProfileFromLocalLoadingState(profileId);
    }
  }

  return (
    <ReportsContext.Provider
      value={{
        updateProfileData,
        profileSyncStatus,
        activeProfileDataStatusInfo,
        isActiveProfileDataBeingProcessed: isProfileDataBeingProcessed(activeProfile?.id),
        activeProfileHasProcessedReports: !isNil(activeProfileDataStatusInfo.lastUpdated),
        getProfileReportsData,
        refetchProfileStatus,
        getProfileReportStatusInfoByProfileId,
        addProfileToLocalLoadingState,
        removeProfileFromLocalLoadingState,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export function useReportsContext(): ReportsContextType {
  const context = useContext(ReportsContext);
  if (!context) {
    throw new Error('useReportsContext must be used within a ReportsProvider');
  }
  return context;
}
