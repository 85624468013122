import { Navigate, RouteObject } from 'react-router-dom';
//import { AmazonSettingsPage } from '../pages/AmazonSettingsPage';
import { BillingSettingsPage } from '../pages/BillingSettingsPage';

export const SETTING_CONFIGURATION_ROUTES: RouteObject[] = [
  {
    path: 'settings',
    children: [
      {
        index: true,
        element: <Navigate to="billing" replace />,
      },
      {
        path: 'billing',
        element: <BillingSettingsPage />,
      },
      // {
      //   path: 'amazon',
      //   element: <AmazonSettingsPage />,
      // },
      // {
      //   path: 'application',
      //   element: <ApplicationSettingsPage />,
      // },
    ],
  },
];
