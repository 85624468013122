import { SettingsRounded } from '@mui/icons-material';
import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainMenuButton } from './MainMenuButton';

interface ConfigurationButtonProps {
  isExpanded: boolean;
}
export const ConfigurationButton: FunctionComponent<ConfigurationButtonProps> = ({ isExpanded }) => {
  const [activePathnames /*setActivePathnames*/] = useState(['/app/configuration']);
  const [isRouteActive, setIsRouteActive] = useState(false);
  const navigate = useNavigate();
  const currentLocation = useLocation();
  useEffect(() => {
    if (currentLocation.pathname) {
      setIsRouteActive(activePathnames.some((r) => currentLocation.pathname.startsWith(r)));
    }
  }, [activePathnames, currentLocation.pathname]);

  function onConfigurationButtonClicked() {
    navigate({ pathname: 'configuration' });
  }

  return (
    <MainMenuButton
      icon={<SettingsRounded></SettingsRounded>}
      isActive={isRouteActive}
      isExpanded={isExpanded}
      label="Configuration"
      onClick={onConfigurationButtonClicked}
    />
  );
};
