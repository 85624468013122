import { FunctionComponent, PropsWithChildren } from 'react';
//import RegisterWizardBackgroundCircles from '@/assets/register_wizard_background_circles.svg';
interface RegistrationWizardPageProps extends PropsWithChildren {}

const RegistrationWizardPage: FunctionComponent<RegistrationWizardPageProps> = ({ children }) => {
  return (
    <div className="relative flex flex-grow flex-col items-center  ">
      {/* Cannot click on wait list link if image is used
      <img className="absolute bg-primary-100 opacity-0" src={RegisterWizardBackgroundCircles} alt="SVG as an image"></img> */}
      <div className="pt-20">{children}</div>
    </div>
  );
};

export default RegistrationWizardPage;
