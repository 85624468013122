export const MIN_UPDATE_INTERVAL_IN_MINUTES = 60;

export enum PlanType {
  FREE_PLAN = 'FREE_PLAN',
  PRO_PLAN = 'PRO_PLAN',
}

export interface PlanCapabilities {
  name: string;
  canScheduleUpdateTime: boolean;
  campaignSelectionLimit: number;
  dateRangeLengthLimitInDays: number;
  updateIntervalLimitMinutes: number;
}

export const FREE_PLAN_CAPABILITIES: PlanCapabilities = {
  name: 'Free plan',
  canScheduleUpdateTime: false,
  campaignSelectionLimit: 10,
  dateRangeLengthLimitInDays: 30,
  updateIntervalLimitMinutes: 1440, // 24h -  Keep this in sync with API
};

export const PRO_PLAN_CAPABILITIES: PlanCapabilities = {
  name: 'Pro plan',
  canScheduleUpdateTime: true,
  campaignSelectionLimit: 99999999,
  dateRangeLengthLimitInDays: 99999999,
  updateIntervalLimitMinutes: MIN_UPDATE_INTERVAL_IN_MINUTES, // Keep this in sync with API
};

export const ADLABS_PLAN_CAPABILITIES_MAP: Record<PlanType, PlanCapabilities> = {
  [PlanType.FREE_PLAN]: FREE_PLAN_CAPABILITIES,
  [PlanType.PRO_PLAN]: PRO_PLAN_CAPABILITIES,
};
