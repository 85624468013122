import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import LoginLayout from '../layouts/LoginLayout';
import LoginLayoutBody from '../components/LoginLayoutBody';
import { Button, TextField, Typography } from '@mui/material';
import { useAuthUserContext } from '@/modules/auth';
import { LoadingButton } from '@mui/lab';
import { Link as RouterLink } from 'react-router-dom';
import { debounce } from 'lodash-es';
import { toast } from 'react-toastify';

interface ResetPasswordPageProps {}

const ResetPasswordPage: FunctionComponent<ResetPasswordPageProps> = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [errorText, setErrorText] = useState('');
  const { sendPasswordResetEmailViaFirebase } = useAuthUserContext();

  const [isSendingPasswordResetEmail, setIsSendingPasswordResetEmail] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const onImpersonateClicked = async () => {
    if (!emailAddress) {
      setErrorText('Firebase ID is required');
      return;
    }
    setIsSendingPasswordResetEmail(true);
    try {
      await sendPasswordResetEmailViaFirebase(emailAddress);
      toast.success('Password reset email sent');
    } catch (err) {
      toast.error('Failed to send password reset email');
      console.log(err);
    }

    setIsSendingPasswordResetEmail(false);
  };

  const validateEmail = (email: string) => {
    const isValid = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );

    return isValid ? '' : 'Invalid email address';
  };

  const validateEmailDebounced = useCallback(
    debounce((email: string) => {
      if (!email) {
        return '';
      }
      if (email.length < 3) {
        return '';
      }

      setErrorText(validateEmail(email));
    }, 750),
    [],
  );

  useEffect(() => {
    // Debounce the validation and error text
    validateEmailDebounced(emailAddress);

    // Directly enable the button if the email is valid
    setIsEmailValid(validateEmail(emailAddress) === '');
  }, [emailAddress]);

  return (
    <LoginLayout>
      <LoginLayoutBody>
        <Typography component="h1" variant="h5">
          Reset your password
        </Typography>
        <Typography component="p" variant="body1">
          Enter your email address to reset your password.
        </Typography>
        <TextField
          label="Emaill Address"
          className="w-full max-w-sm mt-6"
          variant="outlined"
          value={emailAddress}
          onChange={(e) => {
            setEmailAddress(e.target.value);
          }}
          error={errorText !== ''}
          helperText={errorText}
        />
        <div className="flex gap-x-4 mt-6">
          <Button component={RouterLink} disabled={isSendingPasswordResetEmail} size="large" variant="outlined" color="primary" to={'/login'}>
            Back
          </Button>
          <LoadingButton
            disabled={isSendingPasswordResetEmail || !isEmailValid}
            loading={isSendingPasswordResetEmail}
            size="large"
            variant="contained"
            color="primary"
            onClick={onImpersonateClicked}
          >
            Send Reset Email
          </LoadingButton>
        </div>
      </LoginLayoutBody>
    </LoginLayout>
  );
};

export default ResetPasswordPage;
