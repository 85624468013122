export enum FilterKey {
  DATE = 'DATE',
  COMPARE_DATE = 'COMPARE_DATE', // Used only in metrics endpoint
  PORTFOLIO_ID = 'PORTFOLIO_ID', // A collection of Amazon Ads campaigns to manage and organize them.
  PORTFOLIO_NAME = 'PORTFOLIO_NAME',
  CAMPAIGN_ID = 'CAMPAIGN_ID',
  CAMPAIGN_NAME = 'CAMPAIGN_NAME',
  CAMPAIGN_NAME_NOT = 'CAMPAIGN_NAME_NOT',
  AD_GROUP_ID = 'AD_GROUP_ID', // is not available for filtering campaigns
  AD_GROUP_NAME = 'AD_GROUP_NAME',
  CAMPAIGN_STATE = 'CAMPAIGN_STATE', // The current state of the campaign (e.g., Active, Paused, Archived).
  TARGETING_TYPE = 'TARGETING_TYPE', // How the ads are targeted, e.g., manual (chosen by the advertiser) or automatic (chosen by Amazon based on product info).
  CAMPAIGN_START_DATE = 'CAMPAIGN_START_DATE', // The date the campaign starts.
  CAMPAIGN_END_DATE = 'CAMPAIGN_END_DATE', // The date the campaign ends.
  BUDGET = 'BUDGET', // The maximum amount set to spend for a campaign.
  IMPRESSIONS = 'IMPRESSIONS', // The number of times an ad was displayed.
  CLICKS = 'CLICKS', // The number of times an ad was clicked on.
  ORDERS = 'ORDERS', // The number of orders placed after clicking the ad.
  CTR = 'CTR', // Click-Through Rate: The percentage of times the ad was clicked after being seen.
  CVR = 'CVR', // Conversion Rate: The percentage of clicks that resulted in a sale.
  CPC = 'CPC', // Cost Per Click: The average cost paid for each click.
  SPEND = 'SPEND', // The total amount spent on the campaign.
  SALES = 'SALES', // The total revenue generated from the ads.
  ACOS = 'ACOS', // Advertising Cost of Sale: The percentage of ad spend against sales.
  ROAS = 'ROAS', // Return on Ad Spend: Revenue generated for every dollar spent on advertising.
  RPC = 'RPC', // Revenue Per Click: Average revenue generated per click.
  CPA = 'CPA', // Cost Per Acquisition: Cost to acquire a customer.
  AOV = 'AOV', // Average Order Value: Average value of each order.
  CPM = 'CPM', // Cost Per Mille: Cost of 1,000 ad impressions.
  CAMPAIGN_GROUP = 'CAMPAIGN_GROUP',

  // Currently supported only by the preview table
  DELTA = 'DELTA',
  REASON = 'REASON',
  ENTITY_TYPE = 'ENTITY_TYPE',
  MATCH_TYPE = 'MATCH_TYPE',
  TARGETING = 'TARGETING',
  OLD_VALUE = 'OLD_VALUE',
  NEW_VALUE = 'NEW_VALUE',
}

export enum FilterType {
  BETWEEN = 'BETWEEN',
  MULTI_SELECT = 'MULTI_SELECT',
  STRING_COMPARISON = 'STRING_COMPARISON',
}
