import { Button, Card } from '@mui/material';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { CampaignModel } from '../api/campaign/models/CampaignModel';
import { optimizationService } from './optimization/api/optimization-service';
import { FilterModel } from './filter-builder/models/FilterModel';
import { OptimizationParams } from './optimization/OptimizerConfig';

interface CampaignTestingProps {
  rowData: CampaignModel[] | undefined;
  filters: FilterModel[];
  optimizationParams: OptimizationParams;
}

const CampaignTesting: FunctionComponent<CampaignTestingProps> = ({ rowData, filters, optimizationParams }) => {
  const [currentCampaign, setcurrentCampaign] = useState<CampaignModel | null>(null);
  const [doneCount, setDoneCount] = useState(0);
  const [conclusionText, setConclusionText] = useState<string | null>(null);

  const [canRun, setCanRun] = useState<boolean>(false);
  const canRunRef = useRef(false);

  function setCanRunAndUpdateRef(value: boolean) {
    setCanRun(value);
    canRunRef.current = value;
  }

  async function initRun() {
    if (!rowData || rowData?.length == 0) return;
    setDoneCount(0);
    setCanRunAndUpdateRef(true);
  }

  useEffect(() => {
    if (!canRunRef.current) return;
    async function runCampaigns() {
      if (!rowData || rowData?.length == 0) return;
      for (const campaign of rowData) {
        if (!canRunRef.current) {
          console.log('run stopped');
          return;
        }

        setcurrentCampaign(campaign);
        const result = await optimizationService.getOptimizedResults([campaign.id], filters, optimizationParams);
        if (result.isSuccess) {
          setDoneCount((doneCount) => doneCount + 1);
          continue;
        }
        setConclusionText(`Campaign ${campaign.id} ${campaign.name} failed`);
        console.error(result);
        return;
      }
      setConclusionText('All finished successfully');
    }

    runCampaigns();
  }, [canRun]);

  return (
    <Card className="my-4 rounded-xl p-4 flex flex-col gap-3 items-center">
      {rowData && rowData?.length > 0 && (
        <>
          <h1>ADMIN SECTION</h1>
          <span>Calls preview for each campaign with the current params and only check if call is success</span>
          <Button onClick={initRun} disabled={!rowData || rowData?.length == 0 || currentCampaign != null}>
            Try to optimize all campaigns one by one
          </Button>
          <Button onClick={() => setCanRunAndUpdateRef(false)} color="error" disabled={!canRun}>
            STOP
          </Button>

          {currentCampaign != null && !conclusionText && (
            <span>
              Currently testing <strong>{currentCampaign?.name}</strong>
            </span>
          )}
          <span>
            Done: {doneCount} of {rowData?.length}
          </span>
          <span>
            <strong>{conclusionText}</strong>
          </span>
        </>
      )}
    </Card>
  );
};

export default CampaignTesting;
