import { ApiResponse } from '@/lib/api/api-response';
import { apiClient, apiTeamClient } from '@/lib/api/base-client';
import { UserModel } from '../../types/UserModel';
import {
  AuthorizeUserDTO,
  UserAndTeamCreateRequest,
  UserAndTeamCreateResponse,
  UserCreateDTO,
  UserDTO,
  UserUpdateDTO,
} from './users.contracts';
import axios from 'axios';

export const USERS_QUERY_KEY = 'query_status_report';

export class UserService {
  public basePath = 'users';

  async getUsers(): Promise<ApiResponse<UserModel[]>> {
    try {
      console.log({ apiTeamClient, basePath: this.basePath });

      const response = await apiTeamClient.get<UserDTO[]>(this.basePath + '/');
      return response.processPayload((p) => p.map(UserModel.fromDTO));
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getUserById(userId: number): Promise<ApiResponse<UserModel>> {
    try {
      const response = await apiClient.get<UserDTO>(`${this.basePath}/${userId}`);
      return response.processPayload(UserModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getUserByFirebaseId(firebaseId: string): Promise<ApiResponse<UserModel>> {
    try {
      const response = await apiClient.get<UserDTO>(`${this.basePath}/f/${firebaseId}`);
      return response.processPayload(UserModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async createUser(newUser: UserCreateDTO): Promise<ApiResponse<undefined>> {
    try {
      const response = await apiTeamClient.post<undefined>(this.basePath + '/', newUser);
      return response;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async createUserAndTeam(request: UserAndTeamCreateRequest): Promise<ApiResponse<UserAndTeamCreateResponse>> {
    try {
      const response = await apiClient.post<UserAndTeamCreateResponse>(`${this.basePath}/register-user`, request);
      return response;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async updateUser(userId: number, userToUpdate: UserUpdateDTO): Promise<ApiResponse<undefined>> {
    try {
      const response = await apiTeamClient.post<undefined>(`${this.basePath}/${userId}`, userToUpdate);
      return response;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAmazonAuthorizationUrl(userId: number, teamId: number): Promise<ApiResponse<string>> {
    try {
      const response = await apiClient.get<string>(`teams/${teamId}/users/${userId}/amazon-authorization-url`);
      return response;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async authorizeUser(userId: number, payload: AuthorizeUserDTO): Promise<ApiResponse<undefined>> {
    try {
      const response = await apiTeamClient.post<undefined>(`${this.basePath}/${userId}/amazon-authorization`, payload);
      return response;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getCustomTokenForFirebaseId(firebaseUserId: string): Promise<ApiResponse<string>> {
    try {
      const response = await apiClient.post<string>(`${this.basePath}/impersonate?impersonationFirebaseID=${firebaseUserId}`, {});
      return response;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async isEmailInWaitList(email: string): Promise<boolean> {
    try {
      const axiosResponse = await axios.post('https://hook.eu2.make.com/fn9hquw2laxgu7n597a9y1non153dswf', {
        email,
      });
      return axiosResponse && axiosResponse.status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}

export const userService = new UserService();
