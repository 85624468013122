import { BackButton } from '@/components/BackButton';
import { ReactNode, FunctionComponent } from 'react';
import PageLayoutTopBarHeader from './PageLayoutTopBarHeader';

interface PageLayoutTopBarProps {
  header: string | ReactNode;
  actions?: ReactNode;
  center?: ReactNode;
  navigateBackPathname?: string;
  subHeader?: string;
  noBg?: boolean;
}

export const PageLayoutTopBar: FunctionComponent<PageLayoutTopBarProps> = ({
  header,
  actions,
  navigateBackPathname,
  center,
  subHeader,
  noBg,
}) => {
  return (
    <div
      className={`top-0 z-10 flex  min-h-[50px] w-full items-center justify-between    px-4 py-2 ${
        noBg ? '' : 'bg-primary-700 dark:bg-gray-850'
      }    `}
    >
      <div className={` flex items-center gap-x-1  ${navigateBackPathname && '-ml-2 '}`}>
        {navigateBackPathname && <BackButton pathname={navigateBackPathname}></BackButton>}
        <div className="flex flex-col">
          <PageLayoutTopBarHeader noBg={noBg}>{header}</PageLayoutTopBarHeader>
          <div>{subHeader}</div>
        </div>
      </div>
      {center && <div>{center}</div>}
      <div className="flex gap-x-2">{actions}</div>
    </div>
  );
};
