import { Button, Divider, Paper, Tooltip, useTheme } from '@mui/material';
import { FunctionComponent, useRef, useState } from 'react';
import { FilterBuilderPopover } from './FilterBuilderPopover';
import { DateRangePickerPopover } from './date-range-picker/DateRangePickerPopover';
import { FilterChip } from './filter-builder/FilterChip';
import { useTemplateContext } from '../contexts/TemplateContext';
import { createCampaignFilters } from './filter-builder/models/FilterModel';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import OptimizationRangeButton from './OptimizationRangeButton';
import { LoadingButton } from '@mui/lab';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import { toast } from 'react-toastify';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { StatusType } from '@/modules/profiles/api/profile.contracts';
import { MuiColorVariant } from '@/config/theme/color.type';
import { useTranslation } from '@/lib';
import { profileDataStatusColorMap } from '@/types/colors.enum';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { SyncStatusReason, useReportsContext } from '@/modules/teams/contexts/ReportsContext';
import useFormatting from '@/hooks/useFormatting';
import { isNil } from 'lodash-es';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotionRounded';
import { OptimizationGroupsPopover } from './optimization-groups-popover/OptimizationGroupsPopover';
import { CAMPAIGN_GROUPS_QUERY_KEY, campaignService } from '../api/campaign/campaign-service';
import { useQuery } from '@tanstack/react-query';

interface FilterBarProps {
  selectedCampaigns: number[];
}

const FilterBar: FunctionComponent<FilterBarProps> = ({ selectedCampaigns }) => {
  // Date picker
  const dateRangeButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isDateRangePopoverOpen, setIsDateRangePopoverOpen] = useState(false); // state to control the popover

  const filtersButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false); // state to control the popover

  const { filters, setFilters } = useTemplateContext();
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { activeProfileDataStatusInfo, updateProfileData, isActiveProfileDataBeingProcessed, profileSyncStatus } = useReportsContext();

  const { t } = useTranslation();
  const { formatDateStringTimeNoSeconds } = useFormatting();

  const onFiltersButtonClicked = () => {
    setIsFilterPopoverOpen((previousValue) => !previousValue);
  };

  const activeProfileSyncStatus = profileSyncStatus(activeProfile?.id);

  async function onUpdateProfileClicked() {
    try {
      if (activeProfile?.id) {
        if (activeProfileSyncStatus.reason == SyncStatusReason.PLAN_RESTRICTION) {
          setIsPaywallModalOpen(true);
          return;
        }

        await updateProfileData(activeProfile.id);
        toast.success('Report generation started successfully');
      }
    } catch (error) {
      console.log(error);
      toast.error('Unable to update profile data. Please try again later.');
    }
  }

  const { data: campaignGroups } = useQuery({
    queryKey: [CAMPAIGN_GROUPS_QUERY_KEY, activeProfile?.id],
    queryFn: async () => {
      const result = await campaignService.getGroups();
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading groups');
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  const CAMPAIGN_FILTERS = createCampaignFilters(campaignGroups || []);

  const theme = useTheme();
  const muiColor = profileDataStatusColorMap[activeProfileDataStatusInfo.status] ?? profileDataStatusColorMap[StatusType.PENDING];

  const profileDataStatusCircleColor =
    muiColor === MuiColorVariant.Default
      ? theme.palette.background.default
      : theme.palette[muiColor]?.main || theme.palette.background.default;

  const syncButtonTooltip = (() => {
    if (!isNil(activeProfileSyncStatus.reason)) {
      return t(`labels.${activeProfileSyncStatus.reason}`);
    } else if (!isActiveProfileDataBeingProcessed) {
      return 'Sync profile data with Amazon';
    } else {
      let message = 'Data processing may take several minutes';
      if (activeProfileDataStatusInfo.latestStarted) {
        message += `. Started at ${formatDateStringTimeNoSeconds(activeProfileDataStatusInfo.latestStarted)}`;
      }
      return message;
    }
  })();

  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  // Optimizer groups
  const optimizationGroupsButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isOptimizationGroupsPopoverOpen, setIsOptimizationGroupsPopoverOpen] = useState(false);
  const onOptimizationGroupsClicked = () => {
    setIsOptimizationGroupsPopoverOpen((previousValue) => !previousValue);
  };

  return (
    <>
      <Paper
        elevation={0}
        square
        className="sticky top-0 z-10 border-t bg-white px-4 drop-shadow dark:border-b dark:border-slate-700 dark:bg-gray-850"
      >
        <div className="flex items-center gap-x-4 py-2">
          <div className="flex h-full flex-col justify-center">
            <div>
              <OptimizationRangeButton />
            </div>
            <div>
              <DateRangePickerPopover buttonRef={dateRangeButtonRef} setIsOpen={setIsDateRangePopoverOpen} isOpen={isDateRangePopoverOpen} />
            </div>
          </div>

          <Divider className="my-4 " orientation="vertical" flexItem />
          <div className="flex flex-col">
            <div className="mt-1 flex items-start">
              <Button
                variant="outlined"
                color="primary"
                onClick={onFiltersButtonClicked}
                startIcon={<FilterListRoundedIcon />}
                ref={filtersButtonRef}
                className="mr-2 flex-shrink-0"
              >
                Adjust Filters
              </Button>

              <FilterBuilderPopover
                buttonRef={filtersButtonRef}
                setIsOpen={setIsFilterPopoverOpen}
                isOpen={isFilterPopoverOpen}
                availableFilters={CAMPAIGN_FILTERS}
                appliedFilters={filters}
                onFiltersChanged={setFilters}
              />

              <div className="flex flex-row flex-wrap gap-2">
                {filters
                  .filter((f) => f.isFilterBuilderFilter)
                  .map((filter, index) => {
                    return <FilterChip key={filter.key + index} filter={filter} handleClick={onFiltersButtonClicked} />;
                  })}
              </div>
            </div>
          </div>
          <div className="flex flex-grow"></div>
          <DateRangePickerPopover buttonRef={dateRangeButtonRef} setIsOpen={setIsDateRangePopoverOpen} isOpen={isDateRangePopoverOpen} />

          <Button
            ref={optimizationGroupsButtonRef}
            onClick={onOptimizationGroupsClicked}
            variant="outlined"
            startIcon={<AutoAwesomeMotionIcon />}
          >
            Optimization Groups
          </Button>
          <OptimizationGroupsPopover
            buttonRef={optimizationGroupsButtonRef}
            isOpen={isOptimizationGroupsPopoverOpen}
            setIsOpen={setIsOptimizationGroupsPopoverOpen}
            selectedCampaigns={selectedCampaigns}
          />

          {/* //TODO: move syncing into a separate component so report update would affect less components */}
          <div className="flex items-center gap-x-2">
            <div className="flex items-center">
              <Tooltip
                title={
                  t(`team_profiles_page.labels.${activeProfileDataStatusInfo.status}`) +
                  ': ' +
                  t(`team_profiles_page.descriptions.${activeProfileDataStatusInfo.status}`)
                }
              >
                <div className={`w-3 h-3 m-3 rounded-full`} style={{ backgroundColor: profileDataStatusCircleColor }}></div>
              </Tooltip>
              <div className="flex flex-col text-slate-500 dark:text-slate-400">
                <div className="flex text-xs font-semibold ">LAST UPDATED</div>
                <div className="flex text-xs">{formatDateStringTimeNoSeconds(activeProfileDataStatusInfo.lastUpdated)}</div>
              </div>
            </div>
            <div className="flex-col ">
              <Tooltip title={syncButtonTooltip}>
                <div>
                  <LoadingButton
                    className="px-2"
                    loadingPosition="center"
                    variant="text"
                    onClick={onUpdateProfileClicked}
                    loading={isActiveProfileDataBeingProcessed}
                    disabled={!activeProfileSyncStatus.canClickSync}
                  >
                    <CachedRoundedIcon />
                  </LoadingButton>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </Paper>

      <PaywallModal isOpen={isPaywallModalOpen} onClose={onClosePaywallModal}>
        {`Free sync limit in one day reached. Upgrade your subscription to update date more often.`}
      </PaywallModal>
    </>
  );
};

export default FilterBar;
