import AlSelect from '@/components/form/AlSelect';
import { SelectChangeEvent, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { OptimizationApplyData } from './OptimizerConfig';
import { BiddingEntity } from './models/OptimizationModel';

export enum PlacementUpdateType {
  SET_PLACEMENT_TO_PERCENTAGE = 'SET_PLACEMENT_TO_PERCENTAGE',
  INCREASE_PLACEMENT_BY_PERCENTAGE_POINTS = 'INCREASE_PLACEMENT_BY_PERCENTAGE_POINTS',
  DECREASE_PLACEMENT_BY_PERCENTAGE_POINTS = 'DECREASE_PLACEMENT_BY_PERCENTAGE_POINTS',
  NO_CHANGE = 'NO_CHANGE',
}

export enum KeywordUpdateType {
  SET_BID_TO_AMOUNT = 'SET_BID_TO_AMOUNT',
  INCREASE_BID_BY_AMOUNT = 'INCREASE_BID_BY_AMOUNT',
  DECREASE_BID_BY_AMOUNT = 'DECREASE_BID_BY_AMOUNT',
  INCREASE_BID_BY_PERCENTAGE = 'INCREASE_BID_BY_PERCENTAGE',
  DECREASE_BID_BY_PERCENTAGE = 'DECREASE_BID_BY_PERCENTAGE',

  NO_CHANGE = 'NO_CHANGE',
}

export enum ProductTargetUpdateType {
  SET_BID_TO_AMOUNT = 'SET_BID_TO_AMOUNT',
  INCREASE_BID_BY_AMOUNT = 'INCREASE_BID_BY_AMOUNT',
  DECREASE_BID_BY_AMOUNT = 'DECREASE_BID_BY_AMOUNT',
  INCREASE_BID_BY_PERCENTAGE = 'INCREASE_BID_BY_PERCENTAGE',
  DECREASE_BID_BY_PERCENTAGE = 'DECREASE_BID_BY_PERCENTAGE',

  NO_CHANGE = 'NO_CHANGE',
}

interface OptimizationEditSelectionModalProps {
  selectedItems: OptimizationApplyData[];
  isOpen: boolean;
  onClose: () => void;
  onApply: (updateData: TotalUpdateData) => void;
}

export interface BidUpdateData {
  bidUpdateType: KeywordUpdateType;
  newBidValue: number;
}

export interface ProductTargetUpdateData {
  productTargetUpdateType: ProductTargetUpdateType;
  newProductTargetValue: number;
}

export interface PlacementUpdateData {
  placementUpdateType: PlacementUpdateType;
  newPlacementValue: number;
}

export interface TotalUpdateData {
  placementUpdateData: PlacementUpdateData;
  bidUpdateData: BidUpdateData;
  productTargetUpdateData: ProductTargetUpdateData;
}

const OptimizationEditSelectionModal: FunctionComponent<OptimizationEditSelectionModalProps> = ({
  selectedItems,
  isOpen,
  onClose,
  onApply,
}) => {
  const [placementUpdateData, setPlacementUpdateData] = useState<PlacementUpdateData>({
    placementUpdateType: PlacementUpdateType.NO_CHANGE,
    newPlacementValue: 0,
  });

  const [bidUpdateData, setBidUpdateData] = useState<BidUpdateData>({ bidUpdateType: KeywordUpdateType.NO_CHANGE, newBidValue: 0 });
  const [productTargetUpdateData, setProductTargetUpdateData] = useState<ProductTargetUpdateData>({
    productTargetUpdateType: ProductTargetUpdateType.NO_CHANGE,
    newProductTargetValue: 0,
  });

  const handlePlacementUpdateTypeChange = (event: SelectChangeEvent<PlacementUpdateType>) => {
    setPlacementUpdateData({ ...placementUpdateData, placementUpdateType: event.target.value as PlacementUpdateType });
  };

  const handleBidUpdateTypeChange = (event: SelectChangeEvent<KeywordUpdateType>) => {
    setBidUpdateData({ ...bidUpdateData, bidUpdateType: event.target.value as KeywordUpdateType });
  };

  const handleProductTargetUpdateTypeChange = (event: SelectChangeEvent<ProductTargetUpdateType>) => {
    setProductTargetUpdateData({ ...productTargetUpdateData, productTargetUpdateType: event.target.value as ProductTargetUpdateType });
  };

  const PLACEMENT_UPDATE_OPTIONS = [
    { value: PlacementUpdateType.NO_CHANGE, label: 'No change' },
    { value: PlacementUpdateType.SET_PLACEMENT_TO_PERCENTAGE, label: 'Set placement to (%)' },
    { value: PlacementUpdateType.INCREASE_PLACEMENT_BY_PERCENTAGE_POINTS, label: 'Increase by (%) points' },
    { value: PlacementUpdateType.DECREASE_PLACEMENT_BY_PERCENTAGE_POINTS, label: 'Decrease by (%) points' },
  ];

  const KEYWORD_UPDATE_OPTIONS = [
    { value: KeywordUpdateType.NO_CHANGE, label: 'No change' },
    { value: KeywordUpdateType.SET_BID_TO_AMOUNT, label: 'Set bid to ($)' },
    { value: KeywordUpdateType.INCREASE_BID_BY_AMOUNT, label: 'Increase bid by ($)' },
    { value: KeywordUpdateType.DECREASE_BID_BY_AMOUNT, label: 'Decrease bid by ($)' },
    { value: KeywordUpdateType.INCREASE_BID_BY_PERCENTAGE, label: 'Increase bid by (%)' },
    { value: KeywordUpdateType.DECREASE_BID_BY_PERCENTAGE, label: 'Decrease bid by (%)' },
  ];

  const PRODUCT_TARGET_UPDATE_OPTIONS = [
    { value: ProductTargetUpdateType.NO_CHANGE, label: 'No change' },
    { value: ProductTargetUpdateType.SET_BID_TO_AMOUNT, label: 'Set bid to ($)' },
    { value: ProductTargetUpdateType.INCREASE_BID_BY_AMOUNT, label: 'Increase bid by ($)' },
    { value: ProductTargetUpdateType.DECREASE_BID_BY_AMOUNT, label: 'Decrease bid by ($)' },
    { value: ProductTargetUpdateType.INCREASE_BID_BY_PERCENTAGE, label: 'Increase bid by (%)' },
    { value: ProductTargetUpdateType.DECREASE_BID_BY_PERCENTAGE, label: 'Decrease bid by (%)' },
  ];

  const [newPlacementValue, setNewPlacementValue] = useState<string>('');
  const onNewPlacementValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Allow only numeric input
    if (!value || /^[0-9\b]+$/.test(value)) {
      // Update your state or handle the change here
      setNewPlacementValue(value);
      setPlacementUpdateData({ ...placementUpdateData, newPlacementValue: parseFloat(value) });
    }
  };

  const [newBidValue, setNewBidValue] = useState<string>('');
  const onNewBidValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Allow only numeric input with decimals
    if (!value || /^\d*\.?\d*$/.test(value)) {
      // Update your state or handle the change here
      setNewBidValue(value);
      setBidUpdateData({ ...bidUpdateData, newBidValue: parseFloat(value) });
    }
  };

  const [newProductTargetValue, setNewProductTargetValue] = useState<string>('');
  const onNewProductTargetValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Allow only numeric input with decimals
    if (!value || /^\d*\.?\d*$/.test(value)) {
      // Update your state or handle the change here
      setNewProductTargetValue(value);
      setProductTargetUpdateData({ ...productTargetUpdateData, newProductTargetValue: parseFloat(value) });
    }
  };

  const handleApplyChanges = () => {
    onApply({
      placementUpdateData,
      bidUpdateData,
      productTargetUpdateData,
    });

    // Close modal
    onClose();
  };

  const numKeywords = selectedItems.filter((selectedItem) => selectedItem.bidding_entity === BiddingEntity.KEYWORD).length;
  const numProductTargets = selectedItems.filter((selectedItem) => selectedItem.bidding_entity === BiddingEntity.PRODUCT_TARGET).length;
  const numPlacements = selectedItems.filter(
    (selectedItem) => selectedItem.bidding_entity !== BiddingEntity.KEYWORD && selectedItem.bidding_entity !== BiddingEntity.PRODUCT_TARGET,
  ).length;

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="edit-selection-modal-title" maxWidth="lg" className="w-full ">
      <DialogTitle id="edit-selection-modal-title">Edit selected items</DialogTitle>

      <DialogContent className="min-w-[500px]">
        <div className="flex max-w-96 ">
          You have selected {selectedItems.length} items to edit. Please select the type of edits you would like to apply to these items.
        </div>
        <div className="flex flex-col gap-y-6 mt-8 mb-4 min-w-100 ">
          {numPlacements > 0 && (
            <div>
              <div className="mb-2 flex items-center font-medium text-sm leading-none pb-0 ">Edit {numPlacements} Placements</div>

              <div className="flex gap-x-4">
                <AlSelect
                  label={'Update action'}
                  value={placementUpdateData.placementUpdateType}
                  options={PLACEMENT_UPDATE_OPTIONS}
                  onChange={handlePlacementUpdateTypeChange}
                  renderOption={(item) => item.label}
                  valueExtractor={(item) => item.value}
                />
                {placementUpdateData.placementUpdateType !== PlacementUpdateType.NO_CHANGE && (
                  <TextField
                    fullWidth
                    label={
                      placementUpdateData.placementUpdateType === PlacementUpdateType.SET_PLACEMENT_TO_PERCENTAGE
                        ? 'New Placement (%)'
                        : 'Placement (%) points'
                    }
                    value={newPlacementValue}
                    onChange={onNewPlacementValueChange}
                    inputProps={{ pattern: '[0-9]*' }}
                    InputProps={{
                      endAdornment: (
                        <div className="text-sm flex flex-nowrap whitespace-nowrap">
                          % {placementUpdateData.placementUpdateType !== PlacementUpdateType.SET_PLACEMENT_TO_PERCENTAGE && 'pts'}
                        </div>
                      ),
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {numKeywords > 0 && (
            <div>
              <div className="mb-2 flex items-center font-medium text-sm leading-none pb-0 ">
                Edit {selectedItems.filter((selectedItem) => selectedItem.bidding_entity === BiddingEntity.KEYWORD).length} Keyword Bids
              </div>

              <div className="flex gap-x-4">
                <AlSelect
                  label={'Update action'}
                  value={bidUpdateData.bidUpdateType}
                  options={KEYWORD_UPDATE_OPTIONS}
                  onChange={handleBidUpdateTypeChange}
                  renderOption={(item) => item.label}
                  valueExtractor={(item) => item.value}
                />
                {bidUpdateData.bidUpdateType !== KeywordUpdateType.NO_CHANGE && (
                  <TextField
                    fullWidth
                    label={
                      bidUpdateData.bidUpdateType === KeywordUpdateType.SET_BID_TO_AMOUNT
                        ? 'New Bid ($)'
                        : bidUpdateData.bidUpdateType === KeywordUpdateType.INCREASE_BID_BY_AMOUNT ||
                          bidUpdateData.bidUpdateType === KeywordUpdateType.DECREASE_BID_BY_AMOUNT
                        ? 'Bid adjustment ($)'
                        : 'Bid adjustment (%) points'
                    }
                    value={newBidValue}
                    onChange={onNewBidValueChange}
                    inputProps={{ pattern: '[0-9]*' }}
                    InputProps={{
                      endAdornment: (
                        <div className="text-sm flex flex-nowrap whitespace-nowrap">
                          {bidUpdateData.bidUpdateType === KeywordUpdateType.SET_BID_TO_AMOUNT ||
                          bidUpdateData.bidUpdateType === KeywordUpdateType.INCREASE_BID_BY_AMOUNT ||
                          bidUpdateData.bidUpdateType === KeywordUpdateType.DECREASE_BID_BY_AMOUNT
                            ? '$'
                            : '%'}
                        </div>
                      ),
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {numProductTargets > 0 && (
            <div>
              <div className="mb-2 flex items-center font-medium text-sm leading-none pb-0 ">
                Edit {selectedItems.filter((selectedItem) => selectedItem.bidding_entity === BiddingEntity.PRODUCT_TARGET).length} Product
                Target Bids
              </div>

              <div className="flex gap-x-4">
                <AlSelect
                  label={'Update action'}
                  value={productTargetUpdateData.productTargetUpdateType}
                  options={PRODUCT_TARGET_UPDATE_OPTIONS}
                  onChange={handleProductTargetUpdateTypeChange}
                  renderOption={(item) => item.label}
                  valueExtractor={(item) => item.value}
                />
                {productTargetUpdateData.productTargetUpdateType !== ProductTargetUpdateType.NO_CHANGE && (
                  <TextField
                    fullWidth
                    label={
                      productTargetUpdateData.productTargetUpdateType === ProductTargetUpdateType.SET_BID_TO_AMOUNT
                        ? 'New Bid'
                        : productTargetUpdateData.productTargetUpdateType === ProductTargetUpdateType.INCREASE_BID_BY_AMOUNT ||
                          productTargetUpdateData.productTargetUpdateType === ProductTargetUpdateType.DECREASE_BID_BY_AMOUNT
                        ? 'Bid adjustment ($)'
                        : 'Bid adjustment (%)'
                    }
                    value={newProductTargetValue}
                    onChange={onNewProductTargetValueChange}
                    inputProps={{
                      pattern: '[0-9]*',
                    }}
                    //   Show input adornment based on selected update type
                    InputProps={{
                      endAdornment: (
                        <div className="text-sm flex flex-nowrap whitespace-nowrap">
                          {productTargetUpdateData.productTargetUpdateType === ProductTargetUpdateType.SET_BID_TO_AMOUNT ||
                          productTargetUpdateData.productTargetUpdateType === ProductTargetUpdateType.INCREASE_BID_BY_AMOUNT ||
                          productTargetUpdateData.productTargetUpdateType === ProductTargetUpdateType.DECREASE_BID_BY_AMOUNT
                            ? '$'
                            : '%'}
                        </div>
                      ),
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleApplyChanges}>Apply Changes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OptimizationEditSelectionModal;
