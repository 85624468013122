export const Environment = {
  ENVIRONMENT: import.meta.env.VITE_APP_ENVIRONMENT ?? 'dev',
  FIREBASE_PUBLIC_API_KEY: import.meta.env.VITE_APP_FIREBASE_PUBLIC_API_KEY ?? 'dev',
  FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN ?? 'dev',

  isDev: () => {
    return import.meta.env.VITE_APP_ENVIRONMENT === 'dev';
  },

  DEV_API_URL: import.meta.env.VITE_APP_DEV_API_URL ?? undefined,
  API_URL: import.meta.env.VITE_APP_API_URL ?? undefined,
};
