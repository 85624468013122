import { UserSettingKey, useUserSettingsContext } from '@/modules/users';
import { ColDef, ColumnApi, ColumnState } from 'ag-grid-enterprise';
import { useEffect, useCallback, useRef } from 'react';
import { debounce, isBoolean } from 'lodash-es';
import { ColumnMovedEvent, ColumnPinnedEvent, ColumnResizedEvent, ColumnVisibleEvent } from 'ag-grid-community';

interface GridColumnState {
  initialColumnState: ColumnState[] | undefined;
  isLoading: boolean;
  isError: boolean;
  setColumnAPI: (columnApi: ColumnApi) => void;
  handleColumnStateChange: (event: ColumnPinnedEvent | ColumnMovedEvent | ColumnResizedEvent) => void;
  applyStateToDefinitions: (colDefs: ColDef[]) => void;
  isAutoSaveEnabled: boolean;
  setIsAutoSaveEnabled: (isAutoSaveEnabled: boolean) => void;
}

export function useGridColumnState(userSettingKey: UserSettingKey): GridColumnState {
  const { getUserSettingValueByKey, upsertUserSetting } = useUserSettingsContext();

  const initialColumnStateRef = useRef(getUserSettingValueByKey<ColumnState[]>(userSettingKey));
  const isLoadingRef = useRef(false);
  const isErrorRef = useRef(false);
  const columnApiRef = useRef<ColumnApi | null>(null);
  const isAutoSaveEnabledRef = useRef(false);

  const handleColumnStateChange = (event: ColumnPinnedEvent | ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
    if (event.source === 'sizeColumnsToFit') {
      return;
    }
    if (columnApiRef.current && isAutoSaveEnabledRef.current) {
      debouncedSaveColumnStateFn();
    }
  };

  const saveColumnState = useCallback(async () => {
    if (columnApiRef.current) {
      const columnState = columnApiRef.current.getColumnState();
      isLoadingRef.current = true;
      initialColumnStateRef.current = columnState;
      const success = await upsertUserSetting(userSettingKey, columnState);
      isErrorRef.current = !success;
      isLoadingRef.current = false;
    }
  }, [userSettingKey, upsertUserSetting]);

  const debouncedSaveColumnStateFn = useCallback(
    debounce(() => {
      saveColumnState();
    }, 500),
    [saveColumnState],
  );

  const applyStateToDefinitions = useCallback((colDefs: ColDef[]) => {
    const state = initialColumnStateRef.current;
    if (state && state.find) {
      colDefs.forEach((def) => {
        const stateForCol = state.find((s) => s.colId === def.colId);
        if (!stateForCol) {
          return;
        }
        if (isBoolean(stateForCol.hide)) {
          def.hide = stateForCol.hide;
        } else {
          def.hide = true;
        }

        def.width = stateForCol.width;
        if (isBoolean(stateForCol.pinned)) {
          def.pinned = stateForCol.pinned;
        } else {
          def.pinned = null;
        }
      });

      colDefs.sort((a, b) => {
        const aIndex = state.findIndex((s) => s.colId === a.colId);
        const bIndex = state.findIndex((s) => s.colId === b.colId);

        return aIndex - bIndex;
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      columnApiRef.current = null;
    };
  }, []);

  const setColumnAPI = (api: ColumnApi) => {
    columnApiRef.current = api;
  };

  const setIsAutoSaveEnabled = (value: boolean) => {
    isAutoSaveEnabledRef.current = value;
  };

  return {
    initialColumnState: initialColumnStateRef.current,
    isLoading: isLoadingRef.current,
    isError: isErrorRef.current,
    setColumnAPI,
    handleColumnStateChange,
    applyStateToDefinitions,
    isAutoSaveEnabled: isAutoSaveEnabledRef.current,
    setIsAutoSaveEnabled,
  };
}
