import { MainMenuItem } from '../types/MenuItem';
import LeaderboardRounded from '@mui/icons-material/LeaderboardRounded';
import WindowRoundedIcon from '@mui/icons-material/WindowRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotionRounded';

export const MAIN_MENU_ITEMS: MainMenuItem[] = [
  {
    activeRouteIds: ['/app/profiles'],
    label: 'Profiles',
    url: '/app/profiles',
    icon: <WindowRoundedIcon />,
  },
  {
    activeRouteIds: ['/app/optimizer'],
    label: 'Optimizer',
    url: '/app/optimizer',
    icon: <LeaderboardRounded />,
  },
  {
    activeRouteIds: ['/app/campaign-groups'],
    label: 'Optimization Groups',
    url: '/app/campaign-groups',
    icon: <AutoAwesomeMotionIcon />,
  },
  {
    activeRouteIds: ['/app/history'],
    label: 'Time Machine',
    url: '/app/history',
    icon: <HistoryRoundedIcon />,
  },
];
