import { MetricsData } from '@/modules/optimizer/api/campaign/models/CampaignModel';
import { BiddingEntity } from './OptimizationModel';
import { MatchType, OptimizationReason, ResponsePreviewDataRow } from '../api/optimization-contracts';
import { CommonMetrics } from '../../metrics/models/CommonMetricsModel';

export enum PreviewDataRowFields {
  ID = 'id',
  CAMPAIGN_NAME = 'campaignName',
  CAMPAIGN_GROUP = 'campaignGroup',
  BIDDING_ENTITY = 'biddingEntity',
  TARGETING = 'targeting',
  match = 'match',
  AD_GROUP = 'adGroup',
  OLD_VALUE = 'oldValue',
  NEW_VALUE = 'newValue',
  ORIGINAL_NEW_VALUE = 'originalNewValue',
  DELTA = 'delta',
  REASONS = 'reasons',
}

type PreviewDataRowType = number | string | BiddingEntity | OptimizationReason[];
export class PreviewDataRow extends CommonMetrics implements Record<PreviewDataRowFields, PreviewDataRowType> {
  public id: number;
  public campaignName: string;
  public campaignGroup: string;
  public biddingEntity: BiddingEntity;
  public targeting: string;
  public match: MatchType;
  public adGroup: string;
  public oldValue: number;
  public newValue: number;
  public originalNewValue: number;
  public delta: number;
  public reasons: OptimizationReason[];
  public lastOptimizedAt: string | null;

  constructor(args: PreviewDataRowCreateArguments) {
    super(args);
    this.id = args.id;
    this.campaignName = args.campaignName;
    this.campaignGroup = args.campaignGroup;
    this.biddingEntity = args.biddingEntity;
    this.targeting = args.targeting;
    this.match = args.match;
    this.adGroup = args.adGroup;
    this.oldValue = args.oldValue;
    this.newValue = args.newValue;
    this.delta = args.delta;
    this.reasons = args.reasons;
    this.originalNewValue = args.newValue;
    this.lastOptimizedAt = args.lastOptimizedAt;
  }

  public static fromResponseArray(responseArray: ResponsePreviewDataRow[]): PreviewDataRow[] {
    return responseArray.map(
      (response) =>
        new PreviewDataRow({
          id: response.id,
          campaignName: response.campaign_name,
          campaignGroup: response.campaign_group,
          biddingEntity: response.bidding_entity,
          targeting: response.targeting,
          match: response.match,
          adGroup: response.ad_group,
          oldValue: response.old_value,
          newValue: response.new_value,
          originalNewValue: response.new_value,
          lastOptimizedAt: response.last_optimized_at,
          delta: response.delta,
          reasons: response.reasons,
          impressions: response.impressions,
          clicks: response.clicks,
          orders: response.orders,
          acos: response.acos,
          cpm: response.cpm,
          ctr: response.ctr,
          cvr: response.cvr,
          cpa: response.cpa,
          aov: response.aov,
          cpc: response.cpc,
          spend: response.spend,
          sales: response.sales,
          roas: response.roas,
          rpc: response.rpc,
        }),
    );
  }
}

interface PreviewDataRowCreateArguments {
  id: number;
  campaignName: string;
  campaignGroup: string;
  biddingEntity: BiddingEntity;
  targeting: string;
  match: MatchType;
  adGroup: string;
  oldValue: number;
  newValue: number;
  originalNewValue: number;
  lastOptimizedAt: string | null;
  delta: number;
  reasons: OptimizationReason[];
  impressions: MetricsData;
  clicks: MetricsData;
  orders: MetricsData;
  ctr: MetricsData;
  cvr: MetricsData;
  cpc: MetricsData;
  spend: MetricsData;
  sales: MetricsData;
  acos: MetricsData;
  roas: MetricsData;
  rpc: MetricsData;
  cpa: MetricsData;
  aov: MetricsData;
  cpm: MetricsData;
}
