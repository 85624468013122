import { IconButton } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { FunctionComponent } from 'react';

export interface IconButtonCallbackCellRendererParams<T> {
  icon: JSX.Element;
  callback: (rowData: T) => void;
  isDisabled?: boolean;
}

export interface IconButtonCallbackCellRendererProps extends ICellRendererParams, IconButtonCallbackCellRendererParams<unknown> {}

const IconButtonCallbackCellRenderer: FunctionComponent<IconButtonCallbackCellRendererProps> = ({
  data,
  callback,
  icon,
  isDisabled = false,
}) => {
  const handleClick = () => {
    if (data) {
      callback(data);
    }
  };

  return (
    <div className="flex flex-row h-full items-center gap-2">
      <IconButton onClick={handleClick} disabled={isDisabled}>
        {icon}
      </IconButton>
    </div>
  );
};

export default IconButtonCallbackCellRenderer;
