import { FunctionComponent, PropsWithChildren } from 'react';

interface PageLayoutProps extends PropsWithChildren {
  showFullscreen?: boolean;
}

export const PageLayout: FunctionComponent<PageLayoutProps> = ({ children, showFullscreen }) => {
  return (
    <div
      className={` flex max-h-screen min-h-screen w-full flex-col  overflow-y-auto  
 
     ${showFullscreen ? 'fixed inset-0' : ''} `}
    >
      {children}
    </div>
  );
};
