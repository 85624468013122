import { Navigate, RouteObject } from 'react-router-dom';
import { RegistrationLayout } from '@/modules/registration/layouts/RegistrationLayout';
import RegistrationStepCreateAccountPage from '@/modules/registration/pages/RegistrationStepCreateAccountPage';
import { RegistrationStepAuthorizeAmazonPage } from '@/modules/registration/pages/RegistrationStepAuthorizeAmazonPage';
import { RegistrationStepSelectProfilesPage } from '@/modules/registration/pages/RegistrationStepSelectProfilesPage';
import { RegistrationStepStartOptimizingPage } from '@/modules/registration/pages/RegistrationStepStartOptimizingPage';

export const REGISTRATION_ROUTES: RouteObject[] = [
  {
    path: 'register',
    element: <RegistrationLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="create-your-account" replace />,
      },
      {
        path: 'create-your-account',
        element: <RegistrationStepCreateAccountPage />,
      },
      {
        path: 'authorize-amazon',
        element: <RegistrationStepAuthorizeAmazonPage />,
      },
      {
        path: 'select-profiles',
        element: <RegistrationStepSelectProfilesPage />,
      },
      {
        path: 'start-optimizing',
        element: <RegistrationStepStartOptimizingPage />,
      },
    ],
  },
];
