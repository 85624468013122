import { Chip, CircularProgress, Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { MuiColorVariant } from '@/config/theme/color.type';
import { FunctionComponent } from 'react';
import { isNil } from 'lodash-es';

export interface IChipCellRendererParams {
  chipLabel?: string;
  chipColor: MuiColorVariant;
  loadingValue?: string;
  tooltip?: string;
}
interface IChipCellInternalRendererParams extends IChipCellRendererParams, ICellRendererParams {}

export const ChipCellRenderer: FunctionComponent<IChipCellInternalRendererParams> = (props) => {
  const { chipLabel, chipColor, loadingValue, value, valueFormatted, tooltip } = props;

  if (isNil(chipLabel) || chipLabel == '') return null;

  return (
    <Tooltip title={tooltip}>
      <div className="flex h-full items-center">
        {loadingValue == value ? (
          <CircularProgress className="ml-2" size={20} />
        ) : (
          <Chip label={chipLabel ?? valueFormatted ?? value} className={`uppercase`} variant="outlined" color={chipColor} />
        )}
      </div>
    </Tooltip>
  );
};
