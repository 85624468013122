import { FunctionComponent } from 'react';
import RegistrationWizardPage from '../components/RegistrationWizardPage';
import { useNavigate } from 'react-router-dom';
import RegistrationWizardPageTitle from '../components/RegistrationWizardPageTitle';
import { RegistrationWizardPageDescription } from '../components/RegistrationWizardPageDescription';
import { Button } from '@mui/material';

interface RegistrationStepStartOptimizingPageProps {}

export const RegistrationStepStartOptimizingPage: FunctionComponent<RegistrationStepStartOptimizingPageProps> = () => {
  const navigate = useNavigate();
  function onGoToOptimizerClicked() {
    navigate('/app/profiles');
  }

  return (
    <RegistrationWizardPage>
      <div className="flex flex-col items-center">
        <RegistrationWizardPageTitle>Congratulations!</RegistrationWizardPageTitle>
        <RegistrationWizardPageDescription>You are ready to start optimizing!</RegistrationWizardPageDescription>

        <div className="flex flex-col items-center mt-2">
          <Button size="large" onClick={onGoToOptimizerClicked}>
            Go to the Optimizer
          </Button>
        </div>
      </div>
    </RegistrationWizardPage>
  );
};
