import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { FunctionComponent, useRef, useState } from 'react';
import { isNil } from 'lodash-es';
import { CampaignGroupModel } from '../../api/campaign/models/CampaignGroupModel';
import { useTranslation } from '@/lib';
import { OptimizationPreset, availableOptimizationPresets } from '../optimization/OptimizerConfig';
import { campaignService } from '../../api/campaign/campaign-service';
import { toast } from 'react-toastify';
import { CampaignGroupDTO, ChangeCampaignGroupDTO } from '../../api/campaign/campaign-contracts';
import { useMutation } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import { Check } from '@mui/icons-material';

interface CreateGroupProps {
  selectedCampaigns?: number[];
  existingGroups: CampaignGroupModel[];
  onClose: () => void;
  applyChanges?: (changeGroups: ChangeCampaignGroupDTO[]) => void; //TODO: don't use DTOs here?
  isApplyPending?: boolean;
  onCreateConfirmed: (campaignGroup: CampaignGroupModel) => void;
}

export const CreateGroup: FunctionComponent<CreateGroupProps> = ({
  selectedCampaigns,
  existingGroups,
  onClose,
  applyChanges,
  isApplyPending = false,
  onCreateConfirmed,
}) => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState('');
  const [nameError, setNameError] = useState(false);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const [targetAcos, setTargetACOS] = useState<number | ''>('');
  const [targetAcosError, setTargetACOSError] = useState('');
  const prioritizationRef = useRef<HTMLSelectElement>(null);

  const checkGroupNameExists = (name: string): boolean => {
    return !isNil(existingGroups) && existingGroups.some((group) => group.name === name);
  };

  const handleGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setGroupName(name);
    setNameError(checkGroupNameExists(name));
  };

  const handleTargetACOSChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^0-9.]/g, '');

    if (value == '' || value == '0') {
      setTargetACOS('');
      setTargetACOSError('Target ACOS needs to be set');
      return;
    }
    setTargetACOS(Number(value));
    setTargetACOSError('');
  };

  const handleCreate = () => {
    console.log('handleCreate');
    const description = descriptionRef.current?.value ?? '';
    const prioritization = (prioritizationRef.current?.value as OptimizationPreset) ?? OptimizationPreset.BALANCED;

    if (targetAcos !== '' && targetAcos < 1) {
      // This shouldn't happen
      console.log('Negative Target ACOS is not allowed.');
      return;
    }

    const groupData: Omit<CampaignGroupDTO, 'id' | 'total_campaigns'> = {
      name: groupName,
      description: description,
      tacos: (targetAcos as number) / 100, // always in fractions when not displaying
      preset: prioritization,
      bid_ceiling: 0, //TODO
    };

    createGroup(groupData);

    onClose();
  };

  const { mutate: createGroup, isPending: isLoadingCreateGroup } = useMutation({
    mutationFn: (groupData: Omit<CampaignGroupDTO, 'id' | 'total_campaigns'>) => campaignService.createGroups([groupData]),
    onMutate: () => {
      console.log('Creating a new group');
    },
    onSuccess: (data) => {
      if (isNil(data?.payload) || data.httpResponseCode !== 200) {
        toast.error(`Did not receive a response from server: ${data.message}`);
      } else {
        toast.success(`Campaign group created successfully`);

        // mutate doesn't return anything, so need to apply changes here if needed

        const newGroup = data.payload[0];
        if (selectedCampaigns && applyChanges) {
          const newGroupId = newGroup.id;

          const changeGroups: ChangeCampaignGroupDTO[] = selectedCampaigns.map((campaignId) => ({
            campaign_id: campaignId,
            group_id: newGroupId,
          }));

          applyChanges(changeGroups);
        }

        onCreateConfirmed(newGroup);
      }
    },
    onError: (error) => {
      console.error('error:', error);
      toast.error(`Creating group failed: ${error}`);
    },
  });

  return (
    <>
      <DialogTitle>Create New Optimization Group</DialogTitle>
      <DialogContent>
        <form noValidate autoComplete="off">
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                error={nameError}
                label="Group Name"
                value={groupName}
                onChange={handleGroupNameChange}
                helperText={nameError ? 'This group already exists.' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Description" inputRef={descriptionRef} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Target ACOS"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                onChange={handleTargetACOSChange}
                value={targetAcos}
                helperText={targetAcosError}
                error={targetAcosError !== ''}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Prioritization</InputLabel>
                <Select inputRef={prioritizationRef} label="Prioritization" defaultValue={availableOptimizationPresets[0]}>
                  {availableOptimizationPresets.map((preset) => (
                    <MenuItem key={preset} value={preset}>
                      {t(`optimizer_page.optimization_presets.${preset}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>

        <Tooltip title={groupName === '' ? 'Group Name not specified' : targetAcos === '' ? 'Target Acos not specified' : ''}>
          <span>
            <LoadingButton
              onClick={handleCreate}
              loading={isApplyPending || isLoadingCreateGroup}
              loadingPosition="start"
              startIcon={<Check />}
              variant="contained"
              disabled={nameError || targetAcosError !== '' || targetAcos === '' || groupName === ''}
            >
              <span>
                Create
                {selectedCampaigns && selectedCampaigns.length > 0
                  ? ` and Assign to ${selectedCampaigns.length} Campaign` + (selectedCampaigns.length > 1 ? 's' : '')
                  : ''}
              </span>
            </LoadingButton>
          </span>
        </Tooltip>
      </DialogActions>
    </>
  );
};
