import { SettingsContext } from '@/modules/settings/contexts/SettingsContext';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext, useUserSettingsContext, userService, UserSettingKey } from '@/modules/users';
import { isNumber } from 'lodash-es';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const useLogin = () => {
  const navigate = useNavigate();
  const { loadSettings } = useContext(SettingsContext);
  const { setUser } = useUserContext();
  const { setActiveTeam } = useActiveTeamContext();
  const { loadUserSettings } = useUserSettingsContext();

  /**
   * Log in a user into AdLabs, loading all required data (user, settings, etc.)
   * Also navigates to the correct page
   * @param loggedInFirebaseUserId The UID of the currently logged in user in Firebase
   * @param skipImpersonation If true, impersonation will be skipped even if the user is an admin
   * @returns void
   */
  async function logIn(loggedInFirebaseUserId: string, skipImpersonation = false) {
    // Load user
    const getAdLabsUserResponse = await userService.getUserByFirebaseId(loggedInFirebaseUserId);

    if (getAdLabsUserResponse.isSuccess) {
      const user = getAdLabsUserResponse.payload;
      setUser(user);

      if (user.isAdmin && !skipImpersonation) {
        navigate('/impersonate');
        return;
      }

      // Load user settings
      const settings = await loadUserSettings(user.id);

      // Check if user has active profile id setting
      const activeTeamId = settings.find((setting) => setting.key === UserSettingKey.ACTIVE_TEAM_ID)?.value;
      const activeProfileId = settings.find((setting) => setting.key === UserSettingKey.ACTIVE_PROFILE_ID)?.value;

      setActiveTeam({
        teamId: isNumber(activeTeamId) ? activeTeamId : undefined,
        profileId: isNumber(activeProfileId) ? activeProfileId : undefined,
        userTeams: user.teams,
      });

      // Load profile settings
      const loadSettingsResponse = await loadSettings();
      if (!loadSettingsResponse.isSuccess) {
        toast.error('Error loading settings');
        return;
      } else {
        // Change i18n language if configured
        // i18n.changeLanguage('nl');
        navigate('/app');
      }
    } else {
      toast.error('Error loading user');
      return;
    }
  }

  return { logIn };
};

export { useLogin };
