import { FunctionComponent } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { CampaignGroupModel } from '@/modules/optimizer/api/campaign/models/CampaignGroupModel';
import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation } from '@/lib';
import useFormatting from '@/hooks/useFormatting';

export interface ICampaignGroupCellRendererParams extends ICellRendererParams {
  group: CampaignGroupModel | null;
}

export const CampaignGroupCellRenderer: FunctionComponent<ICampaignGroupCellRendererParams> = ({ group, value }) => {
  const { t } = useTranslation();
  const { formatPercent } = useFormatting();

  const tooltipContent = group ? (
    <>
      Name: {group.name}
      <br />
      Description: {group.description}
      <br />
      TACOS: {formatPercent(group.tacos)}
      <br />
      Preset: {t(`optimizer_page.optimization_presets.${group.preset}`)}
      {/* <br />
      Bid Ceiling: {group.bidCeiling} */}
      <br />
      Campaigns In Group: {group.totalCampaigns}
    </>
  ) : null;

  return (
    <Tooltip title={tooltipContent}>
      <span>{value}</span>
    </Tooltip>
  );
};
