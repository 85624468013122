import Gleap from 'gleap';

export function initGleap() {
  Gleap.initialize('uLu9hsekT4onVdFzG4sXfQI16Da27exv');
}

export enum GleapWorkflowType {
  BugReport = 'bugreporting',
  Default = '',
}
