import { ButtonBase } from '@mui/material';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface MainMenuItemProps {
  pathname: string;
  label: string;
  icon: ReactNode;
  activePathnames: string[];
  isExpanded: boolean;
}

export const MainMenuItem: FunctionComponent<MainMenuItemProps> = ({ activePathnames, icon, label, pathname, isExpanded }) => {
  const [isRouteActive, setIsRouteActive] = useState(false);
  const navigate = useNavigate();
  const currentLocation = useLocation();
  useEffect(() => {
    if (currentLocation.pathname) {
      setIsRouteActive(activePathnames.some((r) => currentLocation.pathname.startsWith(r)));
    }
  }, [activePathnames, currentLocation.pathname]);

  function navigateToPath() {
    navigate(pathname);
  }
  return (
    <ButtonBase
      onClick={navigateToPath}
      component="div"
      className={`flex h-10 w-full  justify-start overflow-hidden   rounded-lg my-2    px-2
            focus:text-white dark:first-line:focus:text-primary-700   ${
              isRouteActive
                ? 'bg-primary-700 text-primary-50 dark:bg-gray-850 dark:text-primary-500 '
                : 'text-blue-50 hover:bg-primary-200 hover:bg-opacity-20 dark:text-white dark:hover:bg-gray-700'
            }`}
    >
      {icon}
      <span className={`pl-2.5   font-semibold transition-opacity ${isExpanded ? 'opacity-100' : 'opacity-0'}`}>{label}</span>
    </ButtonBase>
  );
};
