import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import { SelectedProfileDTO } from '@/modules/profiles/types/SelectedProfile';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
import { useUserContext, userService } from '@/modules/users';
import { teamService } from '@/modules/teams';
import { isNil } from 'lodash-es';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, DialogContentText } from '@mui/material';
import ProfileList from '@/modules/profiles/components/ProfileList';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useNavigate } from 'react-router-dom';

export interface ProfileSelectorDialogProps {
  open: boolean;
  onClose: () => void;
}

export function ProfileSelectorDialog({ onClose, open }: ProfileSelectorDialogProps) {
  const navigate = useNavigate();

  const { firebaseId, user, setUser } = useUserContext();
  const { activeTeam } = useActiveTeamContext();
  const [profiles, setProfiles] = useState<SelectedProfileDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (user && open) {
      if (!isNil(activeTeam) && !isNil(activeTeam.amazonAccount) && activeTeam.amazonAccount.isAuthorized) {
        loadProfiles();
      }
    }
  }, [user, open]);

  useEffect(() => {
    if (open && (isNil(activeTeam) || isNil(activeTeam.amazonAccount) || !activeTeam.amazonAccount.isAuthorized)) {
      setOpenNotAuthorizedDialog(true);
      onClose();
    }
  }, [open]);

  function setError(message: string) {
    setIsError(true);
    setErrorMessage(message);
  }

  function resetError() {
    setIsError(false);
    setErrorMessage('');
  }

  async function loadProfiles() {
    try {
      resetError();
      setIsLoading(true);
      const res = await teamService.getAmazonProfiles();

      if (res.isSuccess) {
        if (!isNil(res.payload) && res.payload.length > 0) {
          const profiles = res.payload.map((profile) => {
            return {
              id: profile.id,
              name: profile.name,
              market: profile.countryCode,
              type: profile.type,
              isSelected: false,
            };
          });

          const existingProfileIds = activeTeam?.profiles.map((profile) => profile.id);

          const filteredProfiles = profiles.filter((p) => p.market === 'US' && !existingProfileIds?.includes(p.id));

          if (filteredProfiles.length > 0) {
            setProfiles(filteredProfiles);
          } else {
            setError('No profiles found to add');
          }
        } else {
          setError('No profiles found.');
        }
      } else {
        setError('Something went wrong. ' + res.message);
      }
    } catch (error) {
      setError('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  async function onConfirmClicked() {
    setIsLoading(true);
    resetError();
    try {
      const selectedProfiles = profiles.filter((p) => p.isSelected);
      if (selectedProfiles.length === 0) {
        setError('No profiles selected');
        return;
      }

      const result = await teamService.addProfilesToTeam(
        activeTeam?.id as number,
        selectedProfiles.map((p) => p.id),
      );

      if (result.isSuccess) {
        toast.success('Profiles added successfully');

        const userResponse = await userService.getUserByFirebaseId(firebaseId as string);
        if (userResponse.isSuccess) {
          setUser(userResponse.payload);
        }

        onClose();
      } else {
        setError('Something went wrong. Please try again later.');
      }
    } catch (error) {
      setError('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  function onProfileSelectionChanged(profileId: number) {
    const newProfiles = profiles.map((p) => {
      if (p.id === profileId) {
        return {
          ...p,
          isSelected: !p.isSelected,
        };
      }
      return p;
    });
    setProfiles(newProfiles);
  }

  const [openNotAuthorizedDialog, setOpenNotAuthorizedDialog] = useState(false);

  const handleCloseNotAuthorizedDialog = () => {
    setOpenNotAuthorizedDialog(false);
    onClose();
  };

  const handleConfirmNotAuthorizedDialog = () => {
    setOpenNotAuthorizedDialog(false);
    onClose();
    navigate('/register/authorize-amazon');
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} maxWidth={'md'}>
        <DialogTitle>Add Profiles</DialogTitle>

        <DialogContent>
          {isError && <Alert severity="error">{errorMessage}</Alert>}
          {!isError && (
            <>
              <div>Select which Profiles you would like to add</div>

              <div className="mt-4 flex flex-col justify-stretch gap-y-3">
                {!isError && <ProfileList profiles={profiles} onProfileSelectionChanged={onProfileSelectionChanged} />}
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            className="mt-6 w-52"
            size="large"
            variant="contained"
            color="primary"
            onClick={onConfirmClicked}
            loading={isLoading}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openNotAuthorizedDialog}
        onClose={handleCloseNotAuthorizedDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Amazon account not connected!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your team is not connected to any amazon account. Please connect to an amazon account to add profiles.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotAuthorizedDialog}>Cancel</Button>
          <Button onClick={handleConfirmNotAuthorizedDialog} autoFocus>
            Go to auth page
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
