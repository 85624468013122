import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';

export enum StatusType {
  PENDING = 'PENDING',
  ONGOING = 'ONGOING',
  DOWNLOADED = 'DOWNLOADED',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
}

// Frontend only
export enum AdditionalStatusType {
  NEVER = 'NEVER',
  LOADING = 'LOADING',
  OUTDATED = 'OUTDATED',
  ERROR = 'ERROR',
}

export interface ProfileReportsDTO {
  profile: number;
  placement: ReportInfoDTO[];
  targeting: ReportInfoDTO[];
}

export interface ReportInfoDTO {
  id: string;
  created_at: string;
  updated_at: string;
  ad_product: string;
  type: string;
  status: StatusType;
}

export interface CreateReportsResponse {
  isCreateTargetingReportRequestSuccess: boolean;
  isCreatePlacementReportRequestSuccess: boolean;
}

export interface CreateProfileReportDTO {}
export interface CreateProfileReportResponse {}

export interface RestartInitialReportsDTO {}
export interface RestartInitialReportsResponse {}

export interface OptimizerJobDTO {
  id: number;
  reverted_job_id: number;
  optimizer_version: string;
  user_id: number;
  team_id: number;
  campaign_count: number;
  preview_campaign_count: number;
  preview_entity_count: number;
  applied_entity_count: number;
  success_entity_count: number;
  failed_entity_count: number;
  start_date: string;
  end_date: string;
  compare_start_date: string;
  compare_end_date: string;
  tacos: number;
  sentiment: OptimizationPreset;
  bid_ceiling: number;
  enabled_keyword_groups: string;
  exclude_no_impressions: false;
  state: string;
  created_at: string;
}
