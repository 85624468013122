import { ApiResponse } from '@/lib/api/api-response';
import { apiClient, apiTeamClient } from '@/lib/api/base-client';
import { AddProfilesToTeamDTO, TeamUpdateDTO } from './teams.contracts';
import { ProfileModel } from '@/modules/users/models/ProfileModel';
import { ProfileDTO } from '@/modules/users/api/users/users.contracts';

export class TeamService {
  public basePath = 'teams';

  async addProfilesToTeam(teamId: number, profileIds: number[]): Promise<ApiResponse<undefined>> {
    try {
      const payload: AddProfilesToTeamDTO = {
        profile_ids: profileIds,
      };

      return await apiClient.post<undefined>(`${this.basePath}/${teamId}/profiles/add`, payload);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async removeProfilesFromTeam(teamId: number, profileIds: number[]): Promise<ApiResponse<undefined>> {
    try {
      const payload: AddProfilesToTeamDTO = {
        profile_ids: profileIds,
      };
      return await apiClient.post<undefined>(`${this.basePath}/${teamId}/profiles/remove`, payload);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async updatePreferredUpdateTime(preferredUpdateTime: string): Promise<ApiResponse<undefined>> {
    try {
      const teamUpdateDTO: TeamUpdateDTO = {
        preferred_update_time: preferredUpdateTime,
      };

      return await apiTeamClient.post<undefined>(`/`, teamUpdateDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAmazonProfiles(): Promise<ApiResponse<ProfileModel[]>> {
    try {
      const response = await apiTeamClient.get<ProfileDTO[]>(`/amazon-profiles`);
      return response.processPayload((p) => p.map(ProfileModel.fromDTO));
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const teamService = new TeamService();
