import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FilterModel, OperatorType } from '../models/FilterModel';
import { FunctionComponent, forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { Button, FilterOptionsState, ListItem } from '@mui/material';
import { FilterSelectOptionModel } from '../models/FilterModelSelectOption';

interface FilterMultiSelectProps {
  filter: FilterModel;
  label: string;
}

export const FilterMultiSelect: FunctionComponent<FilterMultiSelectProps> = ({ filter, label }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);
  const [inputValue, setInputValue] = useState('');
  const [selectedOptionIds, setSelectedOptionIds] = useState<number[]>(
    filter.conditions && filter.conditions[0].values ? (filter.conditions[0].values as number[]) : [],
  );

  const handleChange = (event: React.SyntheticEvent<Element, Event>, value: FilterSelectOptionModel[]) => {
    setSelectedOptionIds(value.map((v) => v.selectOptionId as number));
  };

  useEffect(() => {
    filter.conditions = [
      {
        values: selectedOptionIds,
        operator: OperatorType.IN,
      },
    ];

    setFilterValue(filter);
  }, [selectedOptionIds]);

  const [options, setOptions] = useState<FilterSelectOptionModel[]>([]);

  useEffect(() => {
    filter.getOptions().then((options) => {
      setOptions(options);
    });
  }, []);

  const OPTIONS_LIMIT = 250;
  const defaultFilterOptions = createFilterOptions<FilterSelectOptionModel>();

  function filterOptions(options: FilterSelectOptionModel[], state: FilterOptionsState<FilterSelectOptionModel>) {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  }

  // If you control the value, make sure it's referentially stable between renders.
  const selectedValues = useMemo(
    () => options.filter((d) => selectedOptionIds.includes(d.selectOptionId as number)),
    [options, selectedOptionIds],
  );

  // New state to manage the select all behavior
  const handleSelectAll = () => {
    const state: FilterOptionsState<FilterSelectOptionModel> = {
      inputValue,
      getOptionLabel: (option) => option.name,
    };
    const filteredOptions = defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
    setSelectedOptionIds(filteredOptions.map((v) => v.selectOptionId as number));
  };

  const ListboxComponent = forwardRef<HTMLUListElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
    return (
      <ul ref={ref} {...props}>
        <ListItem>
          <Button variant="text" onClick={handleSelectAll}>
            {'Select All'}
          </Button>
        </ListItem>
        {props.children}
      </ul>
    );
  });

  return (
    <div className="flex flex-row gap-2" style={{ maxWidth: '440px' }}>
      {selectedOptionIds && (
        <Autocomplete
          multiple
          id={'multiselect-' + filter.key}
          style={{ width: 500 }}
          filterOptions={filterOptions}
          limitTags={2}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label={label} placeholder={label} InputLabelProps={{ shrink: true }} />}
          groupBy={(option) => option.groupName}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          options={options}
          value={selectedValues}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          ListboxComponent={ListboxComponent}
        />
      )}
    </div>
  );
};
