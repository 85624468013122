import { FunctionComponent, useState } from 'react';
import { getUpdatedSelectionColors } from './MetricsConfig';
import { Alert, AlertTitle, Card, Skeleton, Typography } from '@mui/material';
import { MetricsCustomizationModal } from './MetricsCustomizationModal';
import './Metrics.css';
import { useTemplateContext } from '../../contexts/TemplateContext';
import { METRICS_QUERY_KEY, metricsService } from './api/metrics-service';
import { isEmpty } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { MetricField } from './models/CommonMetricsModel';
import CollapsibleCopyableDetails from '@/components/CollapsibleCopyableDetails';
import { useTranslation } from '@/lib';
import MetricsList from './MetricsList';
import AddMetricButton from './AddMetricButton';

const Metrics: FunctionComponent = () => {
  const { filters, visibleMetrics, setVisibleMetrics, selectedMetrics, setSelectedMetrics, selectedMetricColors, setSelectedMetricColors } =
    useTemplateContext();

  const { activeProfile } = useActiveTeamContext();
  const { t } = useTranslation();

  const {
    data: metricValues,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: [METRICS_QUERY_KEY, activeProfile?.id, filters],
    queryFn: async () => {
      const result = await metricsService.getMetrics(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading metric values\n' + JSON.stringify(result));
      }
    },

    retry: 1,
    enabled: !isEmpty(filters),
  });

  function onSingleSelectionChanged(changedMetric: MetricField) {
    const newSelectedMetrics = selectedMetrics.includes(changedMetric)
      ? selectedMetrics.filter((metric) => metric !== changedMetric)
      : [...selectedMetrics, changedMetric];

    setSelectedMetrics(newSelectedMetrics);
    // TODO: Api.updateUserSetting(newSelectedMetrics)

    const updatedColors = getUpdatedSelectionColors(selectedMetricColors, newSelectedMetrics);
    setSelectedMetricColors(updatedColors);
  }

  const [isCustomizationModalOpen, setIsCustomizationModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsCustomizationModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsCustomizationModalOpen(false);
  };

  const onApplyVisibilityChanges = (visibleMetrics: MetricField[]) => {
    // TODO: Api.updateUserSetting(visibleMetrics)
    setVisibleMetrics(visibleMetrics);
    // Deselect hidden metrics
    setSelectedMetrics(selectedMetrics.filter((metric) => visibleMetrics.includes(metric)));
  };

  return (
    <div className="flex flex-wrap gap-5">
      {isError && (
        <Card className="flex-grow rounded-xl py-0">
          <Alert severity="error">
            <AlertTitle>Oops!</AlertTitle>
            <div className="flex w-full flex-col">
              <Typography variant="body1">{t('messages.errors.generic')}</Typography>

              <CollapsibleCopyableDetails headerText={'Details'} message={error instanceof Error ? error.message : ''} />
            </div>
          </Alert>
        </Card>
      )}
      {isLoading && (
        <div className="flex animate-fadeInBg gap-5">
          {Array(5)
            .fill(1)
            .map((_, i) => (
              <Skeleton key={i} variant="rectangular" width={135} height={120} style={{ borderRadius: '12px' }} />
            ))}
        </div>
      )}
      {!isLoading && !isError && (
        <>
          <MetricsList
            metricValues={metricValues}
            selectedMetrics={selectedMetrics}
            selectedMetricColors={selectedMetricColors}
            visibleMetrics={visibleMetrics}
            onSingleSelectionChanged={onSingleSelectionChanged}
          />
          <AddMetricButton onClick={handleOpenModal} />
        </>
      )}
      <MetricsCustomizationModal
        isOpen={isCustomizationModalOpen}
        onClose={handleCloseModal}
        visibleMetrics={visibleMetrics}
        onApplyVisibilityChanges={onApplyVisibilityChanges}
      />
    </div>
  );
};

export default Metrics;
