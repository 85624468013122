import {
  Dialog,
  Alert,
  AlertTitle,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import { FunctionComponent } from 'react';
import { OptimizationApplyResponse } from '../api/optimization-contracts';
import { useTranslation } from '@/lib';

interface PostOptimizationApplyModalProps {
  isOpen: boolean;
  onClose: () => void;
  optimizationApplyResponse:
    | {
        error: string | null;
        payload: OptimizationApplyResponse[] | undefined | null;
      }
    | undefined;
}

export const PostOptimizationApplyModal: FunctionComponent<PostOptimizationApplyModalProps> = ({
  isOpen,
  onClose,
  optimizationApplyResponse,
}) => {
  const { t } = useTranslation();
  const { error, payload } = optimizationApplyResponse || {};
  const textAreaContent = error || null;

  const handleCopyClick = async () => {
    try {
      if (textAreaContent) {
        await navigator.clipboard.writeText(textAreaContent);
      }
      console.log('Text successfully copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const getCloseAction = () => (
    <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );

  //TODO: see if CollapsibleCopyableDetails can be used
  const getDialogContent = () => {
    // Something wrong with our API
    if (!optimizationApplyResponse || error || !payload) {
      return (
        <Alert severity="error" action={getCloseAction()}>
          <AlertTitle>Oops!</AlertTitle>
          <div className="flex w-full flex-col">
            {textAreaContent && (
              <>
                <Typography variant="body1">{t('messages.errors.generic')}</Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={textAreaContent}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={handleCopyClick}>
                          <ContentCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          </div>
        </Alert>
      );
    }

    // Our API good
    const initialTotals = { totalErrorCount: 0, totalUpdateCount: 0 };

    const totals = payload.reduce((accumulator, currentObject) => {
      return {
        totalErrorCount: accumulator.totalErrorCount + currentObject.error_count,
        totalUpdateCount: accumulator.totalUpdateCount + currentObject.update_count,
      };
    }, initialTotals);

    const { totalErrorCount, totalUpdateCount } = totals;

    return (
      <>
        {totalErrorCount > 0 ? (
          // Some failed on Amazon
          <Alert severity="warning" action={getCloseAction()}>
            <AlertTitle>Hmm...</AlertTitle>
            <Typography variant="body1">
              Not all entities were updated.
              <br />
              If the problem persists, make sure to contact us!
            </Typography>
            <Typography variant="body2">
              <List>
                {payload.map((item, index) => (
                  <>
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <>
                            <Typography variant="h6">{t(`optimizer_page.labels.${item.bidding_entity}`)}</Typography>
                            <Typography variant="body2">Update Count: {item.update_count}</Typography>
                            <Typography variant="body2">Error Count: {item.error_count}</Typography>
                          </>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
            </Typography>
          </Alert>
        ) : (
          <Alert severity="success" action={getCloseAction()}>
            <AlertTitle>Success!</AlertTitle>
            <strong>{totalUpdateCount}</strong> {totalUpdateCount == 1 ? 'entity' : 'entities'} updated successfully
          </Alert>
        )}
      </>
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      {getDialogContent()}
    </Dialog>
  );
};
