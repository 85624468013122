import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Box, SelectChangeEvent, TextField } from '@mui/material';
import { FilterCondition, FilterModel, LogicalOperatorType, OperatorType, UnitType } from '../models/FilterModel';
import { isEmpty, isNil, isNumber } from 'lodash-es';
import { LogicalOperatorSelect } from './LogicalOperatorSelect';
import InputAdornment from '@mui/material/InputAdornment';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import useFormatting, { CurrencyPosition } from '@/hooks/useFormatting';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
interface FilterBetweenProps {
  filter: FilterModel;
  minLabel?: string;
  maxLabel?: string;
  isOperatorDisabled?: boolean;
}
export const FilterBetween: FunctionComponent<FilterBetweenProps> = ({
  filter,
  minLabel = 'Min',
  maxLabel = 'Max',
  isOperatorDisabled = false,
}) => {
  const { setFilterValue } = useContext(PendingFiltersContext);
  const { getCurrencySymbolPosition, getCurrencySymbol } = useFormatting();
  const currencyPosition = getCurrencySymbolPosition();
  const currencySymbol = getCurrencySymbol();

  const [min, setMin] = useState<number | string>(filter.conditions && filter.conditions?.length > 0 ? filter.conditions[0].values[0] : '');
  const [minOperator, setMinOperator] = useState<OperatorType>(
    filter.conditions && filter.conditions?.length > 0 ? filter.conditions[0].operator : OperatorType.GREATER_THAN_OR_EQUAL,
  );

  const [max, setMax] = useState<number | string>(filter.conditions && filter.conditions?.length > 1 ? filter.conditions[1].values[0] : '');
  const [maxOperator, SetMaxOperator] = useState<OperatorType>(
    filter.conditions && filter.conditions?.length > 1 ? filter.conditions[1].operator : OperatorType.LESS_THAN_OR_EQUAL,
  );

  const [logicalOperator, setLogicalOperator] = useState<LogicalOperatorType>(filter.logicalOperator ?? LogicalOperatorType.AND);

  const handleOperatorChange = (event: SelectChangeEvent) => {
    const operator = event.target.value;
    if (operator == OperatorType.NOT_EQUAL) {
      setMinOperator(OperatorType.LESS_THAN_OR_EQUAL);
      SetMaxOperator(OperatorType.GREATER_THAN_OR_EQUAL);
      setLogicalOperator(LogicalOperatorType.OR);
    } else {
      setMinOperator(OperatorType.GREATER_THAN_OR_EQUAL);
      SetMaxOperator(OperatorType.LESS_THAN_OR_EQUAL);
      setLogicalOperator(LogicalOperatorType.AND);
    }
  };

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const min = event.target.value;
    setMin(min);
  };

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const max = event.target.value;
    setMax(max);

    if (isNil(min) || isEmpty(min)) {
      // If user only sets max value we should set min value to zero during handle change
      setMin(0);
    }
  };

  const handleDateRangeChange = (dateRange: DateRange<Dayjs>) => {
    const today = new Date();

    const isSameDate = (date1: Date, date2: Date): boolean => {
      return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
    };

    // Adjusted for the start of the day if it's today
    const getStartOfDay = (date: Date) => {
      if (isSameDate(date, today)) {
        date.setHours(0, 0, 0, 0);
      }
      return date.toISOString();
    };

    // Adjusted for the end of the day if it's today
    const getEndOfDay = (date: Date) => {
      if (isSameDate(date, today)) {
        date.setHours(23, 59, 59, 999);
      }
      return date.toISOString();
    };

    // Convert Dayjs object to JavaScript Date object
    const convertDayjsToDate = (dayjsObj: Dayjs | null) => {
      return dayjsObj ? dayjsObj.toDate() : null;
    };

    const startDate = convertDayjsToDate(dateRange[0]);
    const endDate = convertDayjsToDate(dateRange[1]);

    // If nothing selected, use epoch beginning time. Set time to the very beginning of the day
    const startDateISO = startDate ? getStartOfDay(startDate) : new Date(0).toISOString();
    // If nothing selected, set to the end of today. Set time to the very end of the day
    const endDateISO = endDate ? getEndOfDay(endDate) : getEndOfDay(today);

    setMin(startDateISO);
    setMax(endDateISO);
  };

  useEffect(() => {
    const conditions: FilterCondition[] = [];

    conditions.push({
      values: isEmpty(min) ? [0] : isNumber(min) ? [min as number] : [min as string],
      operator: minOperator,
    });

    if (!isEmpty(max)) {
      conditions.push({
        values: isNumber(max) ? [max as number] : [max as string],
        operator: maxOperator,
      });
    }

    filter.logicalOperator = logicalOperator;
    filter.conditions = conditions;
    setFilterValue(filter);
  }, [min, max, minOperator, maxOperator]);

  const getInputProps = (unitType: UnitType | undefined, currencyPosition: CurrencyPosition, currencySymbol: string) => {
    if (!unitType) {
      return {};
    }

    if (unitType === UnitType.PERCENTAGE) {
      return { endAdornment: <InputAdornment position="end">%</InputAdornment> };
    }

    if (unitType === UnitType.CURRENCY) {
      return currencyPosition === CurrencyPosition.LEFT
        ? { startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment> }
        : { endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment> };
    }

    return {};
  };

  return (
    <div className="flex flex-row gap-2">
      {filter.unitType !== UnitType.DATE ? (
        <>
          <div>
            <LogicalOperatorSelect
              label={'Operator'}
              isDisabled={isOperatorDisabled}
              filterKey={filter.key}
              defaultValue={minOperator === OperatorType.GREATER_THAN_OR_EQUAL ? OperatorType.EQUAL : OperatorType.NOT_EQUAL}
              handleChange={handleOperatorChange}
            />
          </div>
          <div>
            <TextField
              id={minLabel + '-' + filter.key}
              label={minLabel}
              variant="outlined"
              defaultValue={min}
              onChange={handleMinChange}
              className="w-32"
              InputProps={getInputProps(filter.unitType, currencyPosition, currencySymbol)}
            />
          </div>
          <div className={'mt-4'}>to</div>
          <div>
            <TextField
              id={maxLabel + '-' + filter.key}
              label={maxLabel}
              variant="outlined"
              defaultValue={max}
              onChange={handleMaxChange}
              className="w-32"
              InputProps={getInputProps(filter.unitType, currencyPosition, currencySymbol)}
            />
          </div>
        </>
      ) : null}
      {filter.unitType == UnitType.DATE ? (
        // TODO: there should be a better way to center vertically than box top margin
        <Box mt={8}>
          <DateRangePicker
            defaultValue={[min !== '' ? dayjs(min) : null, max !== '' ? dayjs(max) : null]}
            slotProps={{
              textField: ({ position }) => ({
                label: position === 'start' ? 'Start Date' : 'End Date',
              }),
            }}
            onChange={handleDateRangeChange}
          />
        </Box>
      ) : null}
    </div>
  );
};
