import { CircularProgress, Popover } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Dispatch, FunctionComponent, RefObject, SetStateAction, useState } from 'react';
import { campaignService, createCampaignsWithTimelineQueryKey } from '../../api/campaign/campaign-service';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { isNil } from 'lodash-es';
import { CreateGroup } from './CreateGroup';
import { UpdateGroups } from './UpdateGroups';
import { sleep } from '@/lib/api/api-utils';
import { CampaignsWithTimelineResponse, ChangeCampaignGroupDTO } from '../../api/campaign/campaign-contracts';
import { toast } from 'react-toastify';
import { CampaignGroupModel } from '../../api/campaign/models/CampaignGroupModel';
import { useTemplateContext } from '../../contexts/TemplateContext';

interface OptimizationGroupsPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedCampaigns: number[];
}

export const OptimizationGroupsPopover: FunctionComponent<OptimizationGroupsPopoverProps> = ({
  buttonRef,
  isOpen,
  setIsOpen,
  selectedCampaigns,
}) => {
  const [isInCreateMode, setisInCreateMode] = useState(false);
  const { activeTeam, activeProfile, CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE } = useActiveTeamContext();
  const queryClient = useQueryClient();
  const { filters } = useTemplateContext();

  const handlePopoverClose = async () => {
    setIsOpen((previousValue) => !previousValue);
    await sleep(200); // So that the create -> update isn't seen in the closing animation
    setisInCreateMode(false);
  };

  const { data: campaignGroups, isLoading } = useQuery({
    queryKey: CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE,
    queryFn: async () => {
      const result = await campaignService.getGroups();
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading groups');
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  const { mutate: applyChanges, isPending: isApplyingGroupChange } = useMutation({
    mutationFn: (changeGroups: ChangeCampaignGroupDTO[]) => campaignService.changeGroups(changeGroups),
    onMutate: () => {
      console.log('Applying campaign group change');
    },
    onSuccess: (data, variables) => {
      if (isNil(data?.payload) || data.httpResponseCode !== 200) {
        toast.error(`Did not receive a response from server: ${data.message}`);
      } else {
        updateCampaigns(variables);
        toast.success(`Campaign groups changed successfully`);
      }
    },
    onError: (error) => {
      console.error('error:', error);
      toast.error(`Creating group failed: ${error}`);
    },
  });

  const campaignsWithTimelineQueryKey = createCampaignsWithTimelineQueryKey(activeProfile?.id, filters);
  const updateGroups = () => {
    queryClient.setQueryData<CampaignGroupModel[]>(CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE, (oldGroupData) => {
      if (!oldGroupData) return [];

      const newGroupData = oldGroupData.map((group) => ({ ...group, totalCampaigns: 0 }));
      const groupCampaignCounts = new Map<number, number>();
      const allCampaigns = queryClient.getQueryData<CampaignsWithTimelineResponse>(campaignsWithTimelineQueryKey)?.campaigns || [];

      allCampaigns.forEach((campaign) => {
        groupCampaignCounts.set(campaign.groupId, (groupCampaignCounts.get(campaign.groupId) || 0) + 1);
      });

      // Update the totalCampaigns for each group based on the counts
      newGroupData.forEach((group) => {
        const count = groupCampaignCounts.get(group.id) || 0;
        group.totalCampaigns = count;
      });

      // Return the updated group data
      return newGroupData;
    });
  };

  const updateCampaigns = (updatedGroups: ChangeCampaignGroupDTO[]) => {
    const campaignsWithTimelineQueryKey = createCampaignsWithTimelineQueryKey(activeProfile?.id, filters);

    queryClient.setQueryData<CampaignsWithTimelineResponse>(campaignsWithTimelineQueryKey, (oldData) => {
      // If there's no old data, return undefined to indicate no update is possible
      if (!oldData) return undefined;

      // Create a map for quick lookup of the updated group IDs
      const updatedGroupMap = new Map<number, number>();
      updatedGroups.forEach(({ campaign_id, group_id }) => {
        updatedGroupMap.set(campaign_id, group_id);
      });

      // Map through the old campaign data to apply the groupId updates
      const updatedCampaigns = oldData.campaigns.map((campaign) => {
        const newGroupId = updatedGroupMap.get(campaign.id);
        // If there's a new groupId update, apply it
        if (newGroupId !== undefined) {
          return { ...campaign, groupId: newGroupId };
        }
        // Otherwise, return the campaign as is
        return campaign;
      });

      // Return the updated response with the updated campaigns array
      return {
        ...oldData,
        campaigns: updatedCampaigns,
      };
    });

    // Groups changed for some or all campaigns, that means the totalCampaigns needs to be updated too
    // TODO: this can made more efficient (but more complex) when campaign group changes are tracked and the deltas are applied to the groups
    // TODO:   that way you don't need to loop through the whole campaign table, but again it makes it more complex
    updateGroups();
  };

  const onCreateConfirmed = (campaignGroup: CampaignGroupModel) => {
    console.log('Created', campaignGroup);
    addNewRowToCampaignGroups(campaignGroup);
  };

  const addNewRowToCampaignGroups = (newRow: CampaignGroupModel) => {
    queryClient.setQueryData<CampaignGroupModel[]>(CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE, (oldData) => {
      return oldData ? [...oldData, newRow] : [newRow];
    });
  };

  return (
    <Popover
      id={'optimizer-groups-popover'}
      open={isOpen}
      anchorEl={buttonRef.current}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      disablePortal={true} // Allow scrolling bg scrolling when popover is open
      slotProps={{ paper: { style: { maxWidth: 500, maxHeight: 'none' } } }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : isInCreateMode || !campaignGroups || (campaignGroups && campaignGroups.length === 0) ? (
        <CreateGroup
          existingGroups={campaignGroups ?? []}
          onClose={handlePopoverClose}
          applyChanges={applyChanges}
          isApplyPending={false}
          selectedCampaigns={selectedCampaigns}
          onCreateConfirmed={onCreateConfirmed}
        />
      ) : (
        <UpdateGroups
          existingGroups={campaignGroups ?? []}
          selectedCampaigns={selectedCampaigns}
          onClose={handlePopoverClose}
          onCreateClicked={() => setisInCreateMode(true)}
          applyChanges={applyChanges}
          isApplyPending={isApplyingGroupChange}
        />
      )}
    </Popover>
  );
};
