import { FunctionComponent, useState } from 'react';
import { MainMenuItem as MainMenuItemType } from '../types/MenuItem';
import { MainMenuBottom } from './MainMenuBottom';
import { MainMenuItem } from './MainMenuItem';
import { AdLabsLogo } from './AdLabsLogo';

interface MainMenuProps {
  mainMenuItems: MainMenuItemType[];
}

export const MainMenu: FunctionComponent<MainMenuProps> = ({ mainMenuItems }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  function onMouseEnter() {
    setIsExpanded(true);
  }
  function onMouseLeave() {
    setIsExpanded(false);
  }
  return (
    <div>
      <div className="w-16"></div>
      <aside
        className={`fixed z-20 flex  h-full shrink-0 flex-col  items-start  justify-between overflow-y-auto overflow-x-hidden bg-slate-800    text-white transition-all      dark:border-r dark:border-r-gray-800 dark:bg-gray-800  ${
          isExpanded ? '  w-60 shadow-lg' : 'w-16'
        }`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {/* <!-- Side Nav Bar--> */}
        <div className="w-full">
          <div className="ml-0.5 flex w-full items-center  py-3 pl-5">
            <AdLabsLogo isExpanded={isExpanded} />
          </div>
          <div className=" mt-5 w-full px-3">
            {mainMenuItems.map((mmi) => (
              <MainMenuItem
                isExpanded={isExpanded}
                label={mmi.label}
                pathname={mmi.url}
                activePathnames={mmi.activeRouteIds}
                icon={mmi.icon}
                key={mmi.url}
              />
            ))}
          </div>
        </div>

        <div className="  mb-4 flex w-full px-3">
          <MainMenuBottom isExpanded={isExpanded} />
        </div>
      </aside>
    </div>
  );
};
