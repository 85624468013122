import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { CampaignModel } from '../api/campaign/models/CampaignModel';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { GridOptions } from 'ag-grid-enterprise';
import useFormatting from '@/hooks/useFormatting';
import {
  AreaSparklineOptions,
  GridReadyEvent,
  ICellRendererParams,
  IHeaderParams,
  SelectionChangedEvent,
  TooltipRendererParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import CampaignStateCellRenderer from '@/components/grid/cells/CampaignStateCellRenderer';
import ChangePercentageCellRenderer from '@/components/grid/cells/ChangePercentageCellRenderer';
import { SentimentDirection } from '@/types/colors.enum';
import ChangePercentageHeaderRenderer, { IChangePercentageHeaderRendererProps } from '@/components/grid/cells/ChangePercentageHeaderRenderer';
import { isEmpty, isNil } from 'lodash-es';
import { useTemplateContext } from '../contexts/TemplateContext';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { METRICS_QUERY_KEY, metricsService } from './metrics/api/metrics-service';
import { MetricField } from './metrics/models/CommonMetricsModel';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import CollapsibleCopyableDetails from '@/components/CollapsibleCopyableDetails';
import { Alert, AlertTitle, Typography, Card } from '@mui/material';
import { useTranslation } from '@/lib';
import { useQuery } from '@tanstack/react-query';
import { UserSettingKey } from '@/modules/users';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { getSentimentByMetric } from './metrics/MetricsConfig';
import { campaignService } from '../api/campaign/campaign-service';
import { CampaignGroupModel } from '../api/campaign/models/CampaignGroupModel';
import { CampaignGroupCellRenderer } from '@/components/grid/cells/CampaignGroupCellRenderer';

interface CampaignTableProps {
  rowData: CampaignModel[] | undefined;
  onSelectedCampaignsChanged: (selectedCampaigns: number[]) => void;
  isLoading: boolean;
  reduceHeightBy: number;
  deselectAll?: boolean;
  campaignLoadingErrorMessage: string;
  isCampaignLoadingError: boolean;
}

const CampaignTable: FunctionComponent<CampaignTableProps> = ({
  rowData,
  onSelectedCampaignsChanged,
  isLoading,
  reduceHeightBy,
  deselectAll = false,
  campaignLoadingErrorMessage,
  isCampaignLoadingError,
}) => {
  const { setColumnAPI, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled, isAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.CAMPAIGN_TABLE_COLUMN_STATE,
  );

  const campaignTableHeight = `calc(100vh - 90px - ${reduceHeightBy}px)`;

  const { t } = useTranslation();
  const { activeTeam, activeProfile, CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE } = useActiveTeamContext();

  const { formatWithThousandsSeparator, formatCurrency, getLongFormatterForMetricField } = useFormatting();

  const getCellRendererParamsThousandsSeparatorSynced = useCallback(
    (field: keyof CampaignModel) => (params: ICellRendererParams<CampaignModel>) => ({
      currentValueFormatter: formatWithThousandsSeparator,
      sentimentDirection: SentimentDirection.SYNCED,
      value: params.data ? params.data[field] : null,
    }),
    [formatWithThousandsSeparator],
  );

  const graphCellTooltipRenderer = useCallback((params: TooltipRendererParams) => {
    // return { color: 'white', backgroundColor: 'rgb(78,78,255)', opacity: 0.7, title: `${params.xValue}: $` };
    return `<div style='color: white; background-color: rgb(13, 148, 136); opacity: 0.9; font-size: 12px; border-radius: 6px; padding: 10px;' class='my-custom-tooltip my-custom-tooltip-arrow'>
              <div class='tooltip-title'>${params.xValue}</div>
              <div class='tooltip-content'>
                <div>Sales: $${params.yValue}</div>
                
              </div>
          </div>`;
  }, []);

  // HEADER AGGREGATES
  const { filters } = useTemplateContext();

  const {
    data: metricValues,
    isLoading: isMetricsLoading,
    isError: isMetricError,
    error: metricsError,
  } = useQuery({
    queryKey: [METRICS_QUERY_KEY, activeProfile?.id, filters],
    queryFn: async () => {
      const result = await metricsService.getMetrics(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading metric values');
      }
    },
    enabled: !isEmpty(filters),
  });

  type MetricAggregates = { [key: string]: { current: number } };
  const metricColumnAggregates: MetricAggregates = isNil(metricValues)
    ? ({} as MetricAggregates)
    : metricValues.reduce((aggregates, metric) => {
        aggregates[metric.key] = { current: metric.current };
        return aggregates;
      }, {} as MetricAggregates);

  const getChangePercentageHeaderRendererParams = useCallback(
    (params: IHeaderParams<CampaignModel>): IChangePercentageHeaderRendererProps => {
      const colId = params.column.getColId();
      const formattedAggValue = getLongFormatterForMetricField(colId as MetricField)(metricColumnAggregates[colId]?.current);
      return {
        ...params,
        currentValue: formattedAggValue ? formattedAggValue : '0',
      };
    },
    [getLongFormatterForMetricField, metricColumnAggregates],
  );

  // CAMPAIGN GROUPS
  const { data: campaignGroups } = useQuery({
    queryKey: CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE,
    queryFn: async () => {
      const result = await campaignService.getGroups();

      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading groups');
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  const campaignGroupIdToCampaignGroupMap = useMemo(() => {
    const map: Record<string, CampaignGroupModel> = {};
    (campaignGroups || []).forEach((group) => {
      map[group.id] = group;
    });
    return map;
  }, [campaignGroups]);

  const columnDefs: ColDefOrGroup<CampaignModel>[] = useMemo(() => {
    const colDefs: ColDefOrGroup<CampaignModel>[] = [
      {
        colId: ColumnId.CHECKBOX,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        minWidth: 50,
        maxWidth: 50,
        suppressColumnsToolPanel: true,
        pinned: 'left',
      },
      {
        colId: ColumnId.CAMPAIGN_NAME,
        headerName: 'Campaign',
        field: 'name',
        minWidth: 225,
        pinned: 'left',
      },
      {
        colId: ColumnId.GROUP_NAME,
        headerName: 'Opt. Group',
        field: 'groupName',
        minWidth: 125,
        valueGetter: (params) => {
          const groupId = params.data?.groupId;
          const group = groupId ? campaignGroupIdToCampaignGroupMap[groupId] : null;
          if (group) {
            return group.name;
          }
          return params.data?.groupName; // Fallback to initial group name if no local update
        },
        cellRenderer: CampaignGroupCellRenderer,
        cellRendererParams: (params: ICellRendererParams<CampaignModel>) => {
          const groupId = params.data?.groupId;
          const group = groupId ? campaignGroupIdToCampaignGroupMap[groupId] : null;
          return {
            group: group,
            value: group ? group.name : params.data?.groupName,
          };
        },
      },
      {
        colId: ColumnId.STATE,
        headerName: 'State',
        field: 'state',
        minWidth: 90,
        maxWidth: 90,
        cellRenderer: CampaignStateCellRenderer,
      },
      {
        colId: ColumnId.SALES_PREVIOUS_DAYS,
        headerName: 'Last 30d Sales',
        field: 'sales.previousDays',
        minWidth: 150,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            tooltip: { renderer: graphCellTooltipRenderer },
            type: 'area',
            xKey: 'date',
            yKey: 'value',
            fill: 'rgba(26, 149, 106, 0.1)',
            line: {
              stroke: 'rgba(26, 149, 106, 1)',
            },
            axis: {
              stroke: 'rgb(204, 251, 241, 0.2)',
              type: 'category',
            },
            crosshairs: {
              xLine: {
                enabled: false, // disabled by default, set to true to enable the horizontal crosshair line
              },
            },
            highlightStyle: {
              stroke: 'rgba(26, 149, 106, 1)',
              strokeWidth: 2,
            },
          } as AreaSparklineOptions,
        },
        valueFormatter: (params: ValueFormatterParams) => `$${params.value}`,
      },
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Impressions',
        field: 'impressions',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 135,
        cellRenderer: ChangePercentageCellRenderer,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        cellRendererParams: getCellRendererParamsThousandsSeparatorSynced('impressions'),
        valueGetter: (params) => (params.data ? params.data.impressions.current : null),
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Clicks',
        field: 'clicks',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 105,
        cellRenderer: ChangePercentageCellRenderer,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        cellRendererParams: getCellRendererParamsThousandsSeparatorSynced('clicks'),
        valueGetter: (params) => (params.data ? params.data.clicks.current : null),
      },
      {
        colId: ColumnId.ORDERS,
        headerName: 'Orders',
        field: 'orders',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 105,
        cellRenderer: ChangePercentageCellRenderer,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        cellRendererParams: getCellRendererParamsThousandsSeparatorSynced('orders'),
        valueGetter: (params) => (params.data ? params.data.orders.current : null),
      },
      {
        colId: ColumnId.CTR,
        headerName: 'CTR',
        field: 'ctr',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 100,
        cellRenderer: ChangePercentageCellRenderer,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        cellRendererParams: (params: ICellRendererParams<CampaignModel>) => {
          const metricField = MetricField.CTR;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.ctr,
          };
        },
        valueGetter: (params) => (params.data ? params.data.ctr.current : null),
      },
      {
        colId: ColumnId.CVR,
        headerName: 'CVR',
        field: 'cvr',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 100,
        cellRenderer: ChangePercentageCellRenderer,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        cellRendererParams: (params: ICellRendererParams<CampaignModel>) => {
          const metricField = MetricField.CVR;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.cvr,
          };
        },
        valueGetter: (params) => (params.data ? params.data.cvr.current : null),
      },
      {
        colId: ColumnId.CPC,
        headerName: 'CPC',
        field: 'cpc',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 100,
        cellRenderer: ChangePercentageCellRenderer,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        cellRendererParams: (params: ICellRendererParams<CampaignModel>) => {
          const metricField = MetricField.CPC;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.cpc,
          };
        },
        valueGetter: (params) => (params.data ? params.data.cpc.current : null),
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Spend',
        field: 'spend',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 125,
        cellRenderer: ChangePercentageCellRenderer,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        cellRendererParams: (params: ICellRendererParams<CampaignModel>) => {
          const metricField = MetricField.SPEND;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.spend,
          };
        },
        valueGetter: (params) => (params.data ? params.data.spend.current : null),
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Sales',
        field: 'sales',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 125,
        cellRenderer: ChangePercentageCellRenderer,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        cellRendererParams: (params: ICellRendererParams<CampaignModel>) => {
          const metricField = MetricField.SALES;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.sales,
          };
        },
        valueGetter: (params) => (params.data ? params.data.sales.current : null),
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        type: 'numericColumn',
        autoHeight: true,
        minWidth: 105,
        cellRenderer: ChangePercentageCellRenderer,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        cellRendererParams: (params: ICellRendererParams<CampaignModel>) => {
          const metricField = MetricField.ACOS;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.acos,
          };
        },
        valueGetter: (params) => (params.data ? params.data.acos.current : null),
      },

      { colId: ColumnId.START_DATE, headerName: 'Start Date', field: 'startDate', hide: true },
      { colId: ColumnId.END_DATE, headerName: 'End Date', field: 'endDate', hide: true },
      { colId: ColumnId.TARGETING_TYPE, headerName: 'Targeting Type', field: 'targetingType', hide: true },
      { colId: ColumnId.BUDGET_TYPE, headerName: 'Budget Type', field: 'budgetType', hide: true },
      {
        colId: ColumnId.BUDGET_AMOUNT,
        headerName: 'Budget Amount',
        field: 'budgetAmount',
        hide: true,
        valueFormatter: (params) => formatCurrency(params.value),
      },
      { colId: ColumnId.BID_STRATEGY, headerName: 'Bid Strategy', field: 'bidStrategy', hide: true },
      { colId: ColumnId.SERVING_STATUS, headerName: 'Serving Status', field: 'servingStatus', hide: true },
      { colId: ColumnId.TAGS, headerName: 'Tags', field: 'tags', hide: true },
      { colId: ColumnId.CREATED_AT, headerName: 'Created At', field: 'createdAt', hide: true },
      { colId: ColumnId.UPDATED_AT, headerName: 'Updated At', field: 'updatedAt', hide: true },
      {
        colId: ColumnId.ROAS,
        headerName: 'ROAS',
        field: 'roas',
        type: 'numericColumn',
        autoHeight: true,
        hide: true,
        cellRenderer: ChangePercentageCellRenderer,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        cellRendererParams: getCellRendererParamsThousandsSeparatorSynced('roas'),
        valueGetter: (params) => (params.data ? params.data.roas.current : null),
      },
      {
        colId: ColumnId.RPC,
        headerName: 'RPC',
        field: 'rpc',
        type: 'numericColumn',
        autoHeight: true,
        hide: true,
        cellRenderer: ChangePercentageCellRenderer,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        cellRendererParams: (params: ICellRendererParams<CampaignModel>) => {
          const metricField = MetricField.RPC;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.rpc,
          };
        },
        valueGetter: (params) => (params.data ? params.data.rpc.current : null),
      },
      {
        colId: ColumnId.CPA,
        headerName: 'CPA',
        field: 'cpa',
        type: 'numericColumn',
        autoHeight: true,
        hide: true,
        cellRenderer: ChangePercentageCellRenderer,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        cellRendererParams: (params: ICellRendererParams<CampaignModel>) => {
          const metricField = MetricField.CPA;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.cpa,
          };
        },
        valueGetter: (params) => (params.data ? params.data.cpa.current : null),
      },
      {
        colId: ColumnId.AOV,
        headerName: 'AOV',
        field: 'aov',
        type: 'numericColumn',
        autoHeight: true,
        hide: true,
        cellRenderer: ChangePercentageCellRenderer,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        cellRendererParams: (params: ICellRendererParams<CampaignModel>) => {
          const metricField = MetricField.AOV;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.aov,
          };
        },
        valueGetter: (params) => (params.data ? params.data.aov.current : null),
      },
      {
        colId: ColumnId.CPM,
        headerName: 'CPM',
        field: 'cpm',
        type: 'numericColumn',
        autoHeight: true,
        hide: true,
        cellRenderer: ChangePercentageCellRenderer,
        headerComponent: ChangePercentageHeaderRenderer,
        headerComponentParams: getChangePercentageHeaderRendererParams,
        cellRendererParams: (params: ICellRendererParams<CampaignModel>) => {
          const metricField = MetricField.CPM;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.cpm,
          };
        },
        valueGetter: (params) => (params.data ? params.data.cpm.current : null),
      },
    ];

    applyStateToDefinitions(colDefs);

    return colDefs;
  }, [getChangePercentageHeaderRendererParams, getCellRendererParamsThousandsSeparatorSynced, campaignGroupIdToCampaignGroupMap]);

  const handleRowSelection = (event: SelectionChangedEvent<CampaignModel>) => {
    const ids = event.api.getSelectedRows().map((row) => row.id);

    onSelectedCampaignsChanged(ids);
  };

  const customGridOptions: GridOptions<CampaignModel> = useMemo(
    () => ({
      ...DEFAULT_GRID_OPTIONS,
      onSelectionChanged: handleRowSelection,
      maintainColumnOrder: true,
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
    }),
    [handleRowSelection, isAutoSaveEnabled],
  );

  const renderError = useCallback(() => {
    let errorMessage = '';

    if (campaignLoadingErrorMessage) {
      errorMessage += campaignLoadingErrorMessage;
    }

    if (metricsError instanceof Error && metricsError.message) {
      if (errorMessage) {
        errorMessage += '\n\n';
      }
      errorMessage += metricsError.message;
    }

    return (
      <Alert severity="error">
        <AlertTitle>Oops!</AlertTitle>
        <div className="flex w-full flex-col">
          <Typography variant="body1">{t('messages.errors.generic')}</Typography>

          <CollapsibleCopyableDetails headerText={'Details'} message={errorMessage} />
        </div>
      </Alert>
    );
  }, [campaignLoadingErrorMessage, metricsError, t]);

  function onGridReady(params: GridReadyEvent) {
    setColumnAPI(params.columnApi);
  }

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  return (
    <>
      {isCampaignLoadingError || isMetricError ? (
        <Card className="flex-grow rounded-xl py-0">{renderError()}</Card>
      ) : (
        <div style={{ height: campaignTableHeight }}>
          <AlGrid
            colDefs={columnDefs}
            rowData={rowData}
            gridOptions={customGridOptions}
            isLoading={isLoading || isMetricsLoading}
            deselectAll={deselectAll}
            onGridReadyCallback={onGridReady}
          />
        </div>
      )}
    </>
  );
};

export default CampaignTable;
