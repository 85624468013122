export enum UserSettingType {
  'STRING' = 'STRING',
  'NUMBER' = 'NUMBER',
  'BOOLEAN' = 'BOOLEAN',
  'JSON' = 'JSON',
}

// String can be either JSON or an actual String value
export type UserSettingValue = number | string | boolean | object;

export enum UserSettingKey {
  'TEST_SETTING_ENABLED' = 'TEST_SETTING_ENABLED',
  'TEST_SETTING' = 'TEST_SETTING',
  'ACTIVE_PROFILE_ID' = 'ACTIVE_PROFILE_ID',
  'ACTIVE_TEAM_ID' = 'ACTIVE_TEAM_ID',
  'CAMPAIGN_TABLE_COLUMN_STATE' = 'CAMPAIGN_TABLE_COLUMN_STATE',
  'SELECTED_METRICS' = 'SELECTED_METRICS',
  'VISIBLE_METRICS' = 'VISIBLE_METRICS',
  'SELECTED_METRIC_COLORS' = 'SELECTED_METRIC_COLORS',
  'OPTIMIZATION_TABLE_COLUMN_STATE' = 'OPTIMIZATION_TABLE_COLUMN_STATE',
}

export interface UserSettingReadDTO {
  id: number;
  key: UserSettingKey;
  type: UserSettingType;
  value: UserSettingValue;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface UserSettingUpdateDTO {
  key: UserSettingKey;
  type: UserSettingType;
  value: UserSettingValue;
}
