import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { FunctionComponent, useEffect, useState } from 'react';
import { ProfileSelect } from '../components/ProfileSelect';
import MetricTimelineChart from '../components/timeline/MetricTimelineChart';
import Metrics from '../components/metrics/Metrics';
import { TemplateContext, useTemplate } from '../contexts/TemplateContext';
import CampaignTable from '../components/CampaignTable';
import { OptimizationPreset, OptimizationApplyData, OptimizationParams } from '../components/optimization/OptimizerConfig';
import { OptimizationsPreviewModal } from '../components/optimization/OptimizationsPreviewModal';
import { toast } from 'react-toastify';
import { optimizationService } from '../components/optimization/api/optimization-service';
import { OptimizationModel } from '../components/optimization/models/OptimizationModel';
import { useTranslation } from '@/lib';
import { useMutation, useQuery } from '@tanstack/react-query';
import { campaignService, createCampaignsWithTimelineQueryKey } from '../api/campaign/campaign-service';
import { TeamSelect } from '../components/TeamSelect';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import OptimizationParamsBar from '../components/OptimizationParamsBar';
import FilterBar from '../components/FilterBar';
import { isEmpty, isNil, isFinite } from 'lodash-es';
import { OptimizationApplyResponse } from '../components/optimization/api/optimization-contracts';
import { PostOptimizationApplyModal } from '../components/optimization/models/PostOptimizationApplyModal';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import CampaignTesting from '../components/CampaignTesting';
import { useUserContext } from '@/modules/users';
import LoadingOverlay from '@/components/feedback/LoadingOverlay';
import OptimizationAlerts from '../components/OptimizationAlerts';

interface OptimizerPageProps {}

const OptimizerPage: FunctionComponent<OptimizerPageProps> = () => {
  const { activeTeam, activeProfile, activeProfileIdChange } = useActiveTeamContext();

  const { t } = useTranslation();
  const { isAdminModeActive } = useUserContext();

  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  // TEMPLATES
  const {
    filters,
    setFilters,
    setFilterValue,
    visibleMetrics,
    setVisibleMetrics,
    selectedMetrics,
    setSelectedMetrics,
    selectedMetricColors,
    setSelectedMetricColors,
    setDefaultDateRange,
  } = useTemplate();
  // const templatesButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  // const [isTemplatePopoverOpen, setIsTemplatePopoverOpen] = useState(false); // state to control the popover

  // const onTemplatesButtonClicked = () => {
  //   setIsTemplatePopoverOpen((previousValue) => !previousValue);
  // };

  // CAMPAIGNS
  const campaignsWithTimelineQueryKey = createCampaignsWithTimelineQueryKey(activeProfile?.id, filters);
  const [selectedCampaigns, setSelectedCampaigns] = useState<number[]>([]);
  const {
    data: campaignsWithTimeline,
    isLoading: isCampaignRowDataLoading,
    isError: isCampaignLoadingError,
    error: campaignLoadingError,
  } = useQuery({
    queryKey: campaignsWithTimelineQueryKey,
    queryFn: async () => {
      const result = await campaignService.getCampaignsWithTimeline(filters);
      if (result.isSuccess) {
        setSelectedCampaigns([]);
        return result.payload;
      } else {
        throw new Error('Error loading campaign ' + JSON.stringify(result));
      }
    },
    enabled: !isEmpty(filters) && isFinite(activeProfile?.id),
  });

  // OVERLAY BAR
  const [optimizationParams, setOptimizationParams] = useState<OptimizationParams>({
    tacos: 30,
    selectedPreset: OptimizationPreset.BALANCED, // TODO: changing presets should change advanced params
    highAcos: true,
    lowAcos: true,
    highSpend: true,
    lowVisibility: true,
    showZeroImpressions: false,
    smartBidCeilingActive: true,
    bidCeiling: 0,
  });

  const [isLoadingOptimizedResults, setIsLoadingOptimizedResults] = useState(false);
  const onPreviewOptimizationsClicked = () => {
    const fetchOptimizedResults = async () => {
      try {
        if (isNil(activeTeam)) {
          throw new Error('Error active team is not set');
        }

        if (selectedCampaigns.length > activeTeam.subscriptionPlan.campaignSelectionLimit) {
          setIsPaywallModalOpen(true);
          return;
        }

        setIsLoadingOptimizedResults(true);
        const newOptimizedResults = await optimizationService.getOptimizedResults(selectedCampaigns, filters, optimizationParams);

        if (newOptimizedResults.isSuccess) {
          setOptimizedResults(newOptimizedResults.payload);
          setIsOptimizationsPreviewModalOpen(true);
          setOptimizationPreviewSelection([]);
        } else {
          throw new Error('Error loading getting optimized results'); // TODO: make sure this doesn't log you out
        }
      } catch (error) {
        toast.error('Error loading getting optimized results');
        console.error(error);
      }
      setIsLoadingOptimizedResults(false);
    };
    fetchOptimizedResults();
  };

  useEffect(() => {
    clearSelections();
  }, [activeProfileIdChange]);

  // OPTIMIZING PREVIEW MODAL
  const [optimizationResults, setOptimizedResults] = useState<OptimizationModel | null>(null);
  const [isPreviewOptimizationsModalOpen, setIsOptimizationsPreviewModalOpen] = useState(false);
  const handleClosePreviewOptimizationsModal = () => {
    setIsOptimizationsPreviewModalOpen(false);
  };

  const onDiscardClicked = () => {
    clearSelections();
  };

  function clearSelections() {
    setSelectedCampaigns([]);
    setOptimizationPreviewSelection([]);
  }

  const [optimizationPreviewSelection, setOptimizationPreviewSelection] = useState<OptimizationApplyData[]>([]);

  const [optimizationApplyResponse, setOptimizationApplyResponse] = useState<{
    error: string | null;
    payload: OptimizationApplyResponse[] | undefined;
  }>();
  const [postApplyModalOpen, setPostApplyModalOpen] = useState(false);

  const { mutate: onApplyOptimization, isPending: isLoadingApplyOptimization } = useMutation({
    mutationFn: () => optimizationService.applyOptimization(optimizationResults?.jobId ?? 0, optimizationPreviewSelection),
    onMutate: () => {
      console.log('Optimizing these with new values:', optimizationPreviewSelection);
    },
    onSuccess: (data) => {
      if (isNil(data?.payload) || data.httpResponseCode !== 200) {
        setOptimizationApplyResponse({ error: `Did not receive a response from server: ${data.message}`, payload: undefined });
      } else {
        setOptimizationApplyResponse({ error: null, payload: data?.payload });
      }
    },
    onError: (error) => {
      console.error('error:', error);
      setOptimizationApplyResponse({ error: error instanceof Error ? error.message : '', payload: undefined });
    },
    onSettled: () => {
      setPostApplyModalOpen(true);
      setSelectedCampaigns([]);
      setOptimizationPreviewSelection([]);
    },
  });

  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  return (
    <PageLayout>
      <PageLayoutTopBar
        header={t('optimizer_page.header')}
        actions={
          <div className="flex flex-row gap-2">
            <TeamSelect />
            <ProfileSelect />
          </div>
        }
      ></PageLayoutTopBar>

      <OptimizationAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

      {!isShowingAlerts && (
        <TemplateContext.Provider
          value={{
            filters,
            setFilters,
            setFilterValue,
            visibleMetrics,
            setVisibleMetrics,
            selectedMetrics,
            setSelectedMetrics,
            selectedMetricColors,
            setSelectedMetricColors,
            setDefaultDateRange,
          }}
        >
          <FilterBar selectedCampaigns={selectedCampaigns} />

          <PageLayoutBody>
            <div className="mt-4">
              <Metrics />
            </div>

            <div className="my-4 ">
              <MetricTimelineChart />
            </div>

            {/* Bottom padding and height reduce due to overlay bar */}
            <div className={selectedCampaigns.length > 0 ? 'pb-24' : ''}>
              {isAdminModeActive ? (
                <CampaignTesting rowData={campaignsWithTimeline?.campaigns} filters={filters} optimizationParams={optimizationParams} />
              ) : null}
              <CampaignTable
                onSelectedCampaignsChanged={setSelectedCampaigns}
                reduceHeightBy={selectedCampaigns.length > 0 ? 96 : 0}
                rowData={campaignsWithTimeline?.campaigns ?? []}
                isLoading={isCampaignRowDataLoading}
                deselectAll={selectedCampaigns.length == 0}
                campaignLoadingErrorMessage={campaignLoadingError instanceof Error ? campaignLoadingError.message : ''}
                isCampaignLoadingError={isCampaignLoadingError}
              />
            </div>
          </PageLayoutBody>
          {optimizationResults ? (
            <OptimizationsPreviewModal
              isOpen={isPreviewOptimizationsModalOpen}
              onClose={handleClosePreviewOptimizationsModal}
              optimizationResults={optimizationResults}
              onApplyOptimization={onApplyOptimization}
              optimizationPreviewSelection={optimizationPreviewSelection}
              setOptimizationPreviewSelection={setOptimizationPreviewSelection}
              tacos={optimizationParams.tacos} // TODO: just send params so tacos and preset aren't separate?
              preset={optimizationParams.selectedPreset}
            />
          ) : null}
        </TemplateContext.Provider>
      )}

      <OptimizationParamsBar
        optimizationParams={optimizationParams}
        onOptimizationParamsChanged={setOptimizationParams}
        selectedCampaignsCount={selectedCampaigns.length}
        totalCampaignsCount={campaignsWithTimeline ? campaignsWithTimeline.campaigns.length : 0}
        onPreviewOptimizationsClicked={onPreviewOptimizationsClicked}
        onDiscardClicked={onDiscardClicked}
      />

      <PaywallModal isOpen={isPaywallModalOpen} onClose={onClosePaywallModal}>
        {`Free tier ${activeTeam?.subscriptionPlan.campaignSelectionLimit} campaign limit reached. Upgrade to access unlimited batch sizes.`}
      </PaywallModal>

      <PostOptimizationApplyModal
        isOpen={postApplyModalOpen}
        onClose={() => setPostApplyModalOpen(false)}
        optimizationApplyResponse={optimizationApplyResponse}
      />
      <LoadingOverlay isVisible={isLoadingOptimizedResults} message="Crunching numbers..." />
      <LoadingOverlay isVisible={isLoadingApplyOptimization} message="Sending new data to Amazon..." />
    </PageLayout>
  );
};

export default OptimizerPage;
