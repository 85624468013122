import { ButtonBase, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { FunctionComponent, useRef, useState } from 'react';
import { DATE_FORMAT } from './filter-builder/FiltersConfig';
import { DateRangePickerPopover } from './date-range-picker/DateRangePickerPopover';
import { useTemplateContext } from '../contexts/TemplateContext';
import { FilterKey } from './filter-builder/types/FilterKey';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import Tooltip from '@mui/material/Tooltip';

interface OptimizationRangeProps {
  isDisabled?: boolean;
}

const OptimizationRangeButton: FunctionComponent<OptimizationRangeProps> = ({ isDisabled = false }) => {
  const { filters } = useTemplateContext();
  const theme = useTheme();

  const dateRangeButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isDateRangePopoverOpen, setIsDateRangePopoverOpen] = useState(false); // state to control the popover

  const optimizationDateRange = filters.find((filterItem) => filterItem.key === FilterKey.DATE)?.conditions;
  const comparisonDateRange = filters.find((filterItem) => filterItem.key === FilterKey.COMPARE_DATE)?.conditions;

  const onDateRangeButtonClicked = () => {
    setIsDateRangePopoverOpen((previousValue) => !previousValue);
  };
  return (
    <div className="flex">
      <Tooltip title="Date range used to calculate bid optimizations">
        <ButtonBase
          ref={dateRangeButtonRef}
          onClick={onDateRangeButtonClicked}
          className="flex min-w-44 rounded-lg bg-opacity-0 py-1 pl-4 pr-2 transition duration-100 ease-in-out"
          sx={{
            border: '1px solid transparent',
            '&:hover': {
              borderColor: theme.palette.primary.main,
            },
          }}
          disabled={isDisabled}
          focusRipple={!isDisabled}
        >
          <div className="flex flex-col">
            {optimizationDateRange && optimizationDateRange.length > 0 ? (
              <div className="flex font-semibold text-primary-600 dark:text-primary-500">
                {/* <div className="text-xs">From</div> */}
                <div className="">{dayjs(optimizationDateRange[0].values[0], DATE_FORMAT).format('D MMM')}</div>
                <div>
                  {dayjs(optimizationDateRange[0].values[0], DATE_FORMAT).isSame(
                    dayjs(optimizationDateRange[1].values[0], DATE_FORMAT),
                    'year',
                  )
                    ? ''
                    : ', ' + dayjs(optimizationDateRange[0].values[0], DATE_FORMAT).format('YYYY')}
                </div>
                <div className=" px-1">-</div>
                <div className="">{dayjs(optimizationDateRange[1].values[0], DATE_FORMAT).format('D MMM, YYYY')}</div>
                {!isDisabled && (
                  <div className="text-primary-600 dark:text-primary-500">
                    <ArrowDropDownRoundedIcon style={{ fontSize: '16px' }} />
                  </div>
                )}
              </div>
            ) : (
              <div className="flex ">
                <div className="text-sm">-</div>
              </div>
            )}
            {comparisonDateRange && comparisonDateRange.length > 0 ? (
              <div style={{ fontSize: '0.7rem' }} className="flex items-center opacity-70">
                <div className="">{dayjs(comparisonDateRange[0].values[0], DATE_FORMAT).format('D MMM')}</div>
                <div>
                  {dayjs(comparisonDateRange[0].values[0], DATE_FORMAT).isSame(dayjs(comparisonDateRange[1].values[0], DATE_FORMAT), 'year')
                    ? ''
                    : ', ' + dayjs(comparisonDateRange[0].values[0], DATE_FORMAT).format('YYYY')}
                </div>
                <div className=" px-1">-</div>
                <div className="">{dayjs(comparisonDateRange[1].values[0], DATE_FORMAT).format('D MMM, YYYY')}</div>
              </div>
            ) : (
              <div className="flex ">
                <div className="text-sm">-</div>
              </div>
            )}
          </div>
        </ButtonBase>
      </Tooltip>
      <DateRangePickerPopover buttonRef={dateRangeButtonRef} setIsOpen={setIsDateRangePopoverOpen} isOpen={isDateRangePopoverOpen} />
    </div>
  );
};

export default OptimizationRangeButton;
