import { Link, Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { FunctionComponent } from 'react';

export interface ILinkCallbackCellRendererParams<T> {
  buttonText: string;
  callback: (rowData: T) => void;
  tooltip?: string;
}

export interface ILinkCallbackCellRendererProps extends ICellRendererParams, ILinkCallbackCellRendererParams<unknown> {}

const LinkCallbackCellRenderer: FunctionComponent<ILinkCallbackCellRendererProps> = ({ data, callback, buttonText, tooltip }) => {
  const handleClick = () => {
    if (data) {
      callback(data);
    }
  };

  return (
    <Tooltip title={tooltip}>
      <Link onClick={handleClick} className="truncate cursor-pointer" underline="none">
        {buttonText}
      </Link>
    </Tooltip>
  );
};

export default LinkCallbackCellRenderer;
