import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { OperatorType } from '../models/FilterModel';
import { FilterKey } from '../types/FilterKey';
import { FunctionComponent } from 'react';

interface LogicalOperatorSelectProps {
  label: string;
  filterKey: FilterKey;
  defaultValue?: OperatorType;
  handleChange: (event: SelectChangeEvent) => void;
  isDisabled?: boolean;
}

export const LogicalOperatorSelect: FunctionComponent<LogicalOperatorSelectProps> = ({
  label,
  filterKey,
  defaultValue,
  handleChange,
  isDisabled = false,
}) => {
  const OPERATOR_OPTIONS = [
    { value: OperatorType.EQUAL, label: 'IS' },
    { value: OperatorType.NOT_EQUAL, label: 'IS NOT' },
  ];

  return (
    <FormControl className={'w-28'}>
      <InputLabel id={'logical-operator-label-' + filterKey}>{label}</InputLabel>
      <Select
        labelId={'logical-operator-label-' + filterKey}
        label={label}
        id={'logical-operator-' + filterKey}
        disabled={isDisabled}
        defaultValue={OPERATOR_OPTIONS.find((option) => option.value === defaultValue)?.value}
        onChange={handleChange}
      >
        {OPERATOR_OPTIONS.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
