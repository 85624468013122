import { PageLayoutTopBar, PageLayoutBody } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { FunctionComponent } from 'react';
import { Button } from '@mui/material';
import PricingCard from '../configuration/PricingCard';
import { PlanType } from '@/modules/plans/types/SubscriptionPlan';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { planService } from '@/modules/plans/api/plan.service';
import { FREE_PLAN, PRO_PLAN } from '@/modules/plans/configuration/plans';
import { toast } from 'react-toastify';
import { TeamSelect } from '@/modules/optimizer/components/TeamSelect';

interface BillingSettingsPageProps {}

export const BillingSettingsPage: FunctionComponent<BillingSettingsPageProps> = () => {
  const { activeTeam } = useActiveTeamContext();

  async function onDowngradeClicked() {
    const downgradeResponse = await planService.getCancellationLinkForPlan();

    if (downgradeResponse.isSuccess) {
      // Navigate to checkout page
      window.location.href = downgradeResponse.payload.session_url;
    }
  }

  async function onUpgradeClicked(planToUpgradeTo: PlanType) {
    const upgradeResponse = await planService.getCheckoutLinkForPlan(planToUpgradeTo);

    if (upgradeResponse.isSuccess) {
      // Navigate to checkout page
      window.location.href = upgradeResponse.payload.session_url;
    }
  }

  async function onViewInvoiceHistoryClicked() {
    redirectToBillingPortal();
  }

  async function onManagePaymentMethodClicked() {
    redirectToBillingPortal();
  }

  async function onManageBillingAddressClicked() {
    redirectToBillingPortal();
  }

  async function redirectToBillingPortal() {
    const billingPortalLinkResponse = await planService.getBillingLinkForTeam();

    if (billingPortalLinkResponse.isSuccess) {
      // Navigate to billing portal
      window.location.href = billingPortalLinkResponse.payload.session_url;
    }
  }

  async function onResubscribeClicked() {
    const resubscribeResponse = await planService.cancelCancellation();

    if (resubscribeResponse.isSuccess) {
      toast.success('Your subscription has been resubscribed');
    }
  }

  return (
    <PageLayout>
      <PageLayoutTopBar
        header="Manage your Subscription"
        actions={
          <div className="flex flex-row gap-2">
            <TeamSelect />
          </div>
        }
      ></PageLayoutTopBar>

      <PageLayoutBody>
        <section className="max-w-screen-lg mt-6">
          <div className="grid grid-cols-2 sm:gap-6 xl:gap-10 justify-start">
            <PricingCard
              onUpgrade={onUpgradeClicked}
              onDowngrade={onDowngradeClicked}
              onResubscribe={onResubscribeClicked}
              plan={PRO_PLAN}
              isCurrentPlan={activeTeam?.adlabsPlan === PlanType.PRO_PLAN}
              renewDate={activeTeam?.stripeSubscriptionCurrentPeriodEnd}
              isCancelled={activeTeam?.stripeSubscriptionCancelAtPeriodEnd}
            />
            <PricingCard
              onUpgrade={onUpgradeClicked}
              onDowngrade={onDowngradeClicked}
              plan={FREE_PLAN}
              isCurrentPlan={activeTeam?.adlabsPlan === PlanType.FREE_PLAN}
            />
          </div>
        </section>
        {activeTeam?.isCustomer && (
          <div>
            <div className="items-start mt-2 flex flex-col">
              <Button variant="text" onClick={onViewInvoiceHistoryClicked}>
                View Invoice History
              </Button>
              <Button variant="text" onClick={onManagePaymentMethodClicked}>
                Manage Payment Method
              </Button>
              <Button variant="text" onClick={onManageBillingAddressClicked}>
                Manage Billing Address
              </Button>
            </div>
          </div>
        )}
      </PageLayoutBody>
    </PageLayout>
  );
};
