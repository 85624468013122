import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import SvgIcon from '@mui/material/SvgIcon';
import { forwardRef } from 'react';

type FontAwesomeSvgIconProps = {
  icon: IconDefinition;
};

export const FontAwesomeSvgIcon = forwardRef<SVGSVGElement, FontAwesomeSvgIconProps>((props, ref) => {
  const { icon } = props;

  const {
    icon: [width, height, , , svgPathData],
  } = icon;

  return (
    <SvgIcon ref={ref} viewBox={`0 0 ${width} ${height}`}>
      {typeof svgPathData === 'string' ? (
        <path d={svgPathData} />
      ) : (
        svgPathData.map((d: string, i: number) => <path key={i} style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />)
      )}
    </SvgIcon>
  );
});

FontAwesomeSvgIcon.displayName = 'FontAwesomeSvgIcon';
