import { MainMenu } from '../components/MainMenu';
import { Outlet } from 'react-router';
import { MAIN_MENU_ITEMS } from '../configuration/main-menu.configuration';
import { FunctionComponent } from 'react';

export const ApplicationLayout: FunctionComponent = () => {
  return (
    <div className="flex min-h-screen w-full bg-gray-100   dark:bg-gray-900">
      <MainMenu mainMenuItems={MAIN_MENU_ITEMS} />

      <div className="flex-grow">
        <Outlet />
      </div>
    </div>
  );
};
