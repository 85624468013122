import { useAuthUserContext } from '@/modules/auth/context/AuthUserContext';
import { SettingsContext } from '@/modules/settings/contexts/SettingsContext';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const useLogout = () => {
  const { clear: clearUser } = useUserContext();
  const { logOut: firebaseLogOut } = useAuthUserContext();
  const { clearActiveProfileAndTeam: clearActiveTeamAndProfile } = useActiveTeamContext();
  const { clear: clearSettings } = useContext(SettingsContext);
  const navigate = useNavigate();

  async function logOut() {
    try {
      await firebaseLogOut();
      clearSettings();
      clearActiveTeamAndProfile();
      clearUser();
      navigate('/login?logOut=true');
    } catch (error) {
      console.log(error);
      toast.error('Error logging out');
    }
  }

  return { logOut };
};

export { useLogout };
