import { MetricsResponse as MetricsDTO } from '../api/metrics-contracts';
import { MetricField } from './CommonMetricsModel';

export class MetricsModel {
  public key: MetricField;
  public current: number;
  public previous: number;

  constructor(args: MetricsCreateArguments) {
    this.key = args.key;
    this.current = args.current;
    this.previous = args.previous;
  }

  public static fromDTO(response: MetricsDTO): MetricsModel[] {
    const metrics: MetricsModel[] = [];
    for (const [key, value] of Object.entries(response)) {
      metrics.push(
        new MetricsModel({
          key: key as MetricField,
          current: value.current,
          previous: value.previous,
        }),
      );
    }
    return metrics;
  }
}

interface MetricsCreateArguments {
  key: MetricField;
  current: number;
  previous: number;
}
