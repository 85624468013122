import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import { useEffect, useMemo, useState } from 'react';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import { GetRowIdFunc, GetRowIdParams, GridOptions, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { isNil } from 'lodash-es';
import ButtonCallbackCellRenderer, { IButtonCallbackCellRendererParams } from '@/components/grid/cells/ButtonCallbackCellRenderer';
import useFormatting from '@/hooks/useFormatting';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { toast } from 'react-toastify';
import { optimizationService } from '@/modules/optimizer/components/optimization/api/optimization-service';
import { ChipCellRenderer, IChipCellRendererParams } from '@/components/grid/cells/ChipCellRenderer';
import { MuiColorVariant } from '@/config/theme/color.type';
import { OptimizationPreset } from '@/modules/optimizer/components/optimization/OptimizerConfig';
import { useTranslation } from '@/lib';
import LoadingOverlay from '@/components/feedback/LoadingOverlay';

interface ProfileHistoryTableRow {
  jobId: number;
  revertedJobId?: number;
  optimizerVersion: string;
  appliedEntityCount: number;
  successEntityCount: number;
  failedEntityCount?: number;
  startDate: string;
  endDate: string;
  tacos?: number;
  sentiment: string;
  bidCeiling: number;
  createdAt: string;
}

const ProfileHistoryTable = () => {
  const { activeTeam, activeProfile } = useActiveTeamContext();
  const { t } = useTranslation();
  const { formatDateStringTimeNoSeconds, formatDateStringTimeNoHours, formatPercent, formatCurrency } = useFormatting();

  const {
    data: optimizerJobs,
    refetch: refetchJobs,
    isLoading,
  } = useQuery({
    queryKey: ['profileOptimizerJobs', activeProfile?.id],
    queryFn: async () => {
      const result = await optimizationService.getOptimizerJobs();
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading jobs');
      }
    },
    enabled: !isNil(activeTeam) && !isNil(activeProfile),
  });

  useEffect(() => {
    if (!isNil(activeTeam) && !isNil(activeProfile)) {
      refetchJobs();
    }
  }, []);

  const rowData: ProfileHistoryTableRow[] = optimizerJobs
    ? optimizerJobs.map((job) => ({
        jobId: job.id,
        revertedJobId: job.revertedJobId,
        optimizerVersion: job.optimizerVersion,
        appliedEntityCount: job.appliedEntityCount,
        successEntityCount: job.successEntityCount,
        failedEntityCount: job.failedEntityCount,
        startDate: job.startDate,
        endDate: job.endDate,
        tacos: job.tacos,
        sentiment: job.sentiment,
        bidCeiling: job.bidCeiling,
        createdAt: job.createdAt,
      }))
    : [];

  const [revertConfirmationDialogOpen, setRevertConfirmationDialogOpen] = useState(false);
  const [revertOptimizerJobData, setRevertOptimizerJobData] = useState<ProfileHistoryTableRow>();
  async function onRevertOptimizerJobClicked(profileHistoryRowData: ProfileHistoryTableRow) {
    setRevertOptimizerJobData(profileHistoryRowData);
    setRevertConfirmationDialogOpen(true);
  }

  const handleCancelRevert = () => {
    setRevertConfirmationDialogOpen(false);
    setRevertOptimizerJobData(undefined);
  };

  const handleConfirmRevertOptimizerJob = async () => {
    try {
      if (revertOptimizerJobData?.jobId) {
        revertOptimization(revertOptimizerJobData.jobId);
      }
    } catch (error) {
      console.log(error);

      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Unable to revert. Please try again later.');
      }
    } finally {
      setRevertConfirmationDialogOpen(false);
      setRevertOptimizerJobData(undefined);
    }
  };

  const { mutate: revertOptimization, isPending: isLoadingRevertOptimization } = useMutation({
    mutationFn: (jobId: number) => optimizationService.revertOptimizerJob(jobId),
    onMutate: () => {
      console.log('Reverting job:');
    },
    onSuccess: (data) => {
      if (isNil(data?.payload) || data.httpResponseCode !== 200) {
        toast.error(`Did not receive a response from server: ${data.message}`);
      } else {
        refetchJobs();
        toast.success(`Reverted ${data?.payload}`);
      }
    },
    onError: (error) => {
      console.error('error:', error);
      toast.error(`Revert failed: ${error}`);
    },
  });

  const columnDefs: ColDefOrGroup<ProfileHistoryTableRow>[] = [
    {
      colId: ColumnId.REVERT_JOB,
      headerName: 'Revert Job',
      cellRenderer: ButtonCallbackCellRenderer,
      minWidth: 130,
      cellRendererParams: (): IButtonCallbackCellRendererParams<ProfileHistoryTableRow> => {
        return {
          buttonText: 'Revert',
          callback: onRevertOptimizerJobClicked,
        };
      },
    },
    {
      colId: ColumnId.JOB_ID,
      headerName: 'ID',
      field: 'jobId',
    },
    {
      colId: ColumnId.REVERTED_JOB_ID,
      headerName: 'Reverted ID',
      field: 'revertedJobId',
    },
    {
      colId: ColumnId.CREATED_AT,
      headerName: 'Optimized At',
      field: 'createdAt',
      filter: 'agDateColumnFilter',
      minWidth: 200,
      valueFormatter: (params: ValueFormatterParams<ProfileHistoryTableRow>) => formatDateStringTimeNoSeconds(params.value),
    },
    {
      colId: ColumnId.VERSION,
      headerName: 'Version',
      field: 'optimizerVersion',
    },
    {
      colId: ColumnId.APPLIED_ENTITY_COUNT,
      headerName: 'Applied',
      field: 'appliedEntityCount',
    },
    {
      colId: ColumnId.SUCCESS_ENTITY_COUNT,
      headerName: 'Success Entity Count',
      field: 'successEntityCount',
    },
    {
      colId: ColumnId.FAILED_ENTITY_COUNT,
      headerName: 'Failed',
      field: 'failedEntityCount',
    },
    {
      colId: ColumnId.START_DATE,
      headerName: 'Opt. Range Start',
      field: 'startDate',
      filter: 'agDateColumnFilter',
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams<ProfileHistoryTableRow>) => formatDateStringTimeNoHours(params.value),
    },
    {
      colId: ColumnId.END_DATE,
      headerName: 'Opt. Range End',
      field: 'endDate',
      filter: 'agDateColumnFilter',
      minWidth: 150,
      valueFormatter: (params: ValueFormatterParams<ProfileHistoryTableRow>) => formatDateStringTimeNoHours(params.value),
    },
    {
      colId: ColumnId.TACOS,
      headerName: 'TACOS',
      field: 'tacos',
      valueFormatter: (params: ValueFormatterParams<ProfileHistoryTableRow>) => formatPercent(params.value),
    },
    {
      colId: ColumnId.SENTIMENT,
      headerName: 'Prioritization',
      field: 'sentiment',
      cellRenderer: ChipCellRenderer,
      cellRendererParams: (params: ICellRendererParams<ProfileHistoryTableRow>): IChipCellRendererParams => {
        let color: MuiColorVariant;

        switch (params.data?.sentiment) {
          case OptimizationPreset.BALANCED:
            color = MuiColorVariant.Success;
            break;
          case OptimizationPreset.INCREASE_SALES:
            color = MuiColorVariant.Primary;
            break;
          case OptimizationPreset.REDUCE_ACOS:
            color = MuiColorVariant.Warning;
            break;
          default:
            color = MuiColorVariant.Success;
        }

        const value = params.data?.sentiment ? t(`optimizer_page.optimization_presets.${params.data?.sentiment}`) : '';

        return {
          chipColor: color,
          chipLabel: value,
        };
      },
    },
    {
      colId: ColumnId.BID_CEILING,
      headerName: 'Bid Ceiling',
      field: 'bidCeiling',
      valueFormatter: (params: ValueFormatterParams<ProfileHistoryTableRow>) => formatCurrency(params.value),
    },
  ];

  const customGridOptions: GridOptions<ProfileHistoryTableRow> = {
    ...DEFAULT_GRID_OPTIONS,
    defaultColDef: {
      resizable: true,
      sortable: true,
      minWidth: 100,
    },
  };

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => params.data.jobId as string;
  }, []);

  const tableHeight = `calc(100vh - 85px)`;

  return (
    <>
      <LoadingOverlay isVisible={isLoadingRevertOptimization} message="Sending data to Amazon..." />

      <div style={{ height: tableHeight }}>
        <AlGrid colDefs={columnDefs} rowData={rowData} gridOptions={customGridOptions} getRowId={getRowId} isLoading={isLoading} />
      </div>

      <Dialog sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }} maxWidth="xs" open={revertConfirmationDialogOpen}>
        <DialogTitle> {`Revert changes?`}</DialogTitle>
        <DialogContent dividers>{`Are you sure you want to revert ${revertOptimizerJobData?.jobId}?`}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelRevert}>
            Cancel
          </Button>
          <Button onClick={handleConfirmRevertOptimizerJob}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileHistoryTable;
