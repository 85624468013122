import { InfinitySpinner, ColorModeContext } from '@/modules/application';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { AuthUserContext, useFirebaseAuth } from '@/modules/auth';
import { useEffect, useState } from 'react';
import { Environment } from '@/config/Environment';
import { useLocation, useNavigate, Outlet, useSearchParams } from 'react-router-dom';
import { useTheming } from './config/theme/useTheming';

// Toasts
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SettingsProvider } from './modules/settings/contexts/SettingsContext';
import { SettingModel } from './modules/settings/types/SettingModel';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { UserModel, UserProvider, UserSettingKey, UserSettingModel, UserSettingsProvider, userSettingService } from './modules/users';
import { userService } from './modules/users/api/users/users.service';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ActiveTeamProvider } from './modules/teams/contexts/ActiveTeamContext';
import { isNumber } from 'lodash-es';
import { ReportsProvider } from './modules/teams/contexts/ReportsContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10, //ms
    },
  },
});

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [initialSettings, setInitialSettings] = useState<SettingModel[]>([]);
  const [initialUserSettings, setInitialUserSettings] = useState<UserSettingModel[]>([]);
  const [initialUser, setInitialUser] = useState<UserModel>();

  const [initialTeamId, setInitialTeamId] = useState<number>();
  const [initialProfileId, setInitialProfileId] = useState<number>();

  const { colorMode, mode, theme } = useTheming();

  const auth = useFirebaseAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (Environment.isDev()) {
    console.log(`Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`);
  }

  useEffect(() => {
    // Don't log in automatically if there is no connection
    if (searchParams.get('noConnection')) {
      setIsLoading(false);
      return;
    }

    const handleLogin = async () => {
      setIsLoading(true);
      try {
        // Try to login with stored token
        const autoLoggedInUser = await auth.autoLogin();

        if (autoLoggedInUser) {
          try {
            setInitialSettings([]);

            // Load user with teams
            const getAdLabsUserResponse = await userService.getUserByFirebaseId(autoLoggedInUser.uid);
            if (getAdLabsUserResponse.isSuccess) {
              const user = getAdLabsUserResponse.payload;
              if (user && user.id) {
                setInitialUser(user);

                // Load User Settings
                const userSettingsResponse = await userSettingService.getAll(user.id);
                if (userSettingsResponse.isSuccess) {
                  const settings = userSettingsResponse.payload;
                  setInitialUserSettings(settings);
                  // Check if user has active profile id setting
                  const activeTeamId = settings.find((setting) => setting.key === UserSettingKey.ACTIVE_TEAM_ID)?.value;
                  const activeProfileId = settings.find((setting) => setting.key === UserSettingKey.ACTIVE_PROFILE_ID)?.value;

                  setInitialTeamId(isNumber(activeTeamId) ? activeTeamId : undefined);
                  setInitialProfileId(isNumber(activeProfileId) ? activeProfileId : undefined);
                }
              } else {
                throw new Error('Error loading user settings');
              }
            } else {
              console.log('Error loading user', getAdLabsUserResponse.message);
            }

            if (location.pathname.startsWith('/register')) {
              // MUST BE navigate(location) as navigate(location.pathname) removes GET params that are sent from amazon
              navigate(location);
            } else {
              navigate(location.pathname.startsWith('/app') ? location.pathname : '/app');
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          if (!location.pathname.startsWith('/register') || location.pathname === '/') {
            navigate('/login');
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    handleLogin();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div className={`${mode} flex h-full flex-grow overflow-hidden font-sans text-sm`}>
        <StyledEngineProvider injectFirst>
          <ColorModeContext.Provider value={{ toggleColorMode: colorMode.toggleColorMode, mode, isDarkMode: mode === 'dark' }}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {/* {isLoading || (auth.authUser && !location.pathname.startsWith('/app')) ? ( */}
              {isLoading ? (
                <div className="flex h-screen w-full items-center justify-center">
                  <InfinitySpinner></InfinitySpinner>
                </div>
              ) : (
                <SettingsProvider initialSettings={initialSettings}>
                  <AuthUserContext.Provider value={auth}>
                    <UserProvider initialUser={initialUser}>
                      <UserSettingsProvider initialSettings={initialUserSettings}>
                        <ActiveTeamProvider initialActiveTeamId={initialTeamId} initialActiveProfileId={initialProfileId}>
                          <ReportsProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Outlet />
                            </LocalizationProvider>
                            <ToastContainer theme={mode === 'dark' ? 'dark' : 'light'} position={'top-center'} />
                          </ReportsProvider>
                        </ActiveTeamProvider>
                      </UserSettingsProvider>
                    </UserProvider>
                  </AuthUserContext.Provider>
                </SettingsProvider>
              )}
            </ThemeProvider>
          </ColorModeContext.Provider>
        </StyledEngineProvider>
      </div>
    </QueryClientProvider>
  );
}

export default App;
