import { SyncStatusReason } from '@/modules/teams/contexts/ReportsContext';
import { ReducerType } from '../components/metrics/MetricsConfig';
import { OptimizationPreset } from '../components/optimization/OptimizerConfig';
import { OptimizationReason } from '../components/optimization/api/optimization-contracts';
import { BiddingEntity } from '../components/optimization/models/OptimizationModel';
import { AdditionalStatusType, StatusType } from '@/modules/profiles/api/profile.contracts';

export const optimizerTranslationsEnglish = {
  optimizer_page: {
    header: 'Sponsored Products Bid Optimizer',
    sub_header: 'Select campaigns to preview targeting bid suggestions',
    optimization_presets: {
      [OptimizationPreset.BALANCED]: 'Balanced',
      [OptimizationPreset.INCREASE_SALES]: 'Increase Sales',
      [OptimizationPreset.REDUCE_ACOS]: 'Reduce ACOS',
    },
    labels: {
      optimization_preset: 'Prioritization',
      [BiddingEntity.KEYWORD]: 'Keyword',
      [BiddingEntity.PLACEMENT_PRODUCT_PAGE]: 'Product Pages',
      [BiddingEntity.PLACEMENT_REST_OF_SEARCH]: 'Rest of Search',
      [BiddingEntity.PLACEMENT_TOP]: 'Top of Search',
      [BiddingEntity.PRODUCT_TARGET]: 'Product Target',
      [ReducerType.AVG]: 'Average',
      [ReducerType.SUM]: 'Sum',
    },
    chips: {
      [OptimizationReason.LOW_VISIBILITY]: 'Low Visibility',
      [OptimizationReason.HIGH_ACOS]: 'High ACOS',
      [OptimizationReason.HIGH_SPEND]: 'High Spend',
      [OptimizationReason.LOW_ACOS]: 'Low ACOS',
      [OptimizationReason.USER_BID_CEILING]: 'User Bid Ceiling',
      [OptimizationReason.KEYWORD_BID_CEILING]: 'Keyword Bid Ceiling',
      [OptimizationReason.AD_GROUP_BID_CEILING]: 'Ad Group Bid Ceiling',
      [OptimizationReason.CAMPAIGN_BID_CEILING]: 'Campaign Bid Ceiling',
      [OptimizationReason.CAMPAIGN_GROUP_BID_CEILING]: 'Campaign Group Bid Ceiling',
      [OptimizationReason.PROFILE_BID_CEILING]: 'Profile Bid Ceiling',
      [OptimizationReason.LOWEST_POSSIBLE_BID]: 'Lowest Possible Bid',
      [OptimizationReason.SMALLEST_POSSIBLE_INCREASE]: 'Smallest Possible Increase',
      [OptimizationReason.CAMPAIGN_PERFORMANCE]: 'Campaign Performance',
      [OptimizationReason.CAMPAIGN_GROUP_PERFORMANCE]: 'Campaign Group Performance',
      [OptimizationReason.PROFILE_PERFORMANCE]: 'Profile Performance',
      [OptimizationReason.MAX_ONE_TIME_CHANGE]: 'Max One Time Change',
      [OptimizationReason.LOWEST_POSSIBLE_ADJUSTMENT]: 'Lowest Possible Adjustment',
      [OptimizationReason.HIGHEST_POSSIBLE_ADJUSTMENT]: 'Highest Possible Adjustment',
      [OptimizationReason.MAX_BID_DECREASE]: 'Max Bid Decrease',
    },
  },

  team_profiles_page: {
    header: 'Profiles',
    sub_header: 'Manage all your profiles',
    labels: {
      [StatusType.PENDING]: 'Awaiting Amazon',
      [StatusType.ONGOING]: 'Processing',
      [StatusType.DOWNLOADED]: 'Processing',
      [StatusType.PROCESSED]: 'Processed',
      [StatusType.FAILED]: 'Failed',
      [AdditionalStatusType.LOADING]: 'Loading',
      [AdditionalStatusType.NEVER]: 'Never Updated',
      [AdditionalStatusType.OUTDATED]: 'Outdated',
      [AdditionalStatusType.ERROR]: 'Status Error',
    },
    descriptions: {
      [StatusType.PENDING]: 'Waiting for Amazon to create the report',
      [StatusType.ONGOING]: 'Processing data from the report',
      [StatusType.DOWNLOADED]: 'Processing data from the report',
      [StatusType.PROCESSED]: 'Data is ready to be used',
      [StatusType.FAILED]: 'Something went wrong. Try again or contact support',
      [AdditionalStatusType.LOADING]: 'Checking the current status of the report',
      [AdditionalStatusType.NEVER]: 'The data has been never synced with Amazon',
      [AdditionalStatusType.OUTDATED]: 'The data is outdated, we recommend updating it',
      [AdditionalStatusType.ERROR]: 'Error getting the current status of the data',
    },
  },

  profile_history_page: {
    header: 'History',
    sub_header: 'History of all changes',
  },

  campaign_groups_page: {
    header: 'Optimization Groups',
    sub_header: 'Optimization Groups',
  },

  messages: {
    errors: {
      enter_valid_tacos: 'Enter a valid TACOS value',
      generic: 'Something went wrong on our end. If the problem persists, make sure to contact us!',
    },
  },

  labels: {
    [SyncStatusReason.LOADING]: 'Checking current status, please wait...',
    [SyncStatusReason.ONGOING]: 'Syncing is already in progress',
    [SyncStatusReason.PLAN_RESTRICTION]: 'Current plan does not allow syncing more often',
    [SyncStatusReason.TOO_EARLY]: 'Syncing allowed once per hour',
  },
};
