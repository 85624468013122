import { SentimentDirection } from '@/types/colors.enum';
import { MetricField } from './models/CommonMetricsModel';

export const METRIC_COLORS: string[] = ['#2563EB', '#06B6D4', '#0d9488', '#FB923C', '#a65aed'];
export const MAX_METRICS_CAN_SELECT = METRIC_COLORS.length;

export interface MetricColor {
  key: MetricField;
  color: string;
}

export interface MetricCurrentPrevious {
  key: MetricField;
  current: number;
  previous: number;
}

export function getUpdatedSelectionColors(currentSelectedMetricColors: MetricColor[], selectedMetrics: MetricField[]) {
  // Clean the selectedMetricColors of objects with keys not in selectedMetrics anymore
  const filteredSelectedMetricColors = currentSelectedMetricColors.filter((color) => selectedMetrics.includes(color.key));
  const newSelectedMetricColors: MetricColor[] = [];

  selectedMetrics.forEach((metricKey) => {
    const existingColor = filteredSelectedMetricColors.find((color) => color.key === metricKey);

    if (existingColor) {
      newSelectedMetricColors.push(existingColor);
    } else {
      const availableColors = METRIC_COLORS.filter(
        (color) => !newSelectedMetricColors.some((selectedColor) => selectedColor.color === color),
      );

      if (availableColors.length > 0) {
        const newColor = availableColors[0]; // When no colors are left, picking the first one
        newSelectedMetricColors.push({ key: metricKey, color: newColor });
      }
    }
  });

  return newSelectedMetricColors;
}

export enum ReducerType {
  SUM,
  AVG,
}

export interface MetricConfiguration {
  key: MetricField;
  title: string;
  sentimentDirection: SentimentDirection;
  reducerType: ReducerType;
  chartSeriesType: 'bar' | 'line';
}

export function getConfigForMetric(metricKey: MetricField): MetricConfiguration {
  return METRICS[metricKey];
}

export function getMetricReducer(metricKey: MetricField): ReducerType {
  return METRICS[metricKey].reducerType;
}

export function getSentimentByMetric(metricKey: MetricField): SentimentDirection {
  return METRICS[metricKey].sentimentDirection;
}

// This type ensures that every metric is used in METRICS map
type CompleteMetricConfigMap = { [K in MetricField]: MetricConfiguration };

export const METRICS: CompleteMetricConfigMap = {
  [MetricField.IMPRESSIONS]: {
    key: MetricField.IMPRESSIONS,
    title: 'Impressions',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
  },
  [MetricField.CLICKS]: {
    key: MetricField.CLICKS,
    title: 'Clicks',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
  },
  [MetricField.ORDERS]: {
    key: MetricField.ORDERS,
    title: 'Orders',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'bar',
  },
  [MetricField.CTR]: {
    key: MetricField.CTR,
    title: 'CTR',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.AVG,
    chartSeriesType: 'line',
  },
  [MetricField.CVR]: {
    key: MetricField.CVR,
    title: 'CVR',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.AVG,
    chartSeriesType: 'line',
  },
  [MetricField.CPC]: {
    key: MetricField.CPC,
    title: 'CPC',
    sentimentDirection: SentimentDirection.NEUTRAL,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'line',
  },
  [MetricField.SPEND]: {
    key: MetricField.SPEND,
    title: 'Spend',
    sentimentDirection: SentimentDirection.NEUTRAL,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'line',
  },
  [MetricField.SALES]: {
    key: MetricField.SALES,
    title: 'Sales',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'line',
  },
  [MetricField.ACOS]: {
    key: MetricField.ACOS,
    title: 'ACOS',
    sentimentDirection: SentimentDirection.REVERSED,
    reducerType: ReducerType.AVG,
    chartSeriesType: 'line',
  },
  [MetricField.ROAS]: {
    key: MetricField.ROAS,
    title: 'ROAS',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'line',
  },
  [MetricField.RPC]: {
    key: MetricField.RPC,
    title: 'RPC',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'line',
  },
  [MetricField.CPA]: {
    key: MetricField.CPA,
    title: 'CPA',
    sentimentDirection: SentimentDirection.REVERSED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'line',
  },
  [MetricField.AOV]: {
    key: MetricField.AOV,
    title: 'AOV',
    sentimentDirection: SentimentDirection.SYNCED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'line',
  },
  [MetricField.CPM]: {
    key: MetricField.CPM,
    title: 'CPM',
    sentimentDirection: SentimentDirection.REVERSED,
    reducerType: ReducerType.SUM,
    chartSeriesType: 'line',
  },
};
