import { FunctionComponent } from 'react';
import { Chip, Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { AdLabsColorVariant } from '@/config/theme/color.type';

const MAX_CHIPS_TO_SHOW = 1;

export interface ChipArrayChip {
  value: string;
  color: AdLabsColorVariant;
}
export interface IChipArrayCellRendererParams {
  chipArrayChips: ChipArrayChip[];
}

interface IChipArrayCellInternalRendererParams extends IChipArrayCellRendererParams, ICellRendererParams {}

const ChipArrayCellRenderer: FunctionComponent<IChipArrayCellInternalRendererParams> = ({ chipArrayChips }) => {
  if (!Array.isArray(chipArrayChips)) {
    return null;
  }

  const [displayedChipArray, additionalChipArray] = [chipArrayChips.slice(0, MAX_CHIPS_TO_SHOW), chipArrayChips.slice(MAX_CHIPS_TO_SHOW)];

  const displayedChips = displayedChipArray.map((chip, index) => {
    return <Chip key={index} variant="outlined" size="small" color={chip.color} label={chip.value} className={`mr-2`} />;
  });

  const additionalChipsCount = additionalChipArray.length;

  const additionalChip =
    additionalChipsCount > 0 ? (
      <Tooltip title={additionalChipArray.map((chip) => chip.value).join(', ')}>
        <Chip label={`+${additionalChipsCount}`} />
      </Tooltip>
    ) : null;

  return (
    <div className="flex h-full items-center">
      {displayedChips}
      {additionalChip}
    </div>
  );
};

export default ChipArrayCellRenderer;
