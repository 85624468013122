import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { METRICS } from './MetricsConfig';
import { DragToReorder } from '../drag-to-reorder';
import { MetricField } from './models/CommonMetricsModel';

interface MetricsCustomizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  visibleMetrics: MetricField[];
  onApplyVisibilityChanges: (visibleMetrics: MetricField[]) => void;
}

export const MetricsCustomizationModal: FunctionComponent<MetricsCustomizationModalProps> = ({
  isOpen,
  onClose,
  visibleMetrics,
  onApplyVisibilityChanges,
}) => {
  const [tempVisibleMetrics, setTempVisibleMetrics] = useState(visibleMetrics);

  // Needed because optimizer page loads, modal is mounted, but visibleMetrics is not yet set
  useEffect(() => {
    setTempVisibleMetrics(visibleMetrics);
  }, [visibleMetrics]);

  const onApply = () => {
    onApplyVisibilityChanges(tempVisibleMetrics);
    onClose();
  };

  const onMetricsVisibilityChanged = (newVisibleMetrics: string[]): void => {
    setTempVisibleMetrics(newVisibleMetrics as MetricField[]);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={'lg'} fullWidth={true}>
      <DialogTitle>Drag and drop to choose which metrics are visible</DialogTitle>
      <DialogContent>
        <DragToReorder
          chosenInputItems={visibleMetrics}
          allInputItems={Object.values(MetricField)}
          allDisplayNames={Object.values(MetricField).map((key) => METRICS[key]?.title || '')}
          onChoiceChange={onMetricsVisibilityChanged}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button onClick={onApply} color="primary" variant="contained">
          Apply changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
