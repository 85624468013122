import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export function initSentry() {
  Sentry.init({
    dsn: 'https://1ba314a3752117058884ba88c19629d7@o4506019135291392.ingest.sentry.io/4506019203448833',
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // Trace API calls to these domains
    tracePropagationTargets: ['localhost', /^https?:\/\/([a-zA-Z0-9-]+\.)*adlabs\.app\/api/],

    // A pattern for error URLs which should not be sent to Sentry.
    denyUrls: [/https?:\/\/localhost(:\d+)?/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
