import { FunctionComponent } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Tooltip, Typography } from '@mui/material';
import useFormatting from '@/hooks/useFormatting';
import { SentimentDirection, getColorClassBySentimentCurrentPrevious } from '@/types/colors.enum';
import { isNil } from 'lodash-es';

interface IChangePercentageCellRendererParams extends ICellRendererParams {
  currentValueFormatter?: (number: number) => string;
  sentimentDirection: SentimentDirection;
}

const ChangePercentageCellRenderer: FunctionComponent<IChangePercentageCellRendererParams> = ({
  value,
  currentValueFormatter,
  sentimentDirection,
}) => {
  if (isNil(value) || isNil(value.current) || isNil(value.previous)) return null;

  const { current, previous } = value;

  const formatValue = (value: number) => (currentValueFormatter ? currentValueFormatter(value) : value);

  const formattedCurrentValue = formatValue(current);
  const formattedPreviousValue = formatValue(previous);

  const { formatChangeInUnsignedPercentWithArrow } = useFormatting();
  const formattedChange = formatChangeInUnsignedPercentWithArrow(current, previous);
  const colorClass = getColorClassBySentimentCurrentPrevious(sentimentDirection, current, previous);

  return (
    <div className="text-right">
      <Typography variant="body2">{formattedCurrentValue}</Typography>
      <Tooltip title={`Comparison period: ${formattedPreviousValue}`}>
        <Typography variant="body2" className={`${colorClass} text-xs`}>
          {formattedChange}
        </Typography>
      </Tooltip>
    </div>
  );
};

export default ChangePercentageCellRenderer;
