import { DateValuePair, MetricsDataResponse } from '../campaign-contracts';

export type CampaignWithIdAndName = Pick<CampaignModel, 'id' | 'name'>;

export class CampaignModel {
  public id: number;
  public name: string;
  public groupId: number;
  public groupName: string;
  public state: CampaignState;
  public startDate: null | string;
  public endDate: null | string;
  public targetingType: string;
  public budgetType: string;
  public budgetAmount: number;
  public bidStrategy: string;
  public servingStatus: string;
  public tags: string;
  public createdAt: string;
  public updatedAt: string;
  public impressions: MetricsData;
  public clicks: MetricsData;
  public orders: MetricsData;
  public ctr: MetricsData;
  public cvr: MetricsData;
  public cpc: MetricsData;
  public spend: MetricsData;
  public sales: MetricsData;
  public acos: MetricsData;
  public roas: MetricsData;
  public rpc: MetricsData;
  public cpa: MetricsData;
  public aov: MetricsData;
  public cpm: MetricsData;

  constructor(args: CampaignCreateArguments) {
    this.id = args.id;
    this.name = args.name;
    this.groupId = args.groupId;
    this.groupName = args.groupName;
    this.state = args.state;
    this.startDate = args.startDate;
    this.endDate = args.endDate;
    this.targetingType = args.targetingType;
    this.budgetType = args.budgetType;
    this.budgetAmount = args.budgetAmount;
    this.bidStrategy = args.bidStrategy;
    this.servingStatus = args.servingStatus;
    this.tags = args.tags;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;
    this.impressions = args.impressions;
    this.clicks = args.clicks;
    this.orders = args.orders;
    this.ctr = args.ctr;
    this.cvr = args.cvr;
    this.cpc = args.cpc;
    this.spend = args.spend;
    this.sales = args.sales;
    this.acos = args.acos;
    this.roas = args.roas;
    this.rpc = args.rpc;
    this.cpa = args.cpa;
    this.aov = args.aov;
    this.cpm = args.cpm;
  }

  public static fromResponse(response: CampaignDTO): CampaignModel {
    return new CampaignModel({
      id: response.id,
      name: response.name,
      groupId: response.group_id,
      groupName: response.group_name,
      state: response.state,
      startDate: response.start_date,
      endDate: response.end_date,
      targetingType: response.targeting_type,
      budgetType: response.budget_type,
      budgetAmount: response.budget_amount,
      bidStrategy: response.bid_strategy,
      servingStatus: response.serving_status,
      tags: response.tags,
      createdAt: response.created_at,
      updatedAt: response.updated_at,
      impressions: CampaignModel.metricFromResponseMetric(response.impressions),
      clicks: CampaignModel.metricFromResponseMetric(response.clicks),
      orders: CampaignModel.metricFromResponseMetric(response.orders),
      ctr: CampaignModel.metricFromResponseMetric(response.ctr),
      cvr: CampaignModel.metricFromResponseMetric(response.cvr),
      cpc: CampaignModel.metricFromResponseMetric(response.cpc),
      spend: CampaignModel.metricFromResponseMetric(response.spend),
      sales: CampaignModel.metricFromResponseMetric(response.sales),
      acos: CampaignModel.metricFromResponseMetric(response.acos),
      roas: CampaignModel.metricFromResponseMetric(response.roas),
      rpc: CampaignModel.metricFromResponseMetric(response.rpc),
      cpa: CampaignModel.metricFromResponseMetric(response.cpa),
      aov: CampaignModel.metricFromResponseMetric(response.aov),
      cpm: CampaignModel.metricFromResponseMetric(response.cpm),
    });
  }

  private static metricFromResponseMetric(responseMetric: MetricsDataResponse): MetricsData {
    const metric = {
      current: responseMetric.current,
      previous: responseMetric.previous,
    } as MetricsData;

    if (responseMetric.previous_days) {
      metric.previousDays = responseMetric.previous_days.map((dateValuePair: DateValuePair) => ({
        date: dateValuePair.date,
        value: dateValuePair.value,
      }));
    }

    return metric;
  }

  public static fromResponseArray(responseArray: CampaignDTO[]): CampaignModel[] {
    return responseArray.map(CampaignModel.fromResponse);
  }

  public static fromIdsResponseArray(responseArray: CampaignIdWithNameDTO[]): CampaignWithIdAndName[] {
    return responseArray.map((dto) => {
      return {
        id: dto.id,
        name: dto.name,
      };
    });
  }
}

export interface CampaignIdWithNameDTO {
  id: number;
  name: string;
}

export interface MetricsData {
  current: number;
  previous: number;
  previousDays?: DateValuePair[];
}

//TODO: a DTO with the same name is in the campaign-contracts.ts
export interface CampaignDTO {
  id: number;
  name: string;
  group_id: number;
  group_name: string;
  state: CampaignState;
  start_date: null | string;
  end_date: null | string;
  targeting_type: string;
  budget_type: string;
  budget_amount: number;
  bid_strategy: string;
  serving_status: string;
  tags: string;
  created_at: string;
  updated_at: string;
  impressions: MetricsData;
  clicks: MetricsData;
  orders: MetricsData;
  ctr: MetricsData;
  cvr: MetricsData;
  cpc: MetricsData;
  spend: MetricsData;
  sales: MetricsData;
  acos: MetricsData;
  roas: MetricsData;
  rpc: MetricsData;
  cpa: MetricsData;
  aov: MetricsData;
  cpm: MetricsData;
}

interface CampaignCreateArguments {
  id: number;
  name: string;
  groupId: number;
  groupName: string;
  state: CampaignState;
  startDate: null | string;
  endDate: null | string;
  targetingType: string;
  budgetType: string;
  budgetAmount: number;
  bidStrategy: string;
  servingStatus: string;
  tags: string;
  createdAt: string;
  updatedAt: string;
  impressions: MetricsData;
  clicks: MetricsData;
  orders: MetricsData;
  ctr: MetricsData;
  cvr: MetricsData;
  cpc: MetricsData;
  spend: MetricsData;
  sales: MetricsData;
  acos: MetricsData;
  roas: MetricsData;
  rpc: MetricsData;
  cpa: MetricsData;
  aov: MetricsData;
  cpm: MetricsData;
}

export enum CampaignState {
  ENABLED = 'ENABLED',
  PAUSED = 'PAUSED',
  ARCHIVED = 'ARCHIVED',
}
