import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DateRange, LocalizationProvider, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { comparisonCalenderDefaultShortcutsItems, mainCalenderDefaultShortcutsItems } from './DateRangePickerConfig';
import { useContext, useEffect, useState } from 'react';
import { DATE_FORMAT } from '../filter-builder/FiltersConfig';
import { ComparisonDateFilterModel, DateFilterModel, LogicalOperatorType, OperatorType } from '../filter-builder/models/FilterModel';
import { TemplateContext } from '../../contexts/TemplateContext';
import { Button, Paper } from '@mui/material';
import { floor } from 'lodash-es';
import { FilterKey } from '../filter-builder/types/FilterKey';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';

interface OptimizerDateRangePickerProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OptimizerDateRangePicker({ setIsOpen }: OptimizerDateRangePickerProps) {
  const { filters, setFilterValue } = useContext(TemplateContext);
  const { activeTeam } = useActiveTeamContext();
  // if there is an existing date range value in filters then it is used as default
  const defaultDateRange = filters.find((filterItem) => filterItem.key === FilterKey.DATE)?.conditions;

  const today = dayjs();
  const [mainCalendarDateRange, setMainCalendarDateRange] = useState<DateRange<Dayjs>>(
    defaultDateRange ? [dayjs(defaultDateRange[0].values[0]), dayjs(defaultDateRange[1].values[0])] : [today.subtract(6, 'day'), today],
  );
  const defaultComparisonDateRange = filters.find((filterItem) => filterItem.key === FilterKey.COMPARE_DATE)?.conditions;

  // if there is an existing date range value in filters then it is used as default
  const [comparisonCalendarDateRange, setComparisonCalendarDateRange] = useState<DateRange<Dayjs>>(
    defaultComparisonDateRange
      ? [dayjs(defaultComparisonDateRange[0].values[0], DATE_FORMAT), dayjs(defaultComparisonDateRange[1].values[0], DATE_FORMAT)]
      : [today.subtract(13, 'day'), today.subtract(7, 'day')],
  );

  const handleMainCalendarDateChange = (newValue: DateRange<Dayjs>) => {
    setMainCalendarDateRange(newValue);

    const startDate = newValue[0];
    const endDate = newValue[1];

    // Check if both start and end dates are defined before updating the comparison date
    if (startDate && endDate) {
      const periodLengthInDays = endDate.diff(startDate, 'day');
      const newComparisonStart = startDate.subtract(periodLengthInDays + 1, 'day');
      const newComparisonEnd = endDate.subtract(periodLengthInDays + 1, 'day');
      setComparisonCalendarDateRange([newComparisonStart, newComparisonEnd]);
    } else {
      // If either date is undefined, set the comparison value to [null, null]
      setComparisonCalendarDateRange([null, null]);
    }
  };

  const handleComparisonCalendarDateChange = (newValue: DateRange<Dayjs>) => {
    const startDate = newValue[0];
    const endDate = newValue[1];
    if (startDate && endDate) {
      setComparisonCalendarDateRange([startDate, endDate]);
    }
  };

  const [comparisonCalenderShortcutsItems, setComparisonCalenderShortcutsItems] = useState<PickersShortcutsItem<DateRange<Dayjs>>[]>(
    comparisonCalenderDefaultShortcutsItems,
  );

  useEffect(() => {
    setComparisonCalenderShortcutsItems([
      {
        label: 'Preceding Period',
        getValue: () => {
          const mainCalendarStartDate = mainCalendarDateRange[0];
          const mainCalendarEndDate = mainCalendarDateRange[1];
          if (!mainCalendarStartDate || !mainCalendarEndDate) return [null, null];

          const periodLengthInDays = mainCalendarEndDate.diff(mainCalendarStartDate, 'day');
          const start = mainCalendarStartDate.subtract(periodLengthInDays + 1, 'day');
          const end = mainCalendarEndDate.subtract(periodLengthInDays + 1, 'day');
          return [start, end];
        },
      },
      {
        label: 'Preceding Period (Day of Week)',
        getValue: () => {
          const mainCalendarStartDate = mainCalendarDateRange[0];
          const mainCalendarEndDate = mainCalendarDateRange[1];
          if (!mainCalendarStartDate || !mainCalendarEndDate) return [null, null];
          const periodLengthInDays = mainCalendarEndDate.diff(mainCalendarStartDate, 'day');
          const start = mainCalendarStartDate.subtract(floor(periodLengthInDays / 7) * 7 + 7, 'day');
          const end = mainCalendarEndDate.subtract(floor(periodLengthInDays / 7) * 7 + 7, 'day');
          return [start, end];
        },
      },
      { label: 'Custom', getValue: () => [null, null] },
    ]);
  }, [mainCalendarDateRange]);

  function onApplyDateRangeFilterClicked() {
    const startDate = mainCalendarDateRange[0];
    const endDate = mainCalendarDateRange[1];

    if (startDate && endDate) {
      const periodLengthInDays = endDate.diff(startDate, 'day');
      if (activeTeam && periodLengthInDays > activeTeam.subscriptionPlan.dateRangeLengthLimitInDays) {
        setIsPaywallModalOpen(true);
        return;
      }

      const newFilter = new DateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [startDate.format(DATE_FORMAT)],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [endDate.format(DATE_FORMAT)],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      });
      setFilterValue(newFilter);
    }

    if (comparisonCalendarDateRange[0] && comparisonCalendarDateRange[1]) {
      const newFilter = new ComparisonDateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [comparisonCalendarDateRange[0].format(DATE_FORMAT)],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [comparisonCalendarDateRange[1].format(DATE_FORMAT)],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      });
      setFilterValue(newFilter);
    }

    setIsOpen(false);
  }

  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <PaywallModal isOpen={isPaywallModalOpen} onClose={onClosePaywallModal}>
        {`Free tier limit of ${activeTeam?.subscriptionPlan.dateRangeLengthLimitInDays} days reached. Upgrade to access unlimited data retention.`}
      </PaywallModal>

      <div className="flex h-full flex-col justify-between px-2 pt-4">
        <div>
          <div className="my-0 ml-4 text-lg font-bold">Optimization Range</div>
          <StaticDateRangePicker
            className="bg-transparent"
            slots={{
              toolbar: () => <div> </div>,
            }}
            slotProps={{
              shortcuts: {
                items: mainCalenderDefaultShortcutsItems,
                sx: {
                  p: 2,
                  width: 250,
                },
              },
              actionBar: { actions: [] },
            }}
            disableFuture
            calendars={2}
            value={mainCalendarDateRange}
            onChange={handleMainCalendarDateChange}
          />
        </div>
        <div>
          <div className="my-0 ml-4 text-lg font-bold">Comparison Period</div>

          <StaticDateRangePicker
            className="bg-transparent"
            slots={{
              toolbar: () => <div> </div>,
            }}
            slotProps={{
              shortcuts: {
                items: comparisonCalenderShortcutsItems,
                sx: {
                  p: 2,
                  width: 250,
                },
                dense: true,
                disablePadding: true,
              },
              actionBar: { actions: [] },
            }}
            disableFuture
            calendars={2}
            value={comparisonCalendarDateRange}
            onChange={handleComparisonCalendarDateChange}
          />
        </div>
      </div>
      <Paper
        square
        className="sticky bottom-0 left-0 right-0 flex flex-row justify-end  gap-2 border-t px-3  py-2 dark:border-gray-700 dark:bg-gray-700"
      >
        <Button variant="text" size="medium" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button size="medium" onClick={onApplyDateRangeFilterClicked}>
          Apply Date Ranges
        </Button>
      </Paper>
    </LocalizationProvider>
  );
}
