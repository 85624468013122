import { FunctionComponent } from 'react';
import { ColorModeToggleButton } from './ColorModeToggleButton';
import { ConfigurationButton } from './ConfigurationButton';
import { LogOutButton } from './LogOutButton';
import { BugButton } from './BugButton';
import { SupportButton } from './SupportButton';
import { AdminModeToggleButton } from './AdminModeToggleButton';

interface MainMenuBottomProps {
  isExpanded: boolean;
}

export const MainMenuBottom: FunctionComponent<MainMenuBottomProps> = ({ isExpanded }) => {
  return (
    <div className="flex w-full flex-col items-center gap-y-2">
      <AdminModeToggleButton isExpanded={isExpanded} />
      <ColorModeToggleButton isExpanded={isExpanded} />
      <SupportButton isExpanded={isExpanded} />
      <BugButton isExpanded={isExpanded} />

      <ConfigurationButton isExpanded={isExpanded} />
      <LogOutButton isExpanded={isExpanded} />
    </div>
  );
};
