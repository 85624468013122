import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import { Fragment, useRef, useState } from 'react';
import { ProfileModel } from '@/modules/users/models/ProfileModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { ButtonBase } from '@mui/material';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { useNavigate } from 'react-router-dom';

export const ProfileSelect = () => {
  const navigate = useNavigate();
  const { activeTeam, activeProfile, setActiveProfile } = useActiveTeamContext();
  const buttonRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleClick = () => {
    setIsDropdownOpen(true);
  };
  const handleClose = () => {
    setIsDropdownOpen(false);
  };

  const onAddAnotherProfileClicked = () => {
    navigate('/app/profiles');
    setIsDropdownOpen(false);
  };

  const onProfileClicked = (profile: ProfileModel) => {
    if (profile) {
      setActiveProfile(profile.id);
    }

    setIsDropdownOpen(false);
  };

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Switch Profiles">
          <ButtonBase
            ref={buttonRef}
            className="min-w-32 flex cursor-pointer justify-center rounded-md p-2 font-semibold text-gray-50 hover:bg-primary-50 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700"
            onClick={handleClick}
            aria-roledescription="button"
            role="button"
            tabIndex={0}
          >
            <div className="flex items-center gap-1 px-1">
              <BusinessRoundedIcon fontSize="small" className="mr-1" />
              {/* <div className="mr-2 h-2 w-2 rounded-full bg-green-700"></div> */}
              <div className="truncate ">{activeProfile?.name}</div>
              <ArrowDropDownRoundedIcon fontSize="small" />
            </div>
          </ButtonBase>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={buttonRef.current}
        id="account-menu"
        open={isDropdownOpen}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {activeTeam?.profiles.map((profile) => {
          return (
            <MenuItem key={profile.id} onClick={() => onProfileClicked(profile)} disabled={activeProfile?.id == profile.id}>
              {profile.name}
            </MenuItem>
          );
        })}
        <Divider />
        <MenuItem onClick={onAddAnotherProfileClicked}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another profile
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
      </Menu>
    </Fragment>
  );
};
