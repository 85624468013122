import { PlanType } from '@/modules/plans/types/SubscriptionPlan';
import { ReactNode } from 'react';

export class AdLabsPlanModel {
  planType: PlanType;
  name: string;
  price: number;
  features: ReactNode[];
  headline: ReactNode;
  description: string;
  unit: ReactNode;

  constructor(args: AdLabsPlanArguments) {
    this.planType = args.planType;
    this.name = args.name;
    this.price = args.price;
    this.features = args.features;
    this.description = args.description;
    this.headline = args.headline;
    this.unit = args.unit;
  }
}

export interface AdLabsPlanArguments {
  planType: PlanType;
  name: string;
  price: number;
  headline: ReactNode;
  features: ReactNode[];
  description: string;
  unit: ReactNode;
}
