import { Autocomplete, Box, IconButton, TextField } from '@mui/material';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { FunctionComponent, SyntheticEvent, useState } from 'react';
import { FilterModel } from './models/FilterModel';
import { FilterBetween } from './filters/FilterBetween';
import { FilterMultiSelect } from './filters/FilterMultiSelect';
import { FilterStringComparison } from './filters/FilterStringComparison';
import { cloneDeep } from 'lodash-es';
import { FilterKey, FilterType } from './types/FilterKey';

interface FilterRowProps {
  filterDefaultValue: FilterModel;
  unUsedFilters: FilterModel[];
  updateFilterByFilterKey: (oldKey: FilterKey, filter: FilterModel | null) => void;
}

export const FilterRow: FunctionComponent<FilterRowProps> = ({ filterDefaultValue, unUsedFilters, updateFilterByFilterKey }) => {
  const [filter, setFilter] = useState<FilterModel>(filterDefaultValue);

  const handleChange = (newValue: FilterModel) => {
    const newFilter = cloneDeep(newValue);
    updateFilterByFilterKey(filter.key, newFilter); // changes pending filters
    setFilter(newFilter); // changes local value
  };

  const onDeleteFilterClicked = () => {
    updateFilterByFilterKey(filter.key, null);
  };

  return (
    <>
      {filter && filter.isFilterBuilderFilter && (
        <div className="flex flex-row gap-2">
          <Autocomplete
            className="w-80"
            disableClearable
            onChange={(event: SyntheticEvent<Element, Event>, newValue: FilterModel) => {
              handleChange(newValue);
            }}
            options={[filterDefaultValue, ...unUsedFilters]}
            id="filter-select"
            getOptionLabel={(filter) => filter.name}
            renderOption={(props, filter) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {filter.longName || filter.name}
              </Box>
            )}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Filter" InputLabelProps={{ shrink: true }} />}
            defaultValue={filterDefaultValue}
            isOptionEqualToValue={(option, value) => option.name === value.name}
          />

          {filter.type == FilterType.BETWEEN && (
            <FilterBetween
              filter={filter}
              minLabel={filter.minLabel}
              maxLabel={filter.maxLabel}
              isOperatorDisabled={filter.isOperatorDisabled}
            />
          )}

          {filter.type == FilterType.MULTI_SELECT && <FilterMultiSelect filter={filter} label={filter.name} />}
          {filter.type == FilterType.STRING_COMPARISON && <FilterStringComparison filter={filter} label={filter.name} />}
          <div className="flex-grow"></div>
            {/* If we want this exactly in the middle of the box,mt-2 is too little and mt-3 is too much, there is no other way Rob, add two filters together with Campaign Name and you'll see why */}
            <div style={{ marginTop: '11px' }}>
            <IconButton className="text-slate-500" aria-label="delete" onClick={onDeleteFilterClicked}>
              <HighlightOffRoundedIcon />
            </IconButton>
            </div>
        </div>
      )}
    </>
  );
};
