import { FunctionComponent, PropsWithChildren } from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import PricingCard from '@/modules/settings/configuration/PricingCard';
import { PlanType } from '@/modules/plans/types/SubscriptionPlan';
import { planService } from '../api/plan.service';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AdsClickRoundedIcon from '@mui/icons-material/AdsClickRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import { PRO_PLAN } from '../configuration/plans';

interface PaywallModalProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
}

export const PaywallModal: FunctionComponent<PaywallModalProps> = ({ isOpen, onClose, children }) => {
  async function onUpgradeClicked(planToUpgradeTo: PlanType) {
    const upgradeResponse = await planService.getCheckoutLinkForPlan(planToUpgradeTo);

    if (upgradeResponse.isSuccess) {
      // Navigate to checkout page
      window.location.href = upgradeResponse.payload.session_url;
    }
  }

  async function onDowngradeClicked(planToDowngradeTo: PlanType) {
    const downgradeResponse = await planService.getCheckoutLinkForPlan(planToDowngradeTo);

    if (downgradeResponse.isSuccess) {
      // Navigate to checkout page
      window.location.href = downgradeResponse.payload.session_url;
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{ '& .MuiDialog-paper': { backgroundImage: 'linear-gradient(25deg,#172554 84%, #0EA5E9 16%)', color: '#fff' } }}
    >
      <DialogContent>
        <div className="flex flex-row gap-x-4 p-2">
          <div className="flex w-3/5 flex-col">
            <div className="flex flex-col">
              <Typography>You discovered a Pro feature.</Typography>
              <Typography variant="h4" color={'#93C5FD'} fontWeight={500}>
                {children}
              </Typography>
            </div>

            <div className="mt-5 flex flex-col">
              <Typography fontSize={26} fontWeight={500}>
                Why should you upgrade?
              </Typography>
            </div>

            <div className="mt-4 flex flex-col  gap-y-8">
              <div className="flex flex-row gap-x-4">
                <AccessTimeRoundedIcon className=" rounded-md bg-blue-600 p-2" style={{ fontSize: 50 }} />

                <div className="ml-2 flex flex-col">
                  <Typography className="text-blue-300" fontSize={20} fontWeight={500}>
                    Save Time
                  </Typography>
                  <Typography>
                    With unlimited batches, optimizing client accounts is now a matter of minutes, not hours. Transform the way you manage
                    your campaigns and reclaim valuable time.
                  </Typography>
                </div>
              </div>

              <div className="flex flex-row gap-x-4">
                <AdsClickRoundedIcon className=" rounded-md bg-blue-600 p-2" style={{ fontSize: 50 }} />

                <div className="ml-2 flex flex-col">
                  <Typography className="text-blue-300" fontSize={20} fontWeight={500}>
                    Enhanced Performance
                  </Typography>
                  <Typography>
                    Unlock superior performance with unlimited data retention, giving the algorithm historical context for smarter
                    optimizations.
                  </Typography>
                </div>
              </div>

              <div className="flex flex-row gap-x-4">
                <GroupsRoundedIcon className=" rounded-md bg-blue-600 p-2" style={{ fontSize: 50 }} />

                <div className="ml-2 flex flex-col">
                  <Typography className="text-blue-300" fontSize={20} fontWeight={500}>
                    You&apos;re not alone
                  </Typography>
                  <Typography>
                    Leverage our team&apos;s expertise and gain exclusive access to our private Discord channels for specialized insights.
                    You&apos;re never alone in navigating campaign complexities.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-2/5 flex-col">
            <PricingCard onUpgrade={onUpgradeClicked} onDowngrade={onDowngradeClicked} plan={PRO_PLAN} isCurrentPlan={false} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
