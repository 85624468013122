import { createContext } from 'react';
import { FilterModel } from '../models/FilterModel';

interface PendingFiltersContext {
  filters: FilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<FilterModel[]>>;
  setFilterValue: (filter: FilterModel) => void;
}

// Initialize the context variable and also export it.
export const PendingFiltersContext = createContext<PendingFiltersContext>({
  filters: [],
  setFilters: () => null,
  setFilterValue: () => null,
});
