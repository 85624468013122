export enum ColumnId {
  CHECKBOX = 'checkbox',
  BIDDING_ENTITY = 'biddingEntity',
  CAMPAIGN_NAME = 'campaignName',
  TARGETING = 'targeting',
  MATCH = 'match',
  AD_GROUP = 'adGroup',
  OLD_VALUE = 'oldValue',
  NEW_VALUE = 'newValue',
  DELTA = 'delta',
  REASONS = 'reasons',
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  ORDERS = 'orders',
  ACOS = 'acos',
  CTR = 'ctr',
  CVR = 'cvr',
  CPC = 'cpc',
  SPEND = 'spend',
  SALES = 'sales',
  ROAS = 'roas',
  RPC = 'rpc',
  CPA = 'cpa',
  AOV = 'aov',
  CPM = 'cpm',
  NAME = 'name',
  STATE = 'state',
  SALES_PREVIOUS_DAYS = 'sales.previousDays',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  TARGETING_TYPE = 'targetingType',
  BUDGET_TYPE = 'budgetType',
  BUDGET_AMOUNT = 'budgetAmount',
  BID_STRATEGY = 'bidStrategy',
  SERVING_STATUS = 'servingStatus',
  TAGS = 'tags',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PROFILE_NAME = 'profileName',
  BILLING_STATUS = 'billingStatus',
  MARKET = 'market',
  CURRENCY = 'currency',
  TIMEZONE = 'timezone',
  TYPE = 'type',
  ONBOARD_DATE = 'onboardDate',
  TEAM_ACCESS = 'teamAccess',
  DEBUG = 'debug',
  REPORT_INFO = 'reportInfo',
  DATA_STATUS = 'dataStatus',
  PROCESS_LAST = 'processLast',
  MANUAL_UPDATE = 'manualUpdate',
  LAST_OPTIMIZED = 'lastOptimized',
  REMOVE_PROFILE = 'removeProfile',
  JOB_ID = 'jobId',
  REVERTED_JOB_ID = 'revertedJobId',
  VERSION = 'version',
  APPLIED_ENTITY_COUNT = 'appliedEntityCount',
  SUCCESS_ENTITY_COUNT = 'successEntityCount',
  FAILED_ENTITY_COUNT = 'failedEntityCount',
  TACOS = 'tacos',
  SENTIMENT = 'sentiment',
  BID_CEILING = 'bidCeiling',
  REVERT_JOB = 'revertJob',
  RESTART_INITIAL_REPORTS = 'restartInitialReports',
  GROUP_ID = 'groupId',
  GROUP_NAME = 'groupName',
  GROUP_DESCRIPTION = 'groupDescription',
  GROUP_TACOS = 'groupTacos',
  GROUP_PRESET = 'groupPreset',
  GROUP_BID_CEILING = 'groupBidCeiling',
  GROUP_EDIT = 'groupEdit',
  CAMPAIGN_GROUP_COUNT = 'campaignGroupCount',
  CAMPAIGN_GROUP_DELETE = 'campaignGroupDelete',
}
