import CollapsibleCopyableDetails from '@/components/CollapsibleCopyableDetails';
import useFormatting from '@/hooks/useFormatting';
import { AdditionalStatusType, StatusType } from '@/modules/profiles/api/profile.contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useReportsContext } from '@/modules/teams/contexts/ReportsContext';
import { LoadingButton } from '@mui/lab';
import { Alert, Card, Typography, Button } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';

interface OptimizationAlertsProps {
  isShowingAlerts: boolean;
  setIsShowingAlerts: (value: boolean) => void;
}

const OptimizationAlerts: FunctionComponent<OptimizationAlertsProps> = ({ isShowingAlerts, setIsShowingAlerts }) => {
  const { activeTeam, activeProfile, activeProfileIdChange } = useActiveTeamContext();

  const { activeProfileDataStatusInfo, activeProfileHasProcessedReports, updateProfileData, isActiveProfileDataBeingProcessed } =
    useReportsContext();
  const { formatDateStringTimeNoSeconds } = useFormatting();
  const [updateProfileDataErrorMessage, setUpdateProfileDataErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (
      (activeProfileDataStatusInfo.initialLoadFinished && activeProfileHasProcessedReports) ||
      activeProfileDataStatusInfo.status == AdditionalStatusType.ERROR
    ) {
      if (isShowingAlerts) {
        setIsShowingAlerts(false);
      }
    }
  }, [activeProfileDataStatusInfo, activeProfileHasProcessedReports]);

  useEffect(() => {
    setUpdateProfileDataErrorMessage(null);
  }, [activeProfileIdChange]);

  async function onUpdateProfileClicked() {
    try {
      if (activeProfile?.id) {
        await updateProfileData(activeProfile.id);
        toast.success('Report generation started successfully');
      }
    } catch (error) {
      console.log(error);
      const errString = error instanceof Error ? error.message : `${error}`;

      setUpdateProfileDataErrorMessage(errString);
      toast.error('Unable to update profile data. Please try again later.');
    }
  }

  return (
    <>
      {!activeProfileDataStatusInfo.initialLoadFinished && activeProfileDataStatusInfo.status == AdditionalStatusType.LOADING && (
        <div className="m-4">
          <Alert severity="info">Fetching the current status for this profile...</Alert>
        </div>
      )}
      {activeProfileDataStatusInfo.initialLoadFinished &&
        !activeProfileHasProcessedReports &&
        activeProfileDataStatusInfo.status != AdditionalStatusType.ERROR && (
          <Card className="m-4 rounded-xl">
            {/* TODO: Click here to start processing data for this profile + refetch report */}
            <Alert severity={updateProfileDataErrorMessage ? 'error' : 'info'}>
              <div className="flex w-full flex-col gap-4">
                <Typography variant="body1">
                  {activeProfile
                    ? 'No data available for this profile yet!'
                    : activeTeam && activeTeam?.profiles.length > 0
                    ? 'Select a profile to use the Optimizer'
                    : 'Add a profile first to use the Optimizer'}
                </Typography>
                {activeProfileDataStatusInfo.status == StatusType.FAILED && (
                  <Typography variant="body1">Last update failed. If the error persists, please contact us!</Typography>
                )}
                {activeProfile && (
                  <div>
                    {isActiveProfileDataBeingProcessed ? (
                      <div className="flex flex-col gap-2 items-start">
                        <Typography variant="body1">
                          Data is being processed. This may take up to several minutes.
                          {activeProfileDataStatusInfo.latestStarted
                            ? ` Started at ${formatDateStringTimeNoSeconds(activeProfileDataStatusInfo.latestStarted)}`
                            : ''}
                        </Typography>
                        <LoadingButton loadingPosition="center" variant="text" loading={true}>
                          <CachedRoundedIcon />
                        </LoadingButton>
                      </div>
                    ) : !updateProfileDataErrorMessage ? (
                      <Button onClick={onUpdateProfileClicked}>Initiate Update</Button>
                    ) : null}
                    {updateProfileDataErrorMessage && (
                      <CollapsibleCopyableDetails headerText={'Error Details'} message={updateProfileDataErrorMessage} />
                    )}
                  </div>
                )}
              </div>
            </Alert>
          </Card>
        )}
    </>
  );
};

export default OptimizationAlerts;
