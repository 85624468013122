import { SecondaryMenu, SecondaryMenuItemGroup, SecondaryMenuLayout, SecondaryMenuHeader } from '@/modules/application';
import { Outlet } from 'react-router-dom';
// import { USER_CONFIGURATION_SUB_MENU_ITEMS } from '@/modules/users';
import { SETTING_CONFIGURATION_SUB_MENU_ITEMS } from '@/modules/settings';

export function ConfigurationLayout() {
  return (
    <SecondaryMenuLayout
      secondaryMenu={
        <>
          <SecondaryMenu>
            <SecondaryMenuHeader>Configuration</SecondaryMenuHeader>
            {SETTING_CONFIGURATION_SUB_MENU_ITEMS.map((smi) => {
              return <SecondaryMenuItemGroup header={smi.label} key={smi.label} menuItems={smi.children ?? []} />;
            })}
            {/* {USER_CONFIGURATION_SUB_MENU_ITEMS.map((smi) => {
              return <SecondaryMenuItemGroup header={smi.label} key={smi.label} menuItems={smi.children ?? []} />;
            })} */}
          </SecondaryMenu>
        </>
      }
    >
      <Outlet />
    </SecondaryMenuLayout>
  );
}
