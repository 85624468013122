import { OptimizationReason } from './api/optimization-contracts';
import { BiddingEntity } from './models/OptimizationModel';

export enum OptimizationPreset {
  BALANCED = 'BALANCED',
  REDUCE_ACOS = 'REDUCE_ACOS',
  INCREASE_SALES = 'INCREASE_SALES',
}

export const availableOptimizationPresets = Object.values(OptimizationPreset);

export interface OptimizationApplyData {
  id: number;
  new_value: number;
  bidding_entity: BiddingEntity;
  old_value: number;
  reasons: OptimizationReason[];
}

export interface OptimizationParams {
  tacos: number;
  selectedPreset: OptimizationPreset;
  highAcos: boolean;
  lowAcos: boolean;
  highSpend: boolean;
  lowVisibility: boolean;
  showZeroImpressions: boolean;
  smartBidCeilingActive: boolean;
  bidCeiling: number;
}
