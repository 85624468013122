import { FunctionComponent, useEffect, useState } from 'react';
import RegistrationWizardPage from '../components/RegistrationWizardPage';
import { useNavigate } from 'react-router-dom';
import RegistrationWizardPageTitle from '../components/RegistrationWizardPageTitle';
import { RegistrationWizardPageDescription } from '../components/RegistrationWizardPageDescription';
import { useUserContext, userService } from '@/modules/users';
import { teamService } from '@/modules/teams';
import { useRegistrationContext } from '../contexts/registration/RegistrationContext';
import { isNil } from 'lodash-es';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { Alert } from '@mui/material';
import { SelectedProfileDTO } from '@/modules/profiles/types/SelectedProfile';
import ProfileList from '@/modules/profiles/components/ProfileList';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';

interface RegistrationStepSelectProfilesPageProps {}

export const RegistrationStepSelectProfilesPage: FunctionComponent<RegistrationStepSelectProfilesPageProps> = () => {
  const { user, setUser } = useUserContext();
  const { teamId, userId } = useRegistrationContext();
  const { setActiveTeam, setActiveProfile, activeTeam } = useActiveTeamContext();
  const [profiles, setProfiles] = useState<SelectedProfileDTO[]>([]);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (teamId) {
      loadProfiles();
    }
  }, [teamId]);

  function setError(message: string) {
    setIsError(true);
    setErrorMessage(message);
  }

  function resetError() {
    setIsError(false);
    setErrorMessage('');
  }

  async function loadProfiles() {
    try {
      resetError();
      setIsLoading(true);

      const res = await teamService.getAmazonProfiles();
      if (res.isSuccess) {
        if (!isNil(res.payload) && res.payload.length > 0) {
          const profiles = res.payload.map((profile) => {
            return {
              id: profile.id,
              name: profile.name,
              market: profile.countryCode,
              type: profile.type,
              isSelected: false,
            };
          });

          const filteredProfiles = profiles.filter((p) => p.market === 'US');
          setProfiles(filteredProfiles);
        } else {
          setError('No profiles found.');
          toast.error('No profiles found.');
        }
      } else {
        setError('Something went wrong. ' + res.message);
      }
    } catch (error) {
      setError('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  async function onConfirmClicked() {
    resetError();
    setIsLoading(true);

    try {
      const selectedProfiles = profiles.filter((p) => p.isSelected);
      if (selectedProfiles.length === 0) {
        setError('No profiles selected');
        return;
      }

      const result = await teamService.addProfilesToTeam(
        teamId,
        selectedProfiles.map((p) => p.id),
      );

      if (result.isSuccess) {
        toast.success('Profiles added successfully');
        console.log('activeTeam', activeTeam);

        if (userId) {
          const userResponse = await userService.getUserById(userId);
          let userTeams = undefined;

          if (userResponse.isSuccess && user) {
            userTeams = userResponse.payload.teams;
            user.teams = userTeams;
            setUser(user);
          }

          setActiveTeam({ teamId, userTeams });

          setActiveProfile(selectedProfiles[0].id);
          navigate('/register/start-optimizing');
        } else {
          setError('User ID not set. Please try again later.');
        }
      } else {
        setError('Something went wrong. Please try again later.');
      }
    } catch (error) {
      setError('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }

  function onProfileSelectionChanged(profileId: number) {
    const newProfiles = profiles.map((p) => {
      if (p.id === profileId) {
        return {
          ...p,
          isSelected: !p.isSelected,
        };
      }
      return p;
    });
    setProfiles(newProfiles);
  }

  return (
    <RegistrationWizardPage>
      <div className="mb-4 flex flex-col items-center">
        <RegistrationWizardPageTitle>Select Profiles</RegistrationWizardPageTitle>
        <RegistrationWizardPageDescription>Select which Profiles you would like to manage</RegistrationWizardPageDescription>

        {isError && (
          <Alert className="mt-3" severity="error">
            {errorMessage}
          </Alert>
        )}

        <div className="mt-4 flex flex-col justify-stretch gap-y-3">
          <ProfileList profiles={profiles} onProfileSelectionChanged={onProfileSelectionChanged} />
        </div>

        <LoadingButton className="mt-6 w-52" size="large" variant="contained" color="primary" onClick={onConfirmClicked} loading={isLoading}>
          Confirm
        </LoadingButton>
      </div>
    </RegistrationWizardPage>
  );
};
