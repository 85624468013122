import { ButtonBase } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';

interface MainMenuButtonProps {
  isActive: boolean;
  label: string;
  icon: ReactNode;
  isExpanded: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const MainMenuButton: FunctionComponent<MainMenuButtonProps> = ({ onClick, icon, label, isExpanded, isActive }) => {
  return (
    <ButtonBase
      onClick={onClick}
      component="div"
      className={`flex h-10 w-full  justify-start overflow-hidden   rounded-lg    px-2
      focus:text-white dark:first-line:focus:text-primary-700   ${
        isActive
          ? 'bg-primary-900 text-primary-50 dark:bg-gray-850 dark:text-primary-500 '
          : ' text-blue-50 hover:bg-primary-200 hover:bg-opacity-20 dark:text-white dark:hover:bg-gray-700'
      }`}
    >
      {icon}
      <span className={`truncate pl-2.5  font-semibold transition-opacity ${isExpanded ? 'opacity-100' : 'opacity-0'}`}>{label}</span>
    </ButtonBase>
  );
};
