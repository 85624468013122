import { FunctionComponent, useContext } from 'react';
import { ColorModeContext } from '../context/ColorModeContext';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { MainMenuButton } from './MainMenuButton';
import { useUserContext } from '@/modules/users';

interface ColorModeToggleButtonProps {
  isExpanded: boolean;
}

export const ColorModeToggleButton: FunctionComponent<ColorModeToggleButtonProps> = ({ isExpanded }) => {
  const colorMode = useContext(ColorModeContext);

  const { user, isAdminModeActive } = useUserContext();
  if (!user || !user.isAdmin || !isAdminModeActive) {
    return null;
  }

  return (
    <MainMenuButton
      icon={colorMode.mode === 'dark' ? <Brightness4Icon /> : <Brightness7Icon />}
      isActive={false}
      isExpanded={isExpanded}
      label={colorMode.mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
      onClick={colorMode.toggleColorMode}
    />
  );
};
