import { FunctionComponent } from 'react';
import { HelpOutline } from '@mui/icons-material';
import { MainMenuButton } from './MainMenuButton';
import Gleap from 'gleap';

interface SupportButtonProps {
  isExpanded: boolean;
}

export const SupportButton: FunctionComponent<SupportButtonProps> = ({ isExpanded }) => {
  const startGleapFeedbackFlow = () => {
    Gleap.open();
  };
  return (
    <MainMenuButton
      icon={<HelpOutline/>}
      isActive={false}
      isExpanded={isExpanded}
      label={"Need help"}
      onClick={startGleapFeedbackFlow}
    />
  );
};
