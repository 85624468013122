import App from '@/App';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { CONFIGURATION_ROUTES } from '@/modules/configuration/routes/configuration-routes';
import { REGISTRATION_ROUTES } from '@/modules/registration/routes/registration.routes';
import { TEAMS_ROUTES } from '@/modules/teams/routes/teams-routes';
import { OPTIMIZER_ROUTES } from '@/modules/optimizer/routes/optimizer-routes';
import * as Sentry from '@sentry/react';
import { ErrorPage } from '@/modules/application/pages/ErrorPage';
import { PublicLayout } from '@/modules/application/layouts/PublicLayout';
import { LoginPage } from '@/modules/application/pages/LoginPage';
import { ApplicationLayout } from '@/modules/application/layouts/ApplicationLayout';
import ImpersonationPage from '@/modules/application/pages/ImpersonatePage';
import ResetPasswordPage from '@/modules/application/pages/ResetPasswordPage';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,

    children: [
      {
        element: <PublicLayout />,
        children: [
          {
            path: 'login',
            element: <LoginPage />,
          },
          {
            path: 'impersonate',
            element: <ImpersonationPage />,
          },
          {
            path: 'reset-password',
            element: <ResetPasswordPage />,
          },
          ...REGISTRATION_ROUTES,
        ],
      },
      {
        path: 'app',
        element: <ApplicationLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="profiles" replace />,
          },
          /**
           * All App Module Routes go below
           */

          ...OPTIMIZER_ROUTES,
          ...CONFIGURATION_ROUTES,
          ...TEAMS_ROUTES,
        ],
      },
      {
        path: '*',

        element: <Navigate to="/app" replace />,
      },
    ],
  },
]);
