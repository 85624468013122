import { MetricsData } from '@/modules/optimizer/api/campaign/models/CampaignModel';
import { BiddingEntity } from '../models/OptimizationModel';
import { OptimizationPreset } from '../OptimizerConfig';

export interface OptimizationResponse {
  job_id: number;
  preview_data: ResponsePreviewDataRow[];
  debug_data: ResponseAdGroupDebugRow[];
  campaign_debug_data: ResponseCampaignDebugRow[];
  campaign_group_debug_data: ResponseCampaignDebugRow[];
  profile_debug_data: ResponseCampaignDebugRow;
}

// Needs to be kept in sync with MatchTypeFilterModel
export enum MatchType {
  NONE = '',
  AUTO = 'AUTO',
  EXACT = 'EXACT',
  EXPANDED = 'EXPANDED',
  CATEGORY = 'CATEGORY',
  PHRASE = 'PHRASE',
  BROAD = 'BROAD',
}

export interface ResponsePreviewDataRow {
  id: number;
  campaign_name: string;
  campaign_group: string;
  bidding_entity: BiddingEntity;
  targeting: string;
  match: MatchType;
  ad_group: string;
  old_value: number;
  new_value: number;
  last_optimized_at: string | null;
  delta: number;
  reasons: OptimizationReason[];
  impressions: MetricsData;
  clicks: MetricsData;
  orders: MetricsData;
  ctr: MetricsData;
  cvr: MetricsData;
  cpc: MetricsData;
  spend: MetricsData;
  sales: MetricsData;
  acos: MetricsData;
  roas: MetricsData;
  rpc: MetricsData;
  cpa: MetricsData;
  aov: MetricsData;
  cpm: MetricsData;
}

export interface ResponseDebugDataRow {
  tcpa: MetricsData;
  tcpc: MetricsData;
  rpc_inherited: string;
  aov_inherited: string;
  actc_inherited: string;
  impressions: MetricsData;
  clicks: MetricsData;
  orders: MetricsData;
  ctr: MetricsData;
  cvr: MetricsData;
  cpc: MetricsData;
  spend: MetricsData;
  sales: MetricsData;
  acos: MetricsData;
  roas: MetricsData;
  rpc: MetricsData;
  cpa: MetricsData;
  aov: MetricsData;
  cpm: MetricsData;
}

export interface ResponseCampaignDebugRow {
  campaign_name: string;
  placement_mod: number;

  stats_tos: ResponseDebugDataRow;
  stats_pp: ResponseDebugDataRow;
  stats_ros: ResponseDebugDataRow;
}

export interface ResponseAdGroupDebugRow {
  ad_group: string;
  tcpa: MetricsData;
  tcpc: MetricsData;
  rpc_inherited: string;
  aov_inherited: string;
  actc_inherited: string;
  impressions: MetricsData;
  clicks: MetricsData;
  orders: MetricsData;
  ctr: MetricsData;
  cvr: MetricsData;
  cpc: MetricsData;
  spend: MetricsData;
  sales: MetricsData;
  acos: MetricsData;
  roas: MetricsData;
  rpc: MetricsData;
  cpa: MetricsData;
  aov: MetricsData;
  cpm: MetricsData;
}

export enum EnabledKeywordGroups {
  LOW_VISIBILITY = 'LOW_VISIBILITY',
  LOW_ACOS = 'LOW_ACOS',
  HIGH_ACOS = 'HIGH_ACOS',
  HIGH_SPEND_NO_SALES = 'HIGH_SPEND_NO_SALES',
}

export enum OptimizationReason {
  LOW_VISIBILITY = 'LOW_VISIBILITY',
  HIGH_ACOS = 'HIGH_ACOS',
  HIGH_SPEND = 'HIGH_SPEND',
  LOW_ACOS = 'LOW_ACOS',
  USER_BID_CEILING = 'USER_BID_CEILING',
  KEYWORD_BID_CEILING = 'KEYWORD_BID_CEILING',
  AD_GROUP_BID_CEILING = 'AD_GROUP_BID_CEILING',
  CAMPAIGN_BID_CEILING = 'CAMPAIGN_BID_CEILING',
  CAMPAIGN_GROUP_BID_CEILING = 'CAMPAIGN_GROUP_BID_CEILING',
  PROFILE_BID_CEILING = 'PROFILE_BID_CEILING',
  LOWEST_POSSIBLE_BID = 'LOWEST_POSSIBLE_BID',
  SMALLEST_POSSIBLE_INCREASE = 'SMALLEST_POSSIBLE_INCREASE',
  CAMPAIGN_PERFORMANCE = 'CAMPAIGN_PERFORMANCE',
  CAMPAIGN_GROUP_PERFORMANCE = 'CAMPAIGN_GROUP_PERFORMANCE',
  PROFILE_PERFORMANCE = 'PROFILE_PERFORMANCE',
  MAX_ONE_TIME_CHANGE = 'MAX_ONE_TIME_CHANGE',
  LOWEST_POSSIBLE_ADJUSTMENT = 'LOWEST_POSSIBLE_ADJUSTMENT',
  HIGHEST_POSSIBLE_ADJUSTMENT = 'HIGHEST_POSSIBLE_ADJUSTMENT',
  MAX_BID_DECREASE = 'MAX_BID_DECREASE',
}

export interface OptimizationRequestDTO {
  dates: {
    start_date: string;
    end_date: string;
  };
  compare_dates: {
    start_date: string;
    end_date: string;
  };
  campaign_ids: number[];
  tacos: number;
  preset: OptimizationPreset;
  advanced: {
    bid_ceiling: number;
    enabled_keyword_groups: EnabledKeywordGroups[];
    exclude_no_impressions: boolean;
  };
}

export interface OptimizationApplyResponse {
  bidding_entity: BiddingEntity;
  update_count: number;
  error_count: number;
}

export interface OptimizationApplyDTO {
  job_id: number;
  updates: OptimizationUpdates[];
}

export interface OptimizationUpdates {
  id: number;
  new_value: number;
  bidding_entity: BiddingEntity;
  old_value: number;
  reasons: OptimizationReason[];
}

export interface RevertJobDTO {
  job_id: number;
}
