import { useTranslation } from '@/lib/i18n/useTranslate';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { ProfileSelect } from '@/modules/optimizer/components/ProfileSelect';
import { TeamSelect } from '@/modules/optimizer/components/TeamSelect';
import { FunctionComponent } from 'react';
import ProfileHistoryTable from '../../profiles/components/ProfileHistoryTable';

interface HistoryPageProps {}

const HistoryPage: FunctionComponent<HistoryPageProps> = () => {
  const { t } = useTranslation();

  return (
    <PageLayout>
      <PageLayoutTopBar
        header={t('profile_history_page.header')}
        actions={
          <div className="flex flex-row gap-2">
            <TeamSelect />
            <ProfileSelect />
          </div>
        }
      ></PageLayoutTopBar>

      <PageLayoutBody>
        <div className="mt-4">
          <ProfileHistoryTable />
        </div>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default HistoryPage;
