let tagLengthLimit = 20;
export const truncateInputValues = (values: string[] | number[]): string[] => {
  const tags = [];
  if (values.length == 1) {
    tagLengthLimit = 50;
  }

  for (let index = 0; index < values.length; index++) {
    const tag = values[index].toString();
    tags.push(truncateInput(tag));
  }

  return tags;
};

export const truncateInput = (tag: string): string => {
  return tag.length > tagLengthLimit ? tag.substring(0, 4) + '...' + tag.substring(tag.length - 4, tag.length) : tag;
};
