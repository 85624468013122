export enum SettingType {
  'STRING' = 'STRING',
  'NUMBER' = 'NUMBER',
  'BOOLEAN' = 'BOOLEAN',
  'JSON' = 'JSON',
}

// String can be either JSON or an actual String value
export type SettingValue = number | string | boolean | object;

export enum SettingKey {
  'TEST_SETTING_ENABLED' = 'TEST_SETTING_ENABLED',
  'TEST_SETTING' = 'TEST_SETTING',
}

export interface SettingReadDTO {
  id: number;
  key: SettingKey;
  type: SettingType;
  value: SettingValue;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface SettingUpdateDTO {
  key: SettingKey;
  type: SettingType;
  value: SettingValue;
}
