import React, { FunctionComponent, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Autocomplete, Chip, TextField, Tooltip } from '@mui/material';
import { FilterModel, LogicalOperatorType, OperatorType } from '../models/FilterModel';
import { StringComparisonOperatorSelect, StringComparisonOperatorSelectOption } from './StringComparisonOperatorSelect';
import { PendingFiltersContext } from '../context/PendingFiltersContext';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash-es';
import { truncateInput } from './utils';
import { FilterKey } from '../types/FilterKey';

interface FilterStringComparisonProps {
  filter: FilterModel;
  label: string;
}

export const FilterStringComparison: FunctionComponent<FilterStringComparisonProps> = ({ filter }) => {
  const { setFilterValue } = useContext(PendingFiltersContext);
  const filterKey = filter.key;

  const [tags, setTags] = useState<string[]>(
    filter.conditions && Array.isArray(filter.conditions[0].values) ? filter.conditions[0].values.map(String) : [],
  );
  const [inputValue, setInputValue] = useState<string>('');

  const defaultStringComparisonOperator = filter.conditions
    ? filter.conditions[0].operator
    : filterKey == FilterKey.CAMPAIGN_NAME_NOT
    ? OperatorType.NOT_LIKE
    : OperatorType.LIKE;

  const [stringComparisonOperator, setStringComparisonOperator] = useState(defaultStringComparisonOperator);

  const [logicalOperator, setLogicalOperator] = useState(filter.logicalOperator ?? LogicalOperatorType.OR);

  const handleInputChange = (event: SyntheticEvent<Element, Event>, value: string) => {
    setInputValue(value.trim());
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && inputValue) {
      if (isValueValid(inputValue)) {
        setTags([...tags, inputValue]);
        setInputValue('');
        event.preventDefault(); // Prevent form submission if inside a form
      }
    } else if (event.key === 'Backspace' && !inputValue) {
      setTags((prevTags) => prevTags.slice(0, -1));
    }
  };

  const handleBlur = () => {
    if (isValueValid(inputValue)) {
      setTags([...tags, inputValue]);
      setInputValue('');
    }
  };

  const isValueValid = (value: string): boolean => {
    if (isEmpty(value)) {
      return false;
    }

    const isTagDuplicate = tags.some((tag) => tag.toLowerCase() === value.toLowerCase());
    if (isTagDuplicate) {
      toast.error('You cannot enter duplicate values');
    }

    return !isTagDuplicate;
  };

  const handleDelete = (indexToDelete: number) => {
    setTags((prevTags) => prevTags.filter((_, index) => index !== indexToDelete));
  };

  const handleOperatorChange = (newSelection: StringComparisonOperatorSelectOption) => {
    setStringComparisonOperator(newSelection.operator);
    setLogicalOperator(newSelection.logicalOperator);
  };

  useEffect(() => {
    filter.logicalOperator = logicalOperator;
    filter.conditions = [
      {
        values: tags,
        operator: stringComparisonOperator,
      },
    ];

    setFilterValue(filter);
  }, [tags, stringComparisonOperator, logicalOperator]);

  return (
    <div className="flex flex-row gap-2">
      <div>
        <StringComparisonOperatorSelect
          label={'Operator'}
          filterKey={filterKey}
          handleChange={handleOperatorChange}
          defaultOperatorValue={stringComparisonOperator}
          defaultLogicalOperatorValue={logicalOperator}
        />
      </div>

      <div>
        <Autocomplete
          renderInput={(params) => (
            <TextField label="Partial Name" {...params} helperText="Press ENTER to add" InputLabelProps={{ shrink: true }} />
          )}
          id={'input-' + filterKey}
          clearIcon={false}
          options={[]}
          freeSolo
          multiple
          value={tags} // Setting value to the existing tags
          className="w-100"
          onBlur={handleBlur}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          clearOnBlur
          renderTags={(value) =>
            value.map((tag, index) => (
              <Tooltip key={index} title={tag}>
                <Chip className="m-0.5" size="small" label={truncateInput(tag)} onDelete={() => handleDelete(index)} />
              </Tooltip>
            ))
          }
        />
      </div>
    </div>
  );
};
