import { FunctionComponent, useState } from 'react';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { Button } from '@mui/material';
import ProfileTable from '../components/ProfileTable';
import { TeamSelect } from '@/modules/optimizer/components/TeamSelect';
import { ProfileSelectorDialog } from '../components/ProfileSelectorDialog';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import { PreferredTimePicker } from '../components/PreferredTimePicker';

interface TeamProfilesPageProps {}

const TeamProfilesPage: FunctionComponent<TeamProfilesPageProps> = () => {
  const { t } = useTranslation();

  function onAddProfilesClicked() {
    setOpen(true);
  }

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <PageLayout>
      <PageLayoutTopBar header={t('team_profiles_page.header')} actions={<TeamSelect />}></PageLayoutTopBar>
      <PageLayoutBody>
        <div className="mt-4 flex items-center justify-between">
          <div className="flex items-center gap-4">
            <Button size="medium" onClick={onAddProfilesClicked}>
              Add Profiles
            </Button>
            <ProfileSelectorDialog open={open} onClose={handleClose} />
            {/* <Button size="large">Manage Team</Button> */}
          </div>
          <PreferredTimePicker />
        </div>
        <div className="mt-4">
          <ProfileTable />
        </div>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default TeamProfilesPage;
