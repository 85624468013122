import { apiProfileClient } from '@/lib/api/base-client';
import { ApiResponse } from '@/lib/api/api-response';
import { OptimizationApplyData, OptimizationParams } from '../OptimizerConfig';
import { OptimizationModel } from '../models/OptimizationModel';
import {
  EnabledKeywordGroups,
  OptimizationApplyDTO,
  OptimizationApplyResponse,
  OptimizationRequestDTO,
  OptimizationResponse,
  OptimizationUpdates,
  RevertJobDTO,
} from './optimization-contracts';
import { ComparisonDateFilterModel, DateFilterModel, FilterModel } from '../../filter-builder/models/FilterModel';
import { OptimizerJobModel } from '@/modules/profiles/types/OptimizerJobModel';
import { OptimizerJobDTO } from '@/modules/profiles/api/profile.contracts';

export class OptimizationService {
  public basePath = 'optimizer';

  async getOptimizedResults(
    selectedCampaigns: number[],
    filters: FilterModel[],
    optimizationParams: OptimizationParams,
  ): Promise<ApiResponse<OptimizationModel>> {
    // // Mock data
    // await new Promise((resolve) => setTimeout(resolve, 500));
    // return new ApiResponse({
    //   responseCode: ApiResponseCode.SUCCESS,
    //   httpResponseCode: 200,
    //   payload: OptimizationModel.fromResponseArray(MockApi.getOptimizedResults(selectedCampaigns, tacos, selectedPreset)),
    //   message: '',
    // });

    try {
      const datesFilter = filters.find((f) => f instanceof DateFilterModel);
      const compareDatesFilter = filters.find((f) => f instanceof ComparisonDateFilterModel);

      if (!datesFilter || !compareDatesFilter) {
        return ApiResponse.UnknownErrorResponse();
      }

      const keywordGroupMapping: Record<string, EnabledKeywordGroups> = {
        highAcos: EnabledKeywordGroups.HIGH_ACOS,
        highSpend: EnabledKeywordGroups.HIGH_SPEND_NO_SALES,
        lowAcos: EnabledKeywordGroups.LOW_ACOS,
        lowVisibility: EnabledKeywordGroups.LOW_VISIBILITY,
      };

      const enabledKeywordGroups: EnabledKeywordGroups[] = Object.entries(keywordGroupMapping)
        .filter(([key]) => optimizationParams[key as keyof typeof optimizationParams])
        .map(([, value]) => value);

      const requestData: OptimizationRequestDTO = {
        dates: {
          start_date: (datesFilter.conditions ? datesFilter.conditions[0].values[0] : null) as string,
          end_date: (datesFilter.conditions ? datesFilter.conditions[1].values[0] : null) as string,
        },
        compare_dates: {
          start_date: (compareDatesFilter.conditions ? compareDatesFilter.conditions[0].values[0] : null) as string,
          end_date: (compareDatesFilter.conditions ? compareDatesFilter.conditions[1].values[0] : null) as string,
        },
        campaign_ids: selectedCampaigns,
        tacos: optimizationParams.tacos / 100,
        preset: optimizationParams.selectedPreset,
        advanced: {
          bid_ceiling: Number(optimizationParams.bidCeiling),
          enabled_keyword_groups: enabledKeywordGroups,
          exclude_no_impressions: !optimizationParams.showZeroImpressions,
        },
      };

      const applicationResponse = await apiProfileClient.post<OptimizationResponse>(`${this.basePath}/preview`, requestData);
      return applicationResponse.processPayload(OptimizationModel.fromResponse);
    } catch (error) {
      console.log(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async applyOptimization(
    jobId: number,
    optimizationPreviewSelection: OptimizationApplyData[],
  ): Promise<ApiResponse<OptimizationApplyResponse[]>> {
    // Mock data
    // console.log('USING MOCK APPLY');
    // return new ApiResponse({
    //   responseCode: ApiResponseCode.SUCCESS,
    //   httpResponseCode: 200,
    //   payload: [
    //     { bidding_entity: BiddingEntity.KEYWORD, update_count: 1, error_count: 0 },
    //     { bidding_entity: BiddingEntity.PLACEMENT_TOP, update_count: 2, error_count: 1 },
    //     { bidding_entity: BiddingEntity.PLACEMENT_PRODUCT_PAGE, update_count: 3, error_count: 0 },
    //   ],
    //   message: '',
    // });

    const convertDataToRequest = (dataArray: OptimizationApplyData[]): OptimizationUpdates[] => {
      return dataArray.map((dataItem) => {
        const { id, new_value, bidding_entity, old_value, reasons } = dataItem;

        const requestItem: OptimizationUpdates = {
          id,
          new_value,
          bidding_entity,
          old_value,
          reasons,
        };

        return requestItem;
      });
    };

    const updates: OptimizationUpdates[] = convertDataToRequest(optimizationPreviewSelection);

    const requestData: OptimizationApplyDTO = {
      job_id: jobId,
      updates,
    };

    try {
      const applicationResponse = await apiProfileClient.post<OptimizationApplyResponse[]>(`${this.basePath}/apply`, requestData);
      return applicationResponse;
    } catch (error) {
      console.log(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async revertOptimizerJob(jobID: number): Promise<ApiResponse<undefined>> {
    try {
      const revertJobDTO: RevertJobDTO = {
        job_id: jobID,
      };

      const response = await apiProfileClient.post<undefined>(`/optimizer/revert`, revertJobDTO);
      return response;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getOptimizerJobs(): Promise<ApiResponse<OptimizerJobModel[]>> {
    try {
      const applicationResponse = await apiProfileClient.get<OptimizerJobDTO[]>(`/optimizer/jobs`);

      return applicationResponse.processPayload((payload) => {
        return payload.map(OptimizerJobModel.fromDTO);
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const optimizationService = new OptimizationService();
