import { ProfileCreateArguments, ProfileModel } from '@/modules/users/models/ProfileModel';
import { TeamDTO } from '../api/teams.contracts';
import { AmazonAccount } from '@/modules/users/types/AmazonAccount';
import {
  ADLABS_PLAN_CAPABILITIES_MAP,
  FREE_PLAN_CAPABILITIES,
  MIN_UPDATE_INTERVAL_IN_MINUTES,
  PlanCapabilities,
  PlanType,
} from '../../plans/types/SubscriptionPlan';
import dayjs from 'dayjs';

export class TeamModel {
  public id: number;
  public name: string;
  public amazonAccount: AmazonAccount | undefined;
  public preferredUpdateTime: string;
  public preferredLastDone: string;
  public profiles: ProfileModel[];

  public stripeSubscriptionStatus: string;
  public stripeSubscriptionCancelAtPeriodEnd: boolean;
  public stripeSubscriptionCurrentPeriodEnd: string;
  public adlabsPlan: PlanType;
  public currentValidPlan: PlanType;
  public isCustomer: boolean = false;

  constructor(args: TeamCreateArguments) {
    this.id = args.id;
    this.name = args.name;
    this.amazonAccount = args.amazonAccount;
    this.preferredUpdateTime = args.preferredUpdateTime;
    this.preferredLastDone = args.preferredLastDone;
    this.profiles = args.profiles;

    // Billing related fields
    this.stripeSubscriptionStatus = args.stripeSubscriptionStatus;
    this.stripeSubscriptionCancelAtPeriodEnd = args.stripeSubscriptionCancelAtPeriodEnd;
    this.stripeSubscriptionCurrentPeriodEnd = args.stripeSubscriptionCurrentPeriodEnd;
    this.adlabsPlan = args.adlabsPlan;
    this.currentValidPlan = args.currentValidPlan;
    this.isCustomer = args.isCustomer;
  }

  get subscriptionPlan(): PlanCapabilities {
    return ADLABS_PLAN_CAPABILITIES_MAP[this.currentValidPlan] ?? FREE_PLAN_CAPABILITIES;
  }

  public canSyncProfile(lastReportCreatedAt: dayjs.Dayjs): boolean {
    const now = dayjs();
    const diffInMinutes = now.diff(lastReportCreatedAt, 'minutes');
    return diffInMinutes >= this.subscriptionPlan.updateIntervalLimitMinutes;
  }

  public isMinSyncTimePassed(lastReportCreatedAt: dayjs.Dayjs): boolean {
    const now = dayjs();
    const diffInMinutes = now.diff(lastReportCreatedAt, 'minutes');
    return diffInMinutes >= MIN_UPDATE_INTERVAL_IN_MINUTES;
  }

  public static fromDTO(dto: TeamDTO): TeamModel {
    return new TeamModel({
      id: dto.id,
      name: dto.name,
      amazonAccount:
        dto.amazon_account.id == 0
          ? undefined
          : {
              id: dto.amazon_account.id,
              isAuthorized: dto.amazon_account.is_authorized,
              owner: { id: dto.amazon_account.owner.id, name: dto.amazon_account.owner.name, email: dto.amazon_account.owner.email },
            },
      preferredUpdateTime: dto.preferred_update_time,
      preferredLastDone: dto.preferred_last_done,
      profiles: dto.profiles ? dto.profiles.map((profileDTO) => ProfileModel.fromDTO(profileDTO)) : [],

      // Billing related fields
      stripeSubscriptionStatus: dto.stripe_subscription_status,
      stripeSubscriptionCancelAtPeriodEnd: dto.stripe_subscription_cancel_at_period_end,
      stripeSubscriptionCurrentPeriodEnd: dto.stripe_subscription_current_period_end,
      adlabsPlan: dto.adlabs_plan as PlanType,
      currentValidPlan: dto.current_valid_plan as PlanType,
      isCustomer: dto.is_customer,
    });
  }
}

export interface TeamCreateArguments {
  id: number;
  name: string;
  amazonAccount: AmazonAccount | undefined;
  preferredUpdateTime: string;
  preferredLastDone: string;
  profiles: ProfileCreateArguments[];

  // Billing related fields
  stripeSubscriptionStatus: string;
  stripeSubscriptionCancelAtPeriodEnd: boolean;
  stripeSubscriptionCurrentPeriodEnd: string;
  adlabsPlan: PlanType;
  currentValidPlan: PlanType;
  isCustomer: boolean;
}
