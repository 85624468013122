import { DebugDataRow } from './DebugDataRow';
import { ResponseCampaignDebugRow } from '../api/optimization-contracts';

export class CampaignDebugRow {
  public campaignName: string;
  public placementMod: number;
  public statsTos: DebugDataRow;
  public statsPp: DebugDataRow;
  public statsRos: DebugDataRow;

  constructor(args: CampaignDebugRowCreateArguments) {
    this.campaignName = args.campaignName;
    this.placementMod = args.placementMod;
    this.statsTos = args.statsTos;
    this.statsPp = args.statsPp;
    this.statsRos = args.statsRos;
  }

  public static fromResponse(response: ResponseCampaignDebugRow): CampaignDebugRow {
    return new CampaignDebugRow({
      campaignName: response.campaign_name,
      placementMod: response.placement_mod,
      statsTos: DebugDataRow.fromResponseArray([response.stats_tos])[0],
      statsPp: DebugDataRow.fromResponseArray([response.stats_pp])[0],
      statsRos: DebugDataRow.fromResponseArray([response.stats_ros])[0],
    });
  }

  public static fromResponseArray(responseArray: ResponseCampaignDebugRow[]): CampaignDebugRow[] {
    return responseArray.map((response) => CampaignDebugRow.fromResponse(response));
  }
}

export interface CampaignDebugRowCreateArguments {
  campaignName: string;
  placementMod: number;
  statsTos: DebugDataRow;
  statsPp: DebugDataRow;
  statsRos: DebugDataRow;
}
