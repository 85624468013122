import React from 'react';
import ReactDOM from 'react-dom/client';

// CSS Imports

// Font Roboto
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/600.css'; // Doesn't have 600!
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// AG Grid CSS
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-alpine.css'; // Optional theme CSS
import './index.css';

// Libs (Internationalization, Axios, etc..)
import { initLibs } from './lib';
initLibs();

// Router comes after libs because it uses the libs (mainly Sentry)
// Sentry is before router because it wraps the router
import { RouterProvider } from 'react-router-dom';
import { router } from './router/router';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
