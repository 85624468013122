import { TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { FunctionComponent, useState } from 'react';
import { useActiveTeamContext } from '../contexts/ActiveTeamContext';
import { toast } from 'react-toastify';
import { teamService } from '../api/teams.service';
import Tooltip from '@mui/material/Tooltip';
import { PaywallModal } from '../../plans/components/PaywallModal';
import { Button, TextField } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';

const SYNC_DATE_FORMAT = 'ddd, MMM D, YYYY HH:mm';

interface PreferredTimePickerProps {}

export const PreferredTimePicker: FunctionComponent<PreferredTimePickerProps> = () => {
  const { activeTeam } = useActiveTeamContext();
  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);

  const canScheduleUpdateTime = !isNil(activeTeam) && activeTeam.subscriptionPlan.canScheduleUpdateTime;

  const setPreferredTimeToUpdate = async (value: dayjs.Dayjs | null) => {
    if (!isNil(value)) {
      try {
        const res = await teamService.updatePreferredUpdateTime(value.toISOString());
        if (res.isSuccess) {
          let nextSync = value.add(1, 'day'); // Default next day
          const lastDone = dayjs(activeTeam?.preferredLastDone);
          const diffInSeconds = value.diff(lastDone, 'second');

          // Update was made 9:00 UTC
          // User changes preferred update time to 10:00 UTC
          if (diffInSeconds > 0 && diffInSeconds < 43200) {
            // 12 h = 43200 sec.
            toast.warning(
              `Preferred update time updated to ${value.format('HH:mm')}. Due to frequent modifications, next sync will be ${nextSync.format(
                SYNC_DATE_FORMAT,
              )}`,
            );
          } else {
            const today = dayjs();
            if (!value.isBefore(today)) {
              nextSync = value;
            }
            toast.success(
              `Team daily auto sync with Amazon set to ${value.format('HH:mm')}. Next sync will be ${nextSync.format(SYNC_DATE_FORMAT)}`,
            );
          }
        } else {
          toast.error('Cannot update preferred time to update');
        }
      } catch (error) {
        console.log(error);
        toast.error('Cannot update preferred time to update');
      }
    }
  };

  const [isAllowingTimeChange, setIsAllowingTimeChange] = useState<boolean>(false);
  const onSetTimeButtonClicked = () => {
    if (!canScheduleUpdateTime) {
      setIsTimePickerOpen(false);
      setIsPaywallModalOpen(true);
      return;
    }

    setIsTimePickerOpen(true);
    setIsAllowingTimeChange(true);
  };

  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  return (
    <>
      <PaywallModal isOpen={isPaywallModalOpen} onClose={onClosePaywallModal}>
        {`Upgrade your plan to schedule daily reports`}
      </PaywallModal>
      {activeTeam && (
        <Tooltip
          title={
            !isTimePickerOpen ? 'Set your reports to update ~30min before your day starts to ensure you have access to the latest data.' : ''
          }
        >
          <div className="flex items-center">
            {(canScheduleUpdateTime && !isNil(activeTeam?.preferredUpdateTime)) ||
            (isAllowingTimeChange && isNil(activeTeam?.preferredUpdateTime)) ? (
              <TimePicker
                defaultValue={!isNil(activeTeam?.preferredUpdateTime) ? dayjs(activeTeam?.preferredUpdateTime) : undefined}
                open={isTimePickerOpen}
                onClose={() => setIsTimePickerOpen(false)}
                onOpen={() => setIsTimePickerOpen(true)}
                onAccept={(newValue) => setPreferredTimeToUpdate(newValue)}
                label={'Daily Amazon Sync (' + Intl.DateTimeFormat().resolvedOptions().timeZone + ')'}
                format="HH:mm"
                ampm={false}
                ampmInClock={false}
                slots={{ textField: TextField }}
                slotProps={{
                  textField: {
                    onClick: () => {
                      setIsTimePickerOpen(true);
                    },
                  },
                }}
              />
            ) : (
              <Button startIcon={<ScheduleIcon />} variant="contained" color="primary" size="medium" onClick={onSetTimeButtonClicked}>
                Set Daily Amazon Sync Time
              </Button>
            )}
          </div>
        </Tooltip>
      )}
    </>
  );
};
