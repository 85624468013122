import { FunctionComponent } from 'react';
import { RegistrationWizardStep } from './RegistrationWizardStep';
import { WizardStepState } from '../types/wizard_step_state.enum';
import { useLocation } from 'react-router-dom';

interface RegistrationWizardStepsProps {}

const RegistrationWizardSteps: FunctionComponent<RegistrationWizardStepsProps> = () => {
  const currentPath = useLocation().pathname;

  const steps = [
    {
      state: WizardStepState.NotStarted,
      title: 'Create your account',
      description: 'Supply your credentials',
      path: '/register/create-your-account',
    },
    {
      state: WizardStepState.NotStarted,
      title: 'Authorize with Amazon Ads',
      description: 'To access your Amazon Ads Profiles',
      path: '/register/authorize-amazon',
    },
    {
      state: WizardStepState.NotStarted,
      title: 'Select your Profiles',
      description: 'Select the Profiles you want to manage with AdLabs.',
      path: '/register/select-profiles',
    },
    {
      state: WizardStepState.NotStarted,
      isLastStep: true,
      title: 'Start Optimizing',
      description: 'Fine tune your Bids like a Pro!',
      path: '/register/start-optimizing',
    },
  ];

  steps.forEach((step, i) => {
    if (step.path === currentPath) {
      step.state = WizardStepState.InProgress;
      // Set all previous steps to completed
      for (let j = 0; j < i; j++) {
        steps[j].state = WizardStepState.Completed;
      }
      // Set all next steps to not started
      for (let j = i + 1; j < steps.length; j++) {
        steps[j].state = WizardStepState.NotStarted;
      }
    }
  });

  return (
    <div className="flex flex-col items-start">
      <div className="grid w-full grid-cols-[min-content_1fr]">
        {steps.map((step, index) => (
          <RegistrationWizardStep
            key={index}
            state={step.state}
            title={step.title}
            description={step.description}
            isLastStep={step.isLastStep}
          />
        ))}
        {/* <RegistrationWizardStep state={WizardStepState.InProgress} title="Create your account" description="Supply your credentials" />
        <RegistrationWizardStep
          state={WizardStepState.NotStarted}
          title="Authorize with Amazon Ads"
          description="For AdLabs to access your Profiles, we require you to authenticate with Amazon Ads."
        />
        <RegistrationWizardStep
          state={WizardStepState.NotStarted}
          title="Select your Profiles"
          description="Select the Profiles you want to manage with AdLabs."
        />
        <RegistrationWizardStep
          state={WizardStepState.NotStarted}
          isLastStep
          title="Start Optimizing"
          description="Finetune your Bids like a Pro!"
        /> */}
      </div>
    </div>
  );
};

export default RegistrationWizardSteps;
