import { Theme } from '@emotion/react';
import { ThemeOptions, createTheme } from '@mui/material';
import { slate, white, blue, green, red, sky, orange, yellow, lime, pink, violet, amber } from 'tailwindcss/colors';

declare module '@mui/material/styles' {
  interface Palette {
    blue: Palette['primary'];
    orange: Palette['primary'];
    emerald: Palette['primary'];
    slate: Palette['primary'];
    violet: Palette['primary'];
    pink: Palette['primary'];
    lime: Palette['primary'];
    amber: Palette['primary'];
    sky: Palette['primary'];
  }

  interface PaletteOptions {
    blue: PaletteOptions['primary'];
    orange: PaletteOptions['primary'];
    emerald: PaletteOptions['primary'];
    slate: PaletteOptions['primary'];
    violet: PaletteOptions['primary'];
    pink: PaletteOptions['primary'];
    lime: PaletteOptions['primary'];
    amber: PaletteOptions['primary'];
    sky: PaletteOptions['primary'];
  }
}
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    blue: true;
    orange: true;
    emerald: true;
    slate: true;
    violet: true;
    pink: true;
    lime: true;
    amber: true;
    sky: true;
  }
}

const baseThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: blue[600],
      contrastText: '#fff',
    },
    secondary: {
      main: blue[950],
    },
    background: {
      default: slate[50],
    },
    success: {
      main: green[600],
    },
    warning: {
      main: orange[600],
    },
    info: {
      main: sky[600],
    },
    error: {
      main: red[600],
    },
    blue: {
      main: blue[600],
      light: blue[100],
      dark: blue[800],
      contrastText: blue[950],
    },
    orange: {
      main: orange[600],
      light: orange[100],
      dark: orange[800],
      contrastText: orange[950],
    },
    emerald: {
      main: green[600],
      light: green[100],
      dark: green[800],
      contrastText: green[950],
    },
    slate: {
      main: slate[600],
      light: slate[100],
      dark: slate[800],
      contrastText: slate[950],
    },
    violet: {
      main: violet[600],
      light: violet[100],
      dark: violet[800],
      contrastText: violet[950],
    },
    pink: {
      main: pink[600],
      light: pink[100],
      dark: pink[800],
      contrastText: pink[950],
    },
    lime: {
      main: lime[600],
      light: lime[100],
      dark: lime[800],
      contrastText: lime[950],
    },
    amber: {
      main: amber[600],
      light: amber[100],
      dark: amber[800],
      contrastText: amber[950],
    },
    sky: {
      main: sky[600],
      light: sky[100],
      dark: sky[800],
      contrastText: sky[950],
    },
  },

  components: {
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          // Default chip
          // backgroundColor: gray[300],
          // color: gray[900],
          '&.MuiChip-outlined': {
            // Filled chip variations
            // backgroundColor: transparent,
            // color: white,
            // '&:hover, &:focus': {
            //   backgroundColor: blue[600],
            // },
            '&.MuiChip-colorPrimary': {
              backgroundColor: blue[50],
              borderColor: blue[800],
              color: blue[800],
            },
            '&.MuiChip-colorSecondary': {
              backgroundColor: slate[50],
              borderColor: slate[800],
              color: slate[800],
            },
            '&.MuiChip-colorError': {
              backgroundColor: red[50],
              borderColor: red[800],
              color: red[800],
            },
            '&.MuiChip-colorInfo': {
              backgroundColor: sky[50],
              borderColor: sky[800],
              color: sky[800],
            },
            '&.MuiChip-colorSuccess': {
              backgroundColor: green[50],
              borderColor: green[800],
              color: green[800],
            },
            '&.MuiChip-colorWarning': {
              backgroundColor: yellow[50],
              borderColor: yellow[800],
              color: yellow[800],
            },
            '&.MuiChip-colorAmber': {
              backgroundColor: yellow[50],
              borderColor: yellow[800],
              color: yellow[800],
            },
            '&.MuiChip-colorBlue': {
              backgroundColor: blue[50],
              borderColor: blue[800],
              color: blue[800],
            },
            '&.MuiChip-colorEmerald': {
              backgroundColor: green[50],
              borderColor: green[800],
              color: green[800],
            },
            '&.MuiChip-colorLime': {
              backgroundColor: lime[50],
              borderColor: lime[800],
              color: lime[800],
            },
            '&.MuiChip-colorOrange': {
              backgroundColor: orange[50],
              borderColor: orange[500],
              color: orange[500],
            },
            '&.MuiChip-colorPink': {
              backgroundColor: pink[50],
              borderColor: pink[800],
              color: pink[800],
            },
            '&.MuiChip-colorSlate': {
              backgroundColor: slate[50],
              borderColor: slate[800],
              color: slate[800],
            },
            '&.MuiChip-colorViolet': {
              backgroundColor: violet[50],
              borderColor: violet[800],
              color: violet[800],
            },
            '&.MuiChip-colorSky': {
              backgroundColor: sky[50],
              borderColor: sky[800],
              color: sky[800],
            },
          },
          // '&$outlined': {
          //   // Outlined chip variations
          //   borderColor: red[500],
          //   color: red[500],
          //   '&:hover, &:focus': {
          //     borderColor: red[600],
          //     color: red[600],
          //   },
          //   '&.MuiChip-colorPrimary': {
          //     borderColor: blue[500],
          //     color: blue[500],
          //     '&:hover, &:focus': {
          //       borderColor: blue[600],
          //       color: blue[600],
          //     },
          //   },
          //   '&.MuiChip-colorSecondary': {
          //     borderColor: green[500],
          //     color: green[500],
          //     '&:hover, &:focus': {
          //       borderColor: green[600],
          //       color: green[600],
          //     },
          //   },
          //   '&.MuiChip-colorError': {
          //     borderColor: red[500],
          //     color: red[500],
          //     '&:hover, &:focus': {
          //       borderColor: red[600],
          //       color: red[600],
          //     },
          //   },
          //   '&.MuiChip-colorInfo': {
          //     borderColor: sky[500],
          //     color: sky[500],
          //     '&:hover, &:focus': {
          //       borderColor: sky[600],
          //       color: sky[600],
          //     },
          //   },
          //   '&.MuiChip-colorSuccess': {
          //     borderColor: green[500],
          //     color: green[500],
          //     '&:hover, &:focus': {
          //       borderColor: green[600],
          //       color: green[600],
          //     },
          //   },
          //   '&.MuiChip-colorWarning': {
          //     borderColor: yellow[500],
          //     color: yellow[500],
          //     '&:hover, &:focus': {
          //       borderColor: yellow[600],
          //       color: yellow[600],
          //     },
          //   },
          // },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        disableElevation: true,
        variant: 'contained',
      },
      // give medium button 40px height
      styleOverrides: {
        root: {
          '&.MuiButton-sizeMedium': {
            height: 40,
          },
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },

    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: white,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled:hover, &.Mui-disabled:hover *': {
            cursor: 'not-allowed',
          },
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            backgroundColor: slate[200],
          },
          '&.Mui-focused': {
            backgroundColor: slate[50], // Change this to your desired color
          },
          backgroundColor: white,
        },
      },
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: blue[700], // Change this to your desired color
          },
        },
      },
      defaultProps: {
        margin: 'dense',
        shrink: true,
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
    },

    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
        variant: 'outlined',
        InputLabelProps: {
          shrink: true,
        },
      },
    },

    MuiSelect: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
        notched: true,
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
    },
  },

  shape: {
    borderRadius: 6,
  },

  typography: {
    fontFamily: 'Roboto',
    button: {
      textTransform: 'none',
      fontFamily: 'Roboto',
    },
  },
  spacing: 1,
};

export const BASE_THEME: Theme = createTheme(baseThemeOptions);
