import { TextField } from '@mui/material';
import { ICellEditorParams } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { useState, useEffect, useRef, useImperativeHandle, ForwardedRef, forwardRef, ChangeEvent, memo } from 'react';

export interface FormattedCellEditorProps extends ICellEditorParams {
  value: number;
  displayNumberModifierFunc?: (value: number) => number;
  storingNumberModifierFunc?: (value: number) => number;
  stopEditing: () => void;
}

const FormattedCellEditor = forwardRef((props: FormattedCellEditorProps, ref: ForwardedRef<unknown>) => {
  const originalValue = !isNil(props.value) ? props.value : 0;
  const [value, setValue] = useState<number | string>(!isNil(props.value) ? props.value : 0);
  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    refInput.current?.focus();
  }, []);

  const isValidNumber = (value: string | number) => {
    return !isNil(value) && value !== '';
  };

  const displayValue =
    props.displayNumberModifierFunc && value == originalValue && isValidNumber(value)
      ? props.displayNumberModifierFunc(Number(value))
      : value;

  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return props.storingNumberModifierFunc && isValidNumber(value) ? props.storingNumberModifierFunc(Number(value)) : value;
      },
      isCancelBeforeStart() {
        return false;
      },
    };
  });

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value;
    setValue(isValidNumber(eventValue) ? Number(eventValue) : '');
  };

  return (
    <TextField
      variant="standard"
      type="number"
      className="text-right"
      fullWidth={true}
      inputProps={{ style: { textAlign: 'right' } }}
      ref={refInput}
      value={displayValue}
      onChange={handleOnChange}
    />
  );
});

FormattedCellEditor.displayName = 'FormattedCellEditor';

export default memo(FormattedCellEditor);
