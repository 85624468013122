import { FunctionComponent, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { RegistrationProvider } from '../contexts/registration/RegistrationContext';
import { useRegistration } from '../contexts/registration/useRegistration';
import { AdLabsLogo } from '@/modules/application';
import RegistrationWizardSteps from '../components/RegistrationWizardSteps';
import { useUserContext } from '@/modules/users';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { isNil } from 'lodash-es';

export const RegistrationLayout: FunctionComponent = () => {
  const { user } = useUserContext();
  const { activeTeam } = useActiveTeamContext();
  const location = useLocation();
  const registration = useRegistration();
  const navigate = useNavigate();

  useEffect(() => {
    if (activeTeam) {
      if (isNil(activeTeam.amazonAccount) || !activeTeam.amazonAccount.isAuthorized) {
        registration.setTeamId(activeTeam.id);
        navigate('/register/authorize-amazon');
        return; // keep return
      } else {
        navigate('app/profiles');
        return;
      }
    }

    // If user is logged in
    if (user) {
      // If user has teams
      if (user.teams.length > 0) {
        // Find team with at least one profile
        const teamWithAtLeastOneProfile = user.teams.find((t) => t.profiles.length > 0);

        // If user has a team with at least one profile, navigate to app
        if (teamWithAtLeastOneProfile) {
          console.log('teamWithAtLeastOneProfile', teamWithAtLeastOneProfile.amazonAccount);
          if (isNil(teamWithAtLeastOneProfile.amazonAccount) || !teamWithAtLeastOneProfile.amazonAccount.isAuthorized === false) {
            navigate('/register/authorize-amazon');
            return;
          } else {
            navigate('/app');
            return;
          }
        }

        // If user has a team with no profiles, set team id and
        // navigate to select profiles
        registration.setTeamId(user.teams[0].id);
        navigate(location.pathname + location.search);
      } else {
        // If user has no teams, navigate to authorize amazon as well
        navigate('/register/create-your-account');
        return;
      }
    }

    // If there is no user, don't navigate anywhere
    registration.loadStateFromStorage();
  }, [activeTeam]);

  return (
    <RegistrationProvider registration={registration}>
      <div className="flex h-full min-h-screen w-full">
        <div className="flex min-h-screen w-1/3 justify-end bg-slate-800 px-10">
          <div className="mt-10 flex w-96 flex-col  ">
            <div className="mb-16">
              <AdLabsLogo forceMode="dark" />
            </div>
            <div>
              <RegistrationWizardSteps />
            </div>
          </div>
        </div>
        <Outlet />
      </div>
    </RegistrationProvider>
  );
};
