import { apiProfileClient, apiTeamClient } from '@/lib/api/base-client';
import { UserSettingKey, useUserContext, useUserSettingsContext } from '@/modules/users';
import { ProfileModel } from '@/modules/users/models/ProfileModel';
import { TeamModel } from '../types/TeamModel';
import { isNil, isNumber } from 'lodash-es';
import { createContext, useState, useContext, PropsWithChildren, useEffect, FunctionComponent } from 'react';
import { CAMPAIGN_GROUPS_QUERY_KEY } from '@/modules/optimizer/api/campaign/campaign-service';

interface ActiveProfileIdChange {
  previousProfileId: number | undefined;
  newProfileId: number | undefined;
  hasChanged: boolean;
}
interface ActiveTeamContextType {
  setActiveTeam: (args: {
    teamId: number | undefined;
    profileId?: number; // On initial load profileId is also provided
    userTeams?: TeamModel[];
  }) => void;
  activeTeam: TeamModel | undefined;
  setActiveProfile: (profileId: number) => void;
  activeProfile: ProfileModel | undefined;
  removeProfileFromTeam: (profileId: number) => void;
  clearActiveProfileAndTeam: () => void;
  activeProfileIdChange?: ActiveProfileIdChange;
  CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE: (string | number | undefined)[];
}

// Default values
const ActiveTeamContext = createContext<ActiveTeamContextType>({
  setActiveTeam: () => null,
  activeTeam: undefined,
  setActiveProfile: () => null,
  activeProfile: undefined,
  removeProfileFromTeam: () => null,
  clearActiveProfileAndTeam: () => null,
  activeProfileIdChange: undefined,
  CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE: [CAMPAIGN_GROUPS_QUERY_KEY, 0],
});

interface ActiveTeamProviderProps extends PropsWithChildren {
  initialActiveTeamId: number | undefined;
  initialActiveProfileId: number | undefined;
}

export const ActiveTeamProvider: FunctionComponent<ActiveTeamProviderProps> = ({ children, initialActiveTeamId, initialActiveProfileId }) => {
  const { user } = useUserContext();
  const { upsertUserSetting } = useUserSettingsContext();

  const [activeTeamId, setActiveTeamId] = useState<number | undefined>(initialActiveTeamId);
  const [activeProfileId, setActiveProfileId] = useState<number | undefined>(initialActiveProfileId);

  const activeTeam = user?.teams.find((team) => team.id === activeTeamId);
  const activeProfile = activeTeam?.profiles.find((profile) => profile.id === activeProfileId);

  const [activeProfileIdChange, setActiveProfileIdChange] = useState<ActiveProfileIdChange>();

  // Initial load on mount
  useEffect(() => {
    setActiveTeam({ teamId: initialActiveTeamId, profileId: initialActiveProfileId });
  }, []);

  //When user makes changes to active team. For example adds profiles
  useEffect(() => {
    if (isNil(activeProfileId) && activeTeam && activeTeam.profiles.length > 0) {
      setActiveProfile(activeTeam.profiles[0].id);
    }
  }, [activeTeam?.profiles]);

  // teams is provided to fight against teams race condition
  function setActiveTeam({
    teamId,
    profileId,
    userTeams: providedUserTeams,
  }: {
    teamId: number | undefined;
    profileId?: number; // On initial load profileId is also provided
    userTeams?: TeamModel[];
  }) {
    let userTeams = user ? user.teams : [];
    if (providedUserTeams) {
      userTeams = providedUserTeams;
    }

    if ((!isNumber(teamId) || teamId == 0) && userTeams && userTeams.length > 0) {
      teamId = userTeams[0].id; // Load user's first team
    }

    if (isNil(teamId)) {
      console.log('Active team was not set. TeamId is null');
      return;
    }

    apiProfileClient.setTeamId(teamId);
    apiTeamClient.setTeamId(teamId);

    const team = userTeams.find((team) => team.id === teamId);
    if (isNil(team)) {
      console.log('Active team was not set. Team not found');
      return;
    }

    setActiveTeamId(teamId);
    setActiveTeamInUserSettings(teamId);
    console.log('Active team was set to: ', teamId);

    if (profileId) {
      setActiveProfile(profileId);
    } else if (team && team.profiles.length > 0) {
      // If user changes a team then select first profile from the team profiles
      setActiveProfile(team.profiles[0].id);
    } else {
      clearActiveProfile();
    }
  }

  function clearActiveProfile() {
    setActiveProfileInUserSettings(0);
    setActiveProfileId(undefined);
    apiProfileClient.setProfileId(0);
  }

  function setActiveProfile(profileId: number) {
    apiProfileClient.setProfileId(profileId);

    setActiveProfileId(profileId);
    setActiveProfileInUserSettings(profileId);

    if (activeProfileIdChange) {
      setActiveProfileIdChange({
        previousProfileId: activeProfileIdChange.newProfileId,
        newProfileId: profileId,
        hasChanged: activeProfileIdChange.newProfileId !== profileId,
      });
    } else {
      setActiveProfileIdChange({
        previousProfileId: undefined,
        newProfileId: profileId,
        hasChanged: true,
      });
    }

    console.log('Active profile was set to: ', profileId);
  }

  function setActiveTeamInUserSettings(teamId: number) {
    upsertUserSetting(UserSettingKey.ACTIVE_TEAM_ID, teamId);
  }

  function setActiveProfileInUserSettings(profileId: number) {
    upsertUserSetting(UserSettingKey.ACTIVE_PROFILE_ID, profileId);
  }

  function removeProfileFromTeam(profileId: number) {
    const profiles = activeTeam?.profiles.filter((profile) => profile.id !== profileId);
    if (activeTeam && profiles) {
      activeTeam.profiles = profiles;
    }

    if (activeTeam && activeTeam.profiles.length > 0) {
      setActiveProfile(activeTeam.profiles[0].id);
    } else {
      clearActiveProfile();
    }
  }

  function clearActiveProfileAndTeam() {
    setActiveTeamId(undefined);
    setActiveProfileId(undefined);
  }

  const CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE = [CAMPAIGN_GROUPS_QUERY_KEY, activeProfile?.id];
  return (
    <ActiveTeamContext.Provider
      value={{
        setActiveTeam,
        activeTeam,
        setActiveProfile,
        activeProfile,
        removeProfileFromTeam,
        clearActiveProfileAndTeam,
        activeProfileIdChange,
        CAMPAIGN_GROUPS_QUERY_KEY_WITH_ACTIVE_PROFILE,
      }}
    >
      {children}
    </ActiveTeamContext.Provider>
  );
};

// Instead of doing this useContext(ActiveTeamContext);
// We can create a custom hook named useActiveTeamContext
export function useActiveTeamContext(): ActiveTeamContextType {
  const context = useContext(ActiveTeamContext);
  if (!context) {
    throw new Error('useActiveTeamContext must be used within a ActiveTeamProvider');
  }
  return context;
}
