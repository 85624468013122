import { useState } from 'react';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';

export const useDeleteConfirmation = (questionText: string, headerText: string, onDelete: () => void) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDelete = () => {
    onDelete();
    setIsModalOpen(false);
  };

  const ModalComponent = (
    <DeleteConfirmationModal
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      onConfirmDelete={handleConfirmDelete}
      questionText={questionText}
      headerText={headerText}
    />
  );

  return { ModalComponent, handleOpenModal };
};
