import React, { createContext, useState, FunctionComponent, PropsWithChildren } from 'react';
import { SettingModel } from '../types/SettingModel';
import { settingService } from '../api/settings/setting.service';
import { SettingKey, SettingType, SettingValue } from '../api/settings/setting.contracts';
import { ApiResponse } from '@/lib/api/api-response';

interface SettingsContextProps {
  settings: SettingModel[];
  setSettings: React.Dispatch<React.SetStateAction<SettingModel[]>>;
  loadSettings: () => Promise<ApiResponse<SettingModel[]>>;
  getSettingByKey: (key: SettingKey) => SettingModel | undefined;
  getSettingValueByKey: (key: SettingKey) => SettingValue | undefined;
  clear: () => void;
}

export const SettingsContext = createContext<SettingsContextProps>({
  settings: [],
  setSettings: () => null,
  loadSettings: () => new Promise(() => null),
  getSettingByKey: () => undefined,
  getSettingValueByKey: () => undefined,
  clear: () => null,
});

interface SettingsProviderProps extends PropsWithChildren {
  initialSettings: SettingModel[];
}

export const SettingsProvider: FunctionComponent<SettingsProviderProps> = ({ children, initialSettings }) => {
  const [settings, setSettings] = useState<SettingModel[]>(initialSettings);

  async function loadSettings(): Promise<ApiResponse<SettingModel[]>> {
    // Mock settings for now
    return ApiResponse.responseWithPayload(
      [new SettingModel({ createdAt: new Date(), id: 1, key: SettingKey.TEST_SETTING, type: SettingType.STRING, value: 'something' })],
      200,
    );

    const settings = await settingService.getAll();
    if (settings.isSuccess) {
      setSettings(settings.payload);
      return settings;
    }
  }

  function getSettingByKey(key: SettingKey) {
    return settings.find((setting) => setting.key === key);
  }

  function getSettingValueByKey(key: SettingKey) {
    return settings.find((setting) => setting.key === key)?.value;
  }

  function clear() {
    setSettings([]);
  }

  return (
    <SettingsContext.Provider value={{ settings, setSettings, loadSettings, getSettingByKey, getSettingValueByKey, clear }}>
      {children}
    </SettingsContext.Provider>
  );
};
