import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { slate, blue, green, red, sky, orange, lime, pink, violet, amber } from 'tailwindcss/colors';

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      default: slate[900], //"#232333",
      paper: '#162032', // Custom slate 850
    },
    primary: {
      main: blue[600], //"#ea580c",
    },
    secondary: {
      main: slate[400],
    },
    success: {
      main: green[600],
    },
    warning: {
      main: orange[600],
    },
    info: {
      main: sky[600],
    },
    error: {
      main: red[600],
    },
    blue: {
      main: blue[600],
      light: blue[100],
      dark: blue[800],
      contrastText: blue[950],
    },
    orange: {
      main: orange[600],
      light: orange[100],
      dark: orange[800],
      contrastText: orange[950],
    },
    emerald: {
      main: green[600],
      light: green[100],
      dark: green[800],
      contrastText: green[950],
    },
    slate: {
      main: slate[600],
      light: slate[100],
      dark: slate[800],
      contrastText: slate[950],
    },
    violet: {
      main: violet[600],
      light: violet[100],
      dark: violet[800],
      contrastText: violet[950],
    },
    pink: {
      main: pink[600],
      light: pink[100],
      dark: pink[800],
      contrastText: pink[950],
    },
    lime: {
      main: lime[600],
      light: lime[100],
      dark: lime[800],
      contrastText: lime[950],
    },
    amber: {
      main: amber[600],
      light: amber[100],
      dark: amber[800],
      contrastText: amber[950],
    },
    sky: {
      main: sky[600],
      light: sky[100],
      dark: sky[800],
      contrastText: sky[950],
    },
  },

  components: {
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        disableElevation: true,
        variant: 'contained',
      },
      // give medium button 40px height
      styleOverrides: {
        root: {
          '&.MuiButton-sizeMedium': {
            height: 40,
          },
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
        shrink: true,
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
        variant: 'outlined',
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
        notched: true,
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
    },
  },

  shape: {
    borderRadius: 6,
  },

  typography: {
    fontFamily: 'Roboto',
    button: {
      textTransform: 'none',
      fontFamily: 'Roboto',
    },
  },
  spacing: 1,
};

export const DARK_THEME: Theme = createTheme(darkThemeOptions);
