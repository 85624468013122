/* eslint-disable react/prop-types */
import AlGrid, { DEFAULT_GRID_OPTIONS } from '@/components/grid/AlGrid';
import { GridOptions } from 'ag-grid-enterprise';
import { OptimizationApplyData } from './OptimizerConfig';
import { BiddingEntity, OptimizationModel, isCurrencyEntity, isPercentEntity } from './models/OptimizationModel';
import EditableCellRenderer, { IEditiableCellRendererParams } from '@/components/grid/cells/EditableCellRenderer';
import { ColDefOrGroup } from '@/lib/ag-grid/types';
import {
  CellClassParams,
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  ICellEditorParams,
  ICellRendererParams,
  IRowNode,
  SelectionChangedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import ChangePercentageCellRenderer from '@/components/grid/cells/ChangePercentageCellRenderer';
import useFormatting from '@/hooks/useFormatting';
import { ChipCellRenderer, IChipCellRendererParams } from '@/components/grid/cells/ChipCellRenderer';
import { SentimentColors, SentimentDirection } from '@/types/colors.enum';
import FormattedCellEditor, { FormattedCellEditorProps } from '@/components/grid/cells/FormattedCellEditor';
import ButtonPopoverCellRenderer from '@/components/grid/cells/ButtonPopoverCellRenderer';
import { useTranslation } from '@/lib';
import { FilterModel } from '../filter-builder/models/FilterModel';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { MetricField, isValidMetricField } from '../metrics/models/CommonMetricsModel';
import { ReducerType, getMetricReducer, getSentimentByMetric } from '../metrics/MetricsConfig';
import ChangePercentageHeaderRenderer from '@/components/grid/cells/ChangePercentageHeaderRenderer';
import { PreviewDataRow } from './models/PreviewDataRow';
import { AdGroupDebugRow } from './models/AdGroupDebugRow';
import { CampaignDebugRow } from './models/CampaignDebugRow';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ColumnId } from '@/components/grid/columns/columns.enum';
import ChipArrayCellRenderer, { IChipArrayCellRendererParams } from '@/components/grid/cells/ChipArrayCellRenderer';
import { OptimizationReason } from './api/optimization-contracts';
import { isNil } from 'lodash-es';
import { AdLabsColorVariant, MuiColorVariant, TailwindColorVariant } from '@/config/theme/color.type';
import { UserSettingKey, useUserContext } from '@/modules/users';
import { useGridColumnState } from '@/hooks/useGridColumnState';

interface OptimizationTableProps {
  optimizationResults: OptimizationModel;
  setOptimizationPreviewSelection: (optimizationPreviewSelection: OptimizationApplyData[]) => void;
  externalFilters: FilterModel[];
  setSelectionContainsRecentlyOptimized: (selectionContainsRecentlyOptimized: boolean) => void;
  setVisibleRowCount: (visibleRowCount: number) => void;
}

const OptimizationTable: FunctionComponent<OptimizationTableProps> = ({
  optimizationResults,
  setOptimizationPreviewSelection,
  externalFilters,
  setSelectionContainsRecentlyOptimized,
  setVisibleRowCount,
}) => {
  const { setColumnAPI, handleColumnStateChange, applyStateToDefinitions, setIsAutoSaveEnabled } = useGridColumnState(
    UserSettingKey.OPTIMIZATION_TABLE_COLUMN_STATE,
  );

  const { isAdminModeActive } = useUserContext();
  const { t } = useTranslation();
  const { formatDateStringTimeNoSeconds } = useFormatting();

  const [gridApi, setGridApi] = useState<GridApi<PreviewDataRow>>();
  const greyCellClass = 'bg-slate-400 bg-opacity-10';
  const numericGreyCellClass = 'bg-slate-400 bg-opacity-10 text-right';

  const {
    formatWithThousandsSeparator,
    formatCurrency,
    formatPercentWithArrow,
    getShortFormatterForMetricField,
    getLongFormatterForMetricField,
    formatWholePercent,
  } = useFormatting();

  const getCellRendererParamsThousandsSeparatorSynced = useCallback(
    (field: keyof PreviewDataRow) => (params: ICellRendererParams<PreviewDataRow>) => ({
      currentValueFormatter: formatWithThousandsSeparator,
      sentimentDirection: SentimentDirection.SYNCED,
      value: params.data ? params.data[field] : null,
    }),
    [formatWithThousandsSeparator],
  );

  const formatBasedOnBiddingEntity = (params: ValueFormatterParams<PreviewDataRow, number>): string => {
    const biddingEntity = params.data?.biddingEntity;

    // isNil is used because 0 is a valid value for placement
    if (isNil(params.value)) return '';

    if (!biddingEntity) return params.value.toString();

    if (isCurrencyEntity(biddingEntity)) {
      return formatCurrency(params.value);
    }

    if (isPercentEntity(biddingEntity)) {
      return formatWholePercent(params.value);
    }

    return params.value ? params.value.toString() : '';
  };

  const getFormatterBasedOnBiddingEntity = (params: ICellRendererParams<PreviewDataRow>) => {
    const biddingEntity = params.data?.biddingEntity;
    if (!biddingEntity) return (value: number) => value;

    return isPercentEntity(biddingEntity) ? formatWholePercent : formatCurrency;
  };

  const getDisplayNumberModifierFuncBasedOnBiddingEntity = (params: ICellEditorParams<PreviewDataRow>) => {
    const biddingEntity = params.data.biddingEntity;
    if (!biddingEntity) return (value: number) => value;

    return isPercentEntity(biddingEntity) ? (value: number) => value * 100 : (value: number) => value;
  };

  const getStoringNumberModifierFuncBasedOnBiddingEntity = (params: ICellEditorParams<PreviewDataRow>) => {
    const biddingEntity = params.data.biddingEntity;
    if (!biddingEntity) return (value: number) => value;

    return isPercentEntity(biddingEntity) ? (value: number) => value / 100 : (value: number) => value;
  };

  const getColumnAggregates = (gridApi: GridApi<PreviewDataRow>): MetricAggregates => {
    // Initialize aggregatedValues and countValues
    const aggregatedValues: { [key in MetricField]: number } = {} as MetricAggregates;
    const countValues: { [key in MetricField]: number } = {} as MetricAggregates;

    const metricFields = Object.values(MetricField);

    const rowNodes = gridApi.getRenderedNodes();

    // Initialize aggregatedValues and countValues to 0 for all fields
    for (const field of metricFields) {
      aggregatedValues[field] = 0;
      countValues[field] = 0;
    }

    for (let i = 0, len = rowNodes.length; i < len; i++) {
      const rowData = rowNodes[i].data;

      if (rowData) {
        for (const field of metricFields) {
          const value = rowData[field].current;
          if (typeof value === 'number') {
            if (aggregatedValues[field] != null && countValues[field] != null) {
              aggregatedValues[field] += value;
              countValues[field]++;
            }
          }
        }
      }
    }

    // Avgs need to be divided by count
    for (const field of metricFields) {
      const reducerType = getMetricReducer(field);
      if (reducerType === ReducerType.AVG && countValues[field] > 0) {
        aggregatedValues[field] /= countValues[field];
      }
    }

    return aggregatedValues;
  };

  const columnDefs: ColDefOrGroup<PreviewDataRow>[] = useMemo(() => {
    const colDefsToReturn: ColDefOrGroup<PreviewDataRow>[] = [
      {
        colId: ColumnId.CHECKBOX,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        minWidth: 50,
        maxWidth: 50,
        suppressColumnsToolPanel: true,
        cellClass: greyCellClass,
        pinned: 'left',
      },
      {
        colId: ColumnId.CAMPAIGN_NAME,
        headerName: 'Campaign',
        field: 'campaignName',
        filter: 'agNumberColumnFilter',
        cellClass: greyCellClass,
        tooltipField: 'campaignName',
        minWidth: 200,
        pinned: 'left',
      },
      {
        colId: ColumnId.GROUP_NAME,
        headerName: 'Opt. Group',
        field: 'campaignGroup',
        filter: 'agNumberColumnFilter',
        cellClass: greyCellClass,
        minWidth: 150,
        hide: true,
      },
      {
        colId: ColumnId.BIDDING_ENTITY,
        headerName: 'Bidding Entities',
        field: 'biddingEntity',
        minWidth: 175,
        filter: 'agNumberColumnFilter',
        cellClass: greyCellClass,
        cellRenderer: ChipCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>): IChipCellRendererParams => {
          let color: MuiColorVariant;

          switch (params.data?.biddingEntity) {
            case BiddingEntity.PLACEMENT_PRODUCT_PAGE:
              color = MuiColorVariant.Primary;
              break;
            case BiddingEntity.PLACEMENT_REST_OF_SEARCH:
              color = MuiColorVariant.Primary;
              break;
            case BiddingEntity.PLACEMENT_TOP:
              color = MuiColorVariant.Primary;
              break;
            case BiddingEntity.KEYWORD:
              color = MuiColorVariant.Success;
              break;
            case BiddingEntity.PRODUCT_TARGET:
              color = MuiColorVariant.Success;
              break;
            default:
              color = MuiColorVariant.Success;
          }

          const value = params.data?.biddingEntity ? t(`optimizer_page.labels.${params.data.biddingEntity}`) : '';

          return {
            chipColor: color,
            chipLabel: value,
          };
        },
      },
      {
        colId: ColumnId.TARGETING,
        headerName: 'Targeting',
        field: 'targeting',
        filter: 'agNumberColumnFilter',
        cellClass: greyCellClass,
        tooltipField: 'targeting',
        minWidth: 130,
      },
      {
        colId: ColumnId.MATCH,
        headerName: 'Match Type',
        field: 'match',
        filter: 'agNumberColumnFilter',
        cellClass: greyCellClass,
        hide: true,
      },
      {
        colId: ColumnId.AD_GROUP,
        headerName: 'Ad Group',
        field: 'adGroup',
        filter: 'agNumberColumnFilter',
        cellClass: greyCellClass,
        hide: true,
      },
      {
        colId: ColumnId.OLD_VALUE,
        headerName: 'Old Value',
        field: 'oldValue',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        valueFormatter: formatBasedOnBiddingEntity,
      },
      {
        colId: ColumnId.NEW_VALUE,
        headerName: 'New Value',
        field: 'newValue',
        editable: true,
        type: 'numericColumn',
        cellRenderer: EditableCellRenderer,
        cellRendererParams: (params: ICellRendererParams) => {
          return {
            originalValue: params.data?.originalNewValue,
            valueFormatter: getFormatterBasedOnBiddingEntity(params),
          } as IEditiableCellRendererParams;
        },
        cellEditor: FormattedCellEditor,
        cellEditorParams: (params: FormattedCellEditorProps) => {
          return {
            displayNumberModifierFunc: getDisplayNumberModifierFuncBasedOnBiddingEntity(params),
            storingNumberModifierFunc: getStoringNumberModifierFuncBasedOnBiddingEntity(params),
            stopEditing: params.api.stopEditing,
          };
        },
        filter: 'agNumberColumnFilter',
      },
      {
        colId: ColumnId.DELTA,
        headerName: 'Delta',
        field: 'delta',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        minWidth: 105,
        valueFormatter: (params: { value: number }) => {
          return formatPercentWithArrow(params.value);
        },
        cellClass: (params: CellClassParams<PreviewDataRow, number>) => {
          if (!params.data) return '';

          const value = params.data.delta;
          return `${value >= 0 ? SentimentColors.GREEN : SentimentColors.RED}  text-right`;
        },
      },
      {
        colId: ColumnId.REASONS,
        headerName: 'Reasons',
        minWidth: 175,
        field: 'reasons',
        filter: 'agNumberColumnFilter',
        cellClass: greyCellClass,
        cellRenderer: ChipArrayCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>): IChipArrayCellRendererParams => {
          return {
            chipArrayChips: params.data?.reasons
              ? params.data.reasons.map((reason: OptimizationReason) => {
                  let color: AdLabsColorVariant;
                  switch (reason) {
                    case OptimizationReason.LOW_VISIBILITY:
                    case OptimizationReason.HIGH_SPEND:
                      color = MuiColorVariant.Success;
                      break;
                    case OptimizationReason.HIGH_ACOS:
                    case OptimizationReason.LOW_ACOS:
                    case OptimizationReason.USER_BID_CEILING:
                    case OptimizationReason.LOWEST_POSSIBLE_BID:
                    case OptimizationReason.CAMPAIGN_PERFORMANCE:
                    case OptimizationReason.PROFILE_PERFORMANCE:
                      color = TailwindColorVariant.VIOLET;
                      break;
                    case OptimizationReason.KEYWORD_BID_CEILING:
                    case OptimizationReason.CAMPAIGN_GROUP_BID_CEILING:
                    case OptimizationReason.CAMPAIGN_GROUP_PERFORMANCE:
                      color = TailwindColorVariant.ORANGE;
                      break;
                    case OptimizationReason.AD_GROUP_BID_CEILING:
                    case OptimizationReason.MAX_ONE_TIME_CHANGE:
                      color = TailwindColorVariant.PINK;
                      break;
                    case OptimizationReason.SMALLEST_POSSIBLE_INCREASE:
                    case OptimizationReason.LOWEST_POSSIBLE_ADJUSTMENT:
                      color = TailwindColorVariant.LIME;
                      break;
                    case OptimizationReason.HIGHEST_POSSIBLE_ADJUSTMENT:
                    case OptimizationReason.MAX_BID_DECREASE:
                      color = TailwindColorVariant.SKY;
                      break;
                    default:
                      color = TailwindColorVariant.SLATE;
                  }
                  return {
                    value: t(`optimizer_page.chips.${reason}`),
                    color,
                  };
                })
              : [],
          };
        },
        valueGetter: (params) => (params.data ? params.data.reasons : null),
      },
      {
        colId: ColumnId.LAST_OPTIMIZED,
        headerName: 'Last Optimized',
        field: 'lastOptimizedAt',
        filter: 'agDateColumnFilter',
        tooltipValueGetter: (params) => (params.value ? formatDateStringTimeNoSeconds(params.value) : 'Never'),
        valueFormatter: (params) => (params.value ? formatDateStringTimeNoSeconds(params.value) : 'Never'),
      },
      {
        colId: ColumnId.IMPRESSIONS,
        headerName: 'Impressions',
        field: 'impressions',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellClass: numericGreyCellClass,
        autoHeight: true,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: getCellRendererParamsThousandsSeparatorSynced('impressions'),
        valueGetter: (params) => (params.data ? params.data.impressions.current : null),
        headerComponent: ChangePercentageHeaderRenderer,
      },
      {
        colId: ColumnId.CLICKS,
        headerName: 'Clicks',
        field: 'clicks',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellClass: numericGreyCellClass,
        autoHeight: true,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: getCellRendererParamsThousandsSeparatorSynced('clicks'),
        headerComponent: ChangePercentageHeaderRenderer,
        valueGetter: (params) => (params.data ? params.data.clicks.current : null),
      },
      {
        colId: ColumnId.ACOS,
        headerName: 'ACOS',
        field: 'acos',
        autoHeight: true,
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellClass: numericGreyCellClass,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>) => {
          const metricField = MetricField.ACOS;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.acos,
          };
        },
        headerComponent: ChangePercentageHeaderRenderer,
        valueGetter: (params) => (params.data ? params.data.acos.current : null),
      },
      {
        colId: ColumnId.CTR,
        headerName: 'CTR',
        field: 'ctr',
        autoHeight: true,
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellClass: numericGreyCellClass,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>) => {
          const metricField = MetricField.CTR;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.ctr,
          };
        },
        headerComponent: ChangePercentageHeaderRenderer,
        valueGetter: (params) => (params.data ? params.data.ctr.current : null),
      },
      {
        colId: ColumnId.CVR,
        headerName: 'CVR',
        field: 'cvr',
        autoHeight: true,
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellClass: numericGreyCellClass,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>) => {
          const metricField = MetricField.CVR;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.cvr,
          };
        },
        headerComponent: ChangePercentageHeaderRenderer,
        valueGetter: (params) => (params.data ? params.data.cvr.current : null),
      },
      {
        colId: ColumnId.CPC,
        headerName: 'CPC',
        field: 'cpc',
        autoHeight: true,
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellClass: numericGreyCellClass,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>) => {
          const metricField = MetricField.CPC;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.cpc,
          };
        },
        headerComponent: ChangePercentageHeaderRenderer,
        valueGetter: (params) => (params.data ? params.data.cpc.current : null),
      },
      {
        colId: ColumnId.SPEND,
        headerName: 'Spend',
        field: 'spend',
        autoHeight: true,
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellClass: numericGreyCellClass,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>) => {
          const metricField = MetricField.SPEND;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.spend,
          };
        },
        headerComponent: ChangePercentageHeaderRenderer,
        valueGetter: (params) => (params.data ? params.data.spend.current : null),
      },
      {
        colId: ColumnId.SALES,
        headerName: 'Sales',
        field: 'sales',
        autoHeight: true,
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellClass: numericGreyCellClass,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>) => {
          const metricField = MetricField.SALES;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.sales,
          };
        },
        headerComponent: ChangePercentageHeaderRenderer,
        valueGetter: (params) => (params.data ? params.data.sales.current : null),
      },

      {
        colId: ColumnId.ROAS,
        headerName: 'ROAS',
        field: 'roas',
        autoHeight: true,
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellClass: numericGreyCellClass,
        hide: true,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: getCellRendererParamsThousandsSeparatorSynced('roas'),
        headerComponent: ChangePercentageHeaderRenderer,
        valueGetter: (params) => (params.data ? params.data.roas.current : null),
      },
      {
        colId: ColumnId.RPC,
        headerName: 'RPC',
        field: 'rpc',
        autoHeight: true,
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellClass: numericGreyCellClass,
        hide: true,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>) => {
          const metricField = MetricField.RPC;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.rpc,
          };
        },
        headerComponent: ChangePercentageHeaderRenderer,
        valueGetter: (params) => (params.data ? params.data.rpc.current : null),
      },
      {
        colId: ColumnId.CPA,
        headerName: 'CPA',
        field: 'cpa',
        autoHeight: true,
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellClass: numericGreyCellClass,
        hide: true,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>) => {
          const metricField = MetricField.CPA;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.cpa,
          };
        },
        headerComponent: ChangePercentageHeaderRenderer,
        valueGetter: (params) => (params.data ? params.data.cpa.current : null),
      },
      {
        colId: ColumnId.AOV,
        headerName: 'AOV',
        field: 'aov',
        autoHeight: true,
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellClass: numericGreyCellClass,
        hide: true,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>) => {
          const metricField = MetricField.AOV;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.aov,
          };
        },
        headerComponent: ChangePercentageHeaderRenderer,
        valueGetter: (params) => (params.data ? params.data.aov.current : null),
      },
      {
        colId: ColumnId.CPM,
        headerName: 'CPM',
        field: 'cpm',
        autoHeight: true,
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellClass: numericGreyCellClass,
        hide: true,
        cellRenderer: ChangePercentageCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>) => {
          const metricField = MetricField.CPM;
          return {
            currentValueFormatter: (value: number) => getLongFormatterForMetricField(metricField)(value),
            sentimentDirection: getSentimentByMetric(metricField),
            value: params.data?.cpm,
          };
        },
        headerComponent: ChangePercentageHeaderRenderer,
        valueGetter: (params) => (params.data ? params.data.cpm.current : null),
      },
    ];

    if (isAdminModeActive) {
      colDefsToReturn.unshift({
        colId: ColumnId.DEBUG,
        headerName: 'Debug',
        cellRenderer: ButtonPopoverCellRenderer,
        cellRendererParams: (params: ICellRendererParams<PreviewDataRow>) => {
          return {
            content: params.data
              ? `========== AD GROUP DATA ==========\n${JSON.stringify(adGroupToDebugDataMap[params.data.adGroup], null, 2)}` +
                `\n\n\n========== CAMPAIGN DATA ==========\n${JSON.stringify(campaignNameToDebugDataMap[params.data.campaignName], null, 2)}`
              : null,
          };
        },
        width: 100,
      });
    }

    if (!gridApi) {
      applyStateToDefinitions(colDefsToReturn);
      return colDefsToReturn;
    }

    const aggregates = getColumnAggregates(gridApi);
    if (!aggregates) {
      applyStateToDefinitions(colDefsToReturn);
      return colDefsToReturn;
    }
    const updatedColDefs = colDefsToReturn.map((colDef: ColDef<PreviewDataRow>) => {
      const field = colDef.field as MetricField;

      if (field === undefined || !isValidMetricField(field) || aggregates[field] === undefined) {
        return colDef;
      }

      const current = aggregates[field];
      return {
        ...colDef,
        headerComponentParams: {
          currentValue: getShortFormatterForMetricField(field)(current),
          currentValueDescription: t(`optimizer_page.labels.${getMetricReducer(field)}`),
        },
      };
    });

    applyStateToDefinitions(updatedColDefs);

    return updatedColDefs;
  }, [isAdminModeActive, optimizationResults.previewData]);

  // DEBUG DATA
  const adGroupToDebugDataMap = optimizationResults.adGroupDebugData.reduce(
    (map, dataRow) => {
      map[dataRow.adGroup] = dataRow;
      return map;
    },
    {} as { [key: string]: AdGroupDebugRow },
  );

  const campaignNameToDebugDataMap = optimizationResults.campaignDebugData.reduce(
    (map, dataRow) => {
      map[dataRow.campaignName] = dataRow;
      return map;
    },
    {} as { [key: string]: CampaignDebugRow },
  );

  // Pass selected fields values to parent for checked rows //TODO: optimize
  const handleRowSelection = useCallback((event: SelectionChangedEvent<PreviewDataRow>) => {
    let containsRecentlyOptimized = false;
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

    const optimizationData: OptimizationApplyData[] = event.api.getSelectedRows().map((selectedOptimization) => {
      // Check if this row has been optimized today
      if (selectedOptimization.lastOptimizedAt) {
        const lastOptimizedDate = new Date(selectedOptimization.lastOptimizedAt).toISOString().split('T')[0];
        if (lastOptimizedDate === today) {
          containsRecentlyOptimized = true;
        }
      }

      return {
        id: selectedOptimization.id,
        new_value: selectedOptimization.newValue,
        bidding_entity: selectedOptimization.biddingEntity,
        old_value: selectedOptimization.oldValue,
        reasons: selectedOptimization.reasons,
      };
    });

    // Update the states
    setOptimizationPreviewSelection(optimizationData);
    setSelectionContainsRecentlyOptimized(containsRecentlyOptimized);
  }, []);

  // Delta cell calculation
  const onCellValueChanged = useCallback((event: CellValueChangedEvent<PreviewDataRow>) => {
    event.data.newValue = event.newValue;
    event.data.delta = event.data.oldValue != 0 ? event.newValue / event.data.oldValue - 1 : 0;
    event.api.applyTransaction({ update: [event.data] });
  }, []);

  const applyExternalFilters = () => {
    if (gridApi) {
      gridApi.setIsExternalFilterPresent(() => true);
      gridApi.setDoesExternalFilterPass((node: IRowNode<PreviewDataRow>) => {
        if (!node || !node.data || !externalFilters || externalFilters.length === 0) {
          return true;
        }
        return !externalFilters.some((filter) => {
          if (!node.data) {
            return true;
          }
          return !filter.doesFilterPass(node.data);
        });
      });
      gridApi.onFilterChanged();
      setVisibleRowCount(gridApi.getDisplayedRowCount());
      gridApi.deselectAll();
    }
  };

  useEffect(() => {
    setOptimizationPreviewSelection([]);
    applyExternalFilters();
  }, [externalFilters]);

  type MetricAggregates = { [key in MetricField]: number };

  const onGridReady = (params: GridReadyEvent<PreviewDataRow>) => {
    setGridApi(params.api);
    applyExternalFilters();
    setVisibleRowCount(params.api.getDisplayedRowCount());
    setColumnAPI(params.columnApi);
  };

  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  const customGridOptions: GridOptions<PreviewDataRow> = useMemo(
    () => ({
      ...DEFAULT_GRID_OPTIONS,
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 100,
      },
      maintainColumnOrder: true,
      onCellValueChanged: onCellValueChanged,
      onSelectionChanged: handleRowSelection,

      // Column state management
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,

      components: { editableCellRenderer: EditableCellRenderer },
    }),
    [onCellValueChanged, handleRowSelection],
  );

  const tableHeight = `calc(100vh - 74px - 120px)`;
  return (
    <>
      {isAdminModeActive ? (
        <Accordion className="mb-2 rounded-lg">
          <AccordionSummary className="pl-8">General debug data</AccordionSummary>
          <AccordionDetails>
            <div className="flex flex-row pl-8">
              <div>
                <strong>Campaign group</strong>
                <br />
                <pre className="text-xs">{JSON.stringify(optimizationResults.campaignGroupDebugData, null, 2)}</pre>
              </div>
              <div>
                <strong>Profile</strong>
                <br />
                <pre className="text-xs">{JSON.stringify(optimizationResults.profileDebugData, null, 2)}</pre>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ) : null}
      <div style={{ height: tableHeight }}>
        <AlGrid
          gridOptions={customGridOptions}
          colDefs={columnDefs}
          rowData={optimizationResults.previewData}
          onGridReadyCallback={onGridReady}
        />
      </div>
    </>
  );
};

export default OptimizationTable;
